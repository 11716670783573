import { User } from 'src/models'
import { AllEventMask } from 'src/models/events'
import { DailyCall } from '@daily-co/daily-js'
import { createSlice } from '@reduxjs/toolkit'
import { callReducers as reducers } from './reducers'

export type CallState = {
  callee: User | undefined
  startTime: Date | undefined
  callObject: DailyCall | undefined
  event: AllEventMask | undefined
}

const initialState: CallState = {
  callee: undefined,
  startTime: undefined,
  callObject: undefined,
  event: undefined,
}

export const slice = createSlice({
  name: 'call',
  initialState,
  reducers,
})

export const { actions: callReducers } = slice

export const callSliceReducer = slice.reducer
