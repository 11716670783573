import xor from 'lodash/xor'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { FacilityChip } from 'src/components/FacilityChip'
import { CheckboxGroupField } from 'src/components/inputs/CheckboxGroupField'
import { CustomSelect } from 'src/components/inputs/CustomSelect'
import { getCustomers } from 'src/features/Veterinary/selectors'
import { Facility } from 'src/models'
import { getEventStatuses } from 'src/models/events/constants'
import { RequestsFilters, RequestsFiltersClearState } from 'src/models/filters/RequestsFilters'

type FormRequestsFiltersProps = {
  loading: boolean
  facilities: Facility[]
  defaultValues: RequestsFilters
  showAction?: boolean
  className?: string
  familyId?: string
  onChange: (opts: any) => any
}

export const FormRequestsFiltersMobile: React.FC<FormRequestsFiltersProps> = ({
  facilities,
  className = '',
  onChange,
  defaultValues = RequestsFiltersClearState,
}) => {
  const { t } = useTranslation()
  const statuses = getEventStatuses()
  const customers = useSelector(getCustomers)

  const [state, setState] = useState<RequestsFilters>(defaultValues)

  const toggleFacilities = (value: string) => mergeState({ facilities: xor(state.facilities, [value]) })

  const mergeState = (data: any) => {
    const newState = {
      ...state,
      ...data,
    }
    setState(new RequestsFilters(newState))
    onChange(new RequestsFilters(newState))
  }

  return (
    <section className={className}>
      <div className="text-body-large text-black mb-2">{t('LABEL_FOR_FACILITIES')}</div>
      <div className="flex flex-col w-full space-y-1">
        {facilities.map((facility) => (
          <div key={facility.id} className="flex">
            <FacilityChip
              facility={facility}
              active={state.facilities.includes(facility.id)}
              onClick={() => toggleFacilities(facility.id)}
            />
          </div>
        ))}
      </div>
      <CheckboxGroupField
        values={statuses}
        defaultValues={state.statuses}
        label={t('LABEL_FOR_STATUS')}
        onChange={(statuses) => mergeState({ statuses })}
      />

      <div className="text-body-large text-black mt-6">{t('LABEL_FOR_CUSTOMERS')}</div>
      <CustomSelect
        ionicStyle
        className="w-full border-grey-300"
        onChange={(customer: string) => mergeState({ customer })}
        value={state.customer}
        placeholder={t('FIELD_TYPE_PLACEHOLDER')}
        searchable
        options={[
          { value: undefined, label: '' },
          ...customers.map((customer) => ({ value: customer.FamilyId, label: customer.fullName })),
        ]}
      />
    </section>
  )
}
