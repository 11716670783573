import React, { forwardRef, ReactElement } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { object, string } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { IonButton, IonItem, IonSpinner } from '@ionic/react'
import { LoginParameters } from '../../features/Auth/store/types'
import { InputField } from '../inputs/InputField'
import { InputPassword } from '../inputs/InputPassword'
import { FormPropsType } from './Form'

export type FormLoginParams = {
  onSubmit: (parameters: LoginParameters) => void
} & FormPropsType

/**
 * @description FormLogin
 *
 * @param onSubmit
 * @param className
 * @constructor
 */
export const LoginForm = forwardRef<HTMLFormElement, FormLoginParams>(
  ({ onSubmit, isSubmitting = false, className = '' }: FormLoginParams, ref): ReactElement => {
    const { t } = useTranslation()

    const validationSchema = object().shape({
      email: string().required(t('FIELD_REQUIRED_EMAIL')).email(t('FIELD_INVALID_EMAIL')),
      password: string().required(t('FIELD_REQUIRED_PASSWORD')),
    })

    const {
      register,
      handleSubmit,
      formState: { errors },
      setValue,
    } = useForm({
      resolver: yupResolver(validationSchema),
    })

    return (
      <form onSubmit={handleSubmit(onSubmit)} className={`w-full flex flex-col ${className}`} ref={ref}>
        <fieldset disabled={isSubmitting}>
          <InputField
            label={t('FORM_LABEL_EMAIL')}
            placeholder={t('FORM_LABEL_EMAIL_PLACEHOLDER')}
            type="email"
            {...register('email')}
            error={errors.email && errors.email.message}
            onIonChange={(e: any) => setValue('email', e.target.value)}
          />

          <InputPassword
            label={t('FORM_LABEL_PASSWORD')}
            placeholder="*******"
            {...register('password')}
            error={errors.password && errors.password.message}
            onIonChange={(e: any) => setValue('password', e.target.value)}
          />
        </fieldset>

        <div className="flex justify-end">
          <IonItem color="link-primary" routerLink="/password/reset" routerDirection="forward" className="font-bold">
            {t('FORGOT_PASSWORD')}
          </IonItem>
        </div>
        <div className="flex flex-col flex-grow justify-end md:block pt-4">
          <IonButton expand="block" type="submit" disabled={isSubmitting} className="md:inline-block">
            {!isSubmitting ? t('FORM_ACTION_REGISTER') : <IonSpinner />}
          </IonButton>
        </div>
      </form>
    )
  }
)
