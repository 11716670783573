import { isSameDay } from 'date-fns'
import { GlobalStoreState } from 'src/Store'
import { Storage, STORAGE_KEY_CURRENT_FACILITY_ID } from 'src/helpers/Storage'
import { Facility, User } from 'src/models'
import { Conversation } from 'src/models/events/Conversation'
import { EventStatus, EventCategory } from 'src/models/events/enums'
import { calendarEventValidator } from 'src/validators/calendarEventValidators'

export const currentFacilitySelector = ({ auth, facility }: GlobalStoreState): Facility => {
  const isVet = auth.user?.isVet || false
  let facilities = []

  if (!isVet) {
    facilities = auth.user?.vetFacilities || []
  } else {
    facilities = auth.user?.facilities || []
  }

  if (facility.currentFacility?.id) {
    return facility.currentFacility
  }

  const facilityId = Storage.get(STORAGE_KEY_CURRENT_FACILITY_ID)
  let defaultFacility = facilities.find((facility) => facility.id === facilityId)

  if (!defaultFacility && facilities.length) {
    defaultFacility = facilities[0]
    Storage.set(STORAGE_KEY_CURRENT_FACILITY_ID, defaultFacility.id)
  }

  return defaultFacility || new Facility({})
}
export const facilitiesSelector = ({ auth }: GlobalStoreState): Facility[] => auth.user?.facilities || []

export const facilitiesToBeCompletedSelector = ({ auth }: GlobalStoreState): Facility[] => auth.user?.facilitiesToBeCompleted || []

export const facilitiesCount = ({ auth }: GlobalStoreState): number => auth.user?.facilities.length || 0

export const facilityClientsSelector = ({ facility }: GlobalStoreState): User[] => facility.petOwners || []

export const facilityClientsCountSelector = ({ facility }: GlobalStoreState): number => facility.petOwners.length

export const facilityValidClientsSelector = ({ facility }: GlobalStoreState): number =>
  facility.petOwners.filter((user) => user.pets.length > 0 && user.status === 'active').length

export const facilityRequests = ({ facility }: GlobalStoreState): Conversation[] =>
  (facility.requests || []).filter((request) => request?.parentEvent?.category === EventCategory.EVENT_CATEGORY_HEALTH_CONSULTATION)

export const facilityTodayAppointments = ({ facility }: GlobalStoreState): Conversation[] =>
  (facility.requests || []).filter(
    (request) =>
      (request?.parentEvent?.category === EventCategory.EVENT_CATEGORY_HEALTH_VISIT ||
        request?.parentEvent?.category === EventCategory.EVENT_CATEGORY_HEALTH_VIDEOCONSULTATION) &&
      (request?.children || []).filter(
        (children) => calendarEventValidator(children) && isSameDay(children.payload?.event?.start, new Date())
      ).length > 0
  )

export const facilityVeterinarians = ({ facility }: GlobalStoreState): User[] => facility.veterinarians || []

export const facilityVeterinarian = ({ facility }: GlobalStoreState, vetId: string): User | undefined =>
  (facility.veterinarians || []).find((veterinarian) => veterinarian.id === vetId)

export const facilityRequestsToManage = ({ facility }: GlobalStoreState): number =>
  facility.requests.filter(
    (request) =>
      request?.parentEvent?.category === EventCategory.EVENT_CATEGORY_HEALTH_CONSULTATION &&
      request.parentEvent.payload.status === EventStatus.STATUS_NEW
  ).length

export const facilityLoadedSelector = ({ facility }: GlobalStoreState): boolean => facility.loaded || false

export const facilityCalendarEventFromParentId = ({ facility }: GlobalStoreState, parentId: string) =>
  ((facility?.requests ?? []).find((request) => request?.id === parentId)?.children ?? []).find(calendarEventValidator)
