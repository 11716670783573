import isPlainObject from 'lodash/isPlainObject'
import { User } from 'src/models'
import { axiosInstance } from './axios'

export class UsersAPI {
  static async getCurrentUser(): Promise<User> {
    return axiosInstance
      .get(`/users/me`, { headers: { SkipCache: true, ClearAllCache: true } })
      .then((response: any) => new User(response.data.currentUser))
  }

  static getUsers(userIds: string[]): Promise<User[]> {
    const ids = userIds.join(',')

    if (ids.length === 0) {
      return Promise.resolve([])
    }

    return axiosInstance.get(`/users?id=${ids}`).then(({ data }) => {
      if (isPlainObject(data)) {
        return [new User(data)]
      }

      const users = data || []
      return users.map((u: any) => new User(u))
    })
  }
  static getUser<T extends User>(model: new (data: any) => T, id: string, facilityId: string): Promise<T> {
    return axiosInstance.get(`/users/?id=${id}&idFacility=${facilityId}`).then((response: any) => new model(response?.data))
  }

  static addUser(user: User): Promise<User> {
    return axiosInstance
      .post(`/users`, {
        email: user.email,
        firstName: user.firstName || '',
        lastName: user.lastName || '',
        isVet: user.isVet,
        isPetowner: user.isPetOwner,
        marketing: user.marketing,
        thirdparties: user.thirdparties,
      })
      .then((response: any) => new User(response.data))
  }
  static updateCurrentUser(user: User, image: any = null): Promise<any> {
    const formData = new FormData()
    formData.append('firstName', user.firstName)
    formData.append('lastName', user.lastName)
    formData.append('address', user.address || '')
    formData.append('city', user.city || '')
    formData.append('country', user.country || '')
    formData.append('phone', user.phone || '')
    formData.append('mobile', user.mobile || '')
    formData.append('taxNumber', user.taxNumber || '')
    formData.append('postcode', user.postcode || '')
    formData.append('region', user.region || '')

    if (image) {
      formData.append('image', image)
    }

    return axiosInstance.patch('/users', formData, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    })
  }

  static welcomeEmail(user: User) {
    return axiosInstance.post('/welcome', {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
    })
  }

  static getFacilityTypes() {
    return axiosInstance.get('/facilities/types').then((response: any) => response.data)
  }

  static setPushNotificationsToken(userId: string, token: string) {
    return axiosInstance.post(`/token?UserId=${userId}&token=${token}`)
  }

  static deletePushNotificationsToken(userId: string) {
    return axiosInstance.delete(`/token?UserId=${userId}`)
  }
}
