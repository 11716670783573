import { PushNotifications, Token } from '@capacitor/push-notifications'

export const pushNotificationsInit = async (callBack: (token: Token) => void) => {
  console.log('Initializing HomePage')

  // On success, we should be able to receive notifications
  PushNotifications.addListener('registration', (token: Token) => {
    console.log('Push registration success, token: ' + token.value)
    callBack(token)
  }).catch((error) => console.log(error))

  // Some issue with our setup and push will not work
  PushNotifications.addListener('registrationError', (error: any) => {
    // alert('Error on registration: ' + JSON.stringify(error))
    console.error(error)
  }).catch((error) => console.error(error))

  PushNotifications.addListener('pushNotificationReceived', (notification) => {
    console.log('Push notification received: ', notification.title, ' ', notification.body)
  })

  const registerNotifications = async () => {
    let permStatus = await PushNotifications.checkPermissions()

    if (permStatus.receive === 'prompt') {
      permStatus = await PushNotifications.requestPermissions()
    }

    if (permStatus.receive !== 'granted') {
      throw new Error('User denied permissions!')
    }

    await PushNotifications.register()
  }

  await registerNotifications()
}

export const removePushNotificationListeners = async () => {
  await PushNotifications.removeAllListeners()
}
