import { User } from './User'
import { BaseModel } from './partials/BaseModel'

export class Facility extends BaseModel {
  id: string
  FacilityTypeId: string
  code: string
  name: string
  address?: string
  streetNumber?: string
  postcode?: string
  city?: string
  region?: string
  country?: string
  businessName?: string
  vat?: string
  email?: string
  website?: string
  phone?: string
  mobile?: string
  ChargebeeCustomerId?: string
  OrganizationId: string
  MainServices?: any
  paymentEnabled: boolean
  UserFacility?: any
  clients: User[]

  constructor(data: { [key: string]: any }) {
    super(data)
    this.id = data.id
    this.FacilityTypeId = data.FacilityTypeId || ''
    this.code = data.code || ''
    this.name = data.name || ''
    this.address = data.address?.address || ''
    this.streetNumber = data.address?.streetNumber || ''
    this.postcode = data.address?.postcode || ''
    this.city = data.address?.city || ''
    this.region = data.address?.region || ''
    this.country = data.address?.country || ''
    this.businessName = data.businessName || ''
    this.vat = data.vat || ''
    this.email = data.address?.email || ''
    this.website = data.address?.website || ''
    this.phone = data.address?.phone || ''
    this.mobile = data.address?.mobile || ''
    this.clients = data.clients || []
    this.UserFacility = data.UserFacility || {}
    this.paymentEnabled = false
    this.ChargebeeCustomerId = data.ChargebeeCustomerId || ''
    this.OrganizationId = data.OrganizationId || ''
    this.MainServices = data.mainServices || ''
  }

  toFormData() {
    const formData = new FormData()
    formData.append('name', this.name)

    if (this.id) {
      formData.append('facilityId', this.id)
    }

    if (this.FacilityTypeId) {
      formData.append('facilityTypeId', this.FacilityTypeId)
    }
    if (this.address) {
      formData.append('address', this.address)
    }
    if (this.streetNumber || this.streetNumber === '') {
      if (this.streetNumber === '') {
        formData.append('streetNumber', '')
      } else {
        formData.append('streetNumber', this.streetNumber)
      }
    }
    if (this.postcode) {
      formData.append('postcode', this.postcode)
    }
    if (this.city) {
      formData.append('city', this.city)
    }
    if (this.region) {
      formData.append('region', this.region)
    }
    if (this.country) {
      formData.append('country', this.country)
    }
    if (this.businessName) {
      formData.append('businessName', this.businessName)
    }
    if (this.vat) {
      formData.append('vat', this.vat)
    }
    if (this.phone) {
      formData.append('phone', this.phone)
    }
    if (this.mobile) {
      formData.append('mobile', this.mobile)
    }
    if (this.email) {
      formData.append('email', this.email)
    }
    if (this.website || this.website === '') {
      if (this.website === '') {
        formData.append('website', '')
      } else {
        formData.append('website', this.website)
      }
    }

    return formData
  }

  get initials(): string {
    return this.name.substring(0, 2)
  }
}
