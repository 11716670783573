import { useSelector } from 'react-redux'
import { getCallee, getCallObject, getEvent, getStartTime } from 'src/features/Call/selectors'
import { currentUserSelector } from '../features/Auth/store/selectors'
import {
  currentFacilitySelector,
  facilitiesSelector,
  facilitiesToBeCompletedSelector,
  facilityClientsCountSelector,
  facilityClientsSelector,
  facilityLoadedSelector,
  facilityRequests,
  facilityRequestsToManage,
  facilityValidClientsSelector,
} from '../features/Facility/store/selectors'
import {
  familySelector,
  familyConversationsSelector,
  familyPetsSelector,
  familyOwnerSelector,
  isFamilyLoadingSelector,
  familyPastConversationsSelector,
  isFamilyConversationLoadingSelector,
} from '../features/Family/store/selectors'
import { familyThunks } from '../features/Family/store/thunks'

export const useCurrentUser = () => useSelector(currentUserSelector)

export const useCurrentValues = () => ({
  currentUser: useSelector(currentUserSelector),
  currentFacility: useSelector(currentFacilitySelector),
  isFacilityLoaded: useSelector(facilityLoadedSelector),
  facilities: useSelector(facilitiesSelector),
  facilitiesToBeCompleted: useSelector(facilitiesToBeCompletedSelector),
  currentFamily: useSelector(familySelector),
})

export const useCurrentFacility = () => ({
  facility: useSelector(currentFacilitySelector),
  petOwners: useSelector(facilityClientsSelector),
  activePetOwners: useSelector(facilityValidClientsSelector),
  totalPetOwners: useSelector(facilityClientsCountSelector),
  requests: useSelector(facilityRequests),
  requestsCount: useSelector(facilityRequestsToManage),
})

export const useCurrentFamily = () => ({
  isLoading: useSelector(isFamilyLoadingSelector),
  family: useSelector(familySelector),
  pets: useSelector(familyPetsSelector),
  owner: useSelector(familyOwnerSelector),
  reloadFamily: familyThunks.updateFamily,
})

export const useFamilyConversations = () => ({
  isLoadingConversations: useSelector(isFamilyConversationLoadingSelector),
  conversations: useSelector(familyConversationsSelector),
  pastConversations: useSelector(familyPastConversationsSelector),
})

export const useCurrentCall = () => ({
  callObject: useSelector(getCallObject),
  startTime: useSelector(getStartTime),
  callee: useSelector(getCallee),
  event: useSelector(getEvent),
})
