import React from 'react'
import { Pet } from 'src/models'
import { Avatar } from '../Avatar'
import { SquaredCard } from './SquaredCard'

type PetCardProps = {
  pet: Pet
  actionName?: string | null
  onClick?: () => void
}

export const PetCard: React.FC<PetCardProps> = ({ pet, actionName = null, ...props }) => (
  <SquaredCard contentClass="flex flex-col justify-center items-center" {...props}>
    <Avatar className="h-12 w-12" image={pet.avatar} title={pet.name.substring(0, 2)} />
    <span className="text-body-large font-bold text-black mt-1">{pet.name}</span>
    {actionName ? <span className="text-body-small text-primary-500 mt-1 font-bold">{actionName}</span> : null}
  </SquaredCard>
)
