import { FC, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { CallAPI } from 'src/api/CallAPI'
import { Call } from 'src/components/call/Call'
import { Tray } from 'src/components/call/Tray'
import { IconClose, IconCollapse } from 'src/components/icons'
import { callThunks } from 'src/features/Call/thunks'
import { useCurrentCall, useCurrentUser } from 'src/hooks/useCurrentValues'
import { useLoader } from 'src/hooks/useLoader'
import { AllEventMask } from 'src/models/events'
import DailyIframe from '@daily-co/daily-js'
import { IonContent, IonPage, IonFooter, IonButton } from '@ionic/react'
import { NavHeader } from '../components/Header/NavHeader'

export const ModalCall: FC<{ event?: AllEventMask; onDismiss: () => void }> = ({ event, onDismiss }) => {
  const { callObject, callee } = useCurrentCall()
  const dispatch = useDispatch()
  const [showLoading, hideLoading] = useLoader()
  const currentUser = useCurrentUser()

  const vetName = callee?.fullName

  const start = async () => {
    try {
      if (!callObject) {
        return
      }

      if (event && event.payload.videomeeting && event.payload?.videomeeting.status === 'CLOSED') {
        return
      }

      if (event && event.payload.videomeeting && event.payload.videomeeting.status != 'CLOSED') {
        showLoading()

        const { room } = await CallAPI.getVideomeeting(event.payload.videomeeting.name)

        callObject.accessState().access && (await callObject.preAuth(room))
        await callObject.startCamera()
        await callObject.join({ url: room.url })

        callObject
          .requestAccess({
            name: currentUser.fullName,
            access: {
              level: 'full',
            },
          })
          .then(() => dispatch(callThunks.setStartTime(new Date())))
      }
    } catch (error) {
      console.error(error)
    } finally {
      hideLoading()
    }
  }

  const closeCallAndDismiss = () => {
    callObject?.leave()
    dispatch(callThunks.setCallObject(undefined))
    dispatch(callThunks.setStartTime(undefined))
    dispatch(callThunks.setCallee(undefined))
    dispatch(callThunks.setEvent(undefined))
    onDismiss()
  }

  useEffect(() => {
    if (callObject) return

    const newCallObject = DailyIframe.createCallObject()
    dispatch(callThunks.setCallObject(newCallObject))
    dispatch(callThunks.setCallee(event?.Assignee || undefined))
    dispatch(callThunks.setEvent(event || undefined))
  }, [])

  useEffect(() => {
    start()
  }, [callObject])

  return (
    <IonPage>
      <NavHeader
        title={vetName}
        leftActions={
          <IonButton className="pl-2 link" fill="clear" onClick={onDismiss}>
            <IconCollapse className="text-primary-500 w-8 h-8" />
          </IonButton>
        }
        showBackButton={false}
      >
        <IonButton className="link" fill="clear" onClick={closeCallAndDismiss}>
          <IconClose className="text-primary-500 w-8 h-8" />
        </IonButton>
      </NavHeader>
      <IonContent>
        <>
          {callObject && (
            <Call
              className={'h-full'}
              timerClassName="absolute top-10 w-max bg-black text-white font-bold px-2 py-1 rounded-lg text-h400 -mt-4"
              remoteParticipantClassName="flex justify-center h-full py-2"
              localParticipantClassName="absolute mx-6 bottom-0 right-0 w-36 h-36 flex justify-center items-center"
            />
          )}
        </>
      </IonContent>
      <IonFooter className="bg-transparent flex flex-col">
        <Tray className="text-black flex-none h-24 flex justify-center items-center rounded" onClose={closeCallAndDismiss} />
      </IonFooter>
    </IonPage>
  )
}
