import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { LoginForm } from 'src/components/forms/LoginForm'
import { FullLogo } from 'src/components/icons/Logos'
import { authLoadingSelector } from 'src/features/Auth/store/selectors'
import { authThunks } from 'src/features/Auth/store/thunks'
import { LoginParameters } from 'src/features/Auth/store/types'
import { useToast } from 'src/hooks/useToast'
import { Capacitor } from '@capacitor/core'
import { IonContent, IonPage, IonRouterLink } from '@ionic/react'
import { AppRouterHelper } from '../services/AppRouterHelper'

export const PageLogin = (): ReactElement => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const toast = useToast()
  const isSubmitting = useSelector(authLoadingSelector)
  const history = useHistory()

  const onSubmit = async ({ email, password }: LoginParameters) => {
    try {
      await dispatch(authThunks.login(email, password, false, true))
      Capacitor.isNativePlatform() && (await dispatch(authThunks.updatePushNotificationToken()))
      history.push(AppRouterHelper.ROUTES.ROOT)
    } catch ({ message }) {
      toast.error(t(message as string))
    }
  }

  return (
    <IonPage>
      <IonContent>
        <div className="w-full h-full flex flex-col justify-between">
          <div className="mobile-splash-logo py-16 flex items-center justify-center">
            <FullLogo className="h-12 pl-0 mb-0" />
          </div>
          <div className="w-full flex flex-col flex-grow">
            <h1 className="text-left">{t('TITLE_PAGE_LOGIN')}</h1>
            <LoginForm className="h-full" onSubmit={onSubmit} isSubmitting={isSubmitting} />
          </div>
          <div className="py-10 text-center">
            <IonRouterLink color="primary" routerLink="/sign-up" className="text-center">
              {t('REGISTER')}
            </IonRouterLink>
          </div>
          {process.env.REACT_APP_VERSION && (
            <div className="text-body-tiny text-center text-grey-400 py-2">{`Veterly v${process.env.REACT_APP_VERSION}`}</div>
          )}
        </div>
      </IonContent>
    </IonPage>
  )
}
