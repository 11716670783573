import Amplify from 'aws-amplify'
import TimeAgo from 'javascript-time-ago'
import it from 'javascript-time-ago/locale/it'
import * as yup from 'yup'
import { setupConfig } from '@ionic/react'
import { yupLocale } from './yup-locale'

// @see https://github.com/react-hook-form/react-hook-form/discussions/3808
yup.setLocale(yupLocale)

TimeAgo.addDefaultLocale(it)

setupConfig({
  animated: true,
  rippleEffect: false,
  mode: 'md',
})

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID,
    mandatorySignIn: true,
  },
})
