import { EventStatus } from '../events/enums'

export interface RequestsFiltersInterface {
  facilities: string[]
  statuses: string[]
  customer?: string
}

export class RequestsFilters {
  facilities: string[]
  statuses: string[]
  customer?: string

  constructor(data: RequestsFiltersInterface) {
    this.facilities = data.facilities || []
    this.statuses = data.statuses || []
    this.customer = data.customer || undefined
  }

  areClear(): boolean {
    return this.facilities.length == 0 && this.statuses.length == 0 && !this.customer
  }

  getCount(): number {
    return this.facilities.length + this.statuses.length + (this.customer ? 1 : 0)
  }
}

export const RequestsFiltersClearState = new RequestsFilters({ facilities: [], statuses: [] })
export const RequestsFiltersDefaultState = new RequestsFilters({
  facilities: [],
  statuses: [EventStatus.STATUS_NEW, EventStatus.STATUS_OPEN],
})
