import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useCurrentValues } from 'src/hooks/useCurrentValues'
import { Facility } from 'src/models/Facility'
import { FacilityService } from 'src/models/FacilityService'
import { HealthEvent } from 'src/models/events'
import { EventSuggestion } from 'src/models/events/EventSuggestion'
import { EventCategory } from 'src/models/events/enums'
import { BotActionMessage } from '../BotActionMessage'
import { BotAvailability } from './BotAvailability'
import { BotChooseEventCategory } from './BotChooseEventCategory'
import { BotChooseEventServices } from './BotChooseEventServices'
import { BotFacilitySelection } from './BotFacilitySelection'
import { BotPetSelection } from './BotPetSelection'
import { BotMessageProps } from './constants'

export type CreateConversationState = {
  step: number
  category?: EventCategory
  pet?: any
  facility?: string
  services: FacilityService[]
  suggestion?: EventSuggestion
  action?: string
}

type BotCreateConversationProps = BotMessageProps & {
  category?: EventCategory
  step?: number
  onStepChanged?: () => void
  onFinish?: (state: CreateConversationState) => void
}

export const BotCreateConversation: React.FC<BotCreateConversationProps> = ({
  event,
  category,
  step,
  onSuccess,
  onStepChanged,
  onFinish,
}) => {
  const { t } = useTranslation()
  const [state, setState] = useState<CreateConversationState>({
    step: step || 1,
    pet: event.Pets?.length == 1 ? event.Pets[0].id : '' || '',
    facility: event.Facility?.id || '',
    services: [],
    category,
  })
  const selectedPet = event.Pets.find((pet) => pet.id === state.pet)
  const { currentUser } = useCurrentValues()
  const facilities = currentUser.vetFacilities

  event.category = state.category
  event.payload.facilityId = state.facility
  event.payload.pets = state.pet ? [state.pet] : []
  event.payload.services = state.services

  const onCategorySelected = (category: EventCategory, services: any, action: string) => {
    setState({ ...state, step: 4, category, services, action })

    if (category === EventCategory.EVENT_CATEGORY_HEALTH_CONSULTATION && action === 'CHAT') {
      onSuccess && onSuccess(event as HealthEvent)
      onFinish && onFinish({ ...state, step: state.step > 4 ? state.step : 3, category: category, services: services, action: action })
    }
  }

  const onServicesSelected = (services: FacilityService[]) => setState({ ...state, step: 5, services })
  const onPetSelection = (petId: string) => {
    setState({ ...state, step: 3, pet: petId })

    /* if (state.category === EventCategory.EVENT_CATEGORY_HEALTH_CONSULTATION) {
      onSuccess && onSuccess(event as HealthEvent)
      onFinish && onFinish({ ...state, pet: petId })
    } */
  }

  const onFacilitySelection = (facilityId: string) => setState({ ...state, step: 2, facility: facilityId })

  const onAvailabilitySelected = (suggestion: EventSuggestion) => {
    setState({
      ...state,
      suggestion,
    })

    /*
     * Generate Visit Event and child events (like calendar event)
     */
    onFinish && onFinish({ ...state, suggestion })
  }
  useEffect(() => onStepChanged && onStepChanged(), [state, setState])

  return (
    <div className="flex flex-col space-y-4 pl-6">
      <BotFacilitySelection
        event={event}
        facilities={facilities}
        category={state.category}
        facilityId={state.facility}
        onSelectedFacility={onFacilitySelection}
      />
      {state.step > 1 && <BotPetSelection event={event} category={state.category} petId={state.pet} onSelectedPet={onPetSelection} />}
      {state.step > 2 && (
        <BotChooseEventCategory
          event={event}
          onSelect={onCategorySelected}
          userFirstName={event.User?.firstName || ''}
          facilityId={state.facility || ''}
          facilities={facilities}
          selectedCategory={state.category}
        />
      )}
      {state.services.length == 0 && state.action === 'APPOINTMENT' && state.step > 3 && (
        <BotChooseEventServices
          event={event}
          petName={selectedPet?.name || ''}
          listOfServicesToBeChoose={
            facilities.find((element: any) => element.id === state.facility)?.MainServices[state.category || 'VISIT'].services || ''
          }
          onSelect={onServicesSelected}
        />
      )}
      {state.action === 'CHAT' && state.step > 3 && (
        <BotActionMessage
          message={t('BOT_CONSULTATION_EVENT_ASSIGNED_TO_FACILITY_MESSAGE', {
            facility: facilities.find((facility: Facility) => facility.id == state.facility)?.name,
          })}
        />
      )}
      {state.action === 'APPOINTMENT' && state.services.length > 0 && state.step == 4 && (
        <BotAvailability event={event} services={state.services} petId={state.pet || ''} onSuggestionSelected={onAvailabilitySelected} />
      )}
      {state.action === 'APPOINTMENT' && state.step == 5 && (
        <BotAvailability event={event} services={state.services} petId={state.pet || ''} onSuggestionSelected={onAvailabilitySelected} />
      )}
    </div>
  )
}
