import React, { forwardRef, ReactElement } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { object, string } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { IonButton, IonSpinner } from '@ionic/react'
import { IconMail } from '../icons/IconMail'
import { InputField } from '../inputs/InputField'
import { FormPropsType } from './Form'

export type FormEmailConfirmParams = {
  onSubmit: ({ code }: { code: string }) => void
  onResendSubmit: () => void
} & FormPropsType

export const FormEmailConfirm = forwardRef<HTMLFormElement, FormEmailConfirmParams>(
  (
    { onSubmit, onResendSubmit, className = '', isSubmitting = false, showSubmit = true, ...rest }: FormEmailConfirmParams,
    ref
  ): ReactElement => {
    const { t } = useTranslation()

    const validationSchema = object().shape({
      code: string().required(t('FIELD_REQUIRED_CODE')),
    })

    const { register, handleSubmit } = useForm({
      mode: 'onBlur',
      reValidateMode: 'onBlur',
      resolver: yupResolver(validationSchema),
    })

    return (
      <form className={`h-full flex flex-col justify-between ${className}`} ref={ref} onSubmit={handleSubmit(onSubmit)} {...rest}>
        <fieldset disabled={isSubmitting}>
          <IconMail className="inline-block mt-8 md:mt-20 text-primary-500" />
          <h1 className="mt-8 mb-6">{t('PAGE_VERIFY_EMAIL_SUBTITLE')}</h1>
          <p className="text-body-large">{t('PAGE_VERIFY_EMAIL_CAPTION')}</p>
          <InputField className="mt-4" label={t('FORM_LABEL_EMAIL_CONFIRMATION_CODE')} {...register('code')} />
          {showSubmit ? (
            <div className="flex flex-col md:flex-row md:items-center md:justify-between">
              <IonButton disabled={isSubmitting} type="submit" expand="block" className="mt-4">
                {!isSubmitting ? t('FORM_ACTION_PROCEED') : <IonSpinner />}
              </IonButton>
              <IonButton fill="clear" disabled={isSubmitting} buttonType="button" onClick={onResendSubmit}>
                {t('FORM_ACTION_RESEND')}
              </IonButton>
            </div>
          ) : null}
        </fieldset>
      </form>
    )
  }
)
