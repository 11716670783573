import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { EventsAPI } from 'src/api/EventsAPI'
import { familyConversationsSelector } from 'src/features/Family/store/selectors'
import { useCurrentValues } from 'src/hooks/useCurrentValues'
import { useEventDrawer } from 'src/hooks/useEventDrawer'
import { ChatEvent } from 'src/models/events/ChatEvent'
import { EventCategory, EventPriority, EventStatus, EventType } from 'src/models/events/enums'
import { IonButton } from '@ionic/react'
// import { useServices } from '../../../hooks/useServices'
import { HealthEvent } from '../../../models/events'
// import { formatCurrency, safeArray } from '../../../utils'
import { BotActionMessage } from '../BotActionMessage'
import { BotMessageProps } from './constants'

// import { calendarEventValidator } from 'src/validators/calendarEventValidators'

export const BotComeToFacility: React.FC<BotMessageProps> = ({ event, onReset, onSuccess }) => {
  const { t } = useTranslation()
  const { openEventDrawer } = useEventDrawer()
  const [data, setData] = useState<{ bookingParentEventId: string; step: number }>({ bookingParentEventId: '', step: 0 })

  const { currentUser, currentFamily, currentFacility } = useCurrentValues()
  const conversations = useSelector(familyConversationsSelector)
  // const [services] = useServices()

  const bookingEvent = conversations?.find((conversation) => conversation.id === data.bookingParentEventId)
  // const dataServices = services.filter((s: any) => safeArray(bookingEvent?.parentEvent?.payload?.services).includes(s.id))

  const addMessage = async (parentId: string, message: string) => {
    const messageEvent = new ChatEvent(
      { parent_id: parentId },
      {
        userId: currentUser.id,
        familyId: currentUser.FamilyId,
        facilityId: currentFacility.id,
        message,
        user: currentUser,
        seen: false,
      }
    )

    await EventsAPI.createMessage(messageEvent)
  }

  const addLinkingMessages = async () => {
    if (!data.bookingParentEventId || !event.id) {
      return false
    }
    await addMessage(event.id, t('BOT_COME_TO_FACILITY_PARENT_CONVERSATION_MESSAGE', { seq: bookingEvent?.parentEvent?.seq }))
    await addMessage(data.bookingParentEventId, t('BOT_COME_TO_FACILITY_APPOINTMENT_CONVERSATION_MESSAGE', { seq: event?.seq }))
    if (onSuccess) onSuccess(event)
  }

  React.useEffect(() => {
    if (data.step === 1 && bookingEvent && event.id) {
      setData({ ...data, step: 2 })
      addLinkingMessages()
    }
  }, [data.step, bookingEvent])

  const onClose = async ({ parentId }: { parentId: string }) => {
    if (!parentId || !event.id) {
      return false
    }
    setData({ bookingParentEventId: parentId, step: 1 })
  }

  const onConfigureEvent = async () => {
    const evt = new HealthEvent(
      { category: EventCategory.EVENT_CATEGORY_HEALTH_VISIT },
      {
        facilityId: currentFacility.id,
        payment: {},
        priority: EventPriority.EVENT_PRIORITY_MEDIUM,
        status: EventStatus.STATUS_NEW,
        userId: '',
        pets: event.payload.pets || [],
        seen: false,
        videomeeting: {},
      }
    )
    openEventDrawer({
      defaultActivityType: EventType.EVENT_TYPE_HEALTH,
      event: evt,
      onClose,
      readonly: false,
      skipEventLoad: true,
      defaultFamily: currentFamily,
      activityTimeType: 'futureFixed',
    })
  }

  return (
    <>
      {data.step === 0 ? (
        <>
          <BotActionMessage message={t('BOT_COME_TO_FACILITY_MESSAGE', { name: event.User?.firstName })}>
            <div className="flex flex-col items-start">
              <IonButton color="primary" fill="outline" onClick={onConfigureEvent} size="small" className="button-xsmall">
                {t('BOT_LABEL_CONFIGURE_APPOINTMENT')}
              </IonButton>

              <IonButton color="primary" fill="outline" size="small" onClick={onReset} className="button-xsmall mt-4">
                {t('ACTION_CANCEL')}
              </IonButton>
            </div>
          </BotActionMessage>
        </>
      ) : !bookingEvent ? (
        <BotActionMessage message={t('BOT_COME_TO_FACILITY_SUCCESS')} />
      ) : null}
    </>
  )
}

/* <BotActionMessage message={t('BOT_INVITE_PET_OWNER', { name: event.User?.firstName })}>
        <BotActionMessage
          message={t('CHAT_CALENDAR_APPOINTMENT_DETAILS', {
            duration: bookingEvent?.parentEvent?.payload?.duration,
            services: dataServices.map((service: any) => service.name).join(', '),
            amount: formatCurrency(bookingEvent?.parentEvent?.payload?.payment?.amount),
            date: '',
            time: '',
          })}
        />
*/
