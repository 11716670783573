import { BaseModel } from './partials/BaseModel'

export class Pet extends BaseModel {
  id: string
  name: string
  sex: string
  FamilyId?: string
  SpeciesId?: number
  BreedId?: number
  CoatId?: number
  OriginId?: number

  constructor(data: { [key: string]: any }) {
    super(data)
    this.id = data.id
    this.name = data.name
    this.sex = data.sex || 'M'
    this.FamilyId = data.FamilyId
    this.SpeciesId = data.SpeciesId
    this.BreedId = data.BreedId
    this.CoatId = data.CoatId
    this.OriginId = data.OriginId
  }

  get initials(): string {
    return this.name.substr(0, 2) || ''
  }
}
