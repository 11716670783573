import isPlainObject from 'lodash/isPlainObject'
import { CreatePetFormType } from 'src/features/Family/form/CreatePetFormType'
import { Facility, Pet, User } from 'src/models'
import { APP_VET_MODE } from 'src/utils'
import { axiosInstance, getCacheConfig } from './axios'

export type PetSpecie = { id: string; name: string }
export type PetCoat = { id: string; name: string }
export type PetBreed = { id: string; name: string; SpeciesId: string }
export type PetOrigin = { id: string; name: string }
export type PetSex = { id: string; name: string }

export type PetAttributes = {
  species: PetSpecie[]
  coats: PetCoat[]
  breeds: PetBreed[]
  origins: PetOrigin[]
  sex: PetSex[]
}

export const EmptyPetAttributes = { species: [], coats: [], breeds: [], origins: [], sex: [] }

export class PetsAPI {
  static getSpeciesConfig(skipCache = false): Promise<PetAttributes> {
    return axiosInstance
      .get('/pets/attributes', getCacheConfig(skipCache))
      .then((response) => {
        const data = response.data
        data.sex = data.sex.map((value: string) => ({ id: value, name: value }))

        return data
      })
      .catch(() => Promise.resolve(EmptyPetAttributes))
  }

  static create(payload: CreatePetFormType, facility?: Facility, user?: User): Promise<Pet> {
    const formData = new FormData()
    formData.append('name', payload.name)
    formData.append('sex', payload.sex)
    payload.FamilyId != '' && formData.append('FamilyId', payload.FamilyId)
    formData.append('SpeciesId', '' + payload.SpeciesId)
    formData.append('BreedId', '' + payload.BreedId)
    formData.append('CoatId', '' + payload.CoatId)
    formData.append('OriginId', '' + payload.OriginId)

    if (payload.image) {
      formData.append('image', payload.image)
    }

    const url = APP_VET_MODE ? `/pets?facilityId=${facility?.id}&userId=${user?.id}` : '/pets'

    return axiosInstance.post(url, formData, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    })
  }

  static update(petId: string, payload: CreatePetFormType): Promise<Pet> {
    const formData = new FormData()
    formData.append('name', payload.name)
    formData.append('sex', payload.sex)
    formData.append('FamilyId', payload.FamilyId)
    formData.append('SpeciesId', '' + payload.SpeciesId)
    formData.append('BreedId', '' + payload.BreedId)
    formData.append('CoatId', '' + payload.CoatId)
    formData.append('OriginId', '' + payload.OriginId)

    if (payload.image) {
      formData.append('image', payload.image)
    }

    return axiosInstance
      .patch(`/pets/?PetId=${petId}`, formData, {
        headers: {
          'content-type': 'multipart/form-data',
        },
      })
      .then(({ data }) => new Pet(data.pet))
  }

  static getPetsById(petIds: string[]): Promise<Pet[]> {
    if (petIds.length == 0) {
      return Promise.resolve([])
    }

    const ids = petIds.join(',')
    return axiosInstance.get(`/pets?PetId=${ids}`).then((res) => {
      if (isPlainObject(res.data)) {
        return [new Pet(res.data)]
      }

      const pets = res.data || []
      return pets.map((pet: any) => new Pet(pet))
    })
  }

  static async getPets(FamilyId: string): Promise<Pet[]> {
    const res = await axiosInstance.get('/pets/list', {
      params: {
        FamilyId,
      },
    })

    const pets = res.data.petFamily || []
    return pets.map((pet: any) => new Pet(pet))
  }

  static async deletePet(pet: Pet): Promise<void> {
    return await axiosInstance.delete(`/pets/`, {
      params: {
        PetId: pet.id,
      },
    })
  }
}
