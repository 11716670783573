import React from 'react'
import { useTranslation } from 'react-i18next'
import { User } from 'src/models'
import { ChildEventMask } from 'src/models/events'
import { IonButton } from '@ionic/react'
import { Avatar } from '../Avatar'
import { BackgroundLogo, LogoThemesEnum } from '../icons/Logos'
import { ChatMessageWrapper } from './ChatMessageWrapper'

type SenderMessageProps = {
  event: ChildEventMask
  user?: User | null
  message: string
  time: string
  className?: string
  active?: boolean
}

export const SenderMessage: React.FC<SenderMessageProps> = ({ event, user, children, className, message, time, active = false }) => {
  const { t } = useTranslation()
  if (!message && !event.payload.file) return null

  return (
    <ChatMessageWrapper className={className} event={event}>
      <div className="flex-grow">
        <div className="text-grey-600 text-right text-body-tiny mb-1">{time}</div>
        <div
          className={`whitespace-pre-line shadow-light-40 rounded rounded-tr-none p-4 ${
            active ? 'bg-primary-500 text-white' : 'bg-white text-black'
          }`}
        >
          {event?.payload?.file && event?.payload?.filetype.startsWith('image') && (
            <div className="w-full h-auto py-6 flex justify-center">
              <img src={event.FileUrl || ''} className="rounded shadow-lg" />
            </div>
          )}
          {event?.payload?.file && event?.payload?.filetype === 'application-pdf' && (
            <IonButton href={event.FileUrl || ''} target="_blank" fill="clear" className="font-normal text-left -my-2">
              {t('LABEL_ATTACHMENT_PDF')}
            </IonButton>
          )}
          {message.trim()}
        </div>
        {children}
      </div>
      <div className={`h-12 w-12 shadow-light-40 rounded-full flex items-center justify-center flex-shrink-0 ${user ? '' : 'p-2'}`}>
        {user ? <Avatar title={user.initials} image={user.avatar} className="w-12 h-12" /> : <BackgroundLogo theme={LogoThemesEnum.DARK} />}
      </div>
    </ChatMessageWrapper>
  )
}
