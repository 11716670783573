import { ReactElement } from 'react'
import { Redirect, Route } from 'react-router-dom'
import { useCurrentUser } from '../hooks/useCurrentValues'

type PrivateRouteParams = {
  component: any
  path: string
  strict?: boolean
  exact?: boolean
}

/**
 * @description Handle authenticate routes
 *
 * @param Component
 * @param otherProps
 * @constructor
 */
export const PrivateRoute = ({ component: Component, path, strict = false, exact }: PrivateRouteParams): ReactElement => {
  const currentUser = useCurrentUser()
  return (
    <Route
      path={path}
      strict={strict}
      exact={exact}
      render={(props) =>
        currentUser.id ? <Component {...props} /> : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
      }
    />
  )
}
