import { IonRouterLink } from '@ionic/react'

type BannerPropsType = {
  className?: string
  title?: string
  link?: {
    route: string
    label: string
  }
}

export const Banner: React.FC<BannerPropsType> = ({ className = '', title, link }) => (
  <div className={`rounded-lg border border-primary-400 bg-primary-200 p-8 text-center ${className}`}>
    <h1 className="mb-2">{title}</h1>
    {link && (
      <IonRouterLink className="link" routerLink={link.route}>
        {link.label}
      </IonRouterLink>
    )}
  </div>
)
