import { createSelector } from 'reselect'
import { GlobalStoreState } from 'src/Store'
import { Family, Pet } from 'src/models'
import { User } from 'src/models/User'
import { Conversation } from 'src/models/events/Conversation'

export const familySelector = createSelector(
  ({ family }: GlobalStoreState): Family => family.family,
  (family) => family || new Family({})
)

export const familyPetsSelector = ({ family }: GlobalStoreState): Pet[] => family.pets || []

export const familyOwnerSelector = ({ family }: GlobalStoreState): User | null => family.members[0] || null

export const familyConversationsSelector = ({ family }: GlobalStoreState): Conversation[] => family.conversations

export const familyPastConversationsSelector = ({ family }: GlobalStoreState): Conversation[] => family.pastConversations

export const isFamilyLoadingSelector = ({ family }: GlobalStoreState): boolean => family.loading

export const isFamilyConversationLoadingSelector = ({ family }: GlobalStoreState): boolean => family.loadingConversations

export const familiesSelector = ({ auth }: GlobalStoreState): Family[] => auth.user?.families || []
