type AppRoutes = {
  ROOT: string
  HOME: string
  PROFILE: string
  PERFORMANCES: string
  CHAT: string
  NOTIFICATIONS: string
  DIARY: string
  EDIT_PROFILE: string
  CHANGE_PASSWORD: string
  EDIT_PET: string
  ADD_PERFORMANCE: string
  MAP: string
  WRONG_APP: string
}

type AppRoutesVeterinary = {
  ROOT: string
  PROFILE: string
  REQUESTS: string
  REQUEST: string
  NOTIFICATIONS: string
}

export class AppRouterHelper {
  static get ROUTES(): AppRoutes {
    return {
      ROOT: '/app',
      HOME: '/app/home',
      PROFILE: '/app/profile',
      PERFORMANCES: '/app/performances',
      CHAT: '/app/chat/:eventId',
      NOTIFICATIONS: '/app/notifications',
      DIARY: '/app/diary',
      EDIT_PROFILE: '/app/profile/edit',
      CHANGE_PASSWORD: '/app/profile/change-password',
      EDIT_PET: '/app/pets/:petId/edit',
      ADD_PERFORMANCE: '/app/performances/add',
      MAP: '/app/map',
      WRONG_APP: '/app/disclaimer',
    }
  }

  static get VETERINARY_ROUTES(): AppRoutesVeterinary {
    return {
      ROOT: '/app',
      PROFILE: '/app/profile',
      REQUESTS: '/app/requests',
      REQUEST: '/app/requests/:eventId',
      NOTIFICATIONS: '/app/notifications',
    }
  }
}
