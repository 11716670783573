import { useTranslation } from 'react-i18next'
import { useIonToast } from '@ionic/react'

type TryCallback = () => any
type OnSuccessCallback = (data: any) => string

type ToastHookType = {
  error: (message: string) => void
  success: (message: string) => void
  dismiss: () => void
}

export const useToast = (): ToastHookType => {
  const [present, dismiss] = useIonToast()
  const defaultOptions = {
    duration: 5000,
    buttons: [{ icon: 'close', cssClass: 'closeIcon', handler: () => dismiss() }],
  }

  return {
    error: (message: string) => {
      present({
        color: 'danger',
        ...defaultOptions,
        message,
      })
    },
    success: (message: string) => {
      present({
        color: 'dark',
        cssClass: 'ion-toast-success',
        ...defaultOptions,
        message,
      })
    },
    dismiss,
  }
}

export const useToastCallback = () => {
  const { t } = useTranslation()

  const toast = useToast()

  return async (callback: TryCallback, onSuccess: OnSuccessCallback) => {
    try {
      const data = await callback()
      const successMessage = onSuccess(data)

      toast.success(successMessage)
    } catch ({ message }) {
      toast.error(t(message as string))
    }
  }
}

/**
 * const toast = useToast()
 * toast.error( '' )
 */
