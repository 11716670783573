import { axiosInstance } from './axios'

export class CallAPI {
  static async requestVideomeeting(eventId: string) {
    return axiosInstance
      .post(
        '/videomeeting/room',
        {},
        {
          params: {
            eventId,
          },
        }
      )
      .then((response) => response.data)
  }

  static async getVideomeeting(roomName: string) {
    return axiosInstance
      .get('/videomeeting/room', {
        params: {
          name: roomName,
        },
      })
      .then((response) => response.data)
  }

  static async isVideomeeting(services: any, facilityId: string) {
    return axiosInstance
      .get('/v1/service/isVideomeeting', {
        params: {
          services: services,
          facilityId: facilityId,
        },
      })
      .then((response) => response.data)
  }

  static async deleteVideomeeting(roomName: string) {
    return axiosInstance
      .delete('/videomeeting/room', {
        params: {
          name: roomName,
        },
      })
      .then((response) => response.data)
  }
}
