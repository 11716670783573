import { format } from 'date-fns'
import { enUS } from 'date-fns/locale'
import { isEqual, set } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { GlobalStoreState } from 'src/Store'
import { EventsAPI } from 'src/api/EventsAPI'
import { IconClock } from 'src/components/icons'
import { IconThermometer } from 'src/components/icons/IconThermometer'
import { RemoteWomanCheck } from 'src/components/icons/RemoteWomanCheck'
import { CloudClock } from 'src/components/illustrations/CloudClock'
import { SelectField } from 'src/components/inputs/SelectField'
import { facilityVeterinarian } from 'src/features/Facility/store/selectors'
import { GenericEditableProps } from 'src/helpers/props'
import { useCurrentValues } from 'src/hooks/useCurrentValues'
import { useServices } from 'src/hooks/useServices'
import { useToast } from 'src/hooks/useToast'
import { Pet } from 'src/models'
import { FacilityService, UpfrontType } from 'src/models/FacilityService'
import {
  CalendarEventStatus,
  EventCategory,
  EventPaymentStatus,
  EventPaymentType,
  EventPriority,
  EventStatus,
  EventType,
  ServiceCategory,
} from 'src/models/events/enums'
import { DateConverter, formatDate } from 'src/utils'
import { array, number, object, string } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { IonButton } from '@ionic/react'
import { useIonAlert } from '@ionic/react'
import { useQueryClient } from '@tanstack/react-query'
import { InputTextArea } from '../../../../components/inputs/InputTextArea'
import { AppointmentProps } from '../DrawerEvent'
import { ActivityTimeSwitcher, ActivityTimeType } from './ActivityTime'
import { NewHealthEventPartial } from './NewHealthEventPartial'
import { PaymentSection } from './PaymentSection'
import { PetSelector } from './PetSelector'
import { PrioritySelector } from './PrioritySelector'
import { useHealthEventGenerator } from './health-event-generator'
import { useEditHealthEvent } from './health-event-updater'

const durationValues = [15, 30, 45, 60].map((v) => `${v}`)

export type HealthEventAppointmentProps = {
  activityTime: {
    type?: string
    mode: ActivityTimeType
    date: Date
  }
  pets: string[]
  services: string[]
  duration: number | string
  priority: EventPriority
  payment: {
    amount: number
    mode: string
    status: EventPaymentStatus
    type: EventPaymentType
  }
}

export const HealthAppointmentBody: React.FC<GenericEditableProps & AppointmentProps> = ({
  event,
  calendarEvent,
  className,
  client,
  skipEventLoad,
  onSuccess,
  onClose,
  onDelete,
  setBlockDismiss,
  activityTimeType,
}) => {
  const { t } = useTranslation()
  const toast = useToast()
  const history = useHistory()
  const isEditingMode = !!event?.id && event?.id !== ''
  const { currentUser, currentFacility } = useCurrentValues()
  const [services] = useServices()
  const [selectedTime, setSelectedTime] = useState<boolean>(true)
  const [petSelected, setPetSelected] = useState<boolean>(false)
  const [clientChoice, setClientChoice] = useState<boolean>(false)
  const [serviceSelected, setServiceSelected] = useState<boolean>(false)
  const queryClient = useQueryClient()

  const mapServiceIdToMainId: Record<string, number> = {}

  services.forEach((service: FacilityService) => {
    mapServiceIdToMainId[service.id] = service.MainServiceId
  })

  const pets: string[] = (client?.pets || []).map((pet: Pet) => pet.id)
  const [activityTime, setActivityTime] = useState<ActivityTimeType | null>(
    activityTimeType === 'future' || activityTimeType === 'futureFixed'
      ? ActivityTimeType.FUTURE
      : !isEditingMode
      ? ActivityTimeType.NOW
      : null
  )
  const [isPriceCustomized, setIsPriceCustomized] = useState<boolean>(false)
  const { generateNewFutureActivity, generateNewCurrentActivity, generateNewPastActivity } = useHealthEventGenerator()
  const isClosedEvent = event?.payload.status === EventStatus.STATUS_CLOSED
  const editHealthEvent = useEditHealthEvent()
  const [present] = useIonAlert()

  const veterinarian = useSelector((state: GlobalStoreState) => facilityVeterinarian(state, calendarEvent?.payload?.event?.resource ?? ''))
  const calendarDateTime = calendarEvent?.payload?.event?.start
    ? {
        date: format(calendarEvent.payload.event.start, 'MMMM dd, yyyy', { locale: enUS }),
        startTime: format(calendarEvent.payload.event.start, 'HH:mm a', { locale: enUS }),
        endTime: format(calendarEvent.payload.event.end, 'HH:mm a', { locale: enUS }),
      }
    : { date: '', startTime: '', endTime: '' }

  const onEdit = async (evt: any) => {
    setBlockDismiss(false)

    if (event && calendarEvent) await editHealthEvent({ currentUser, evt, event, calendarEvent, activityTime, onSuccess })

    queryClient.invalidateQueries({
      queryKey: ['conversations'],
    })

    queryClient.invalidateQueries({
      queryKey: ['today'],
    })
  }

  const onSubmit = async (evt: HealthEventAppointmentProps) => {
    try {
      let parentId = ''

      switch (activityTime) {
        case ActivityTimeType.PAST:
          parentId = await generateNewPastActivity(client, currentUser, currentFacility, services, evt, event)
          break
        case ActivityTimeType.NOW:
          parentId = await generateNewCurrentActivity(client, currentUser, currentFacility, services, evt, event)
          break
        case ActivityTimeType.FUTURE:
          parentId = await generateNewFutureActivity(client, currentUser, currentFacility, services, evt, event)
          break
      }

      queryClient.invalidateQueries({
        queryKey: ['conversations'],
      })

      queryClient.invalidateQueries({
        queryKey: ['today'],
      })

      queryClient.invalidateQueries({
        queryKey: ['performance'],
      })

      toast.success(t('EVENT_CREATED_SUCCESSFULLY'))

      if (!skipEventLoad)
        setTimeout(() => {
          if (activityTime !== ActivityTimeType.PAST) history.push(`/dashboard/clients/${client?.FamilyId}/${parentId}`)
          else history.push(`/dashboard/clients/${client?.FamilyId}`)
        }, 300)

      setBlockDismiss(false)
      onSuccess({ parentId })
    } catch ({ message }) {
      console.error(message)
      toast.error(t(String(message ?? '')))
    }
  }

  const deleteAppointment = () => {
    const cancelEvent = async () => {
      try {
        if (!event || !calendarEvent) throw new Error(t('EVENT_NOT_FOUND'))

        event.payload.status = EventStatus.STATUS_CLOSED
        event.payload.closeDate = DateConverter.now()

        await EventsAPI.updateEvent(event, true)

        calendarEvent.payload.valid = false
        calendarEvent.payload.invalidatedAt = DateConverter.now()
        calendarEvent.payload.invalidatedBy = currentUser.id
        calendarEvent.payload.status = CalendarEventStatus.CALENDAR_EVENT_STATUS_CANCELED

        await EventsAPI.updateEvent(calendarEvent, true)

        toast.success(t('CALENDAR_EVENT_DELETED_SUCCESSFULLY'))
        onSuccess({})
        onDelete?.()
      } catch (error) {
        console.error(error)
        toast.error(t(error as string))
      }
    }

    present({
      header: t('ALERT_DELETE_CALENDAR_EVENT_TITLE'),
      message: t('ALERT_DELETE_CALENDAR_EVENT_TEXT', { clientName: client?.firstName }),
      buttons: [t('ACTION_CANCEL'), { text: t('ACTION_OK'), handler: cancelEvent }],
    })
  }

  const defaultValues = {
    _id: event?.id || null,
    parent_id: event?.parent_id || null,
    type: EventType.EVENT_TYPE_HEALTH,
    category: event?.category || EventCategory.EVENT_CATEGORY_HEALTH_VISIT,
    pets: event?.Pets.map((pet: Pet) => pet.id) || [],
    priority: event?.payload?.priority || EventPriority.EVENT_PRIORITY_MEDIUM,
    services: event?.payload?.services?.map((service: any) => service.id) || [],
    duration: event?.payload?.duration || '30',
    note: event?.payload?.note || '',
    payment: {
      amount: event?.payload?.payment?.amount || 0,
      mode: '',
      status: event?.payload?.payment?.status || '',
      type: event?.payload?.payment?.type || EventPaymentType.PAYMENT_TYPE_CASH,
    },
    activityTime: {
      mode: activityTime,
      date: event?.payload.date || DateConverter.now(),
    },
  }

  const validationSchema = object().shape({
    pets: array()
      .compact((v) => !pets.includes(v))
      .required(t('FIELD_REQUIRED_PET'))
      .min(1, t('FIELD_REQUIRED_PET_MIN_VALUE')),
    priority: string().required(t('FIELD_REQUIRED_PRIORITIES')),
    services: array().required(t('FIELD_REQUIRED_SERVICES')).min(1, t('FIELD_REQUIRED_SERVICES')),
    duration: number().positive().integer().required(t('FIELD_REQUIRED_ACTIVITY_DURATION')).min(1, t('FIELD_REQUIRED_DURATION_MIN_VALUE')),
  })

  const {
    watch,
    control,
    handleSubmit,
    register,
    formState: { isDirty },
    setValue,
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: yupResolver(validationSchema),
    defaultValues,
  })

  const watchAllFields = watch()

  const showUpfront = useMemo(() => {
    if (watchAllFields.services.length != 1) return false
    else {
      const selectedService = services.find((service) => watchAllFields.services.includes(service.id))
      if (selectedService) return selectedService.upfront === UpfrontType.UPFRONT_TYPE_ALL
    }
    return false
  }, [watchAllFields.services, services])

  //   useEffect(() => {
  //     !isEqual(watchAllFields.services, event?.payload?.services) && setIsPriceCustomized(true)

  //     if (watchAllFields.services.length > 0) {
  //       if (isPriceCustomized) {
  //         const selectedServices = services.filter((service) => watchAllFields.services.includes(service.id))

  //         let durationSum = 0
  //         let priceSum = 0

  //         selectedServices.forEach((service) => {
  //           durationSum += service.duration
  //           // priceSum += parseInt(service.price.toString())
  //           // priceSum += parseInt(service.price)
  //           priceSum += service.price
  //         })

  //         setValue('duration', `${durationSum}`)
  //         setValue('payment.amount', priceSum)
  //       }
  //     } else {
  //       setValue('duration', '30')
  //       setValue('payment.amount', 0)
  //     }
  //   }, [watchAllFields.services, services, isPriceCustomized])

  useEffect(() => {
    const isVideoconsultationServicePresent = watchAllFields.services.some(
      (addedService: string) => mapServiceIdToMainId[addedService] === ServiceCategory.VIDEOCONSULTATION
    )

    if (isVideoconsultationServicePresent) {
      setValue('category', EventCategory.EVENT_CATEGORY_HEALTH_VIDEOCONSULTATION)
      setValue('payment.type', EventPaymentType.PAYMENT_TYPE_DIGITAL)
    } else {
      setValue('category', EventCategory.EVENT_CATEGORY_HEALTH_VISIT)
    }
  }, [watchAllFields.services, services])

  useEffect(() => {
    if (showUpfront) {
      setValue('payment.type', EventPaymentType.PAYMENT_TYPE_DIGITAL)
      setValue('payment.status', EventPaymentStatus.PAYMENT_STATUS_PENDING)
    } else {
      !event?.payload?.payment?.status && setValue('payment.status', EventPaymentStatus.PAYMENT_STATUS_FREE)
    }
  }, [showUpfront])

  useEffect(() => {
    if (isDirty) setBlockDismiss(true)
    else setBlockDismiss(false)
  }, [isDirty])

  useEffect(() => {
    if (watchAllFields.pets.length > 0 && watchAllFields.services.length > 0) {
      setSelectedTime(false)
    } else {
      setSelectedTime(true)
    }
  }, [watchAllFields.pets, watchAllFields.services])

  const openPerformance = () => {
    const goToPerformance = () => {
      history.push(`/dashboard/clients/${client?.FamilyId}/${event?.id}`)
      //   setBlockDismiss(false)
      onClose?.({}, true)
    }

    if (isDirty)
      present({
        header: t('ALERT_EDITS_NOT_SAVED_TITLE'),
        message: t('ALERT_EDITS_NOT_SAVED_TEXT'),
        buttons: [t('ACTION_CANCEL'), { text: t('ACTION_PROCEED'), handler: goToPerformance }],
      })
    else goToPerformance()
  }

  if (!client) {
    return (
      <div className="flex flex-grow justify-center items-center">
        <div className="flex-1 pt-40 px-10 h-full w-full justify-center">
          <p className="text-h300 font-bold text-black text-center">{t('LABEL_SELECT_CLIENT')}</p>
        </div>
      </div>
    )
  }

  return (
    <form className={`flex flex-grow ${className}`} onSubmit={handleSubmit(isEditingMode ? onEdit : onSubmit)}>
      <div className="flex-1 pt-10 px-10">
        <div className="flex items-center space-x-2">
          <IconThermometer className="w-6 h-6 text-primary-500" />
          <span className="text-h300 font-bold text-black">{t('LABEL_TRIAGE')}</span>
        </div>

        <PetSelector
          name="pets"
          control={control}
          className="my-6"
          single={true}
          pets={client?.pets}
          readonly={isEditingMode}
          onPetSelected={setPetSelected}
        />
        <PrioritySelector name="priority" control={control} className="my-6" />
        <NewHealthEventPartial
          activityName="services"
          durationName="duration"
          control={control}
          durationValues={durationValues}
          readonly={isClosedEvent}
          onServiceSelected={setServiceSelected}
        />
        <PaymentSection
          name="payment"
          control={control}
          category={watchAllFields.category}
          className="my-6"
          readonly={isClosedEvent}
          client={client}
          showUpfront={showUpfront}
        />
        {isEditingMode && (
          <InputTextArea
            className="mb-10"
            label={t('LABEL_NOTE')}
            {...register('note')}
            description={t('LABEL_NOTE_NOT_VISIBLE_PET', { name: client?.firstName })}
          />
        )}
      </div>

      <div className="flex-1 pt-10 px-10 bg-grey-100 flex flex-col flex-grow">
        {isEditingMode && event?.payload.status === EventStatus.STATUS_OPEN ? (
          <div className="flex flex-col">
            <div className="flex items-center space-x-2">
              <IconClock className="w-6 h-6 text-primary-500" />
              <span className="text-h300 font-bold text-black">{t('LABEL_DATE_AND_HOURS')}</span>
            </div>
            <div className="flex-grow flex flex-col items-center justify-center text-center">
              <p className="text-lead mt-12 px-12">{t('LABEL_PERFORMANCE_ON_GOING')}</p>
              <CloudClock className="w-80 mt-8" />
            </div>
          </div>
        ) : isEditingMode && activityTime === null ? (
          <div className="flex flex-col">
            <div className="flex items-center space-x-2">
              <IconClock className="w-6 h-6 text-primary-500" />
              <span className="text-h300 font-bold text-black">{t('LABEL_DATE_AND_HOURS')}</span>
            </div>
            <div className="flex-grow flex flex-col items-center justify-center text-center">
              <div className="h-8" />
              <p className="text-lead">
                {t('LABEL_PERFORMANCE_PROGRAMMED_WITH', {
                  vetName: `${veterinarian?.firstName ?? ''} ${(veterinarian?.lastName ?? '').slice(0, 1)}.`,
                })}
              </p>
              <div className="h-2" />
              <p>{t('LABEL_PERFORMANCE_DATETIME', calendarDateTime)}</p>
              <div className="h-4" />
              <p
                className="text-primary-500 cursor-pointer"
                onClick={() => {
                  setActivityTime(ActivityTimeType.FUTURE)
                  setValue('activityTime', { mode: ActivityTimeType.FUTURE, date: null })
                  setSelectedTime(false)
                }}
              >
                {t('ACTION_EDIT_TIME_PERFORMANCE')}
              </p>
              <RemoteWomanCheck className="w-80 mt-8" />
            </div>
          </div>
        ) : (
          <>
            <div className="flex justify-between items-center">
              <div className="flex items-center space-x-2">
                <IconClock className="w-6 h-6 text-primary-500" />
                <span className="text-h300 font-bold text-black">{t('LABEL_DATE_AND_HOURS')}</span>
              </div>
              <SelectField
                className="my-0 w-40"
                options={[
                  { value: 'now', label: t('LABEL_DATE_NOW') },
                  { value: 'past', label: t('LABEL_DATE_PAST') },
                  { value: 'future', label: t('LABEL_FUTURE') },
                ]}
                readonly={activityTimeType === 'futureFixed'}
                value={activityTime}
                onChange={(activityTime: any) => {
                  setActivityTime(activityTime)
                  setSelectedTime(false)
                }}
              />
            </div>

            {activityTime !== null && (
              <ActivityTimeSwitcher
                name="activityTime"
                activityTime={activityTime}
                client={client}
                control={control}
                fields={watchAllFields}
                isEditingMode={isEditingMode}
                onSelectedTime={setSelectedTime}
                onClientChoice={setClientChoice}
              />
            )}
          </>
        )}
      </div>

      <footer className="flex justify-end shadow-light-80 border-t border-solid border-grey-100 bg-white fixed bottom-0 left-0 w-full px-6 py-4 z-20">
        {isEditingMode ? (
          <>
            <div className="w-2" />
            <IonButton onClick={deleteAppointment} fill="clear" className="text-h150" color="tertiary">
              <span>{t('ACTION_DELETE')}</span>
            </IonButton>
            <div className="flex-grow" />
            <IonButton onClick={openPerformance} fill="outline" className="text-h150">
              <span>{t('ACTION_GO_TO_PERFORMANCE')}</span>
            </IonButton>
            <div className="w-2" />
          </>
        ) : null}
        {!isEditingMode || isDirty ? (
          activityTime === null ? (
            <IonButton type="submit" className="text-h150">
              <span className="px-4">{t('ACTION_SAVE_PERFORMANCE')}</span>
            </IonButton>
          ) : activityTime == ActivityTimeType.PAST ? (
            <IonButton type="submit" className="text-h150">
              <span className="px-4">{t('ACTION_SAVE_PERFORMANCE')}</span>
            </IonButton>
          ) : activityTime == ActivityTimeType.NOW ? (
            <IonButton type="submit" className="text-h150" color="tertiary">
              <span className="text-white px-4">{t('ACTION_START_PERFORMANCE')}</span>
            </IonButton>
          ) : (
            <IonButton type="submit" className="text-h150" disabled={selectedTime}>
              <span className="px-4">{t('ACTION_SET_APPOINTMENT')}</span>
            </IonButton>
          )
        ) : null}
      </footer>
    </form>
  )
}
