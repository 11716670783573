import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Avatar } from 'src/components/Avatar'
import { IconArrow, IconArrowType, IconLogout } from 'src/components/icons'
import { FullLogo } from 'src/components/icons/Logos'
import { currentUserSelector } from 'src/features/Auth/store/selectors'
import { authThunks } from 'src/features/Auth/store/thunks'
import { useToast } from 'src/hooks/useToast'
import { EmailComposer } from '@awesome-cordova-plugins/email-composer'
import { Browser } from '@capacitor/browser'
import { Capacitor } from '@capacitor/core'
import { IonContent, IonItem, IonLabel, IonList, IonPage } from '@ionic/react'
import { NavHeader } from '../components/Header/NavHeader'
import { AppRouterHelper } from '../services/AppRouterHelper'

export const PageProfile: React.FC<{ vetMode: boolean }> = ({ vetMode = false }) => {
  const currentUser = useSelector(currentUserSelector)

  const history = useHistory()
  const toast = useToast()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const onLogout = async () => {
    try {
      Capacitor.isNativePlatform() && (await dispatch(authThunks.deletePushNotificationToken()))
      await dispatch(authThunks.logout())
      history.push('/login')
    } catch ({ message }) {
      toast.error(t(message as string))
    }
  }

  const goToPrivacy = async () =>
    await Browser.open({
      url: 'https://veterly.com/informativa-sul-trattamento-dei-dati-ai-sensi-dellart-13-del-regolamento-ue-n-679-2016/',
    })
  const goToGeneralConditions = async () => await Browser.open({ url: 'https://veterly.com/condizioni-generali/' })

  const openEmail = async (subject: string) => {
    EmailComposer.open({
      to: 'info@veterly.com',
      subject: t(subject),
    })
  }

  const getList = () => {
    if (vetMode) {
      return (
        <>
          <IonItem className="text-body-large text-tertiary-700 pr-6" onClick={onLogout}>
            <IonLabel>{t('LOGOUT')}</IonLabel>
            <div slot="end">
              <IconLogout className="w-6 h-6" />
            </div>
          </IonItem>{' '}
          <div className="h-px bg-grey-200 w-full" />
        </>
      )
    } else {
      return (
        <>
          <IonItem className="text-body-large pr-6" routerLink={AppRouterHelper.ROUTES.EDIT_PROFILE}>
            <IonLabel>{t('PAGE_EDIT_PROFILE_TITLE')}</IonLabel>
            <div slot="end">
              <IconArrow type={IconArrowType.RIGHT} className="text-primary-500 w-6 h-6" />
            </div>
          </IonItem>
          <div className="h-px bg-grey-200 w-full" />
          <IonItem className="text-body-large pr-6" routerLink={AppRouterHelper.ROUTES.CHANGE_PASSWORD}>
            <IonLabel>{t('PAGE_CHANGE_PASSWORD_TITLE')}</IonLabel>
            <div slot="end">
              <IconArrow type={IconArrowType.RIGHT} className="text-primary-500 w-6 h-6" />
            </div>
          </IonItem>
          <div className="h-px bg-grey-200 w-full" />
          <IonItem className="text-body-large pr-6" onClick={goToGeneralConditions}>
            <IonLabel>{t('LABEL_GENERAL_CONDITIONS')}</IonLabel>
            <div slot="end">
              <IconArrow type={IconArrowType.RIGHT} className="text-primary-500 w-6 h-6" />
            </div>
          </IonItem>
          <div className="h-px bg-grey-200 w-full" />
          <IonItem className="text-body-large pr-6" onClick={goToPrivacy}>
            <IonLabel>{t('LABEL_PRIVACY_POLICY')}</IonLabel>
            <div slot="end">
              <IconArrow type={IconArrowType.RIGHT} className="text-primary-500 w-6 h-6" />
            </div>
          </IonItem>
          <div className="h-px bg-grey-200 w-full" />
          <IonItem className="text-body-large pr-6" onClick={() => openEmail('EMAIL_SUBJECT_REPORT_PROBLEM')}>
            <IonLabel>{t('LABEL_HELP')}</IonLabel>
            <div slot="end">
              <IconArrow type={IconArrowType.RIGHT} className="text-primary-500 w-6 h-6" />
            </div>
          </IonItem>
          <div className="h-px bg-grey-200 w-full" />
          <IonItem className="text-body-large pr-6" onClick={() => openEmail('EMAIL_SUBJECT_DELETE_ACCOUNT_REQUEST')}>
            <IonLabel>{t('LABEL_DELETE_ACCOUNT')}</IonLabel>
            <div slot="end">
              <IconArrow type={IconArrowType.RIGHT} className="text-primary-500 w-6 h-6" />
            </div>
          </IonItem>
          <div className="h-px bg-grey-200 w-full" />
          <IonItem className="text-body-large text-tertiary-500 pr-6" onClick={onLogout}>
            <IonLabel>{t('LOGOUT')}</IonLabel>
            <div slot="end">
              <IconLogout className="w-6 h-6" />
            </div>
          </IonItem>
          <div className="h-px bg-grey-200 w-full" />
        </>
      )
    }
  }

  return (
    <IonPage>
      <NavHeader titleClassName="text-center p-0" title={t('PROFILE')} showBackButton={true} />
      <IonContent>
        <section className="mt-6 flex items-center flex-col">
          <Avatar className="h-20 w-20" classNameTitle="text-lead font-bold" title={currentUser.initials} image={currentUser.avatar} />
          <h1 className="mt-4">{currentUser.firstName}</h1>
        </section>
        <div className="h-2/3 flex flex-col">
          <IonList className="flex-none -mr-6">{getList()}</IonList>
          <div className="flex-grow" />
          {process.env.REACT_APP_VERSION && (
            <div className="flex-none w-full flex flex-col items-center text-body-tiny text-center text-grey-700 py-2">
              {`v. ${process.env.REACT_APP_VERSION}`}
              <FullLogo className="w-1/3 h-14" />
            </div>
          )}
        </div>
      </IonContent>
    </IonPage>
  )
}
