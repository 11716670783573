import { encode } from 'js-base64'
import { ReactElement, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { submitForm } from 'src/components/forms/Form'
import { FormPetOwnerRegister } from 'src/components/forms/FormPetOwnerRegister'
import { SignUpFormType } from 'src/features/Auth/forms/SignUpForm'
import { authThunks } from 'src/features/Auth/store/thunks'
import { useToast } from 'src/hooks/useToast'
import { IonButton, IonContent, IonFooter, IonPage } from '@ionic/react'
import { NavHeader } from '../components/Header/NavHeader'

export const PageRegister = (): ReactElement => {
  const { t } = useTranslation()
  const toast = useToast()
  const dispatch = useDispatch()
  const history = useHistory()

  const formRef = useRef<HTMLFormElement>(null)

  const checkValidation = () => submitForm(formRef.current)

  const onSubmit = async (form: SignUpFormType) => {
    try {
      const res = await dispatch<any>(authThunks.signUp(form))
      history.push({ pathname: `/email-confirmation/${encode(res.user.username)}` })
    } catch ({ message }) {
      toast.error(t(message as string))
    }
  }

  return (
    <IonPage>
      <NavHeader title={t('TITLE_PAGE_CREATE_NEW_ACCOUNT')} />
      <IonContent>
        <div className="mt-10 font-bold">
          {t('LABEL_FOR_VETERINARY_REGISTRATION_ALERT')}
          <a className="ml-1 text-h100 link" href="https://web.veterly.com/sign-up">
            {t('LABEL_SIGN_UP_HERE_LINK')}
          </a>
        </div>
        <FormPetOwnerRegister onSubmit={onSubmit} className="py-6" ref={formRef} showSubmit={false} />
      </IonContent>
      <IonFooter className="bg-transparent">
        <IonButton type="submit" className="mb-4 px-6" expand="block" onClick={checkValidation}>
          {t('SIGN_UP')}
        </IonButton>
      </IonFooter>
    </IonPage>
  )
}
