import { close } from 'ionicons/icons'
import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PetsAPI } from 'src/api'
import { FormEditPet } from 'src/components/forms/FormEditPet'
import { CreatePetFormType } from 'src/features/Family/form/CreatePetFormType'
import { usePetAttributes } from 'src/hooks/data'
import { useCurrentFamily } from 'src/hooks/useCurrentValues'
import { useToast } from 'src/hooks/useToast'
import { IonButton, IonButtons, IonContent, IonIcon, IonPage } from '@ionic/react'
import { NavHeader } from '../components/Header/NavHeader'

type PageAddPetProps = {
  onDismiss: () => void
}
export const PageAddPet: FC<PageAddPetProps> = ({ onDismiss }) => {
  const { family } = useCurrentFamily()
  const { t } = useTranslation()
  const toast = useToast()
  const [loading, setLoading] = useState(false)
  const [speciesConfig] = usePetAttributes()

  const onSubmit = async (values: CreatePetFormType) => {
    setLoading(true)
    values.FamilyId = family.id
    try {
      await PetsAPI.create(values)
      toast.success(t('PET_CREATED_SUCCESSFULLY', { name: values.name }))
      onDismiss()
    } finally {
      setLoading(false)
    }
  }

  return (
    <IonPage>
      <NavHeader title={t('ADD_PET')} showBackButton={false}>
        <IonButtons slot="end">
          <IonButton className="px-3 link" fill="clear" onClick={onDismiss}>
            <IonIcon icon={close} />
          </IonButton>
        </IonButtons>
      </NavHeader>
      <IonContent>
        <FormEditPet onSubmit={onSubmit} className="py-6" loading={loading} data={speciesConfig} familyId={family?.id} />
      </IonContent>
    </IonPage>
  )
}
