import { useIonLoading } from '@ionic/react'

export const useLoader = () => {
  const [present, dismiss] = useIonLoading()

  const showLoading = () => present({ spinner: 'lines', backdropDismiss: false })

  const hideLoading = () => dismiss()

  return [showLoading, hideLoading]
}
