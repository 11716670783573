import { CalendarEvent } from '.'
import { Facility, Family, Pet, User } from '..'
import { AllEventMask } from './index'

type EnrichedDataType = {
  Family: Family | null
  Facility: Facility | null
  User: User | null
  Assignee: User | null
  Pets: Pet[]
  LastMessage: AllEventMask | null
  LastUserMessage: User | null
  Appointment: CalendarEvent | null
}

export class EnrichedEventData {
  _enrichedData: EnrichedDataType = {
    Family: null,
    Facility: null,
    User: null,
    Assignee: null,
    Pets: [],
    LastMessage: null,
    LastUserMessage: null,
    Appointment: null,
  }

  enrich(data: EnrichedDataType) {
    this._enrichedData = data
  }
  addEnrichedData(data: any) {
    this._enrichedData = {
      ...this._enrichedData,
      ...data,
    }
  }

  get Family(): Family | null {
    return this._enrichedData.Family
  }

  get Facility(): Facility | null {
    return this._enrichedData.Facility
  }

  get User(): User | null {
    return this._enrichedData.User
  }

  get Pets(): Pet[] {
    return this._enrichedData.Pets || []
  }

  get Assignee(): User | null {
    return this._enrichedData.Assignee
  }

  get LastMessage(): AllEventMask | null {
    return this._enrichedData.LastMessage
  }

  get LastUserMessage(): User | null {
    return this._enrichedData.LastUserMessage
  }

  get Appointment(): CalendarEvent | null {
    return this._enrichedData.Appointment
  }
}
