import { ReactElement } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { object, string, ref } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { IonButton } from '@ionic/react'
import { NewPasswordParameters } from '../../features/Auth/store/types'
import { InputField } from '../inputs/InputField'
import { InputPassword } from '../inputs/InputPassword'

export type FormNewPasswordParams = {
  // eslint-disable-next-line no-unused-vars
  onSubmit: (parameters: NewPasswordParameters) => void
  className?: string
}

/**
 * @description FormNewPassword
 *
 * @param onSubmit
 * @param className
 * @constructor
 */
export const FormNewPassword = ({ onSubmit, className = '' }: FormNewPasswordParams): ReactElement => {
  const { t } = useTranslation()

  const validationSchema = object().shape({
    code: string().required(t('FIELD_REQUIRED_EMAIL')),
    password: string()
      .required(t('FIELD_REQUIRED_PASSWORD'))
      .min(8, t('PASSWORD_FIELD_MIN_LENGTH', { count: 8 }))
      .matches(/(?=.*\d)(?=.*[A-Z])/, t('PASSWORD_FIELD_SECURITY')),
    passwordConfirmation: string().oneOf([ref('password'), null], t('FIELD_REQUIRED_PASSWORD_MATCH')),
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: yupResolver(validationSchema),
  })

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={`w-full flex flex-col ${className}`}>
      <InputField
        label={t('FORM_LABEL_VERIFICATION_CODE')}
        placeholder={t('FORM_LABEL_VERIFICATION_CODE_PLACEHOLDER')}
        type="text"
        {...register('code')}
        error={errors.code?.message}
      />
      <InputPassword label={t('FORM_LABEL_NEW_PASSWORD')} {...register('password')} error={errors.password?.message} />
      <InputPassword
        label={t('FORM_LABEL_NEW_PASSWORD_CONFIRMATION')}
        {...register('passwordConfirmation')}
        error={errors.passwordConfirmation?.message}
      />
      <div className="block pt-4">
        <IonButton expand="block" type="submit" className="md:inline-block">
          {t('FORM_ACTION_CHANGE_PASSWORD')}
        </IonButton>
      </div>
    </form>
  )
}
