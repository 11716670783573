import { EventNotificationAction } from './enums'

export interface NotificationInterface {
  _id: string
  action: EventNotificationAction
  payload?: any
  seq?: string
  appointment?: any
}

export class Notification {
  id?: string
  action: EventNotificationAction
  payment: any
  seq: string
  appointment: any

  constructor(data: NotificationInterface) {
    this.id = data._id || ''
    this.seq = data.seq || ''
    this.action = data.action
    this.payment = data.payload?.payment || null
    this.appointment = data.appointment?.payload || null
  }
}
