import { SignUpFormType } from '../forms/SignUpForm'

export class SignUpPayload {
  username: string
  password: string
  attributes?: Record<string, unknown>

  constructor(data: SignUpFormType) {
    this.username = data.email
    this.password = data.password
    this.attributes = {
      email: data.email,
      name: data.firstName,
      family_name: data.lastName,
      'custom:marketing': data.terms_marketing === 'on' ? '1' : '0',
      'custom:thirdparties': data.terms_thirdparties === 'on' ? '1' : '0',
    }
  }
}
