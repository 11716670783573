import format from 'date-fns/format'
import it from 'date-fns/locale/it'
import dateFormat from 'dateformat'
import compact from 'lodash/compact'
import flatten from 'lodash/flatten'
import flattenDeep from 'lodash/flattenDeep'
import isArray from 'lodash/isArray'
import uniq from 'lodash/uniq'
import uniqBy from 'lodash/uniqBy'

export const APP_VET_MODE = process.env.REACT_APP_TYPE === 'web'

export const safeArray = (data: any): any[] => (isArray(data) ? data : [])
export const unique = (data: any[]): string[] => uniq(compact(flattenDeep(data))) || []
export const uniqueById = (data: any[], iteratee: string): any[] => uniqBy(flatten(data), iteratee)

export const arrayToDictionary = (data: any[], key: string): { [key: string]: any } =>
  data.reduce((acc: any, curr) => {
    acc[curr[key]] = curr
    return acc
  }, {})

export const DATE_MONTHS = [
  'Gennaio',
  'Febbraio',
  'Marzo',
  'Aprile',
  'Maggio',
  'Giugno',
  'Luglio',
  'Agosto',
  'Settembre',
  'Ottobre',
  'Novembre',
  'Dicembre',
]

export const DATE_MONTHS_US = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

// export const DATE_WEEKDAYS = ['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato']
export const DATE_WEEKDAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

dateFormat.i18n = {
  dayNames: ['Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab', 'Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato'],
  monthNames: [
    'Gen',
    'Feb',
    'Mar',
    'Apr',
    'Mag',
    'Giu',
    'Lug',
    'Ago',
    'Set',
    'Ott',
    'Nov',
    'Dic',
    'Gennaio',
    'Febbraio',
    'Marzo',
    'Aprile',
    'Maggio',
    'Giugno',
    'Luglio',
    'Agosto',
    'Settembre',
    'Ottobre',
    'Novembre',
    'Dicembre',
  ],
  timeNames: ['a', 'p', 'am', 'pm', 'A', 'P', 'AM', 'PM'],
}

export const formatDate = (date: any, f: string) => {
  if (!isValidDate(date)) {
    return ''
  }

  date.set

  return format(date, f, { locale: it })
}
const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
})

export const formatCurrency = (data: any): string => currencyFormatter.format(data)

export const isValidDate = (d: any): boolean => d instanceof Date && !isNaN(d.getTime())

export const fromDate = (date: any): Date => {
  const offsetTimezone = new Date().getTimezoneOffset() * 60000
  const dateTime = date.getTime()

  return new Date(dateTime - offsetTimezone)
}

export const getNow = () => {
  const offsetTimezone = new Date().getTimezoneOffset() * 60000
  const dateTime = new Date().getTime()

  return new Date(dateTime - offsetTimezone).toISOString()
}

export const DATE_FORMAT = 'dd/MM/yyyy'
export const TIME_FORMAT = 'HH:mm'
export const DATETIME_FORMAT = 'dd/MM/yyyy HH:mm'
export const LONG_DATE_FORMAT = 'EEEE, dd MMMM yyyy'

// const getTimezoneOffset = () => new Date().getTimezoneOffset() * 60000
const convertAnyToDate = (data: any): Date => (data instanceof Date ? data : new Date(data || ''))

export const DateConverter = {
  from(date: any): Date {
    const d = convertAnyToDate(date)
    return new Date(d.getTime())
  },

  fromServer(date: any): Date {
    const d = convertAnyToDate(date)
    return new Date(d.getTime())
  },
  /**
   * Generate a ISO date for the server that Ignore the offset
   * @param date
   */
  toServer(date: any): string | null {
    const d = convertAnyToDate(date)

    if (!isValidDate(d)) {
      return null
    }

    return new Date(d.getTime()).toISOString()
  },
  now() {
    return new Date(new Date().getTime())
  },
}

export const convertMillisecondsToUnits = (duration: number) => {
  const minutes = Math.floor(duration / (1000 * 60))
  const hours = Math.floor(duration / (1000 * 60 * 60))
  const days = Math.floor(duration / (1000 * 60 * 60 * 24))

  return {
    hours,
    minutes,
    days,
  }
}
