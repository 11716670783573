import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Avatar } from 'src/components/Avatar'
import { useCurrentUser } from 'src/hooks/useCurrentValues'
import { IonContent, IonPage, IonRouterLink } from '@ionic/react'
import { NavHeader } from '../../components/Header/NavHeader'
import { AppRouterHelper } from '../../services/AppRouterHelper'

export const PageNotifications: FC = () => {
  const { t } = useTranslation()
  const currentUser = useCurrentUser()

  return (
    <IonPage>
      <NavHeader titleClassName="text-center p-0" title={t('TITLE_NOTIFICATIONS', { count: 0 })} showBackButton={false}>
        <IonRouterLink routerLink={AppRouterHelper.VETERINARY_ROUTES.PROFILE}>
          <Avatar image={currentUser.avatar} title={currentUser.initials} className="cursor-pointer h-12 w-12" />
        </IonRouterLink>
      </NavHeader>
      <IonContent>
        <div className="h-full w-full flex items-center justify-center">{t('Notifications')}</div>
      </IonContent>
    </IonPage>
  )
}
