import max from 'lodash/max'
import React, { useState } from 'react'
import { Control, useController } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Tooltip } from 'src/components/Tooltip'
import { FieldWrapper } from 'src/components/forms/FieldWrapper'
import { IconInfo, IconInfoFill } from 'src/components/icons'
import { CurrencyField } from 'src/components/inputs/CurrencyField'
import { GenericProps } from 'src/helpers/props'
import { useCurrentValues } from 'src/hooks/useCurrentValues'
import { User } from 'src/models'
import { StatusTypes } from 'src/models/User'
import {
  EventPaymentStatus,
  EventPaymentType, // , EventCategory
} from 'src/models/events/enums'
import { IonRadioGroup, IonRadio, IonLabel, IonButton } from '@ionic/react'

type PaymentSectionProps = GenericProps & {
  name: string
  control: Control<any>
  category: any
  readonly?: boolean
  client: User | null
  showUpfront: boolean
}

export const PaymentSection: React.FC<PaymentSectionProps> = ({
  name,
  control,
  //   category,
  className = '',
  readonly = false,
  client,
  showUpfront = false,
}) => {
  const { t } = useTranslation()
  const { currentUser, currentFacility } = useCurrentValues()
  const [tooltipVisible, setTooltipVisible] = useState(false)

  const handleTooltipVisibility = (isVisible: boolean) => {
    setTooltipVisible(isVisible)
  }

  const upfrontTooltip = (
    <Tooltip text={t('TOOLTIP_APPOINTMENT_UPFRONT')} showOnHover={tooltipVisible}>
      <div onMouseEnter={() => handleTooltipVisibility(true)} onMouseLeave={() => handleTooltipVisibility(false)}>
        <IconInfo className="ml-1 w-5 text-grey-500" />
      </div>
    </Tooltip>
  )

  const isStripeEnabled = currentUser.isStripeEnabled(currentFacility.OrganizationId)
  const isDigitalDisabled = (client && client.status !== StatusTypes.ACTIVE) || !isStripeEnabled
  //   const isVideoConsultation = category === EventCategory.EVENT_CATEGORY_HEALTH_VIDEOCONSULTATION || false
  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({ name, control })

  const onPaymentCurrencyChange = (evt: any) => {
    const amount = max([0, parseInt(evt.target.value)]) || 0

    onChange({
      ...value,
      amount,
      status: amount > 0 ? EventPaymentStatus.PAYMENT_STATUS_PENDING : EventPaymentStatus.PAYMENT_STATUS_FREE,
    })
  }

  const onPaymentTypeChange = (type: string) => {
    onChange({
      ...value,
      type,
    })
  }

  /*
  const paymentOptions = [
    {
      value: 'a20',
      label: 'Anticipo (10%)',
    },
  ]*/

  return (
    <FieldWrapper className={`${className} ${readonly && 'opacity-70'}`} error={error && error.message}>
      <div className="flex flex-col space-x-2">
        <div className="flex space-x-8">
          <CurrencyField
            label={t('LABEL_PAYMENT')}
            className={'w-1/2'}
            placeholder={'15.00'}
            value={parseFloat(value.amount)}
            onChange={onPaymentCurrencyChange}
            disabled={readonly}
          />
          <CurrencyField
            label={t('LABEL_PAYMENT_UPFRONT')}
            className="w-1/2 text-grey-500"
            value={showUpfront ? value.amount : '-'}
            // disabled
            {...(!showUpfront && { tooltip: upfrontTooltip })}
          />
        </div>
        {/*<SelectField
          className="flex-1 h-full"
          value={value.mode}
          options={paymentOptions}
          onChange={onPaymentModeChange}
          disabled={value.amount === 0}
        />*/}
        <IonRadioGroup
          className="flex flex-col items-start my-2 mb-4"
          value={value.type}
          onIonChange={(e) => onPaymentTypeChange(e.detail.value)}
        >
          {!showUpfront && (
            <div className="flex items-center">
              <IonRadio
                value={EventPaymentType.PAYMENT_TYPE_CASH}
                className=""
                //   disabled={isVideoConsultation}
              />
              <IonLabel className="px-3">{t('LABEL_PHYSICAL_PAYMENT_METHOD')}</IonLabel>
            </div>
          )}

          <div className="flex items-start my-2">
            <IonRadio
              value={
                // EventPaymentType.PAYMENT_TYPE_DIGITAL
                false
              }
              className=""
              disabled={isDigitalDisabled}
            />
            <div className="flex flex-col justify-end">
              <IonLabel className={`px-3 ${isDigitalDisabled && 'text-grey-500'}`}>{t('LABEL_DIGITAL_PAYMENT_METHOD')}</IonLabel>
              {showUpfront && (
                <div className="mt-1 px-2 py-1 rounded flex items-center space-x-1 bg-primary-300 ml-4 text-primary-500">
                  <IconInfoFill className="w-4" />
                  <span>
                    {client?.firstName
                      ? t('MESSAGE_UPFRONT_PAYMENT_WILL_BE_SENT_TO', { name: client.firstName })
                      : t('MESSAGE_UPFRONT_PAYMENT_WILL_BE_SENT')}
                  </span>
                </div>
              )}
            </div>
          </div>
          {isDigitalDisabled && (
            <div className="flex flex-col items-start ml-8">
              {!isStripeEnabled ? (
                <span className="text-grey-500">{t('MESSAGE_DIGITAL_PAYMENT_NOT_ENABLED')}</span>
              ) : (
                <>
                  <span className="text-grey-500">
                    {t('MESSAGE_HEALTH_EVENT_CLIENT_NOT_ENABLED_DIGITAL_PAYMENT', { clientName: client?.fullName })}
                  </span>
                  <IonButton className="link" fill="clear">
                    {t('ACTION_INVITE')}
                  </IonButton>
                </>
              )}
            </div>
          )}
        </IonRadioGroup>
      </div>
    </FieldWrapper>
  )
}
