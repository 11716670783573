import { encode } from 'js-base64'
import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { FormResetPassword } from 'src/components/forms/FormResetPassword'
import { authThunks } from 'src/features/Auth/store/thunks'
import { useToast } from 'src/hooks/useToast'
import { IonContent, IonPage } from '@ionic/react'
import { NavHeader } from '../components/Header/NavHeader'

export const PagePasswordReset = (): ReactElement => {
  const { t } = useTranslation()
  const toast = useToast()
  const history = useHistory()
  const dispatch = useDispatch()

  const onSubmit = async (email: string) => {
    try {
      await dispatch<any>(authThunks.forgotPassword(email))
      toast.success(t('FORGOT_PASSWORD_SUCCESS_MESSAGE'))

      history.push({ pathname: `/password/new/${encode(email)}` })
    } catch ({ message }) {
      toast.error(t(message as string))
    }
  }

  return (
    <IonPage>
      <NavHeader title={t('TITLE_PAGE_FORGOT_PASSWORD')} showBackButton={true} />
      <IonContent>
        <div className="w-full h-full flex flex-col pt-4">
          <FormResetPassword onSubmit={onSubmit} />
        </div>
      </IonContent>
    </IonPage>
  )
}
