import { User } from 'src/models'
import { PayloadAction } from '@reduxjs/toolkit'
import { AuthState } from './slice'

export const authReducers = {
  setLoading: (state: AuthState, action: PayloadAction<boolean>): void => {
    state.isFetching = action.payload
  },
  setUser: (state: AuthState, action: PayloadAction<User>): void => {
    state.user = action.payload
  },
  setLoginData: (state: AuthState, action: PayloadAction<any>): void => {
    const { cognitoUser, cognitoUserId, user } = action.payload
    state.user = user
    state.accessToken = cognitoUser.getSignInUserSession().getAccessToken().getJwtToken()
    state.cognitoid = cognitoUserId
    state.cognitoUser = cognitoUser
    state.isFetching = false
  },
  resetLoginData: (state: AuthState): void => {
    state.user = null
    state.accessToken = null
    state.initialized = false
    state.isFetching = false
  },
}
