import React, { createContext, useContext, useState } from 'react'
import { Dialog } from '@headlessui/react'

// import { IonModal } from '@ionic/react'

const PopupModalContext = createContext<any>(undefined)

const PopupModalProvider: React.FC = ({ children }) => {
  const [visible, setVisible] = useState(false)
  const [content, setContent] = useState<React.ReactElement>()

  const showModal = (content: React.ReactElement) => {
    setContent(content)
    setVisible(true)
  }

  const hideModal = () => {
    setContent(undefined)
    setVisible(false)
  }
  return (
    <PopupModalContext.Provider value={{ content, showModal, hideModal }}>
      <Dialog as="div" className="text-black relative z-10 w-full bg-white" open={visible} onClose={hideModal}>
        <div className="fixed inset-0 bg-black opacity-60" aria-hidden="true" />
        <div className="fixed inset-0 flex items-center justify-center p-4 w-full">
          <Dialog.Panel className="w-full max-w-xl rounded bg-white">
            <Dialog.Description as="div">{content}</Dialog.Description>
          </Dialog.Panel>
        </div>
      </Dialog>
      {children}
    </PopupModalContext.Provider>
  )
}
/**
 * Need to use this hook in order to have the possibility to use Redux store inside modals
 */
const usePopupModal = (): any => {
  const { showModal, hideModal } = useContext(PopupModalContext)
  return { showModal, hideModal }
}

export { PopupModalContext, PopupModalProvider, usePopupModal }
