import { addOutline } from 'ionicons/icons'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Banner } from 'src/components/Banner'
import { CTACard } from 'src/components/cards/CTACard'
import { PetCard } from 'src/components/cards/PetCard'
import { IconPlus } from 'src/components/icons'
import { IconLink } from 'src/components/icons/IconLink'
import { authThunks } from 'src/features/Auth/store/thunks'
import { familyThunks } from 'src/features/Family/store/thunks'
import { useCurrentFamily, useCurrentUser } from 'src/hooks/useCurrentValues'
import { useInterval } from 'src/hooks/useInterval'
import { useModal } from 'src/hooks/useModal'
import { Pet } from 'src/models'
import { IonButton, IonContent, IonPage, IonSlide, useIonViewWillEnter } from '@ionic/react'
import { FacilityCard } from '../components/FacilityCard'
import { AppHeader } from '../components/Header/AppHeader'
import { Slider } from '../components/Slider/Slider'
import { AppRouterHelper } from '../services/AppRouterHelper'
import { PageAddPet } from './PageAddPet'
import { PageAddVeterinary } from './PageAddVeterinary'

export const PageHome: FC = () => {
  const { showModal, hideModal } = useModal()
  const history = useHistory()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const currentUser = useCurrentUser()
  const { family, pets } = useCurrentFamily()

  const updatePets = () => dispatch(familyThunks.setFamily(family.id))
  const updateUser = () => dispatch(authThunks.checkLogin())

  useIonViewWillEnter(() => {
    if (currentUser.isVet) {
      history.replace(AppRouterHelper.ROUTES.WRONG_APP)
    } else {
      updatePets()
      updateUser()
    }
  })

  const onAddPet = () => {
    showModal(
      <PageAddPet
        onDismiss={() => {
          setTimeout(updatePets, 50)
          hideModal()
        }}
      />
    )
  }

  const onAddVeterinary = () => {
    showModal(
      <PageAddVeterinary
        onDismiss={() => {
          setTimeout(() => updateUser(), 50)
          hideModal()
        }}
      />
    )
  }

  useInterval(() => {
    dispatch(familyThunks.updateFamilyEvents(family))
  }, 1000 * 30)

  const editPet = (pet: Pet) => {
    history.push(`/app/pets/${pet.id}/edit`)
  }

  return (
    <IonPage>
      <AppHeader />
      <IonContent>
        <div className="py-8 space-y-14">
          {!currentUser.isProfileCompleted && (
            <Banner
              title={t('TITLE_WELCOME_USER', { firstName: currentUser.firstName })}
              link={{ label: t('ACTION_COMPLETE_PROFILE'), route: AppRouterHelper.ROUTES.PROFILE }}
            />
          )}
          <div className="-mx-6 space-y-14">
            <Slider title={t('TITLE_MY_PETS')} sliderKey={pets.map((pet) => pet.dateUpdated).join('_')}>
              {pets.map((pet) => (
                <IonSlide key={pet.id} className="w-2/5">
                  <PetCard pet={pet} actionName={t('ACTION_EDIT')} onClick={() => editPet(pet)} />
                </IonSlide>
              ))}
              <IonSlide className="w-2/5">
                <CTACard ionicIcon={addOutline} label={t('ACTION_ADD')} onClick={onAddPet} />
              </IonSlide>
            </Slider>
            {currentUser.vetFacilities.length > 0 ? (
              <div className="px-6">
                <div className="-mx-6 flex flex-row items-center pl-6 mb-6">
                  <h1 className="mb-0">{t('TITLE_MY_VETERINARIES')}</h1>
                  <IonButton fill="clear" size="small" onClick={onAddVeterinary}>
                    <IconPlus className="ml-3 w-6 h-6" />
                  </IonButton>
                </div>

                {currentUser.vetFacilities.map((facility) => (
                  <FacilityCard facility={facility} key={facility.id} />
                ))}
              </div>
            ) : (
              <Slider sliderKey="test" title={t('TITLE_MY_VETERINARIES')}>
                <IonSlide className="w-2/5">
                  <CTACard icon={<IconLink />} label={t('ACTION_CONNECT_TO')} onClick={onAddVeterinary} />
                </IonSlide>
              </Slider>
            )}
          </div>
        </div>
      </IonContent>
    </IonPage>
  )
}
