import { DateConverter } from '../../../utils'
import { EventPaymentCurrency, EventPaymentPaymentType, EventPaymentStatus, EventPaymentType } from '../enums'

export type PaymentEventPayloadInterface = {
  amount?: number
  status?: EventPaymentStatus
  currency?: EventPaymentCurrency
  type?: EventPaymentType
  createdDate?: Date | string
  paidDate?: Date | string

  payment_type?: EventPaymentPaymentType
}

export class PaymentEventPayload {
  amount: number | null
  status: EventPaymentStatus | null
  currency: EventPaymentCurrency | EventPaymentCurrency.PAYMENT_CURRENCY_EUR
  type: EventPaymentType | null
  createdDate: Date | null
  paidDate: Date | null
  payment_type?: EventPaymentPaymentType | null

  constructor(payload: PaymentEventPayloadInterface) {
    this.amount = payload.amount || null
    this.status = payload.status || null
    this.currency = EventPaymentCurrency.PAYMENT_CURRENCY_EUR
    this.type = payload.type || null
    this.createdDate = payload.createdDate ? DateConverter.fromServer(payload.createdDate) : null
    this.paidDate = payload.paidDate ? DateConverter.fromServer(payload.paidDate) : null
    this.payment_type = payload.payment_type || null
  }

  json(): any {
    return {
      amount: this.amount,
      status: this.status,
      currency: this.currency,
      type: this.type,
      createdDate: this.createdDate,
      paidDate: this.paidDate,
      payment_type: this.payment_type,
    }
  }

  isAnUpfrontPayment() {
    return this.payment_type === EventPaymentPaymentType.UPFRONT
  }

  isPending() {
    return this.status === EventPaymentStatus.PAYMENT_STATUS_PENDING
  }

  hasToBePaid() {
    return this.status === EventPaymentStatus.PAYMENT_STATUS_TO_BE_PAID
  }

  isPaid() {
    return this.status === EventPaymentStatus.PAYMENT_STATUS_PAID
  }
}
