import { DateConverter } from '../utils'

export type WorkingTime = {
  from: string
  to: string
}

export class TimeTable {
  id: string
  default: boolean
  name: string

  FacilityId: string
  user_defined: boolean

  monday: WorkingTime[] | null
  tuesday: WorkingTime[] | null
  wednesday: WorkingTime[] | null
  thursday: WorkingTime[] | null
  friday: WorkingTime[] | null
  saturday: WorkingTime[] | null
  sunday: WorkingTime[] | null

  dateCreated: Date | null
  dateUpdated: Date | null

  constructor(data: { [key: string]: any }) {
    this.id = data.id
    this.default = data.default || false
    this.name = data.name || ''
    this.FacilityId = data.FacilityId || ''
    this.user_defined = data.user_defined || false

    this.monday = data.monday || null
    this.tuesday = data.tuesday || null
    this.wednesday = data.wednesday || null
    this.thursday = data.thursday || null
    this.friday = data.friday || null
    this.saturday = data.saturday || null
    this.sunday = data.sunday || null

    this.dateCreated = data.dateCreated ? DateConverter.fromServer(data.dateCreated) : null
    this.dateUpdated = data.dateUpdated ? DateConverter.fromServer(data.dateUpdated) : null
  }

  getHoursByWeekDay(weekDay: number): WorkingTime[] | null {
    switch (weekDay) {
      case 0:
        return this.sunday
      case 1:
        return this.monday
      case 2:
        return this.tuesday
      case 3:
        return this.wednesday
      case 4:
        return this.thursday
      case 5:
        return this.friday
      case 6:
        return this.saturday
      default:
        return null
    }
  }

  json() {
    return {
      ...(this.id ? { id: this.id } : {}),
      default: this.default,
      name: this.name,
      FacilityId: this.FacilityId,
      ...(this.monday ? { monday: this.monday } : {}),
      ...(this.tuesday ? { tuesday: this.tuesday } : {}),
      ...(this.wednesday ? { wednesday: this.wednesday } : {}),
      ...(this.thursday ? { thursday: this.thursday } : {}),
      ...(this.friday ? { friday: this.friday } : {}),
      ...(this.saturday ? { saturday: this.saturday } : {}),
      ...(this.sunday ? { sunday: this.sunday } : {}),
    }
  }
}
