import memoizeOne from 'memoize-one'
import React, { forwardRef } from 'react'
import { Capacitor } from '@capacitor/core'
import { isPlatform, IonSelect, IonSelectOption } from '@ionic/react'
import { FieldWrapper, FieldWrapperProps } from '../forms/FieldWrapper'
import { IconArrow, IconArrowType } from '../icons'
import { CustomSelect, DefaultItemElement } from './CustomSelect'

const isMobile = memoizeOne(() => {
  if (Capacitor.getPlatform() === 'web') {
    return false
  }

  const isMobile = !isPlatform('desktop')
  console.log('MobileDevice', isMobile)
  return isMobile
})

const isMobilePlatform = isMobile()

type SelectFieldProps = FieldWrapperProps & {
  label?: string
  className?: string
  description?: string
  options: any[]
  large?: boolean
  readonly?: boolean
  searchable?: boolean
  itemElement?: any
  [key: string]: any
}

export const SelectField = forwardRef<HTMLSelectElement, SelectFieldProps>(
  (
    {
      error,
      large = false,
      searchable = false,
      label = undefined,
      readonly = false,
      className = '',
      description,
      itemElement = DefaultItemElement,
      options,
      ...rest
    },
    ref
  ) => (
    <FieldWrapper className={`block ${className ? className : 'mb-6'}`} label={label} description={description} error={error || ''}>
      <div className="overflow-visible">
        {isMobilePlatform ? (
          <div className="flex relative">
            <IonSelect onIonChange={rest.onChange} {...rest} className={`input-element ${large ? 'h-12 text-h200' : 'h-12'}`}>
              <IonSelectOption value="">...</IonSelectOption>
              {options.map((item: any) => (
                <IonSelectOption key={item.value} value={item.value}>
                  {item.label}
                </IonSelectOption>
              ))}
            </IonSelect>
            <IconArrow type={IconArrowType.BOTTOM} className="text-primary-500 w-6 h-6 absolute top-4 right-3" />
          </div>
        ) : (
          <CustomSelect
            options={options}
            {...rest}
            ref={ref}
            large={large}
            readonly={readonly}
            searchable={searchable}
            itemElement={itemElement}
            value={rest?.value}
          />
        )}
      </div>
    </FieldWrapper>
  )
)

SelectField.displayName = 'SelectField'
