import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { EmptyPetAttributes, PetAttributes, PetsAPI } from '../api/PetsAPI'

export const usePetAttributes = (permanentSkipCache = false): [PetAttributes, (skipCache: boolean) => void] => {
  const { t } = useTranslation()
  const [attributes, setData] = useState<PetAttributes>(EmptyPetAttributes)

  const translateAttributes = (petAttributes: PetAttributes): PetAttributes => {
    petAttributes.sex = petAttributes.sex.map((item) => {
      if (item.name === 'M') item.name = t('SEX_MALE')
      if (item.name === 'F') item.name = t('SEX_FEMALE')
      return item
    })
    return petAttributes
  }

  const reload = async (skipCache = false): Promise<void> => {
    const data = translateAttributes(await PetsAPI.getSpeciesConfig(permanentSkipCache || skipCache))
    setData(data)
  }

  useEffect(() => {
    reload(false)
  }, [])

  return [attributes, reload]
}
