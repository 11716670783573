import { GlobalStoreState } from 'src/Store'
import { EventsAPI } from 'src/api/EventsAPI'
import { User } from 'src/models'
import { Dispatch } from '@reduxjs/toolkit'
import { veterinaryReducers } from './slice'

export const veterinaryThunks = {
  getRequests:
    () =>
    (dispatch: Dispatch, getState: () => GlobalStoreState): Promise<any> => {
      const areFiltersClear = getState().veterinary.requestsFilters.areClear()

      if (areFiltersClear) {
        return EventsAPI.getAllUserRequests().then((requests) => {
          dispatch(veterinaryReducers.setRequests(requests))
          const customers = requests.map((request) => new User({ ...request.User, FamilyId: request.parentEvent.payload.familyId }))

          const uniques = [...new Map(customers.map((item) => [item['id'], item])).values()]

          dispatch(veterinaryReducers.setCustomers(uniques))
        })
      } else {
        return EventsAPI.getFilteredRequests(getState().veterinary.requestsFilters).then((requests) =>
          dispatch(veterinaryReducers.setRequests(requests))
        )
      }
    },
}
