import { FC, useEffect, useState } from 'react'

type TooltipProps = {
  className?: string
  showOnHover?: boolean
  text?: string
}

export const Tooltip: FC<TooltipProps> = ({ className, text, children, showOnHover = false }) => {
  const [showTooltip, setShow] = useState(false)

  if (!text) {
    return <>{children}</>
  }

  useEffect(() => {
    // useful?
  }, [showTooltip])

  return showOnHover ? (
    <div className="relative flex flex-col items-center group">
      <div className="group">{children}</div>
      <div className="absolute bottom-0 flex flex-col items-center mb-8 group-hover:flex z-1000 ">
        <span className="relative w-72 p-2 text-body-small text-center leading-loose text-white whitespace-no-wrap bg-black shadow-lg rounded-md">
          {text}
        </span>
        <div className="w-3 h-3 -mt-2 transform rotate-45 bg-black"></div>
      </div>
    </div>
  ) : (
    <div className={`relative cursor-pointer ${className}`}>
      {showTooltip && <div className="fixed top-0 left-0 w-full h-full z-1000" onClick={() => setShow(false)} />}
      <div className={`tooltip flex flex-col items-center ${showTooltip ? 'z-2000' : 'hidden'}`}>
        <div className=" bg-black text-white w-80 py-3 px-6 text-body-medium text-center rounded mb-2">{text}</div>
        <div className="w-3 h-3 -mt-4 transform rotate-45 bg-black" />
      </div>
      <div onClick={() => setShow(true)} onMouseEnter={() => showOnHover && setShow(true)}>
        {children}
      </div>
    </div>
  )
}
