import { DateConverter } from '../utils'
import { FacilityServiceCategory } from './FacilityServiceCategory'
import { User } from './User'

export enum UpfrontType {
  UPFRONT_TYPE_NONE = 'NONE',
  UPFRONT_TYPE_PARTIAL = 'PARTIAL',
  UPFRONT_TYPE_ALL = 'ALL',
}

export class FacilityService {
  id: string
  name: string
  internal_name: string
  FacilityId: string
  TimetableId: string
  ServiceCategoryId: number
  user_defined: boolean
  duration: number
  price: number
  deposit: number
  enabled: boolean
  enabledPO: boolean
  user_ids: string[]
  category?: FacilityServiceCategory
  MainServiceId: number

  upfront: UpfrontType

  dateCreated: Date | null
  dateUpdated: Date | null
  vets: User[]

  constructor(data: { [key: string]: any }) {
    this.id = data.id || ''
    this.name = data.name || ''
    this.FacilityId = data.FacilityId || ''

    this.internal_name = data.internal_name || ''
    this.FacilityId = data.FacilityId || ''
    this.TimetableId = data.TimetableId || ''
    this.ServiceCategoryId = data.ServiceCategoryId || ''
    this.user_defined = data.user_defined || true
    this.duration = data.duration || 0
    this.price = data.price || 0
    this.deposit = data.deposit || 0
    this.enabled = data.enabled || false
    this.enabledPO = data.enabledPO || false
    this.user_ids = data.user_ids || []
    this.category = data.category || null
    this.vets = []
    this.MainServiceId = data.MainServiceId || null

    this.upfront = data.upfront

    this.dateCreated = data.dateCreated ? DateConverter.fromServer(data.dateCreated) : null
    this.dateUpdated = data.dateUpdated ? DateConverter.fromServer(data.dateUpdated) : null
  }

  json() {
    return {
      ...(this.id ? { id: this.id } : {}),
      name: this.name,
      FacilityId: this.FacilityId,
      TimetableId: this.TimetableId,
      ServiceCategoryId: this.ServiceCategoryId,
      user_defined: this.user_defined,
      duration: this.duration,
      price: this.price,
      deposit: this.deposit,
      enabled: this.enabled,
      enabledPO: this.enabledPO,
      MainServiceId: this.MainServiceId,
      user_ids: this.user_ids,
    }
  }
}
