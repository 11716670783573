import { FC } from 'react'
import { Pet } from '../models'
import { Avatar } from './Avatar'

export type PetChipProps = {
  className?: string
  active?: boolean
  readonly?: boolean
  onClick?: any
  pet: Pet
}

export const PetChip: FC<PetChipProps> = ({ pet, className = '', active = false, readonly = false, onClick }) => {
  const classes = active ? 'bg-primary-500 text-white' : 'bg-grey-100 text-black'

  return (
    <div
      className={`h-8 rounded-full p-1 pr-3 inline-flex items-center ${classes} ${!readonly && 'cursor-pointer'} ${className}`}
      {...(onClick && !readonly ? { onClick } : null)}
    >
      <Avatar image={pet.avatar} title={pet.initials} className="w-6 h-6 mr-2 text-body-tiny" />
      <span className="text-body-small">{pet.name}</span>
    </div>
  )
}
