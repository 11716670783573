import { format } from 'date-fns'
import { enUS } from 'date-fns/locale'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { IconCalendar, IconInfo } from 'src/components/icons'
import { Facility } from 'src/models'
import { Notification } from 'src/models/events/Notification'
import { EventNotificationAction } from 'src/models/events/enums'
import { formatDate } from 'src/utils'

type FacilityCardNotificationProps = {
  facility: Facility
  notification: Notification
}

export const FacilityCardNotification: React.FC<FacilityCardNotificationProps> = ({ notification }) => {
  const { t } = useTranslation()

  const paymentData = notification.payment
  const bookingData = notification.appointment

  const time =
    notification.action != EventNotificationAction.EVENT_TYPE_PAYMENT
      ? format(new Date(bookingData.event?.start), 'h:mm a', { locale: enUS })
      : ''
  const date =
    notification.action != EventNotificationAction.EVENT_TYPE_PAYMENT
      ? format(new Date(bookingData.event?.start), 'MMMM dd, yyyy', { locale: enUS })
      : ''

  const getNotification = (action: EventNotificationAction) => {
    let notification = <></>
    switch (action) {
      case EventNotificationAction.EVENT_TYPE_PAYMENT:
        notification = (
          <div className={`mt-2 w-full flex flex-col justify-between px-4 py-2 bg-warning-100`}>
            <span className="text-black font-bold flex items-center">
              <IconInfo className="w-4 h-4 text-warning-600 fill-none mr-2" /> {t('TITLE_NOTIFICATIONS_PAYMENT')}
            </span>
            <span className="mt-2">{t('MESSAGE_NOTIFICATIONS_PAYMENT', { amount: paymentData.amount })}</span>
          </div>
        )
        break

      case EventNotificationAction.EVENT_TYPE_BOOKING:
        notification = (
          <div className={`mt-2 w-full flex flex-col justify-between px-4 py-2 bg-grey-100`}>
            <span className="text-black font-bold flex items-center">
              <IconCalendar className="w-6 h-6 text-primary-500 mr-2" />
              {t('LABEL_APPOINTMENT_REQUESTED')}
            </span>
            <span className="mt-2">{t('MESSAGE_NOTIFICATIONS_APPOINTMENT_INVITE')}</span>
          </div>
        )
        break

      case EventNotificationAction.EVENT_TYPE_NEXT_APPOINTMENT:
        notification = (
          <div className={`mt-2 w-full flex flex-col justify-between px-4 py-2 bg-grey-100`}>
            <span className="text-black font-bold flex items-center">
              <IconCalendar className="w-6 h-6 text-primary-500 mr-2" />
              {t('LABEL_NEXT_APPOINTMENT')}
            </span>
            <span className="mt-2 capitalize">{t('MESSAGE_NOTIFICATIONS_APPOINTMENT', { date, time })}</span>
          </div>
        )
        break

      default:
        break
    }
    return notification
  }

  return <> {getNotification(notification.action)}</>
}
