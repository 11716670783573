import React from 'react'
import { AllEventMask, ChildEventMask } from 'src/models/events'
import { Switcher } from '../Switcher'
import { BotAddAttachment } from './Bot/BotAddAttachment'
import { BotComeToFacility } from './Bot/BotComeToFacility'
import { BotCreateConversation } from './Bot/BotCreateConversation'
import { BotJoinVideomeeting } from './Bot/BotJoinVideomeeting'
import { BotOpenConversation } from './Bot/BotOpenConversation'
import { BotAction } from './Bot/constants'

export type BotRendererProps = {
  event: AllEventMask | undefined
  action?: string
  onSuccess: (event: AllEventMask, childEvents?: ChildEventMask[]) => void
  onReset?: () => void
}

export const BotRenderer: React.FC<BotRendererProps> = ({ event, action = '', onSuccess, onReset = () => ({}) }) => {
  const onInternalSuccess = (event: AllEventMask) => {
    onSuccess(event)
  }

  return (
    <div className="mt-8 flex flex-col space-y-4">
      {event && (
        <Switcher active={action}>
          <BotOpenConversation event={event} name={BotAction.OPEN_CHAT} onSuccess={onInternalSuccess} onReset={onReset} />
          <BotComeToFacility event={event} name={BotAction.COME_TO_FACILITY} onSuccess={onInternalSuccess} onReset={onReset} />
          <BotAddAttachment event={event} name={BotAction.ADD_ATTACHMENT} onSuccess={onInternalSuccess} onReset={onReset} />
          <BotCreateConversation event={event} name={BotAction.CREATE_CONVERSATION} onSuccess={onInternalSuccess} onReset={onReset} />
          <BotJoinVideomeeting event={event} name={BotAction.JOIN_VIDEOMEETING} />
        </Switcher>
      )}
    </div>
  )
}
