import { EventBotActions } from '../enums'
import { ParentEventPayload, ParentEventPayloadInterface } from './ParentEventPayload'

export type BotEventPayloadInterface = ParentEventPayloadInterface & {
  eventAction: EventBotActions | string
}

export class BotEventPayload extends ParentEventPayload {
  eventAction: EventBotActions | string

  constructor(payload: BotEventPayloadInterface) {
    super(payload)
    this.eventAction = payload.eventAction
  }

  json(): any {
    return {
      ...super.json(),
      eventAction: this.eventAction,
    }
  }
}
