import { Animation, createAnimation } from '@ionic/react'

const PAGE_FADE_ANIMATION_DURATION = 150
const PAGE_MOBILE_ANIMATION_DURATION = 300

export const PageMobileAnimation = (baseEl: HTMLElement, opts?: any): Animation => {
  if (opts.direction === 'forward') {
    const enteringAnimation = createAnimation()
      .addElement(opts.enteringEl)
      .duration(PAGE_MOBILE_ANIMATION_DURATION)
      .easing('ease-in-out')
      .keyframes([
        { offset: 0, opacity: '1', transform: 'translateX(100%)' },
        { offset: 1, opacity: '1', transform: 'translateX(0)' },
      ])

    const leavingAnimation = createAnimation()
      .addElement(opts.leavingEl)
      .duration(PAGE_MOBILE_ANIMATION_DURATION)
      .easing('ease-in-out')
      .keyframes([
        { offset: 0, opacity: '1', transform: 'translateX(0)' },
        { offset: 1, opacity: '1', transform: 'translateX(-100%)' },
      ])

    return createAnimation().addAnimation([enteringAnimation, leavingAnimation])
  }

  const enteringAnimation = createAnimation()
    .addElement(opts.enteringEl)
    .duration(PAGE_MOBILE_ANIMATION_DURATION)
    .easing('ease-in-out')
    .keyframes([
      { offset: 0, opacity: '1', transform: 'translateX(-100%)' },
      { offset: 1, opacity: '1', transform: 'translateX(0)' },
    ])

  const leavingAnimation = createAnimation()
    .addElement(opts.leavingEl)
    .duration(PAGE_MOBILE_ANIMATION_DURATION)
    .easing('ease-in-out')
    .keyframes([
      { offset: 0, opacity: '1', transform: 'translateX(0)' },
      { offset: 1, opacity: '1', transform: 'translateX(100%)' },
    ])

  return createAnimation().addAnimation([enteringAnimation, leavingAnimation])
}

export const PageFadeAnimation = (baseEl: HTMLElement, opts?: any): Animation => {
  const enteringAnimation = createAnimation()
    .addElement(opts.enteringEl)
    .duration(PAGE_FADE_ANIMATION_DURATION)
    .easing('ease-in')
    .fromTo('opacity', 0, 1)

  if (opts.direction === 'forward') {
    return enteringAnimation
  }

  const leavingAnimation = createAnimation()
    .addElement(opts.leavingEl)
    .duration(PAGE_FADE_ANIMATION_DURATION)
    .easing('ease-out')
    .fromTo('opacity', 1, 0)

  return createAnimation().addAnimation([enteringAnimation, leavingAnimation])
}

export const DrawerEnterFromLeft = (baseEl: any) => {
  const backdropAnimation = createAnimation()
    .addElement(baseEl.querySelector('ion-backdrop')!)
    .fromTo('opacity', '0.01', 'var(--backdrop-opacity)')

  const wrapperAnimation = createAnimation()
    .addElement(baseEl.querySelector('.modal-wrapper')!)
    .keyframes([
      { offset: 0, opacity: '1', transform: 'translateX(100%)' },
      { offset: 1, opacity: '1', transform: 'translateX(0)' },
    ])

  return createAnimation().addElement(baseEl).easing('ease-out').duration(300).addAnimation([backdropAnimation, wrapperAnimation])
}

export const DrawerLeaveFromLeft = (baseEl: any) => DrawerEnterFromLeft(baseEl).direction('reverse')

export const DrawerEnterFromTop = (baseEl: any) => {
  const backdropAnimation = createAnimation()
    .addElement(baseEl.querySelector('ion-backdrop')!)
    .fromTo('opacity', '0.01', 'var(--backdrop-opacity)')

  const wrapperAnimation = createAnimation()
    .addElement(baseEl.querySelector('.popover-wrapper')!)
    .keyframes([
      { offset: 0, opacity: '1', transform: 'translateY(100%)' },
      { offset: 1, opacity: '1', transform: 'translateY(0)' },
    ])

  return createAnimation().addElement(baseEl).easing('ease-out').duration(300).addAnimation([backdropAnimation, wrapperAnimation])
}

export const DrawerLeaveFromTop = (baseEl: any) => DrawerEnterFromTop(baseEl).direction('reverse')
