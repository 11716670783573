import { useEffect, useState } from 'react'
import { ModalCall } from 'src/apps/petOwner/pages/ModalCall'
import { useCurrentCall } from 'src/hooks/useCurrentValues'
import { useModal } from 'src/hooks/useModal'
import { useParticipantIds, DailyVideo, DailyAudio } from '@daily-co/daily-react'

export const CallMiniature = () => {
  const [calleeId, setCalleeId] = useState<string | null>()
  const remoteParticipantIds = useParticipantIds({ filter: 'remote' })
  const { callObject } = useCurrentCall()
  const { showModal, hideModal } = useModal()

  const returnToCall = () => {
    showModal(<ModalCall onDismiss={hideModal} />)
  }

  useEffect(() => {
    const callee = remoteParticipantIds?.length > 0 ? remoteParticipantIds[0] : null
    setCalleeId(callee)
  }, [remoteParticipantIds])

  return (
    <>
      {callObject && calleeId && (
        <div className="absolute flex justify-center items-center z-20 top-20 right-10 h-1/5 w-3/6" onClick={returnToCall}>
          <DailyVideo
            style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', borderRadius: 10 }}
            automirror
            sessionId={calleeId}
            type={'video'}
          />
          <DailyAudio />
        </div>
      )}
    </>
  )
}
