import React from 'react'

export const IconLink: React.FC<{ className?: string }> = ({ className }: { className?: string }) => (
  <svg width="28" height="28" viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg" className={`fill-current ${className}`}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.3667 8.99982H25.6667C26.5871 8.99982 27.3333 9.74601 27.3333 10.6665C27.3333 11.587 26.5871 12.3332 25.6667 12.3332H17.3333C16.4129 12.3332 15.6667 11.587 15.6667 10.6665V2.33315C15.6667 1.41268 16.4129 0.666487 17.3333 0.666487C18.2538 0.666487 19 1.41268 19 2.33315V6.61649L24.4833 1.14982C25.1369 0.496284 26.1965 0.496284 26.85 1.14982C27.5035 1.80335 27.5035 2.86295 26.85 3.51649L21.3667 8.99982ZM2.33649 15.6665H10.6698C11.5903 15.6665 12.3365 16.4127 12.3365 17.3332V25.6665C12.3365 26.587 11.5903 27.3332 10.6698 27.3332C9.74934 27.3332 9.00315 26.587 9.00315 25.6665V21.3665L3.51982 26.8498L3.51015 26.8595C2.85662 27.5077 1.80135 27.5034 1.15315 26.8498L1.14349 26.8402C0.49529 26.1866 0.499617 25.1314 1.15315 24.4832L6.61982 18.9998H2.33649C1.41601 18.9998 0.66982 18.2536 0.66982 17.3332C0.66982 16.4127 1.41601 15.6665 2.33649 15.6665Z"
      fill="#0170F4"
    />
  </svg>
)
