import React, { ChangeEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { EventsAPI } from 'src/api/EventsAPI'
import { useCurrentValues } from 'src/hooks/useCurrentValues'
import { ChatEvent } from 'src/models/events'
import { IonButton } from '@ionic/react'
import { IconPhoto } from '../../icons'
import { BotActionMessage } from '../BotActionMessage'
import { BotMessageProps } from './constants'

export const BotAddAttachment: React.FC<BotMessageProps> = ({ event, onSuccess, onReset }) => {
  const { t } = useTranslation()

  const [preview, setPreview] = useState<{ file: File | null; style: string; src: string; type: string }>({
    file: null,
    style: '',
    src: '',
    type: '',
  })

  const { currentFacility, currentUser } = useCurrentValues()

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files
    if (!files || !files.length) {
      return
    }

    const file = files[0]

    if (file.type === 'application/pdf') {
      setPreview({
        file,
        style: '',
        src: file.name,
        type: 'application',
      })

      return
    }

    const image = new Image()
    const objectUrl = URL.createObjectURL(file)
    image.onload = () => {
      setPreview({
        file,
        style: '',
        src: objectUrl,
        type: 'image',
      })
    }

    image.src = objectUrl
  }

  const onSave = async () => {
    const evt = new ChatEvent(
      {
        parent_id: event.id,
        file: preview.file,
      },
      {
        message: '',
        facilityId: currentFacility.id,
        userId: currentUser.id,
        user: currentUser,
        seen: false,
      }
    )

    await EventsAPI.createMessage(evt)
    onSuccess && onSuccess(event)
  }

  return (
    <BotActionMessage message={t('BOT_ADD_ATTACHMENT_MESSAGE', { name: event.User?.firstName })}>
      <div className="flex flex-col items-start">
        <IonButton size="small" fill="outline" className="button-xsmall flex items-center mr-2">
          <input
            type="file"
            onChange={onChange}
            accept="image/*, video/*, application/pdf"
            className="cursor-pointer absolute bg-grey-100 inset-0 opacity-0 z-10 appearance-none"
          />
          <IconPhoto className="w-4 h-4 mr-2" />
          {t('LABEL_ADD_ATTACHMENT')}
        </IonButton>
        {preview.src && preview.type === 'image' && (
          <div className="w-full h-auto py-6 flex justify-center">
            <img src={preview.src} className="rounded shadow-lg" />
          </div>
        )}
        {preview.src && preview.type === 'application' && <div className="w-full h-auto py-6 flex justify-center">{preview.src}</div>}

        <div className="flex items-center justify-between w-full">
          <IonButton color="primary" fill="outline" size="small" onClick={onReset} className="button-xsmall mt-4">
            {t('ACTION_CANCEL')}
          </IonButton>
          {preview.src && (
            <IonButton color="primary" fill="solid" size="small" onClick={onSave} className="button-xsmall mt-4">
              {t('ACTION_SAVE')}
            </IonButton>
          )}
        </div>
      </div>
    </BotActionMessage>
  )
}
