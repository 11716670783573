import React, { useState } from 'react'
import { IonModal } from '@ionic/react'

const ModalContext = React.createContext<any>(undefined)

const ModalProvider: React.FC = ({ children }) => {
  const [visible, setVisible] = useState(false)
  const [content, setContent] = useState<React.ReactElement>()

  const showModal = (component: React.ReactElement) => {
    setContent(component)
    setVisible(true)
  }

  const hideModal = () => {
    setContent(undefined)
    setVisible(false)
  }
  return (
    <ModalContext.Provider value={{ content, showModal, hideModal }}>
      <IonModal isOpen={visible}>{content}</IonModal>
      {children}
    </ModalContext.Provider>
  )
}
/**
 * Need to use this hook in order to have the possibility to use Redux store inside modals
 */
const useModal = (): any => {
  const { showModal, hideModal } = React.useContext(ModalContext)
  return { showModal, hideModal }
}

export { ModalContext, ModalProvider, useModal }
