import React from 'react'
import { useTranslation } from 'react-i18next'
import { ModalCall } from 'src/apps/petOwner/pages/ModalCall'
import { DrawerCall } from 'src/apps/veterinary/drawers/DrawerCall'
import { useCurrentUser } from 'src/hooks/useCurrentValues'
import { useDrawer } from 'src/hooks/useDrawer'
import { useModal } from 'src/hooks/useModal'
import { VideomeetingStatus } from 'src/models/events/enums'
import { IonButton } from '@ionic/react'
import { BotActionMessage } from '../BotActionMessage'
import { BotMessageProps } from './constants'

export const BotJoinVideomeeting: React.FC<BotMessageProps> = ({ event }) => {
  const { t } = useTranslation()
  const { isVet } = useCurrentUser()
  const isVideomeetingClosed = event.payload?.videomeeting?.status === VideomeetingStatus.CLOSED || false

  let openCall = null

  if (isVet) {
    const { showDrawer, hideLastDrawer } = useDrawer()
    openCall = () => {
      showDrawer(<DrawerCall event={event} onDismiss={hideLastDrawer} />, 'fullscreen')
    }
  } else {
    const { showModal, hideModal } = useModal()
    openCall = () => {
      showModal(<ModalCall event={event} onDismiss={hideModal} />)
    }
  }

  return (
    <BotActionMessage message={t('BOT_JOIN_VIDEOMEETING')} date={event.dateCreated}>
      <div className="flex flex-col items-start">
        <IonButton color="primary" fill="solid" size="small" disabled={isVideomeetingClosed} onClick={openCall} className="mt-2">
          {t('ACTION_JOIN')}
        </IonButton>
      </div>
    </BotActionMessage>
  )
}
