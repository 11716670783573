import { eye, eyeOff } from 'ionicons/icons'
import React, { useMemo, useState, ReactElement, forwardRef } from 'react'
import { IonIcon, IonInput, IonItem, IonLabel } from '@ionic/react'
import { FormError } from './FormError'

type InputFieldProps = {
  error?: string
  label: string
  placeholder?: string
  className?: string
  description?: string
  [key: string]: any
  onIonChange?: (e: CustomEvent) => void
}

export const InputPassword = forwardRef<HTMLIonInputElement, InputFieldProps>(
  ({ error, label, placeholder, className = '', description, onIonChange = () => null, ...rest }: InputFieldProps, ref): ReactElement => {
    const [showPassword, setShowPassword] = useState(false)
    const inputType = useMemo(() => (showPassword ? 'text' : 'password'), [showPassword])
    const iconName = useMemo(() => (showPassword ? eye : eyeOff), [showPassword])

    const toggleShowPassword = () => {
      setShowPassword(!showPassword)
    }

    return (
      <div className={`block mb-6 ${className}`}>
        <IonItem color="transparent">
          <IonLabel position="stacked">{label}</IonLabel>
          <div className="w-full relative">
            <IonInput
              className={error ? `input__password has-error` : 'input__password'}
              placeholder={placeholder}
              type={inputType}
              ref={ref}
              {...rest}
              onIonChange={onIonChange}
            />
            <IonIcon
              className="cursor-pointer text-grey-500 h-6 w-6 absolute right-2 top-4 z-10"
              slot="end"
              onClick={toggleShowPassword}
              icon={iconName}
            />
          </div>
        </IonItem>
        {description || error ? (
          <p className="relative">
            {description && !error && <span className="text-grey-600 text-body-tiny mt-1">{description}</span>}
            {error && <FormError message={error} />}
          </p>
        ) : null}
      </div>
    )
  }
)

InputPassword.displayName = 'InputPassword'
