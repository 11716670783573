import { createSlice } from '@reduxjs/toolkit'
import { PayloadAction } from '@reduxjs/toolkit'

/**
 * This STORE is used for bad things
 */
export type UtilitesState = {
  eventsUpdated: boolean
}

const initialState: UtilitesState = {
  eventsUpdated: false,
}

export const reducers = {
  setEventsUpdated: (state: UtilitesState, action: PayloadAction<boolean>): void => {
    state.eventsUpdated = action.payload
  },
}

export const slice = createSlice({
  name: 'utilities',
  initialState,
  reducers,
})

export const { actions: utilitiesReducers } = slice

export const utilitiesSliceReducer = slice.reducer
