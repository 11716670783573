import { useTranslation } from 'react-i18next'
import { AllEventMask } from 'src/models/events'
import { EventCategory, EventPaymentStatus, EventType } from 'src/models/events/enums'
import { Pet, Facility } from '../models'
import { usePetCategories } from '../models/events/constants'
import { DateConverter } from '../utils'

export type EventCardInternalProps = {
  eventCreationDate: Date
  eventAppointmentDate: Date
  appointmentDate?: Date
  paymentStatus: EventPaymentStatus
  title: string
  pets: Pet[]
  facility: Facility | null
  lastEventInteraction?: string
  lastEventTime?: Date
  paymentAmount?: number
  eventNumber: number
  lastUserInteractionAvatar?: string
  lastUserInteractionInitials?: string
  enrichmentData?: any
}

export const ConvertEventToCard = (event: AllEventMask): EventCardInternalProps => ({
  eventCreationDate: event.payload.creationDate || DateConverter.now(),
  eventAppointmentDate: event.payload.date || DateConverter.now(),
  appointmentDate: event.category === EventCategory.EVENT_CATEGORY_HEALTH_VISIT ? getAppointmentDate(event) : undefined,
  paymentStatus: event.payload?.payment?.status || EventPaymentStatus.PAYMENT_STATUS_UNKNOWN,
  title: getCardTitle(event),
  pets: event.Pets,
  facility: event.Facility,
  lastEventInteraction: event.LastMessage?.payload?.message || undefined,
  lastEventTime: event.LastMessage?.payload?.date || undefined,
  paymentAmount: event.payload?.payment?.amount || 0,
  eventNumber: event.seq || 0,
  lastUserInteractionAvatar: event.LastUserMessage?.avatar || undefined,
  lastUserInteractionInitials: event.LastUserMessage?.initials || undefined,
  enrichmentData: event._enrichedData,
})

export const getCardTitle = (event: AllEventMask): string => {
  const { t } = useTranslation()
  const categories = usePetCategories()

  const { type, category, payload } = event

  if (type === EventType.EVENT_TYPE_DIARY) {
    return categories.find((cat) => cat.value === category)?.label || t('LABEL_MEDICAL_ADVICE')
  }

  if (type === EventType.EVENT_TYPE_HEALTH && payload?.services && payload?.services.length > 0) {
    //return APP_VET_MODE ? t('LABEL_MEDICAL_VISIT') : payload.services.map((service: any) => service.name).join(', ')
    return payload.services.map((service: any) => service.name).join(', ')
  }

  return t('LABEL_MEDICAL_ADVICE')
}

export const getAppointmentDate = (event: AllEventMask): Date => event._enrichedData.LastMessage?.payload?.event?.start
