import React from 'react'
import { GenericProps } from 'src/helpers/props'
import { FormError } from '../inputs/FormError'

/*
type FieldWrapperRenderProps = {
  name: string
  value: any
  onChange: any
  error: FieldError | undefined
}
*/

export type FieldWrapperProps = {
  className?: string
  description?: string
  error?: string
  label?: string
  // name: string
  // control: Control<any>
  // render: (props: FieldWrapperRenderProps) => React.ReactElement[] | React.ReactNode
}

export type FieldInternalWrapperProps = GenericProps &
  FieldWrapperProps & {
    children: React.ReactElement[] | React.ReactNode
  }

export const FieldWrapper: React.FC<FieldInternalWrapperProps> = ({ children, className = '', label, description = '', error = '' }) => (
  <div className={`flex flex-col ${className}`}>
    {label ? <div className="text-body-large text-black">{label}</div> : null}
    {children}
    {description || error ? (
      <p className="relative">
        {description && !error && <span className="text-grey-600 text-body-tiny mt-1">{description}</span>}
        {error && <FormError message={error} />}
      </p>
    ) : null}
  </div>
)

/*
 * TODO: Test Wrapper
export const FieldWrapper: React.FC<FieldInternalWrapperProps> = ({ label,name, control, className = '', description = '', render }) => {
  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({ name, control })

  return (
    <div className={`flex flex-col ${className}`}>
      {label ? <div className="text-body-large text-black">{label}</div> : null }
      {render({ name, value, onChange, error })}
      {description || error ? (
        <p className="relative">
          {description && !error && <span className="text-grey-600 text-body-tiny mt-1">{description}</span>}
          {error && <FormError message={(error && error.message) || ''} />}
        </p>
      ) : null}
    </div>
  )
}

*/
