import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import { FacilitiesAPI } from 'src/api'
import { EventsAPI } from 'src/api/EventsAPI'
import { BotCreateConversation, CreateConversationState } from 'src/components/chat/Bot/BotCreateConversation'
import { BotAction } from 'src/components/chat/Bot/constants'
import { TextareaFooter } from 'src/components/inputs/TextareaFooter'
import { useCurrentFamily, useCurrentValues } from 'src/hooks/useCurrentValues'
import { useLoader } from 'src/hooks/useLoader'
import { Facility } from 'src/models'
import { FacilityService } from 'src/models/FacilityService'
import { CalendarEvent, ChatEvent, HealthEvent } from 'src/models/events'
import { EventSuggestion } from 'src/models/events/EventSuggestion'
import {
  EventCategory,
  CalendarEventStatus,
  EventPaymentStatus,
  EventPaymentType,
  EventPaymentCurrency,
  EventPaymentPaymentType,
} from 'src/models/events/enums'
import { useEmptyEvent } from 'src/models/events/useEmptyEvent'
import { DateConverter } from 'src/utils'
import { IonButton, IonContent, IonFooter, IonPage } from '@ionic/react'
import { useQueryClient } from '@tanstack/react-query'
import { IconPlus } from '../../../components/icons'
import { NavHeader } from '../components/Header/NavHeader'

export const PageAddPerformance: React.FC = () => {
  const { t } = useTranslation()
  const [showLoading, hideLoading] = useLoader()
  const messagesEndRef = useRef<any>(null)
  const history = useHistory()
  const { state: locationState } = useLocation()
  const quertyClient = useQueryClient()
  const { EmptyHealth } = useEmptyEvent()
  const { currentUser, currentFacility } = useCurrentValues()
  const { family, pets } = useCurrentFamily()
  const [state, setState] = useState<CreateConversationState>({
    category: undefined,
    pet: '',
    services: [],
    step: 0,
    suggestion: undefined,
  })
  const isFinishState =
    ((state.category === EventCategory.EVENT_CATEGORY_HEALTH_VISIT ||
      state.category === EventCategory.EVENT_CATEGORY_HEALTH_VIDEOCONSULTATION) &&
      state.pet &&
      state.services.length > 0 &&
      state.suggestion instanceof EventSuggestion) ||
    (state.category === EventCategory.EVENT_CATEGORY_HEALTH_CONSULTATION && !!state.pet)

  const isConsultation = state.category === EventCategory.EVENT_CATEGORY_HEALTH_CONSULTATION

  const scrollBottom = () => messagesEndRef && messagesEndRef?.current && messagesEndRef?.current?.scrollIntoView({ behavior: 'smooth' })
  const scrollToBottom = () => {
    setTimeout(scrollBottom, 500)
    setTimeout(scrollBottom, 1200)
  }
  const onStepChanged = () => scrollToBottom()
  const fakeParentEvent = EmptyHealth(locationState?.facility || currentFacility, family, currentUser, pets)

  useEffect(() => {
    scrollToBottom()
  }, [])

  const createHealthEvent = async (newState: CreateConversationState) => {
    setState(newState)
    scrollToBottom()
  }

  const createConversation = async (event: HealthEvent, cState: CreateConversationState, sync = false): Promise<string> => {
    const now = DateConverter.now()

    event.category = cState.category
    event.payload.pets = [cState.pet]
    event.payload.services = cState.services?.map((s) => String(s.id ?? s))
    event.payload.date = cState.suggestion?.from
    event.payload.creationDate = now

    return await EventsAPI.createChatConversation(event as HealthEvent, '', sync)
  }

  const createEvent = async () => {
    const upfrontNeeded = state.services.filter((service) => service.deposit > 0).length > 0

    fakeParentEvent.category = state.category
    fakeParentEvent.payload.pets = [state.pet]
    fakeParentEvent.payload.services = state.services
    fakeParentEvent.payload.customerId = currentUser.id

    const totalAmount = state.services
      .map((service: any) => parseFloat(service.price))
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0)

    //TODO: da configurare in futuro
    const isDigitalPaymentRequired = state.services.some((service: FacilityService) => service.digitalpayment === true)

    fakeParentEvent.payload.payment = {
      amount: totalAmount,
      currency: EventPaymentCurrency.PAYMENT_CURRENCY_EUR,
      status: EventPaymentStatus.PAYMENT_STATUS_FREE,
      type: isDigitalPaymentRequired ? EventPaymentType.PAYMENT_TYPE_DIGITAL : EventPaymentType.PAYMENT_TYPE_CASH,
    }

    const parentId = await createConversation(fakeParentEvent, state, true)

    const calendarEvent = new CalendarEvent(
      {
        parent_id: parentId,
        category: EventCategory.EVENT_CATEGORY_CALENDAR_BOOKING,
      },
      {
        event: {
          title: `${currentUser.safeName} > ${currentUser.vetFacilities.find((facility: Facility) => facility.id == state.facility)?.name}`,
          subtitle: state.services.map((service) => service.name).join(', '),
          start: state.suggestion?.from,
          end: state.suggestion?.to,
          allDay: false,
          resource: currentUser.isVet ? currentUser.id : state.suggestion?.ids ?? '',
        },
        valid: true,
        customerId: currentUser.id,
        familyId: family.id,
        facilityId: state.facility || currentFacility.id,
        userId: currentUser.id,
        status: upfrontNeeded ? CalendarEventStatus.CALENDAR_EVENT_STATUS_RESERVATION : CalendarEventStatus.CALENDAR_EVENT_STATUS_NEW,
      }
    )

    await EventsAPI.createEvent(calendarEvent, true)

    const facilities = currentUser.vetFacilities
    const facility = facilities.find((element: any) => element.id === state.facility)

    if (upfrontNeeded && facility) {
      await FacilitiesAPI.createPaymentIntent(
        facility.OrganizationId,
        currentUser.FamilyId,
        parentId,
        fakeParentEvent.payload.payment.amount,
        fakeParentEvent.payload.payment.type,
        fakeParentEvent.payload.payment.amount,
        EventPaymentPaymentType.UPFRONT
      )
    }

    return parentId
  }

  const onConfirmClicked = async () => {
    showLoading()
    const parentId = await createEvent()
    quertyClient.invalidateQueries({ queryKey: ['performances'] })

    hideLoading()
    setTimeout(() => history.replace(`/app/chat/${parentId || ''}`), 500)
  }

  const onConsultationFirstMessageSent = async (message: string) => {
    showLoading()
    fakeParentEvent.category = state.category
    fakeParentEvent.payload.pets = [state.pet]
    const parentId = await createConversation(fakeParentEvent, state)

    const firstMessageEvent = new ChatEvent(
      {
        parent_id: parentId,
      },
      {
        userId: currentUser.id,
        familyId: family.id,
        facilityId: state.facility || currentFacility.id,
        message,
      }
    )
    await EventsAPI.createMessage(firstMessageEvent)

    hideLoading()
    setTimeout(() => history.replace(`/app/chat/${parentId || ''}`), 500)

    /*for (const childEvent of childEvents) {
        childEvent.parent_id = parentId
        await EventsAPI.createEvent(childEvent)
      }*/
  }

  return (
    <IonPage>
      <NavHeader title={t('NEW_PERFORMANCE')} />
      <IonContent className="ion-bg-grey-100">
        <section className="min-h-full flex flex-grow -mx-12">
          <div className="w-full pt-14 px-10 bg-grey-200 z-0 flex flex-col justify-end pb-12">
            <BotCreateConversation
              event={fakeParentEvent}
              name={BotAction.CREATE_CONVERSATION}
              category={locationState?.category}
              step={locationState?.step}
              onStepChanged={onStepChanged}
              onFinish={createHealthEvent}
            />
            <div ref={messagesEndRef} />
          </div>
        </section>
      </IonContent>
      {isFinishState && !isConsultation && (
        <IonFooter className="p-4 bg-grey-200 ion-no-border">
          <IonButton expand="block" onClick={onConfirmClicked}>
            {t('ACTION_CONFIRM_APPOINTMENT')}
          </IonButton>
        </IonFooter>
      )}
      {isFinishState && isConsultation && (
        <IonFooter className="bg-white ion-no-border flex items-center">
          <IonButton fill="clear" className="flex-shrink-0 px-3" disabled>
            <IconPlus className="w-6 h-6 text-primary-500" />
          </IonButton>
          <TextareaFooter onSend={onConsultationFirstMessageSent} className="flex-grow" />
        </IonFooter>
      )}
    </IonPage>
  )
}
