import React, { forwardRef } from 'react'
import { IonInput, IonLabel } from '@ionic/react'
import { IconClose, IconSearch } from '../icons'
import { FormError } from './FormError'
import { InputFieldProps } from './InputField'

export type SearchFieldProps = InputFieldProps & {
  clearable?: boolean
}

export const SearchField: React.FC<SearchFieldProps> = forwardRef<HTMLIonInputElement, SearchFieldProps>(
  ({ error, label, type = 'text', placeholder, className = '', description, clearable = false, ...rest }: SearchFieldProps, ref) => (
    <div className={`relative input-field ${className}`}>
      <div>
        {label ? <IonLabel position="stacked">{label}</IonLabel> : null}
        <IonInput
          placeholder={placeholder}
          type={type}
          ref={ref}
          className={error ? `has-error h-10 pr-12 truncate` : 'h-10 pr-12 truncate'}
          {...rest}
        />
        {clearable ? (
          <div className="absolute top-3 right-4 z-10" onClick={() => rest?.onChange('')}>
            <IconClose className="cursor-pointer w-6 h-6 text-grey-500" />
          </div>
        ) : (
          <IconSearch className="absolute top-3 right-4 z-10 w-6 h-6 text-grey-300" />
        )}
      </div>
      {description || error ? (
        <p className="relative">
          {description && !error && <span className="text-grey-600 text-body-tiny mt-1">{description}</span>}
          {error && <FormError message={error} />}
        </p>
      ) : null}
    </div>
  )
)

SearchField.displayName = 'SearchField'
