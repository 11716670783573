import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IonButton, IonTextarea } from '@ionic/react'

type TextareaFooterProps = {
  fixedRows?: number
  className?: string
  loading?: boolean
  mobile?: boolean
  onSend: (message: string) => void
  //   onFirstClick?: (value: boolean) => void
}

export const TextareaFooter: React.FC<TextareaFooterProps> = ({
  onSend,
  className = '',
  loading = false,
  fixedRows = 0,
  mobile = false,
  //   onFirstClick,
}) => {
  const { t } = useTranslation()
  const ref = useRef<HTMLIonTextareaElement>(null)
  const [text, setText] = useState('')

  const rows = text.split('\n').length
  const realRowsLength = fixedRows > 0 ? fixedRows : rows < 2 ? 2 : rows < 4 ? rows : 4

  const onSendMessage = () => {
    if (text.length > 0) {
      onSend(text)
      setText('')

      if (ref.current) {
        ref.current.value = ''
      }
    }
  }

  return mobile ? (
    <footer className={`max-h-10 flex px-4 py-2 items-center ${className} ${loading ? 'pointer-events-none opacity-50' : ''}`}>
      <IonTextarea
        autoGrow
        rows={1}
        ref={ref}
        className="textarea--mobile mt-0 min-h-8 max-h-16"
        placeholder={t('WRITE_YOUR_MESSAGE')}
        onIonChange={(e) => setText(e.detail.value!)}
      />
      <IonButton color="primary" className="uppercase ml-4" size="small" onClick={onSendMessage}>
        {t('SEND')}
      </IonButton>
    </footer>
  ) : (
    <footer className={`flex px-4 py-2 items-end ${className} ${loading ? 'pointer-events-none opacity-50' : ''}`}>
      <IonTextarea
        ref={ref}
        className="textarea--no-border mt-0"
        rows={realRowsLength}
        placeholder={t('WRITE_YOUR_MESSAGE')}
        onIonChange={(e) => setText(e.detail.value!)}
      />
      <IonButton color="primary" className="uppercase ml-4 mb-2.5" size="small" onClick={onSendMessage}>
        {t('SEND')}
      </IonButton>
    </footer>
  )
}
