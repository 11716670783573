import { Facility, Pet, User } from '../models'
import { AllEventMask, Conversation } from '../models/events'
import { EventAdapter } from '../services/EventAdapter'
import { arrayToDictionary, unique } from '../utils'
import { FacilitiesAPI } from './FacilitiesAPI'
import { FacilityServicesAPI } from './FacilityServicesAPI'
import { PetsAPI } from './PetsAPI'
import { UsersAPI } from './UsersAPI'
import { axiosInstance } from './axios'
import { getSearchQuery, SearchOptionsParams } from './eventApiUtilities'

/**
 * Return all events grouped buy parent Id and the latest messages
 *
 * @param object
 * @param opts
 */
export const getConversationByCalendar = async (object: any, opts: SearchOptionsParams): Promise<Conversation[]> => {
  /**
   * Retrieve all the latest messages ordered by date
   */
  const optionsStr = getSearchQuery({
    page: 0,
    sort: '-payload.date',
    perPage: 10000,
  })

  const calendarEvents: AllEventMask[] = await axiosInstance
    .post(`/events/search${optionsStr}`, object)
    .then((res) => res.data.map((evt: any) => EventAdapter.from(evt)))

  const parentIds = calendarEvents.map((evt) => evt.parent_id).filter((id) => !!id)
  console.log('calendarEvents')
  console.log(calendarEvents)
  /**
   * Get parent events grouped by _id
   */
  const parentOptionsStr = getSearchQuery({ ...opts, group_by: '_id', group_by_limit: 1 })
  const parentEvents: AllEventMask[] = await axiosInstance
    .post(`/events/search${parentOptionsStr}`, {
      _id: {
        type: 'OR',
        values: parentIds,
      },
    })
    .then((res) => res.data.map((evt: any) => EventAdapter.from(evt)))

  /**
   * Extract all unique values to retrieve
   */
  const pets: string[] = unique(parentEvents.map((event: AllEventMask) => event.payload?.petIds))
  const facilities: string[] = unique([
    parentEvents.map((event: AllEventMask) => event.payload?.facilityId),
    calendarEvents.map((event: AllEventMask) => event.payload?.facilityId),
  ])
  //const listOfServices: string[] = unique(parentEvents.map((event: AllEventMask) => event.payload?.services))
  const users: string[] = unique([
    parentEvents.map((event: AllEventMask) => [event.payload?.userId, event.payload.assigneeId]),
    calendarEvents.map((event: AllEventMask) => [event.payload?.userId]),
  ])

  //console.log('getConversationByCalendar')
  //console.log(parentEvents)
  //console.log(listOfServices)
  console.log(calendarEvents)
  console.log('facilities')
  console.log(facilities)

  /**
   * Fetch asynchronous all the related data to fill
   */
  return await Promise.all<Pet[], Facility[], User[], Services[]>([
    PetsAPI.getPetsById(pets),
    FacilitiesAPI.getFacilities(facilities),
    UsersAPI.getUsers(users),
    FacilityServicesAPI.getServices(facilities[0]),
  ]).then(([p, f, u, s]) => {
    //const petsList = arrayToDictionary(p, 'id')
    const usersList = arrayToDictionary(u, 'id')

    return calendarEvents.map((evt: any) => {
      const parent = evt.parent_id !== null ? parentEvents.find((e: AllEventMask) => e.id === evt.parent_id) : evt
      const children = [evt]
      const facility = f.find((facility) => facility.id === evt.payload.facilityId)
      const serviceslist = parent.payload?.services
      console.log('serviceslist')
      console.log(serviceslist)
      let servs = []
      if (serviceslist && serviceslist.length > 0) {
        servs = s.filter((sv) => serviceslist.includes(sv.id))
      }
      const petlist = parent.payload?.pets
      console.log('petlist')
      console.log(petlist)
      let pts = []
      if (petlist && petlist.length > 0) {
        pts = p.filter((pt) => petlist.includes(pt.id))
      }

      parent.payload.services = servs
      parent.payload.pets = pts

      //console.log('getConversationByCalendar')
      //console.log(parent)

      parent.enrich({
        Services: servs,
        Facility: facility || null,
        Family: null,
        User: usersList[evt.payload.userId] || null,
        Assignee: usersList[evt.payload.assigneeId] || null,
        Pets: pts,
        LastMessage: evt || null,
        LastUserMessage: usersList[evt?.payload?.userId] || null,
      })

      children.forEach((child) => {
        child.enrich({
          Facility: facility || null,
          Family: null,
          User: usersList[child.payload.userId] || null,
          Assignee: usersList[child.payload.assigneeId] || null,
          Pets: [],
          LastMessage: null,
          LastUserMessage: null,
        })
      })

      return new Conversation(parent, children)
    })
  })
}
