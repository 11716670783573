import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { CallAPI } from 'src/api/CallAPI'
import { DrawerContent } from 'src/components/Drawer'
import { Call } from 'src/components/call/Call'
import { Tray } from 'src/components/call/Tray'
import { callThunks } from 'src/features/Call/thunks'
import { useCurrentCall, useCurrentFamily, useCurrentUser } from 'src/hooks/useCurrentValues'
import { useLoader } from 'src/hooks/useLoader'
import { AllEventMask } from 'src/models/events'
import DailyIframe from '@daily-co/daily-js'
import { AccessModal } from '../partials/AccessModal'
import { CallHeader } from '../partials/CallHeader'

export const DrawerCall = ({ event, onDismiss }: { event?: AllEventMask; onDismiss: () => void }) => {
  const { callObject } = useCurrentCall()
  const dispatch = useDispatch()
  const [showLoading, hideLoading] = useLoader()
  const { owner } = useCurrentFamily()
  const currentUser = useCurrentUser()

  const start = async () => {
    try {
      if (!callObject) {
        return
      }

      if (event && event.payload?.videomeeting && event.payload?.videomeeting.status === 'CLOSED') {
        return
      }

      if (event && event.payload?.videomeeting && event.payload?.videomeeting.status != 'CLOSED') {
        showLoading()

        const { room } = await CallAPI.getVideomeeting(event.payload.videomeeting.name)

        callObject.accessState().access && (await callObject.preAuth(room))
        await callObject.startCamera()
        await callObject.join({ url: room.url })
      }
    } catch (error) {
      console.error(error)
    } finally {
      hideLoading()
    }
  }

  const closeCallAndDismiss = () => {
    callObject?.leave()
    dispatch(callThunks.setCallObject(undefined))
    dispatch(callThunks.setStartTime(undefined))
    dispatch(callThunks.setCallee(undefined))
    dispatch(callThunks.setEvent(undefined))
    onDismiss()
  }

  useEffect(() => {
    if (callObject) return

    const newCallObject = DailyIframe.createCallObject()
    dispatch(callThunks.setCallObject(newCallObject))
    dispatch(callThunks.setCallee(owner || undefined))
    dispatch(callThunks.setEvent(event || undefined))
  }, [])

  useEffect(() => {
    start()
  }, [callObject])

  return (
    <DrawerContent
      headerClassName="pl-1 pr-1"
      header={<CallHeader callee={owner} onClose={closeCallAndDismiss} onDismiss={onDismiss} />}
      showClose={false}
      contentClassName={'pr-0 pl-0 w-screen h-full flex flex-col'}
    >
      <>
        {callObject && (
          <>
            <Call
              className={'h-5/6'}
              timerClassName="absolute top-30 left-10 ml-4 bg-black text-white font-bold px-2 py-1 rounded-lg text-h400"
              remoteParticipantClassName="flex justify-center h-full"
              localParticipantClassName="absolute bottom-40 left-10 h-1/5 w-1/6 flex justify-center items-center"
            />
            <Tray className="flex-none h-1/6 flex justify-center items-center rounded" onClose={closeCallAndDismiss} />
            {currentUser.isVet && <AccessModal />}
          </>
        )}
      </>
    </DrawerContent>
  )
}
