import { format } from 'date-fns'
import { enUS } from 'date-fns/locale'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { EventSuggestion } from 'src/models/events/EventSuggestion'
import { IonButton } from '@ionic/react'
import { useIonAlert } from '@ionic/react'

type EventSuggestionCardProps = {
  className?: string
  shadow?: boolean
  showDate?: boolean
  date: Date | string
  rows?: number
  columns?: number
  label?: string
  selectedSuggestion?: EventSuggestion
  suggestions: EventSuggestion[]
  onChange?: (suggestion: EventSuggestion) => void
  isEditingMode?: boolean
  clientName?: string
}

export const EventSuggestionCard: React.FC<EventSuggestionCardProps> = ({
  className = '',
  label,
  date,
  shadow = false,
  showDate = false,
  rows = -1,
  columns = 3,
  selectedSuggestion,
  suggestions = [],
  onChange,
  isEditingMode = false,
  clientName = '',
}) => {
  const [selected, setSelected] = useState<EventSuggestion>(selectedSuggestion || new EventSuggestion({}))
  const [notAlreadyEdited, setNotAlreadyEdited] = useState<boolean>(isEditingMode)
  const visibleSuggestions = rows > 0 ? suggestions.slice(0, rows * columns) : suggestions
  const [present] = useIonAlert()
  const { t } = useTranslation()

  const onSuggestionClicked = (suggestion: EventSuggestion) => {
    if (isEditingMode && notAlreadyEdited) {
      present({
        cssClass: 'my-css',
        header: t('EVENT_SUGGESTION_CONFIRM_TIME_CHANGE_TITLE'),
        message: t('EVENT_SUGGESTION_CONFIRM_TIME_CHANGE_TEXT', {
          clientName,
        }),
        buttons: [
          t('ACTION_CANCEL'),
          {
            text: t('ACTION_MOVE'),
            handler: () => {
              setSelected(suggestion)
              onChange && onChange(suggestion)
              setNotAlreadyEdited(false)
            },
          },
        ],
      })
    } else {
      setSelected(suggestion)
      onChange && onChange(suggestion)
    }
  }

  return (
    <div className={`bg-white space-y-4 ${shadow ? 'shadow-light-40 rounded' : ''} ${className}`}>
      {showDate && <div className="text-body-large font-bold">{format(Number(date), 'MMMM dd, yyyy')}</div>}
      <div>
        <div className="text-grey-600 text-body-small mb-2">{label}</div>
        <div className="flex items-center flex-wrap">
          {visibleSuggestions.map((suggestion) => (
            <IonButton
              key={suggestion.id}
              color="primary"
              fill={selected.id === suggestion.id ? 'solid' : 'outline'}
              size="small"
              className={`button-xsmall  mt-2 mr-2 ${selected.id === suggestion.id ? 'w-22' : 'w-18'}`}
              onClick={() => onSuggestionClicked(suggestion)}
            >
              {selected.id === suggestion.id ? suggestion.slot : suggestion.startingFrom}
            </IonButton>
          ))}
        </div>
      </div>
      {/*<div>
      <div className="text-grey-600 text-body-small mb-2">Bassa affluenza</div>
      <div className="flex items-center flex-wrap">
        {events.lowTurnout.map(({ time, discount }: { time: string; discount: string }) => (
          <div
            key={`${time}-${discount}`}
            className="flex items-center space-x-2 cursor-pointer border-2 border-solid border-tertiary-500 py-1 px-2 rounded text-tertiary-500 text-body-small mr-2 font-bold"
          >
            <IconEuro className="w-4 h-4" />
            <span>
              {time} {`(-${discount}%)`}
            </span>
          </div>
        ))}
      </div>
    </div>*/}
    </div>
  )
}
