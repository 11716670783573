import { DateConverter } from '../../utils'

export class BaseModel {
  name: string
  ImageId?: string
  image: string

  dateCreated: Date | null
  dateUpdated: Date | null

  constructor(data: { [key: string]: any }) {
    if (!data) {
      throw new Error('Impossible to create Model without data')
    }

    this.name = data.name || ''
    this.dateCreated = data.dateCreated ? DateConverter.fromServer(data.dateCreated) : null
    this.dateUpdated = data.dateUpdated ? DateConverter.fromServer(data.dateUpdated) : null
    this.ImageId = data.idImage || data.ImageId || null
    this.image = data.image || null
  }

  get initials(): string {
    return ''
  }

  get avatar(): string | undefined {
    if (!this.ImageId) {
      return undefined
    }

    return this.ImageId.startsWith('http')
      ? this.ImageId
      : `${process.env.REACT_APP_FILES_PATH}/${this.ImageId}?width=64&height=64&format=png`
  }
}
