import { useTranslation } from 'react-i18next'
import { EventCategory, EventPaymentStatus, EventStatus, EventType } from './enums'

export const getEventTypes = () => {
  const { t } = useTranslation()

  return [
    {
      value: EventType.EVENT_TYPE_HEALTH,
      label: t('EVENT_TYPE_HEALTH'),
    },
    {
      value: 'OTHER',
      label: t('EVENT_TYPE_OTHER'),
    },
    {
      value: 'DIARY',
      label: t('EVENT_TYPE_DIARY'),
    },
  ]
}

export const usePetCategories = () => {
  const { t } = useTranslation()

  return [
    {
      value: EventCategory.EVENT_CATEGORY_DIARY_GENERIC,
      label: t('EVENT_CATEGORY_DIARY_GENERIC'),
    },
    {
      value: EventCategory.EVENT_CATEGORY_DIARY_TRIP,
      label: t('EVENT_CATEGORY_DIARY_TRIP'),
    },
    {
      value: EventCategory.EVENT_CATEGORY_DIARY_TRAINING,
      label: t('EVENT_CATEGORY_DIARY_TRAINING'),
    },
    {
      value: EventCategory.EVENT_CATEGORY_DIARY_BIRTH,
      label: t('EVENT_CATEGORY_DIARY_BIRTH'),
    },
    {
      value: EventCategory.EVENT_CATEGORY_DIARY_DEATH,
      label: t('EVENT_CATEGORY_DIARY_DEATH'),
    },
  ]
}

export const getEventStatuses = () => {
  const { t } = useTranslation()

  return [
    {
      value: EventStatus.STATUS_NEW,
      label: t('EVENT_STATUS_NEW'),
    },
    {
      value: EventStatus.STATUS_OPEN,
      label: t('EVENT_STATUS_OPEN'),
    },
    {
      value: EventStatus.STATUS_CLOSED,
      label: t('EVENT_STATUS_CLOSED'),
    },
  ]
}

export const getEventPaymentStatuses = () => {
  const { t } = useTranslation()

  return [
    {
      value: EventPaymentStatus.PAYMENT_STATUS_FREE,
      label: t('EVENT_PAYMENT_STATUS_FREE'),
    },
    {
      value: EventPaymentStatus.PAYMENT_STATUS_PENDING,
      label: t('EVENT_PAYMENT_STATUS_PENDING'),
    },
    {
      value: EventPaymentStatus.PAYMENT_STATUS_PAID,
      label: t('EVENT_PAYMENT_STATUS_PAID'),
    },
  ]
}

export const getVeterinaryEventPaymentStatuses = (value: EventPaymentStatus): string => {
  const { t } = useTranslation()

  const labels = [
    {
      value: EventPaymentStatus.PAYMENT_STATUS_FREE,
      label: t('EVENT_PAYMENT_STATUS_FREE_VET'),
    },
    {
      value: EventPaymentStatus.PAYMENT_STATUS_PENDING,
      label: t('EVENT_PAYMENT_STATUS_PENDING_VET'),
    },
    {
      value: EventPaymentStatus.PAYMENT_STATUS_PAID,
      label: t('EVENT_PAYMENT_STATUS_PAID_VET'),
    },
  ]

  return labels.find((v) => v.value === value)?.label || ''
}
