import { IconActive } from './IconActive'
import { IconArrow } from './IconArrow'
import { IconArrowBackward } from './IconArrowBackward'
import { IconAttachment } from './IconAttachment'
import { IconBillingPageCreditCard } from './IconBillingPageCreditCard'
import { IconCalendar } from './IconCalendar'
import { IconCalendarCheck } from './IconCalendarCheck'
import { IconCamera } from './IconCamera'
import { IconCheckCircle } from './IconCheckCircle'
import { IconCheckmark } from './IconCheckmark'
import { IconClient } from './IconClient'
import { IconClock } from './IconClock'
import { IconClose } from './IconClose'
import { IconCloseFile } from './IconCloseFile'
import { IconCollapse } from './IconCollapse'
import { IconCopy } from './IconCopy'
import { IconCustomerWithDoctor } from './IconCustomerWithDoctor'
import { IconDollar } from './IconDollar'
import { IconDotsHorizontal } from './IconDotsHorizontal'
import { IconDoubleArrow } from './IconDoubleArrow'
import { IconEuro } from './IconEuro'
import { IconExpand } from './IconExpand'
import { IconExport } from './IconExport'
import { IconFile } from './IconFile'
import { IconFilter } from './IconFilter'
import { IconFilters } from './IconFilters'
import { IconGear } from './IconGear'
import { IconHelpDesk } from './IconHelpDesk'
import { IconHelpDeskWithoutBackground } from './IconHelpDeskWithoutBackground'
import { IconHome } from './IconHome'
import { IconImportFile } from './IconImportFile'
import { IconInbox } from './IconInbox'
import { IconInfo } from './IconInfo'
import { IconInfoFill } from './IconInfoFill'
import { IconLink } from './IconLink'
import { IconLogout } from './IconLogout'
import { IconMail } from './IconMail'
import { IconMicOff } from './IconMicOff'
import { IconMicOn } from './IconMicOn'
import { IconMoreVertical } from './IconMoreVertical'
import { IconNote } from './IconNote'
import { IconNotification } from './IconNotification'
import { IconOffline } from './IconOffline'
import { IconOnboardingComplete } from './IconOnboardingComplete'
import { IconOnline } from './IconOnline'
import { IconPeople } from './IconPeople'
import { IconPeopleComplete } from './IconPeopleComplete'
import { IconPeopleEmpty } from './IconPeopleEmpty'
import { IconPerson } from './IconPerson'
import { IconPersonImport } from './IconPersonImport'
import { IconPersonPlus } from './IconPersonPlus'
import { IconPhoneHangUp } from './IconPhoneHangUp'
import { IconPhoto } from './IconPhoto'
import { IconPlus } from './IconPlus'
import { IconPlusBoxed } from './IconPlusBoxed'
import { IconRegistered } from './IconRegistered'
import { IconReport } from './IconReport'
import { IconRequests } from './IconRequests'
import { IconSearch } from './IconSearch'
import { IconSelectionFile } from './IconSelectionFile'
import { IconSettings } from './IconSettings'
import { IconShare } from './IconShare'
import { IconSort } from './IconSort'
import { IconStar } from './IconStar'
import { IconStarFill } from './IconStarFill'
import { IconSwap } from './IconSwap'
import { IconTrash } from './IconTrash'
import { IconUserPlus } from './IconUserPlus'
import { IconVideoOff } from './IconVideoOff'
import { IconVideoOn } from './IconVideoOn'
import { IconWarning } from './IconWarning'
import { StripeLogo } from './StripeLogo'

enum IconArrowType {
  BOTTOM = '',
  TOP = 'rotate-180',
  LEFT = 'rotate-90',
  RIGHT = '-rotate-90',
}

export {
  IconActive,
  IconSwap,
  IconUserPlus,
  IconArrow,
  IconArrowBackward,
  IconAttachment,
  IconArrowType,
  IconCalendar,
  IconCalendarCheck,
  IconCamera,
  IconCheckmark,
  IconCheckCircle,
  IconClient,
  IconClose,
  IconCloseFile,
  IconClock,
  IconCollapse,
  IconCustomerWithDoctor,
  IconDotsHorizontal,
  IconDoubleArrow,
  IconEuro,
  IconExpand,
  IconExport,
  IconFile,
  IconFilters,
  IconFilter,
  IconGear,
  IconHelpDesk,
  IconHome,
  IconImportFile,
  IconInbox,
  IconInfo,
  IconInfoFill,
  IconLink,
  IconLogout,
  IconMail,
  IconMoreVertical,
  IconNote,
  IconNotification,
  IconOffline,
  IconOnboardingComplete,
  IconOnline,
  IconPeople,
  IconPeopleComplete,
  IconPerson,
  IconPersonImport,
  IconPersonPlus,
  IconPlus,
  IconPlusBoxed,
  IconPhoto,
  IconRegistered,
  IconReport,
  IconRequests,
  IconSearch,
  IconSettings,
  IconShare,
  IconSort,
  IconStar,
  IconStarFill,
  IconTrash,
  IconPeopleEmpty,
  IconSelectionFile,
  StripeLogo,
  IconCopy,
  IconVideoOff,
  IconVideoOn,
  IconMicOff,
  IconMicOn,
  IconPhoneHangUp,
  IconWarning,
  IconBillingPageCreditCard,
  IconHelpDeskWithoutBackground,
  IconDollar,
}
