import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLoader } from 'src/hooks/useLoader'
import { IonButton } from '@ionic/react'
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js'

export const ModalCheckout: React.FC<{ onComplete: () => void }> = ({ onComplete }) => {
  const stripe = useStripe()
  const elements = useElements()
  const [showLoading, hideLoading] = useLoader()

  const { t } = useTranslation()

  //const [errorMessage, setErrorMessage] = useState(null)

  const handleSubmit = async (event: Event) => {
    //setErrorMessage(null)
    showLoading()
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault()

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return
    }

    const { error } = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: '',
      },
      redirect: 'if_required',
    })

    if (error) {
      console.error(error)
    }

    // if (!error) {
    //   // This point will only be reached if there is an immediate error when
    //   // confirming the payment. Show error to your customer (for example, payment
    //   // details incomplete)
    //   //setErrorMessage(error.message)
    // } else {
    //   // Your customer will be redirected to your `return_url`. For some payment
    //   // methods like iDEAL, your customer will be redirected to an intermediate
    //   // site first to authorize the payment, then redirected to the `return_url`.
    //   onComplete()
    // }
    onComplete()
    hideLoading()
  }

  return (
    <form className="flex-grow flex flex-col justify-between" onSubmit={handleSubmit}>
      <PaymentElement />
      {/* Show error message to your customers */}
      {/* {errorMessage && <div className="my-4 text-error-500">{errorMessage}</div>} */}
      <IonButton className="w-full mb-8" type="submit">
        {t('ACTION_CONFIRM')}
      </IonButton>
    </form>
  )
}
