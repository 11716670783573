import React, { forwardRef, ReactChild, useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { InputField } from 'src/components/inputs/InputField'
import { UploadField } from 'src/components/inputs/UploadField'
import { CreatePetFormType } from 'src/features/Family/form/CreatePetFormType'
import { Pet } from 'src/models'
import { object, string, number } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { IonButton, IonItem, IonLabel, IonSelect, IonSelectOption } from '@ionic/react'
import { PetAttributes } from '../../api/PetsAPI'
import { CustomSelect } from '../inputs/CustomSelect'
import { SelectField } from '../inputs/SelectField'

type FormCreatePetPropsType = {
  loading: boolean
  showAction?: boolean
  className?: string
  familyId?: string
  defaultValue?: Pet | null
  actions?: ReactChild | null
  data: PetAttributes
  disabled?: boolean
  onSubmit: (values: CreatePetFormType) => Promise<void>
}

export const FormEditPet = forwardRef<HTMLFormElement, FormCreatePetPropsType>(
  ({ defaultValue = null, actions = null, showAction = true, familyId, loading, className, data, disabled = false, onSubmit }, ref) => {
    const { t } = useTranslation()

    const validationSchema = object().shape({
      name: string().required(t('FIELD_REQUIRED_NAME')),
      // image: mixed().notRequired(),
      SpeciesId: number().required(t('FIELD_REQUIRED_SPECIES_ID')),
      // BreedId: string().notRequired(),
      // CoatId: string().notRequired(),
      // sex: string().notRequired(),
      // OriginId: string().notRequired(),
    })

    const {
      control,
      register,
      watch,
      handleSubmit,
      formState: { errors },
      getValues,
      setValue,
    } = useForm({
      mode: 'onBlur',
      reValidateMode: 'onBlur',
      resolver: yupResolver(validationSchema),
      defaultValues: defaultValue
        ? {
            name: defaultValue.name,
            sex: defaultValue.sex,
            image: null,
            FamilyId: defaultValue.FamilyId || familyId || '',
            SpeciesId: defaultValue.SpeciesId,
            BreedId: defaultValue.BreedId,
            CoatId: defaultValue.CoatId,
            OriginId: defaultValue.OriginId,
          }
        : {},
    })

    const onSubmitCallback = (values: any) => {
      console.log(values)
      onSubmit({
        name: values.name,
        image: values.image ? values.image[0] : null,
        sex: values.sex,
        FamilyId: familyId || '',
        SpeciesId: parseInt(values.SpeciesId),
        BreedId: parseInt(values.BreedId),
        CoatId: parseInt(values.CoatId),
        OriginId: parseInt(values.OriginId),
      })
    }

    const currentSpecies = watch('SpeciesId')
    const breeds = data.breeds.filter((breed) => `${breed.SpeciesId}` === `${currentSpecies}`)
    const coats = data.coats //.filter((coat) => coat.SpeciesId === Number.parseInt(currentSpecies as string))

    useEffect(() => {
      setValue('BreedId', defaultValue?.BreedId)
    }, [currentSpecies])

    return (
      <form ref={ref} className={className || ''} onSubmit={handleSubmit(onSubmitCallback)}>
        <fieldset disabled={loading}>
          <UploadField
            label={t('UPLOAD_PET_IMAGE')}
            editLabel={t('UPLOAD_NEW_PET_IMAGE')}
            {...register('image')}
            defaultValue={defaultValue?.avatar}
            readonly={disabled}
          />
          <InputField
            labelClassName={`${disabled && 'opacity-30'}`}
            label={t('LABEL_NAME')}
            {...register('name')}
            error={errors.name?.message}
            disabled={disabled}
          />
          <IonItem className="mb-6">
            <IonLabel position="stacked">{t('LABEL_SPECIES')}</IonLabel>
            <Controller
              name="SpeciesId"
              control={control}
              defaultValue={defaultValue?.SpeciesId}
              render={({ field }) => (
                <IonSelect
                  {...field}
                  compareWith={(a: string, b: string) => a == b}
                  onIonChange={field.onChange}
                  interfaceOptions={{
                    showBackdrop: false,
                  }}
                  style={errors.SpeciesId?.message ? { border: '1px solid red', color: 'red' } : {}}
                  disabled={disabled}
                >
                  {data.species.map((spc) => (
                    <IonSelectOption key={spc.id} value={spc.id}>
                      {t(spc.name)}
                    </IonSelectOption>
                  ))}
                </IonSelect>
              )}
            />
            <p className="text-error-500">{errors.SpeciesId?.message ? t('FIELD_REQUIRED_SPECIES_ID') : ''}</p>
          </IonItem>
          <IonItem className="mb-6">
            <IonLabel position="stacked">{t('LABEL_GENDER')}</IonLabel>
            <IonSelect
              {...register('sex')}
              placeholder={t('FORM_GENDER_PLACEHOLDER')}
              interfaceOptions={{
                showBackdrop: false,
              }}
              defaultValue={defaultValue?.sex}
              disabled={disabled}
            >
              {data.sex.map((sex) => (
                <IonSelectOption key={sex.id} value={sex.id}>
                  {t(sex.name)}
                </IonSelectOption>
              ))}
            </IonSelect>
          </IonItem>
          <div className="mb-6">
            <Controller
              control={control}
              name="BreedId"
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <SelectField
                  value={value}
                  onChange={onChange}
                  label={t('LABEL_BREED')}
                  options={breeds.map(({ id, name }) => ({ value: id, label: name }))}
                  placeholder={t('FIELD_TYPE_PLACEHOLDER')}
                  error={error && error.message}
                />
              )}
            />
          </div>

          <IonItem className="mb-6">
            <IonLabel position="stacked">{t('LABEL_COAT')}</IonLabel>
            <IonSelect
              {...register('CoatId')}
              placeholder={t('FORM_COAT_PLACEHOLDER')}
              disabled={!currentSpecies || disabled}
              defaultValue={defaultValue?.CoatId}
              interfaceOptions={{
                showBackdrop: false,
              }}
            >
              {coats.map((coat) => (
                <IonSelectOption key={coat.id} value={coat.id}>
                  {t(coat.name)}
                </IonSelectOption>
              ))}
            </IonSelect>
          </IonItem>
          <IonItem className="mb-6">
            <IonLabel position="stacked">{t('LABEL_ORIGIN')}</IonLabel>
            <IonSelect
              {...register('OriginId')}
              placeholder={t('FORM_ORIGIN_PLACEHOLDER')}
              defaultValue={defaultValue?.OriginId}
              interfaceOptions={{
                showBackdrop: false,
              }}
              disabled={disabled}
            >
              {data.origins.map((origin) => (
                <IonSelectOption key={origin.id} value={origin.id}>
                  {t(origin.name)}
                </IonSelectOption>
              ))}
            </IonSelect>
          </IonItem>
          {!disabled && (
            <>
              {actions ? (
                actions
              ) : showAction ? (
                <IonButton expand="block" type="submit">
                  {t('ADD')}
                </IonButton>
              ) : null}
            </>
          )}
        </fieldset>
      </form>
    )
  }
)
