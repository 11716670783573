import { format } from 'date-fns'
import { enUS } from 'date-fns/locale'
import React, { useEffect, useRef, useState } from 'react'
import { useCurrentUser } from 'src/hooks/useCurrentValues'
import { ChildEventMask, Conversation } from 'src/models/events'
import { EventType } from 'src/models/events/enums'
import { formatDate } from 'src/utils'
import { BotMessage } from './BotMessage'
import { CalendarMessage } from './CalendarMessage'
import { ReceiverMessage } from './ReceiverMessage'
import { SenderMessage } from './SenderMessage'

export type EventsByDate = {
  date: Date
  events: ChildEventMask[]
}

type ChatRendererProps = {
  conversation?: Conversation
  events: EventsByDate[]
  vetMode?: boolean
  doRefresh?: () => void
}

const formatChatDate = (date: Date) => (date ? formatDate(date, 'EEEE, dd MMMM yyyy') : '')

export const ChatRenderer: React.FC<ChatRendererProps> = ({ conversation, events, doRefresh }) => {
  const requireEventRefresh = () => doRefresh && doRefresh()
  const currentUser = useCurrentUser()
  const messageRef = useRef<any>(null)
  const [firstMessageNotSeenId, setFirstMessageNotSeenId] = useState<string>()

  const scrollToMessage = () => messageRef && messageRef?.current && messageRef?.current?.scrollIntoView({ behavior: 'smooth' })

  const getFirstMessageNotSeen = () => {
    const allEvents = events.reduce((acc: ChildEventMask[], currentValue: EventsByDate) => [...acc, ...currentValue.events], [])
    const message = allEvents.find((event: ChildEventMask) => event.type == EventType.EVENT_TYPE_CHAT && !event.payload.seen)

    return message
  }

  useEffect(() => {
    const message = getFirstMessageNotSeen()

    if (message) {
      setFirstMessageNotSeenId(message.id)
    } else {
    }
  }, [events])

  useEffect(() => {
    scrollToMessage()
  }, [firstMessageNotSeenId])

  return (
    <div className="flex flex-col space-y-4">
      {events.map((evtByDate, index) => (
        <div className="flex flex-col space-y-4" key={formatChatDate(evtByDate.date) + evtByDate.events.length + index}>
          <div className="flex items-center relative mb-6">
            <div className="h-px bg-grey-300 absolute w-full" />
            <span className="border border-solid bg-grey-200 border-grey-300 text-grey-600 text-body-small py-1 px-3 text-center mx-auto z-10 rounded">
              {/* {formatChatDate(evtByDate.date)} */}
              {format(evtByDate.date, 'EEEE, MMMM dd, yyyy', { locale: enUS })}
            </span>
          </div>
          {evtByDate.events.map((evt, index) => {
            if (evt.type == EventType.EVENT_TYPE_BOT) {
              return <BotMessage conversation={conversation} key={index} event={evt} />
            }

            if (evt.type == EventType.EVENT_TYPE_CALENDAR) {
              return <CalendarMessage conversation={conversation} key={index} event={evt} doRefresh={requireEventRefresh} />
            }

            return evt?.User?.id === currentUser.id ? (
              <SenderMessage
                key={index}
                user={evt.User}
                event={evt}
                message={evt.payload.message}
                time={formatDate(evt.payload.date, 'HH:mm')}
              />
            ) : (
              <>
                {evt.id == firstMessageNotSeenId && <div ref={messageRef} />}
                <ReceiverMessage
                  key={index}
                  user={evt.User}
                  event={evt}
                  message={evt.payload.message}
                  time={formatDate(evt.payload.date, 'HH:mm')}
                />
              </>
            )
          })}
        </div>
      ))}
    </div>
  )
}
