import React from 'react'
import { Avatar } from 'src/components/Avatar'
import { FullLogo } from 'src/components/icons/Logos'
import { useCurrentUser } from 'src/hooks/useCurrentValues'
import { IonHeader, IonRouterLink, IonToolbar } from '@ionic/react'
import { AppRouterHelper } from '../../services/AppRouterHelper'

type HeaderProps = {
  title?: string
  showBackButton?: boolean
  titleClassName?: string
}

export const AppHeader: React.FC<HeaderProps> = (): React.ReactElement => {
  const currentUser = useCurrentUser()

  return (
    <IonHeader className="bg-white ion-no-border h-24 items-center flex">
      <IonToolbar className="h-full flex items-center border-b border-grey-300">
        <div className="px-6 flex items-center justify-between">
          <FullLogo className="h-8" />
          <IonRouterLink routerLink={AppRouterHelper.ROUTES.PROFILE}>
            <Avatar image={currentUser.avatar} title={currentUser.initials} className="cursor-pointer h-12 w-12" />
          </IonRouterLink>
        </div>
      </IonToolbar>
    </IonHeader>
  )
}
