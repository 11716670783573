import { Auth } from 'aws-amplify'
import axios from 'axios'
import { setupCache } from 'axios-cache-adapter'

// const cache = setupCache({
//   maxAge: 2 * 60 * 1000,
//   debug: false,
//   exclude: { query: false, methods: ['get', 'patch'] },
// })

const cache = setupCache({
  maxAge: 2 * 60 * 1000,
  debug: false,
  exclude: { query: false, methods: ['get', 'patch'] }, //cache disattivata sulle get momentaneamente
  invalidate: async (cfg, req) => {
    /*
     * Is very ugly I know
     */
    const requestSkipCache = req.headers.SkipCache || false
    const requestClearAll = !!req.headers.ClearAllCache

    if (requestClearAll || requestSkipCache) {
      const uuid = cfg.key ? cfg?.key(req) : null
      if (uuid) {
        const store = cfg.store as any
        if (store && store.removeItem) {
          await store.removeItem(uuid)
        }

        if (requestClearAll) {
          await store.clear()
        }
      }
    }
  },
})

const instance = axios.create({
  adapter: cache.adapter,
  baseURL: process.env.REACT_APP_API_PATH,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json',
  },
})

instance.interceptors.request.use(
  async (config) => {
    try {
      const user = await Auth.currentAuthenticatedUser()
      const token = user.signInUserSession.idToken.jwtToken

      config.headers.Authorization = `Bearer ${token}`
    } catch (err) {
      config.headers.Authorization = ''
    }
    return config
  },
  (error) => Promise.reject(error)
)

instance.interceptors.response.use(
  async (response) => response,
  async (error) => {
    const { response, config } = error
    if (response && response.status === 401 && !config._retry) {
      // NOTE: errore unauthorized: tentativo di refresh token
      try {
        const user = await Auth.currentAuthenticatedUser()
        // const refreshToken = user.signInUserSession.refreshToken.token

        // NOTE: controllo se il Refresh Token è scaduto
        const refreshTokenExpiration = user.signInUserSession.refreshToken.expiresAt
        const currentTime = Math.floor(Date.now() / 1000)
        if (currentTime > refreshTokenExpiration) {
          // NOTE: Refresh Token scaduto: logout e redirect al login
          await Auth.signOut()
          window.location.replace('/login')

          return Promise.reject(error)
        }

        // NOTE: Refresh del token
        const session = await Auth.currentSession()
        const token = session.getIdToken().getJwtToken()
        config.headers.Authorization = `Bearer ${token}`
        config._retry = true

        return axios.request(config)
      } catch (refreshError) {
        console.error('Token refresh failed:', refreshError)
        await Auth.signOut()
        window.location.replace('/login')

        throw refreshError
      }
    }
    return Promise.reject(error)
  }
)

export const axiosInstance = instance

export const getCacheConfig = (skipCache: boolean) => (skipCache ? { headers: { SkipCache: true } } : {})
export const clearAxiosCache = async () => {
  const store = cache.store as any
  await store.clear()
}
