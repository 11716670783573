import sortedUniqBy from 'lodash/sortedUniqBy'
import { Storage, STORAGE_KEY_CURRENT_FACILITY_ID } from 'src/helpers/Storage'
import { Facility, User } from 'src/models'
import { Conversation } from 'src/models/events'
import { PayloadAction } from '@reduxjs/toolkit'
import { DateConverter } from '../../../utils'
import { FacilityState } from './slice'

export const facilityReducers = {
  setCurrentFacility: (state: FacilityState, action: PayloadAction<Facility>): void => {
    state.currentFacility = action.payload
    Storage.set(STORAGE_KEY_CURRENT_FACILITY_ID, action.payload.id)
    state.lastUpdated = DateConverter.now()
  },
  setClientList: (state: FacilityState, action: PayloadAction<any>): void => {
    state.petOwners = action.payload.filter((user: User) => user.isPetOwner)
  },
  setVeterinariansList: (state: FacilityState, action: PayloadAction<any>): void => {
    // state.veterinarians = action.payload.filter((user: User) => user.isVet) //TODO: apply filter
    state.veterinarians = action.payload
  },
  setRequests: (state: FacilityState, action: PayloadAction<any>): void => {
    state.requests = action.payload
    state.lastUpdated = DateConverter.now()
  },
  addRequests: (state: FacilityState, action: PayloadAction<any>): void => {
    state.requests = sortedUniqBy([...action.payload, ...state.requests], (data: Conversation) => data.id)
    state.lastUpdated = DateConverter.now()
  },
  removeRequests: (state: FacilityState, action: PayloadAction<string[]>): void => {
    const newRequests = state.requests.filter((request: Conversation) => !action.payload.includes(request.id))
    state.requests = sortedUniqBy(newRequests, (data: Conversation) => data.id)
  },
  setFacilityLoaded: (state: FacilityState): void => {
    state.loaded = true
  },
}
