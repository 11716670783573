import React, { ReactElement } from 'react'
import { IconArrowBackward } from 'src/components/icons'
import { IonBackButton, IonButton, IonButtons, IonHeader, IonTitle, IonToolbar } from '@ionic/react'
import './header.css'

type HeaderProps = {
  title?: string
  imageTitle?: ReactElement
  showBackButton?: boolean
  titleClassName?: string
  backAction?: () => void
  className?: string
  leftActions?: any
  extra?: any
}

export const NavHeader: React.FC<HeaderProps> = ({
  className = '',
  children,
  title,
  imageTitle = null,
  showBackButton = true,
  titleClassName,
  backAction = null,
  leftActions = null,
  extra = null,
}): React.ReactElement => (
  <IonHeader className={`ion-no-border items-center flex flex-col ${className ? className : 'h-24'}`}>
    <IonToolbar className={`flex flex-col ${!extra && 'border-b border-grey-300'}`}>
      <div className="flex">
        <IonButtons slot="start">
          {leftActions}
          {showBackButton ? (
            backAction ? (
              <IonButton className="ml-3 px-3 link" fill="clear" onClick={backAction}>
                <IconArrowBackward className="w-6 h-6" />
              </IonButton>
            ) : (
              <IonBackButton className="-ml-3 px-3 link" defaultHref="/">
                <IconArrowBackward className="w-6 h-6" />
              </IonBackButton>
            )
          ) : null}
        </IonButtons>
        <IonTitle>
          {imageTitle ? (
            <div className="flex justify-center py-8">{imageTitle}</div>
          ) : (
            <h1 className={`mb-0 py-8 text-h200 text-center ${titleClassName}`}>{title}</h1>
          )}
        </IonTitle>
        <IonButtons slot="end" className="pr-2">
          {children}
        </IonButtons>
      </div>
      {extra && <div className="border-t border-grey-300 text-center">{extra}</div>}
    </IonToolbar>
  </IonHeader>
)
