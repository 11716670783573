// import isPlainObject from 'lodash/isPlainObject'
import { AllEventMask, BotEvent, CalendarEvent, ChatEvent, DiaryEvent, HealthEvent } from 'src/models/events'
import { EventType } from 'src/models/events/enums'

export class EventAdapter {
  static from(data: any): AllEventMask {
    const type = data.type

    /*
     *
     */
    if (data.payload) {
      const pets = data?.payload?.pets || []
      data.payload.pets = pets
      //data.payload.pets = pets.map((pet: any) => (isPlainObject(pet) ? pet.id : pet)) || []
    }

    switch (type) {
      case EventType.EVENT_TYPE_DIARY:
        return new DiaryEvent(data, data.payload)
      case EventType.EVENT_TYPE_CHAT:
        return new ChatEvent(data, data.payload)
      case EventType.EVENT_TYPE_CALENDAR:
        return new CalendarEvent(data, data.payload)
      case EventType.EVENT_TYPE_BOT:
        return new BotEvent(data, data.payload)
      case EventType.EVENT_TYPE_HEALTH:
      default:
        return new HealthEvent(data, data.payload)
    }
  }
}
