import React, { FC } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { FacilitiesAPI } from 'src/api'
import { IconInfo } from 'src/components/icons'
import { useCurrentUser } from 'src/hooks/useCurrentValues'
import { useModal } from 'src/hooks/useModal'
import { Facility } from 'src/models'
import { Notification } from 'src/models/events/Notification'
import { EventNotificationAction } from 'src/models/events/enums'
import { IonButton, IonContent, IonPage, IonFooter } from '@ionic/react'
import { NavHeader } from '../components/Header/NavHeader'
import { CheckoutWrapper } from './CheckoutWrapper'

// type ModalAccountMappingProps = {}
export const ModalPendingPayments: FC<{ fromEvent: boolean; facility: Facility; onDismiss: () => void }> = ({
  fromEvent = false,
  facility,
  onDismiss,
}) => {
  const { t } = useTranslation()
  const currentUser = useCurrentUser()
  const { showModal, hideModal } = useModal()

  const pendingPayments =
    currentUser.pendingEvents
      ?.filter((event: any) => event.payload.facility.id === facility.id && event.action == EventNotificationAction.EVENT_TYPE_PAYMENT)
      .map((event: any) => new Notification(event)) || []

  const totalAmount = pendingPayments
    .map((notification: any) => notification.payment.amount)
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0)

  const checkout = async () => {
    //Questi sono gli id dei pagamenti per cui bisogna creare l'intent
    const eventsIds = pendingPayments.map((notification: Notification) => notification.id) as string[]
    const seqIds = pendingPayments.map((notification: Notification) => notification.seq)

    const result = await FacilitiesAPI.getClientSecret(eventsIds)

    showModal(
      <CheckoutWrapper
        clientSecret={result.clientSecret}
        accountId={result.stripeAccountId}
        intentId={result.stripePaymentId}
        amount={result.amount}
        sequenceNumber={seqIds}
        facility={facility}
        onDismiss={async () => {
          hideModal()
          onDismiss()
        }}
      />
    )
  }

  return (
    <IonPage>
      <NavHeader title={t('TITLE_CONFIRM_PAYMENT')} backAction={onDismiss} />
      <IonContent className="flex items-start">
        <div className="w-full mt-24 flex flex-col justify-center">
          <IconInfo className="mb-8 w-16 h-16 w-full text-center text-grey-500" />
          <div className="text-center mb-4 font-bold text-h200">{t('TITLE_PENDING_PAYMENTS')}</div>

          <div className="flex justify-center text-center">
            <div className="w-3/4">
              <Trans i18nKey={'DESCRIPTION_PENDING_PAYMENTS'} values={{ amount: pendingPayments.length }} />
            </div>
          </div>
          <div className="mt-8 flex justify-center">
            <ul>
              {pendingPayments.map((pendingPayment: Notification, index) => (
                <li className="list-disc" key={index}>
                  <Trans
                    i18nKey={'LABEL_SERVICE_ITEM_PENDING_PAYMENT'}
                    values={{ amount: pendingPayment.payment.amount, seq: pendingPayment.seq }}
                  />
                </li>
              ))}
            </ul>
          </div>
          <div className="mt-8 flex justify-center font-h200">
            {t('LABEL_TOTAL')} <span className="ml-1 font-bold">{totalAmount}€</span>
          </div>
        </div>
      </IonContent>
      <IonFooter className="bg-transparent flex justify-around mb-4">
        {fromEvent && (
          <IonButton className="link mr-4" fill="clear">
            <span className="font-bold">{t('ACTION_PAY_SINGLE_SERVICE')}</span>
          </IonButton>
        )}
        <IonButton className={`${fromEvent ? '' : 'w-5/6'}`} onClick={checkout}>
          <span className="font-bold text-h100">{t('ACTION_PAY_ALL_SERVICES')}</span>
        </IonButton>
      </IonFooter>
    </IonPage>
  )
}
