const storageName = process.env.REACT_APP_TYPE === 'web' ? 'VETERLY_VET' : 'VETERLY_PO'

const getStorage = (): { [key: string]: any } => {
  const item = window.localStorage.getItem(storageName)

  if (!item) {
    return {}
  }

  return JSON.parse(item)
}

const updateStorage = (storage: { [key: string]: any }) => {
  window.localStorage.setItem(storageName, JSON.stringify(storage))
}

const LocalStorage = () => {
  let storage: { [key: string]: any } = getStorage()

  return {
    get(key: string, defaultValue: any = null): any {
      if (storage.hasOwnProperty(key)) {
        return storage[key]
      }

      return defaultValue
    },

    set(key: string, value: any) {
      storage[key] = value
      updateStorage(storage)
      storage = getStorage()
    },

    delete(key: string) {
      delete storage[key]
      updateStorage(storage)
      storage = getStorage()
    },
  }
}

export const STORAGE_KEY_CURRENT_FACILITY_ID = 'currentFacilityId'

export const Storage = LocalStorage()
