import { BaseEventPayload, BaseEventPayloadInterface } from './BaseEventPayload'

export type ParentEventPayloadInterface = BaseEventPayloadInterface & {
  facilityId: string
  familyId?: string
  customerId?: string
}

export class ParentEventPayload extends BaseEventPayload {
  familyId: string | null
  facilityId: string
  customerId?: string

  constructor(payload: ParentEventPayloadInterface) {
    super(payload)
    this.familyId = payload.familyId || null
    this.facilityId = payload.facilityId
    this.customerId = payload.customerId
  }

  json(): any {
    return {
      ...super.json(),
      familyId: this.familyId,
      facilityId: this.facilityId,
      customerId: this.customerId,
    }
  }
}
