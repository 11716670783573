import { Auth } from 'aws-amplify'
import head from 'lodash/head'
import { UsersAPI } from 'src/api'
import { pushNotificationsInit, removePushNotificationListeners } from 'src/api/pushNotificationsAPI'
import { User } from 'src/models'
import { Token } from '@capacitor/push-notifications'
import { SplashScreen } from '@capacitor/splash-screen'
import { Dispatch } from '@reduxjs/toolkit'
import { SignUpFormType } from '../forms/SignUpForm'
import { SignUpConfirmationPayload } from '../payload/SignUpConfirmationPayload'
import { SignUpPayload } from '../payload/SignUpPayload'
import { authReducers } from './slice'
import { ResetPasswordConfirmParameters } from './types'

export const authThunks = {
  confirmSignUp:
    ({ username, code }: SignUpConfirmationPayload) =>
    async (dispatch: Dispatch): Promise<unknown> => {
      dispatch(authReducers.setLoading(true))
      try {
        const response = await Auth.confirmSignUp(username, code)
        dispatch(authReducers.setUser(new User(response)))
      } catch (err) {
        return Promise.reject(err)
      } finally {
        dispatch(authReducers.setLoading(false))
      }
    },

  resendConfirmationSignUp:
    (username: string) =>
    async (dispatch: Dispatch): Promise<unknown> => {
      dispatch(authReducers.setLoading(true))
      try {
        return await Auth.resendSignUp(username)
      } catch (err) {
        return Promise.reject(err)
      } finally {
        dispatch(authReducers.setLoading(false))
      }
    },

  login:
    (email: string, password: string, isVet: boolean, isPetOwner: boolean) =>
    async (dispatch: Dispatch): Promise<{ user: User }> => {
      dispatch(authReducers.setLoading(true))
      try {
        const cognitoUser = await Auth.signIn(email, password)
        const { sub: cognitoUserId } = cognitoUser.attributes
        let user = null

        try {
          user = await UsersAPI.getCurrentUser()
        } catch (e) {
          /**
           * First Login
           * User doesn't exist into our database
           */

          user = await UsersAPI.addUser(
            new User({
              email: cognitoUser.username,
              firstName: cognitoUser.attributes.name || head(cognitoUser.username.split('@')),
              lastName: cognitoUser.attributes.family_name || '',
              isVet,
              isPetOwner,
              marketing: cognitoUser.attributes['custom:marketing'],
              thirdparties: cognitoUser.attributes['custom:thirdparties'],
            })
          )
          await UsersAPI.welcomeEmail(user)
        }

        dispatch(authReducers.setLoginData({ cognitoUser, cognitoUserId, user }))
        return Promise.resolve({ cognitoUser, cognitoUserId, user })
      } catch (err) {
        localStorage.clear()
        dispatch(authReducers.resetLoginData())
        return Promise.reject(err)
      } finally {
        dispatch(authReducers.setLoading(false))
      }
    },

  checkLogin:
    () =>
    async (dispatch: Dispatch): Promise<User> => {
      dispatch(authReducers.setLoading(true))
      try {
        const cognitoUser = await Auth.currentAuthenticatedUser()
        const { sub: cognitoUserId } = cognitoUser.attributes
        const user = await UsersAPI.getCurrentUser()

        dispatch(authReducers.setLoginData({ cognitoUser, cognitoUserId, user }))
        return user
      } catch (err) {
        dispatch(authReducers.resetLoginData())
        localStorage.clear()
        return Promise.reject(err)
      } finally {
        dispatch(authReducers.setLoading(false))
        SplashScreen.hide()
      }
    },

  changePassword:
    (oldPassword: string, newPassword: string) =>
    async (dispatch: Dispatch): Promise<User> => {
      dispatch(authReducers.setLoading(true))
      try {
        const cognitoUser = await Auth.currentAuthenticatedUser()
        await Auth.changePassword(cognitoUser, oldPassword, newPassword)

        return await UsersAPI.getCurrentUser()
      } catch (err) {
        dispatch(authReducers.resetLoginData())
        localStorage.clear()
        return Promise.reject(err)
      } finally {
        dispatch(authReducers.setLoading(false))
      }
    },

  logout:
    (reload = true) =>
    async (dispatch: Dispatch): Promise<unknown> => {
      dispatch(authReducers.setLoading(true))

      try {
        await Auth.signOut({ global: true })
        dispatch(authReducers.resetLoginData())
        localStorage.clear()
        return true
      } catch (e) {
        dispatch(authReducers.resetLoginData())
        localStorage.clear()
        return true
      } finally {
        dispatch(authReducers.setLoading(false))

        /*
         * In order to delete ionic cache I need to do a complete refresh of the app,
         * On mobile side I can show the splash-screen for 2 seconds
         */
        SplashScreen.show({ showDuration: 2000, autoHide: true })
        reload && window.location.reload()
      }
    },

  signUp:
    (payload: SignUpFormType) =>
    async (dispatch: Dispatch): Promise<unknown> => {
      dispatch(authReducers.setLoading(true))
      try {
        const result = await Auth.signUp(new SignUpPayload(payload))
        return Promise.resolve(result)
      } catch (e) {
        return Promise.reject(e)
      } finally {
        dispatch(authReducers.setLoading(false))
      }
    },

  forgotPassword:
    (email: string) =>
    async (dispatch: Dispatch): Promise<unknown> => {
      dispatch(authReducers.setLoading(true))
      try {
        return await Auth.forgotPassword(email)
      } catch (e) {
        return Promise.reject(e)
      } finally {
        dispatch(authReducers.setLoading(false))
      }
    },

  resetPassword:
    ({ email, code, password }: ResetPasswordConfirmParameters) =>
    async (dispatch: Dispatch): Promise<unknown> => {
      dispatch(authReducers.setLoading(true))
      try {
        return await Auth.forgotPasswordSubmit(email, code, password)
      } catch (e) {
        return Promise.reject(e)
      } finally {
        dispatch(authReducers.setLoading(false))
      }
    },

  refreshUser:
    () =>
    async (dispatch: Dispatch): Promise<User> => {
      dispatch(authReducers.setLoading(true))
      try {
        const user = await UsersAPI.getCurrentUser()
        dispatch(authReducers.setUser(user))
        return user
      } catch (err) {
        dispatch(authReducers.resetLoginData())
        localStorage.clear()
        return Promise.reject(err)
      } finally {
        dispatch(authReducers.setLoading(false))
      }
    },

  updatePushNotificationToken: () => async (): Promise<void> => {
    try {
      const user = await UsersAPI.getCurrentUser()

      if (user.id) {
        const registerNewToken = async (token: Token) => {
          try {
            console.log('updatePushNotificationToken')
            console.log(token)
            await UsersAPI.deletePushNotificationsToken(user.id)
            await UsersAPI.setPushNotificationsToken(user.id, token.value)
          } catch (error) {
            console.error(error)
          }
        }

        await pushNotificationsInit(registerNewToken)
      }
    } catch (error) {
      console.error(error)
    }
  },

  deletePushNotificationToken: () => async (): Promise<void> => {
    try {
      const user = await UsersAPI.getCurrentUser()

      if (user.id) {
        await UsersAPI.deletePushNotificationsToken(user.id)
        await removePushNotificationListeners()
      }
    } catch (error) {
      console.error(error)
    }
  },
}
