import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { axiosInstance } from 'src/api/axios'
import { parseEventsToConversations } from 'src/api/eventApiUtilities'
import { familyThunks } from 'src/features/Family/store/thunks'
import { useCurrentFacility, useCurrentFamily, useFamilyConversations } from 'src/hooks/useCurrentValues'
import { AllEventMask, Conversation, DiaryEvent } from 'src/models/events'
import { EventStatus } from 'src/models/events/enums'
import { APP_VET_MODE } from 'src/utils'
import { RefresherEventDetail } from '@ionic/core'
import { IonContent, IonInfiniteScroll, IonInfiniteScrollContent, IonRefresher, IonRefresherContent, IonSpinner } from '@ionic/react'
import { useQuery } from '@tanstack/react-query'
import { FilterState } from '../../apps/veterinary/forms/FormDiaryFilters'
import { IconCalendar } from '../icons'
import { EventCard, EventCardMode } from './EventCard'
import { EventSection, EventSectionTitle } from './EventSection'

export type ConversationsSectionContentProps = {
  actions?: any
  onCardClick: (event: AllEventMask) => void
  onDiaryCardClick: (event: DiaryEvent) => void
  mode: EventCardMode
  filters: FilterState
  wrapper?: any
  onConversationsLenght?: (lenght: number) => void
}

export const ConversationsSectionContent: React.FC<ConversationsSectionContentProps> = ({
  mode,
  actions,
  filters,
  onCardClick,
  onDiaryCardClick,
  wrapper: Wrapper = IonContent,
  onConversationsLenght,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { family } = useCurrentFamily()
  const { facility } = useCurrentFacility()
  const [currentPage, setCurrentPage] = useState(0)
  const [disableInfiniteScroll] = useState<boolean>(true)
  const {
    isPending,
    error,
    data: conversations = [],
  } = useQuery({
    queryKey: ['conversations'],
    queryFn: async () => {
      let url = `/${family.id}/diary`

      if (APP_VET_MODE) {
        url = url.concat(`?filterByFacilityId=${facility.id}`)
      }

      const response = await axiosInstance.get(url)

      return parseEventsToConversations(response.data)
    },
  })

  const applyFilters = (conversations: any, filters: any) =>
    conversations.filter((conversation: any) => {
      const { pets, types, statuses, payments, message, period } = filters
      const searchText = message.toLowerCase()

      const matchesPets = pets.length
        ? pets.some((pet: any) => conversation.parentEvent.payload.pets.some((eventPet: any) => eventPet.id === pet))
        : true
      const matchesTypes = types.length ? types.includes(conversation?.parentEvent?.type) : true
      const matchesStatuses = statuses.length ? statuses.includes(conversation.parentEvent.payload.status) : true
      const matchesPayments = payments.length ? payments.includes(conversation?.parentEvent?.payload?.payment?.status) : true
      const matchesPeriod =
        period.from && period.to
          ? new Date(conversation.parentEvent.payload.date) >= new Date(period.from) &&
            new Date(conversation.parentEvent.payload.date) <= new Date(period.to)
          : true

      const matchesServices = conversation.parentEvent.payload.services
        ? conversation.parentEvent.payload.services.some((service: any) => service.name.toLowerCase().includes(searchText))
        : searchText === ''

      return matchesPets && matchesTypes && matchesStatuses && matchesPayments && matchesServices && matchesPeriod
    })

  const filteredConversations = applyFilters(conversations, filters)

  const hasConversations = filteredConversations.length > 0

  const nextConversations = filteredConversations.filter(
    (conversation: Conversation) => conversation?.parentEvent?.payload?.status === EventStatus.STATUS_NEW
  )
  const openConversations = filteredConversations.filter(
    (conversation: Conversation) => conversation?.parentEvent?.payload?.status === EventStatus.STATUS_OPEN
  )
  const pastConversationsFiltered = filteredConversations.filter(
    (conversation: Conversation) => conversation?.parentEvent?.payload?.status === EventStatus.STATUS_CLOSED
  )

  useEffect(() => {
    onConversationsLenght && onConversationsLenght(filteredConversations.length)
  }, [filteredConversations, onConversationsLenght])

  //   async function searchNext($event: CustomEvent<void>) {
  //     const nextPage = currentPage + 1
  //     setCurrentPage(nextPage)
  //     dispatch(familyThunks.getConversationsByFamily(currentPage, filters))
  //     ;($event.target as HTMLIonInfiniteScrollElement).complete()
  //   }

  return (
    <>
      {!hasConversations || !family.id ? (
        <Wrapper className="ion-bg-grey-100">
          <div className="flex w-full h-full items-center justify-center">
            <IonSpinner name="lines" color="primary" />
          </div>
        </Wrapper>
      ) : (
        <Wrapper className="ion-bg-grey-100 w-full">
          <div className="flex flex-col min-h-full">
            {!hasConversations ? (
              <div className="flex flex-col px-2 w-full">
                <div className="flex-1">
                  <IconCalendar className="text-grey-400 h-9" />
                  <h1 className="text-lead font-bold my-6">{t('NO_EVENTS')}</h1>
                  <p className="text-body-large text-grey-600">{t('NO_EVENTS_PET_OWNER_DESC')}</p>
                </div>
              </div>
            ) : (
              <div>
                <div className="flex flex-col">
                  <EventSection
                    dotted
                    empty={!hasConversations}
                    className={`${hasConversations ? 'mt-6 flex-grow' : 'mb-0 flex-grow'} before:rounded-t-full`}
                  >
                    {nextConversations.length ? <EventSectionTitle title={t('NEXT_EVENTS')} /> : null}
                    {nextConversations.map(({ parentEvent: evt }: { parentEvent: any }) => (
                      <EventCard key={evt?.id} event={evt} mode={mode} onClick={onCardClick} onEditClick={onDiaryCardClick} />
                    ))}
                  </EventSection>
                </div>
                <div className="flex flex-col ">
                  <EventSection empty={!hasConversations} className={hasConversations ? 'flex-grow' : 'mb-0 flex-grow'}>
                    {openConversations.map(({ parentEvent: evt }: { parentEvent: any }) => (
                      <EventCard key={evt?.id} event={evt} mode={mode} onClick={onCardClick} onEditClick={onDiaryCardClick} />
                    ))}
                  </EventSection>
                </div>
                <div className="flex flex-col">
                  <EventSection
                    empty={!hasConversations}
                    className={`${hasConversations ? 'flex-grow' : 'mb-0 flex-grow'} before:rounded-b-full`}
                  >
                    {pastConversationsFiltered.length ? <EventSectionTitle title={t('PREVIOUS_EVENTS')} /> : null}
                    {pastConversationsFiltered.map(({ parentEvent: evt }: { parentEvent: any }) => (
                      <EventCard key={evt?.id} event={evt} mode={mode} onClick={onCardClick} onEditClick={onDiaryCardClick} />
                    ))}
                  </EventSection>
                </div>
              </div>
            )}
            {/* <IonInfiniteScroll threshold="100px" disabled={disableInfiniteScroll} onIonInfinite={(e: CustomEvent<void>) => searchNext(e)}>
              <IonInfiniteScrollContent loadingText={t('LOADING_MORE')} />
            </IonInfiniteScroll> */}
          </div>

          {actions}
        </Wrapper>
      )}
    </>
  )
}
