import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { FacilitiesAPI } from 'src/api'
import { Avatar } from 'src/components/Avatar'
import { SquaredCard } from 'src/components/cards/SquaredCard'
import { IconCollapse } from 'src/components/icons'
import { useCurrentFamily, useCurrentUser } from 'src/hooks/useCurrentValues'
import { useModal } from 'src/hooks/useModal'
import { useToast } from 'src/hooks/useToast'
import { Pet } from 'src/models'
import { IonButton, IonContent, IonPage, IonFooter } from '@ionic/react'
import { NavHeader } from '../components/Header/NavHeader'
import { AppRouterHelper } from '../services/AppRouterHelper'
import { ModalPetsMapping } from './ModalPetsMapping'

// type ModalAccountMappingProps = {}
export const ModalAccountMapping: FC = () => {
  const { t } = useTranslation()
  const currentUser = useCurrentUser()
  const history = useHistory()
  const { family } = useCurrentFamily()
  const toast = useToast()
  const { showModal, hideModal } = useModal()

  const [currentIndex, setCurrentIndex] = useState<number>(0)
  const pendingFacilities = currentUser.pendingFacilities

  const onLink = async () => {
    try {
      const response = await FacilitiesAPI.petOwnerJoinFacility(pendingFacilities[currentIndex].code, currentUser, family)
      toast.success(t('FACILITY_ASSOCIATED_SUCCESSFULLY'))
      if (response.data?.pendingPets?.length) {
        showModal(
          <ModalPetsMapping
            facilityName={pendingFacilities[currentIndex].name}
            pendingPets={response.data.pendingPets}
            onComplete={() => {
              hideModal()
              getNextFacility()
            }}
          />
        )
      } else {
        getNextFacility()
      }
    } catch ({ message }) {
      toast.error(t(message as string))
    }
  }

  const onUnlink = async () => {
    try {
      await FacilitiesAPI.petOwnerUnlinkFacility(pendingFacilities[currentIndex].id, family.id)
      toast.success(t('FACILITY_DISASSOCIATED_SUCCESSFULLY'))
      getNextFacility()
    } catch ({ message }) {
      toast.error(t(message as string))
    }
  }

  const getNextFacility = () => {
    if (currentIndex + 1 >= pendingFacilities.length) {
      history.replace(AppRouterHelper.ROUTES.HOME)
      setCurrentIndex(0)
    } else {
      setCurrentIndex(currentIndex + 1)
    }
  }

  return (
    <IonPage>
      <NavHeader title={t('TITLE_WELCOME')} showBackButton={false} />
      <IonContent>
        <div className="mt-4">{t('DESCRIPTION_USER_FACILITY_MAPPING')}</div>
        {pendingFacilities[currentIndex] && (
          <div className="mt-4 flex justify-between h-36">
            {pendingFacilities[currentIndex].pets.length > 0 ? (
              <>
                <div className="w-36">
                  <SquaredCard contentClass="flex flex-col justify-center items-center text-center text-black text-h200 font-bold">
                    <Avatar className="h-12 w-12" />
                    <span className="mt-4">{pendingFacilities[currentIndex].pets.map((pet: Pet) => pet.name).join(',')}</span>
                  </SquaredCard>
                </div>
                <div className="flex items-center">
                  <IconCollapse className="absolute left-0 right-0 m-auto text-center z-10 w-12 h-12 text-primary-500" />
                </div>
                <div className="w-36">
                  <SquaredCard contentClass="flex flex-col justify-center items-center text-center text-black text-h200 font-bold">
                    <Avatar className="h-12 w-12" />
                    <span className="mt-4">{pendingFacilities[currentIndex].name}</span>
                  </SquaredCard>
                </div>
              </>
            ) : (
              <div className="w-full flex justify-center">
                <div className="w-48">
                  <SquaredCard contentClass="flex flex-col justify-center items-center text-center text-black text-h200 font-bold">
                    <Avatar className="h-12 w-12" />
                    <span className="mt-4">{pendingFacilities[currentIndex].name}</span>
                  </SquaredCard>
                </div>
              </div>
            )}
          </div>
        )}
      </IonContent>
      <IonFooter className="bg-transparent flex flex-col">
        <IonButton className="px-3" onClick={() => onLink()}>
          <span className="font-bold">{t('ACTION_CONFIRM')}</span>
        </IonButton>
        <IonButton className="px-3 my-4 link" fill="clear" onClick={() => onUnlink()}>
          <span className="font-bold">{t('ACTION_DO_NOT_CONFIRM')}</span>
        </IonButton>
      </IonFooter>
    </IonPage>
  )
}
