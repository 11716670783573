import orderBy from 'lodash/orderBy'
import { RequestsFilterState } from 'src/apps/veterinary/forms/FormRequestsFilters'
import { RequestsFilters } from 'src/models/filters/RequestsFilters'
import { FilterState } from '../apps/veterinary/forms/FormDiaryFilters'
import { Facility, Family, Pet, User } from '../models'
import { AllEventMask, ChildEventMask, Conversation } from '../models/events'
import { EventStatus, EventType } from '../models/events/enums'
import { EventAdapter } from '../services/EventAdapter'
import { arrayToDictionary, safeArray, unique } from '../utils'
import { FacilitiesAPI } from './FacilitiesAPI'
import { PetsAPI } from './PetsAPI'
import { UsersAPI } from './UsersAPI'
import { axiosInstance } from './axios'

export type SearchOptionsParams = {
  page?: number
  sort?: string
  perPage?: number
  group_by?: string
  group_by_limit?: number
}

export const MAX_PAGE_SIZE = 100

export const DefaultSearchOptions: SearchOptionsParams = {
  page: 0,
  sort: '-payload.date',
  perPage: MAX_PAGE_SIZE,
}

export const getSearchQuery = (opts: SearchOptionsParams = DefaultSearchOptions) => {
  const options = { ...DefaultSearchOptions, ...opts }
  const page = options.page || 0
  const limit = options.perPage || MAX_PAGE_SIZE
  const offset = page * limit
  let str = `?limit=${options.perPage}&offset=${offset}&sort=${options.sort}`

  if (options.group_by) {
    str += `&group_by=${options.group_by}`
  }

  if (options.group_by_limit) {
    str += `&group_by_limit=${options.group_by_limit}`
  }

  return str
}

/**
 * Return all events grouped buy parent Id and the latest messages
 *
 * @param object
 * @param opts
 * @param skipChildren
 * @param allChildren
 */
export const getConversationsByQuery = async (
  object: any,
  opts: SearchOptionsParams,
  skipChildren = false,
  allChildren = false,
  message?: string,
  showChildren = true
): Promise<Conversation[]> => {
  /**
   * Get parent events grouped by _id
   */
  const parentOptionsStr = getSearchQuery({ ...opts, group_by: '_id', group_by_limit: 1 })
  let parentEvents: AllEventMask[] = await axiosInstance
    .post(`/events/search${parentOptionsStr}`, { ...object, parent_id: null })
    .then((res) => res.data.map((evt: any) => EventAdapter.from(evt)))

  if (parentEvents.length === 0) {
    return []
  }

  /**
   * Retrieve all the latest messages ordered by date
   */
  const optionsStr = getSearchQuery({
    page: 0,
    ...(allChildren
      ? {
          sort: 'payload.date',
        }
      : {
          sort: '-payload.date',
          perPage: parentEvents.length,
          group_by: 'parent_id',
          group_by_limit: 1,
        }),
  })

  let lastMessageEvents: AllEventMask[] = skipChildren
    ? []
    : await axiosInstance
        .post(`/events/search${optionsStr}`, {
          parent_id: { type: 'OR', values: parentEvents.map((evt) => evt.id) },
          ...(message && message.length > 0 && { payload: { message } }),
        })
        .then((res) => res.data.map((evt: any) => EventAdapter.from(evt)))

  /**
   * Filter all events when message occurs
   */

  if (message && message.length > 0) {
    const parentsIds = lastMessageEvents.map((e: AllEventMask) => e.parent_id)
    parentEvents = parentEvents.filter(
      (event: AllEventMask) =>
        (event.id && parentsIds.includes(event.id)) ||
        (event.payload.message && event.payload.message.toLowerCase().includes(message.toLowerCase()))
    )
  }

  if (!showChildren) {
    lastMessageEvents = []
  }

  /**
   * Extract all unique values to retrieve
   */

  const pets: string[] = unique(parentEvents.map((event: AllEventMask) => event.payload?.petIds))
  const facilities: string[] = unique(parentEvents.map((event: AllEventMask) => event.payload?.facilityId))
  const users: string[] = unique([
    parentEvents.map((event: AllEventMask) => [event.payload?.userId, event.payload.assigneeId]),
    lastMessageEvents.map((event: AllEventMask) => [event.payload?.userId]),
  ])

  /**
   * Fetch asynchronous all the related data to fill
   */
  return await Promise.all<Pet[], Facility[], User[]>([
    PetsAPI.getPetsById(pets),
    FacilitiesAPI.getFacilities(facilities),
    UsersAPI.getUsers(users),
  ]).then(([p, f, u]) => {
    const petsList = arrayToDictionary(p, 'id')
    const usersList = arrayToDictionary(u, 'id')

    return parentEvents.map((evt: any) => {
      const children = lastMessageEvents.filter((e: AllEventMask) => e.parent_id === evt.id) as ChildEventMask[]
      const lastMessage = lastMessageEvents.find((e: AllEventMask) => e.parent_id === evt.id)
      const facility = f.find((facility) => facility.id === evt.payload.facilityId)

      evt.enrich({
        Facility: facility || null,
        Family: null,
        User: usersList[evt.payload.userId] || null,
        Assignee: usersList[evt.payload.assigneeId] || null,
        Pets: safeArray(evt.payload?.pets)
          .map((id: any) => petsList[id])
          .filter(Boolean),
        LastMessage: lastMessage || null,
        LastUserMessage: usersList[lastMessage?.payload?.userId] || null,
      })

      children.forEach((child) => {
        child.enrich({
          Facility: facility || null,
          Family: null,
          User: usersList[child.payload.userId] || null,
          Assignee: usersList[child.payload.assigneeId] || null,
          Pets: [],
          LastMessage: null,
          LastUserMessage: null,
        })
      })

      return new Conversation(evt, children)
    })
  })
}

/**
 *
 * Returns all the direct events
 *
 * @param query
 * @param opts
 */
export const getEventsByQuery = async (query: any, opts: SearchOptionsParams): Promise<AllEventMask[]> => {
  const optionsStr = getSearchQuery({
    ...opts,
  })

  const messagesEvents: AllEventMask[] = await axiosInstance.post(`/events/search${optionsStr}`, query).then((res) =>
    orderBy(
      res.data.map((evt: any) => EventAdapter.from(evt)),
      (evt) => evt.payload.date,
      'asc'
    )
  )

  const pets: string[] = unique([messagesEvents.map((event: AllEventMask) => event.payload?.petIds)])
  const users: string[] = unique([
    messagesEvents.map((event: AllEventMask) => event.payload?.assigneeId),
    messagesEvents.map((event: AllEventMask) => event.payload?.userId),
  ])

  return await Promise.all<Pet[], User[]>([PetsAPI.getPetsById(pets), UsersAPI.getUsers(users)]).then(([p, u]) => {
    const petsList = arrayToDictionary(p, 'id')
    const usersList = arrayToDictionary(u, 'id')

    return messagesEvents.map((evt: any) => {
      evt.enrich({
        Facility: null,
        Family: null,
        LastMessage: null,
        LastUserMessage: null,
        Pets: safeArray(evt.payload?.pets)
          .map((id: any) => petsList[id])
          .filter(Boolean),
        User: usersList[evt.payload.userId] || null,
        Assignee: usersList[evt.payload?.assigneeId] || null,
      })

      return evt
    })
  })
}

const buildPayload = (facilityId: string, filters?: RequestsFilterState) => {
  interface Payload {
    [key: string]: any
  }

  const payload: Payload = {
    facilityId,
    status: {
      type: 'OR',
      values: [EventStatus.STATUS_NEW, EventStatus.STATUS_OPEN],
    },
  }

  if (filters) {
    if (filters.veterinarians?.length > 0) {
      payload.assigneeId = filters.veterinarians.includes('ASSIGNED')
        ? {
            type: 'NOT',
            values: null,
          }
        : {
            type: 'OR',
            values: filters.veterinarians,
          }
    }

    payload.status =
      filters.statuses?.length > 0
        ? {
            type: 'OR',
            values: filters.statuses,
          }
        : {
            type: 'OR',
            values: [EventStatus.STATUS_NEW, EventStatus.STATUS_OPEN, EventStatus.STATUS_CLOSED],
          }
  }

  return payload
}

const buildMobilePayload = (filters?: RequestsFilters) => {
  const payload: {
    [key: string]: any
  } = {}

  if (!!filters) {
    if (filters.customer) {
      payload.familyId = {
        type: 'OR',
        values: [filters.customer],
      }
    }
    if (filters.facilities.length > 0) {
      payload.facilityId = {
        type: 'OR',
        values: filters.facilities,
      }
    }

    payload.status =
      filters.statuses.length > 0
        ? {
            type: 'OR',
            values: filters.statuses,
          }
        : {
            type: 'OR',
            values: [EventStatus.STATUS_NEW, EventStatus.STATUS_OPEN, EventStatus.STATUS_CLOSED],
          }
  }

  return payload
}

export const getVeterinaryRequestQuery = (facility: Facility, filters?: RequestsFilterState) => ({
  parent_id: null,
  type: {
    type: 'OR',
    values: [EventType.EVENT_TYPE_HEALTH],
  },
  ...(filters && filters.period
    ? {
        period: filters.period,
      }
    : {}),
  payload: buildPayload(facility.id, filters),
})

export const getVeterinaryMobileRequestQuery = (filters?: RequestsFilters) => ({
  parent_id: null,
  type: {
    type: 'OR',
    values: [EventType.EVENT_TYPE_HEALTH],
  },
  payload: buildMobilePayload(filters),
})

export const getPetOwnerRequestQuery = (family: Family, filters: FilterState) => ({
  parent_id: {
    type: 'NOT',
    values: null,
  },
  type: {
    type: 'OR',
    values: [EventType.EVENT_TYPE_CHAT, EventType.EVENT_TYPE_BOT, EventType.EVENT_TYPE_CALENDAR],
  },
  payload: {
    familyId: family.id,
    ...(filters.statuses.length
      ? {
          status: {
            type: 'OR',
            values: filters.statuses,
          },
        }
      : {}),
    ...(filters.pets.length
      ? {
          pets: {
            type: 'OR',
            values: filters.pets.map((id) => [id]),
          },
        }
      : {}),
    ...(filters.payments.length
      ? {
          payment: {
            status: {
              type: 'OR',
              values: filters.payments,
            },
          },
        }
      : {}),
  },
})

export const getConversationMessagesQuery = (eventId: string, additional: any = {}) => ({
  parent_id: eventId,
  ...additional,
})

export const getSingleEvent = (eventId: string) => ({
  parent_id: eventId,
})

export const parseEventsToConversations = (events: AllEventMask[]) =>
  events.map((event: any) => {
    const evt = EventAdapter.from(event) as AllEventMask

    evt.addEnrichedData({
      Family: event.payload.family ? new Family({ ...event.payload.family, UserFamily: event.payload.customer }) : null,
      User: event.payload.user ? new User(event.payload.user) : null,
      Facility: event.payload.facility ? new Facility(event.payload.facility) : null,
      Pets: event.payload.pets.map((pet: any) => new Pet(pet)),
      Assignee: event.payload.assignee ? new User(event.payload.assignee) : null,
      Appointment: event.appointment ? event.appointment : null,
    })

    //necessarie per fare update (avviare, terminare e credo anche modificare) degli eventi
    evt.payload.userId = evt.User?.id || ''
    evt.payload.facilityId = evt.Facility?.id || ''
    evt.payload.familyId = evt.Family?.id || ''
    evt.payload.assigneeId = evt.Assignee?.id || ''
    evt.payload.customerId = event.payload.customer?.id || ''

    if (event.chat?.length > 0) {
      const lastMessageIndex = event.chat.length - 1

      if (lastMessageIndex >= 0) {
        const lastMessage = event.chat[lastMessageIndex]
        const lastMessageEvent = EventAdapter.from(lastMessage) as AllEventMask

        evt.addEnrichedData({ LastMessage: lastMessageEvent, LastUserMessage: lastMessageEvent.payload.user })
      }
    }

    let children = [] as AllEventMask[]

    children = children.concat(event.chat || ([] as AllEventMask[]))
    children = children.concat(event.appointment || ([] as AllEventMask[]))

    children = children.map((childEvent: any) => {
      const child = EventAdapter.from(childEvent) || ([] as ChildEventMask[])

      child.addEnrichedData({
        Family: childEvent.payload.family ? new Family(childEvent.payload.family) : null,
        User: childEvent.payload.user ? new User(childEvent.payload.user) : null,
        Facility: childEvent.payload.facility ? new Facility(childEvent.payload.facility) : null,
        Pets: childEvent.payload.pets.map((pet: any) => new Pet(pet)),
        Assignee: childEvent.payload.assignee ? new User(childEvent.payload.assignee) : null,
      })

      return child
    })

    return new Conversation(evt, children)
  })
