import React, { forwardRef, ReactElement } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { ControllerRenderProps } from 'react-hook-form/dist/types/controller'
import { useTranslation } from 'react-i18next'
import { InputField } from 'src/components/inputs/InputField'
import { User } from 'src/models'
import { mixed, object, string } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { UploadField } from '../../../components/inputs/UploadField'

export type FormEditProfileParams = {
  onSubmit: (parameters: any) => void
  className?: string
  user: User
  editForm?: boolean
  submitText?: string
  onDelete?: () => void
}
/**
 * @description FormEditProfile
 *
 * @param onSubmit
 * @param className
 * @param editForm
 * @constructor
 */
export const FormEditProfile = forwardRef<HTMLFormElement, FormEditProfileParams>(
  ({ onSubmit, user, className = '' }: FormEditProfileParams, ref): ReactElement => {
    const { t } = useTranslation()

    const onTaxNumberInputChange = (field: ControllerRenderProps<any>, e: React.ChangeEvent<HTMLIonInputElement>) => {
      const { value } = e.target as HTMLIonInputElement
      field.onChange(value ? value.toString().toUpperCase() : null)
    }

    const validationSchema = object().shape({
      firstName: string().required(),
      lastName: string().required(),
      image: mixed(),
      phone: string().nullable(),
      //   taxNumber: string().nullable(),
      address: string().nullable(),
      city: string().nullable(),
      country: string().nullable(),
    })

    const onSubmitCallback = (data: any) => {
      onSubmit({
        firstName: data.firstName,
        lastName: data.lastName,
        image: data.image ? data.image[0] : null,
        email: user.email,
        phone: data.phone,
        // taxNumber: data.taxNumber,
        address: data.address,
        city: data.city,
        country: data.country,
      })
    }
    const {
      register,
      control,
      handleSubmit,
      formState: { errors },
    } = useForm({
      mode: 'onBlur',
      reValidateMode: 'onBlur',
      resolver: yupResolver(validationSchema),
      defaultValues: {
        firstName: user.firstName,
        lastName: user.lastName,
        image: null,
        phone: user.phone,
        // taxNumber: user.taxNumber,
        address: user.address,
        city: user.city,
        country: user.country,
      },
    })
    return (
      <form onSubmit={handleSubmit(onSubmitCallback)} className={`w-full flex flex-col py-6 ${className}`} ref={ref}>
        <UploadField {...register('image')} defaultValue={user.avatar} label={t('UPLOAD_YOUR_PHOTO')} />
        <fieldset className="mt-6">
          <InputField label={t('FORM_LABEL_NAME')} {...register('firstName')} error={errors.firstName?.message} />
          <InputField label={t('FORM_LABEL_SURNAME')} {...register('lastName')} error={errors.lastName?.message} />
          <InputField disabled type="email" label={t('FORM_LABEL_EMAIL')} value={user.email} />
          <InputField type="tel" label={t('FORM_LABEL_PHONE')} {...register('phone')} error={errors.phone?.message} />
          {/* <Controller
            name="taxNumber"
            control={control}
            defaultValue={user.taxNumber}
            render={({ field }) => (
              <InputField
                label={t('FORM_LABEL_FISCAL_CODE')}
                error={errors.taxNumber?.message}
                autoCapitalize="characters"
                onInput={(e: React.ChangeEvent<HTMLIonInputElement>) => onTaxNumberInputChange(field, e)}
                value={field.value}
              />
            )}
          /> */}
          <InputField label={t('FORM_LABEL_ADDRESS')} {...register('address')} error={errors.address?.message} />
          <InputField label={t('FORM_LABEL_CITY')} {...register('city')} error={errors.city?.message} />
          <InputField label={t('FORM_LABEL_NATION')} {...register('country')} error={errors.country?.message} />
        </fieldset>
      </form>
    )
  }
)
