import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { UsersAPI } from 'src/api'
import { currentUserSelector } from 'src/features/Auth/store/selectors'
import { useToast } from 'src/hooks/useToast'
import { User } from 'src/models'
import { IonButton, IonContent, IonLoading, IonPage } from '@ionic/react'
import { submitForm } from '../../../components/forms/Form'
import { authThunks } from '../../../features/Auth/store/thunks'
import { NavHeader } from '../components/Header/NavHeader'
import { FormEditProfile } from '../forms/FormEditProfile'

export const PageEditProfile: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const [isLoading, setLoading] = useState<boolean>(false)
  const toast = useToast()
  const formRef = useRef<HTMLFormElement>(null)

  const checkValidation = () => submitForm(formRef.current)
  const currentUser: User = useSelector(currentUserSelector)

  const onSubmit = async (data: any) => {
    setLoading(true)
    try {
      await UsersAPI.updateCurrentUser(data, data.image)
      setLoading(false)
      await dispatch(authThunks.refreshUser())
      history.goBack()
    } catch ({ message }) {
      setLoading(false)
      toast.error(t(message as string))
    } finally {
      setLoading(false)
    }
  }

  return (
    <IonPage>
      <IonLoading isOpen={isLoading} />
      <NavHeader title={t('PAGE_EDIT_PROFILE_TITLE')}>
        <IonButton onClick={checkValidation}>
          <span className="text-primary-500 font-bold px-2">{t('SAVE')}</span>
        </IonButton>
      </NavHeader>
      <IonContent>
        <FormEditProfile onSubmit={onSubmit} user={currentUser} ref={formRef} />
      </IonContent>
    </IonPage>
  )
}
