import { uniqueId } from 'lodash'
import { BaseEvent, BaseEventInterface } from './BaseEvent'
import { EventBotActions, EventType } from './enums'
import { BotEventPayload, BotEventPayloadInterface } from './payload/BotEventPayload'

export class BotEvent extends BaseEvent<BotEventPayloadInterface> {
  constructor(data: BaseEventInterface, payload: BotEventPayloadInterface) {
    data.type = EventType.EVENT_TYPE_BOT
    super(data, payload)
  }

  parsePayload(payload: any) {
    return new BotEventPayload(payload)
  }

  static uiMessage(date: Date, eventAction: EventBotActions) {
    return new BotEvent({ _id: uniqueId() }, { date, eventAction, facilityId: '', userId: '' })
  }
}
