import { DateConverter } from 'src/utils'

export type BaseEventPayloadInterface = {
  userId: string
  date?: string | Date
  file?: string
  filetype?: string
}

export class BaseEventPayload {
  userId: string
  date: Date
  file: string | null
  filetype: string

  constructor(payload: BaseEventPayloadInterface) {
    this.userId = payload.userId || ''
    this.date = payload.date ? DateConverter.fromServer(payload.date) : DateConverter.now()
    this.file = payload.file || null
    this.filetype = payload.filetype || ''
  }

  json(): any {
    return {
      userId: this.userId,
      date: DateConverter.toServer(this.date),
      ...(this.file ? { image: this.file } : null),
    }
  }
}
