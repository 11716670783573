import { format } from 'date-fns'
import { enUS } from 'date-fns/locale'
import { unescape } from 'html-escaper'
import parse from 'html-react-parser'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useCurrentUser } from 'src/hooks/useCurrentValues'
import { AllEventMask, ChildEventMask, Conversation } from 'src/models/events'
import { EventAction, EventBotActions, BotMessageWithActions, CalendarEventStatus } from 'src/models/events/enums'
import { formatDate } from 'src/utils'
import { BackgroundLogo, LogoThemesEnum } from '../icons/Logos'
import { BotRenderer } from './BotRenderer'
import { ChatMessageWrapper } from './ChatMessageWrapper'

type BotMessageProps = {
  conversation?: Conversation
  event: ChildEventMask
  isLast?: boolean
  className?: string
}

const getDueTimeLabel = (status: CalendarEventStatus): string => {
  const { t } = useTranslation()

  switch (status) {
    case CalendarEventStatus.CALENDAR_EVENT_STATUS_TO_BE_CONFIRMED:
      return t('LABEL_WITHIN_24_HOURS')
    case CalendarEventStatus.CALENDAR_EVENT_STATUS_TO_BE_BOOKED:
      return t('LABEL_WITHIN_15_MINUTES')
    case CalendarEventStatus.CALENDAR_EVENT_STATUS_RESERVATION:
      return t('LABEL_WITHIN_15_MINUTES')
    default:
      return ''
  }
}

export const getActionText = (event: AllEventMask, conversation?: Conversation): string => {
  const { t } = useTranslation()
  const currentUser = useCurrentUser()
  const { payload } = event
  const { eventAction } = payload

  const appointmentServices = useMemo(
    () => conversation?.parentEvent?.payload?.services?.map((service: { name: any }) => service.name).join(','),
    [conversation]
  )

  if (eventAction === EventBotActions.BOT_ERROR_INVALID_CONVERSATION) {
    return t('BOT_ERROR_INVALID_CONVERSATION_MESSAGE')
  }

  if (eventAction === EventBotActions.BOT_EVENT_OPENED) {
    return t('BOT_EVENT_OPENED', {
      date: `<strong>${format(conversation?.parentEvent.payload.openDate, 'MMMM dd yyyy', { locale: enUS })}</strong>`,
      time: `<strong>${format(conversation?.parentEvent.payload.openDate, 'h:mm a', { locale: enUS })}</strong>`,
    })
  }

  if (eventAction === EventBotActions.BOT_EVENT_CLOSED) {
    return t('BOT_EVENT_CLOSED_MESSAGE', { duration: conversation?.duration })
  }

  if (eventAction === EventBotActions.BOT_VISIT_EVENT_UPDATED_BY_VET) {
    if (currentUser?.isVet)
      return t('BOT_VISIT_EVENT_UPDATED_BY_VET_MESSAGE_TO_VET', {
        seq: conversation?.parentEvent.seq,
        vetName: conversation?.parentEvent?._enrichedData?.User?.firstName || '',
        petName: conversation?.pets?.[0].name ?? '',
      })
    else
      return t('BOT_VISIT_EVENT_UPDATED_BY_VET_MESSAGE_TO_PET_OWNER', {
        seq: conversation?.parentEvent.seq,
        vetName: conversation?.parentEvent?._enrichedData?.User?.firstName || '',
        petName: conversation?.pets?.[0].name ?? '',
      })
  }

  if (eventAction === EventBotActions.BOT_VISIT_EVENT_UPDATED_BY_PET_OWNER) {
    if (currentUser?.isVet)
      return t('BOT_VISIT_EVENT_UPDATED_BY_PET_OWNER_MESSAGE_TO_VET', {
        seq: conversation?.parentEvent.seq,
        ownerName: conversation?.parentEvent?._enrichedData?.User?.firstName || '',
        petName: conversation?.pets?.[0].name ?? '',
      })
    else
      return t('BOT_VISIT_EVENT_UPDATED_BY_PET_OWNER_MESSAGE_TO_PET_OWNER', {
        seq: conversation?.parentEvent.seq,
        ownerName: conversation?.parentEvent?._enrichedData?.User?.firstName || '',
        petName: conversation?.pets?.[0].name ?? '',
      })
  }

  if (eventAction === EventBotActions.BOT_VISIT_EVENT_OPENED_BY_VET) {
    if (currentUser?.isVet)
      return t('BOT_VISIT_EVENT_OPENED_BY_VET_MESSAGE_TO_VET', {
        seq: conversation?.parentEvent.seq,
        vetName: conversation?.parentEvent?._enrichedData?.User?.firstName || '',
        petName: conversation?.pets?.[0].name ?? '',
      })
    else
      return t('BOT_VISIT_EVENT_OPENED_BY_VET_MESSAGE_TO_PET_OWNER', {
        seq: conversation?.parentEvent.seq,
        vetName: conversation?.parentEvent?._enrichedData?.User?.firstName || '',
        petName: conversation?.pets?.[0].name ?? '',
      })
  }

  if (eventAction === EventBotActions.BOT_VISIT_EVENT_OPENED_BY_PET_OWNER) {
    if (currentUser?.isVet)
      return t('BOT_VISIT_EVENT_OPENED_BY_PET_OWNER_MESSAGE_TO_VET', {
        seq: conversation?.parentEvent.seq,
        ownerName: conversation?.parentEvent?._enrichedData?.User?.firstName || '',
        petName: conversation?.pets?.[0].name ?? '',
      })
    else
      return t('BOT_VISIT_EVENT_OPENED_BY_PET_OWNER_MESSAGE_TO_PET_OWNER', {
        seq: conversation?.parentEvent.seq,
        ownerName: conversation?.parentEvent?._enrichedData?.User?.firstName || '',
        petName: conversation?.pets?.[0].name ?? '',
      })
  }

  if (eventAction === EventBotActions.BOT_CONSULTATION_EVENT_OPENED_BY_PET_OWNER) {
    if (currentUser?.isVet)
      return t('BOT_CONSULTATION_EVENT_OPENED_BY_PET_OWNER_MESSAGE_TO_VET', {
        seq: conversation?.parentEvent.seq,
        services: appointmentServices,
        ownerName: conversation?.parentEvent?._enrichedData?.User?.firstName || '',
        petName: conversation?.pets?.[0].name ?? '',
      })
    else
      return t('BOT_CONSULTATION_EVENT_OPENED_BY_PET_OWNER_MESSAGE_TO_PET_OWNER', {
        seq: conversation?.parentEvent.seq,
        services: appointmentServices,
        petName: conversation?.pets?.[0].name ?? '',
      })
  }

  if (eventAction === EventBotActions.BOT_CONSULTATION_EVENT_OPENED_BY_VET) {
    if (currentUser?.isVet)
      return t('BOT_CONSULTATION_EVENT_OPENED_BY_VET_MESSAGE_TO_VET', {
        seq: conversation?.parentEvent.seq,
        vetName: conversation?.parentEvent?.Assignee?.safeName || '',
        petName: conversation?.pets?.[0].name ?? '',
      })
    else
      return t('BOT_CONSULTATION_EVENT_OPENED_BY_VET_MESSAGE_TO_PET_OWNER', {
        seq: conversation?.parentEvent.seq,
        vetName: conversation?.parentEvent?.Assignee?.safeName || '',
        petName: conversation?.pets?.[0].name ?? '',
      })
  }

  if (eventAction === EventBotActions.BOT_CONSULTATION_DETAILS) {
    return t('BOT_CONSULTATION_DETAILS_MESSAGE', {
      seq: conversation?.parentEvent.seq,
    })
  }

  if (eventAction === EventBotActions.BOT_CONSULTATION_EVENT_ASSIGNED_TO_FACILITY) {
    return t('BOT_CONSULTATION_EVENT_ASSIGNED_TO_FACILITY_MESSAGE', { facility: conversation?.parentEvent.Facility?.name })
  }

  if (eventAction === EventAction.EVENT_ACTION_PROPOSE_TIME) {
    return t('EVENT_ACTION_PROPOSE_TIME_MESSAGE')
  }

  if (eventAction === EventAction.EVENT_ACTION_PROPOSE_TIME) {
    return t('EVENT_ACTION_PROPOSE_TIME_MESSAGE')
  }

  if (eventAction === EventAction.EVENT_ACTION_RELATED_TO_APPOINTMENT) {
    return t('EVENT_ACTION_RELATED_TO_APPOINTMENT_MESSAGE', {
      name: event.User?.name,
    })
  }

  if (eventAction === EventAction.EVENT_ACTION_OPEN_PERFORMANCE) {
    return t('EVENT_ACTION_OPEN_PERFORMANCE_MESSAGE')
  }
  if (eventAction === EventAction.EVENT_ACTION_APPOINTMENT) {
    return t('EVENT_ACTION_APPOINTMENT_MESSAGE', {
      date: `<strong>${format(event.payload.date, 'MMMM dd yyyy', { locale: enUS })}</strong>`,
      time: `<strong>${format(event.payload.date, 'h:mm a', { locale: enUS })}</strong>`,
    })
  }

  if (eventAction === EventAction.EVENT_ACTION_CLOSE_PERFORMANCE) {
    return t('EVENT_ACTION_CLOSE_PERFORMANCE_MESSAGE')
  }

  if (eventAction === EventBotActions.BOT_PAYMENT_REQUEST) {
    return t('BOT_MESSAGE_PAYMENT_REQUEST', { amount: conversation?.parentEvent.payload.payment?.amount })
  }

  if (eventAction === EventBotActions.BOT_PAYMENT_REQUEST_UPFRONT) {
    if (currentUser?.isVet) {
      return t('BOT_MESSAGE_PAYMENT_REQUEST_UPFRONT_OPENED_BY_VET', {
        amount: conversation?.parentEvent.payload.payment?.amount,
      })
    } else {
      return t('BOT_MESSAGE_PAYMENT_REQUEST_UPFRONT_OPENED_BY_PET_OWNER', {
        vetName: conversation?.parentEvent.Assignee?.firstName || conversation?.parentEvent.Facility?.name,
        amount: conversation?.parentEvent.payload.payment?.amount,
        time: getDueTimeLabel(conversation?.getCalendarEvent()?.payload.status),
      })
    }
  }

  if (eventAction === EventBotActions.BOT_PAYMENT_PAID) {
    return t('BOT_MESSAGE_PAYMENT_PAID')
  }
  if (eventAction === EventBotActions.BOT_PAYMENT_FAILED) {
    return t('BOT_MESSAGE_PAYMENT_FAILED')
  }

  if (eventAction === EventBotActions.BOT_JOIN_VIDEOMEETING) {
    return t('BOT_JOIN_VIDEOMEETING')
  }

  if (eventAction === EventBotActions.BOT_CLOSE_VIDEOMEETING) {
    return t('BOT_CLOSE_VIDEOMEETING')
  }

  return eventAction
}

export const BotMessage: React.FC<BotMessageProps> = ({ conversation, event, className }) => {
  if (!event) return null

  const time = formatDate(event.payload.date, 'HH:mm')
  const actionText = unescape(getActionText(event, conversation).replaceAll('&#x2F;', '/'))

  if (actionText.length === 0) return null

  const isBotMessageWithAction = BotMessageWithActions.includes(event.payload.eventAction)

  return isBotMessageWithAction ? (
    <BotRenderer event={conversation?.parentEvent} action={event.payload.eventAction} onSuccess={() => null} />
  ) : (
    <ChatMessageWrapper className={className} event={event}>
      <div className="h-12 w-12 shadow-light-40 rounded-full flex items-center justify-center bg-white flex-shrink-0 p-2">
        <BackgroundLogo theme={LogoThemesEnum.DARK} />
      </div>
      <div className="flex-grow">
        <div className="text-grey-600 text-body-tiny mb-1">{time}</div>
        <div className="whitespace-pre-line shadow-light-40 rounded rounded-tl-none p-4 bg-primary-200">{parse(actionText || '')}</div>
      </div>
    </ChatMessageWrapper>
  )
}
