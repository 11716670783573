import isPlainObject from 'lodash/isPlainObject'
import sortBy from 'lodash/sortBy'
import { Facility, Family, User, UserRole } from 'src/models'
import { EventPaymentPaymentType } from 'src/models/events/enums'
import { axiosInstance, getCacheConfig } from './axios'

export class FacilitiesAPI {
  static async create(facility: Facility, image: File | null): Promise<any> {
    const formData = facility.toFormData()

    if (image) {
      formData.append('image', image)
    }

    return axiosInstance
      .post('/organizations', formData, {
        headers: {
          'content-type': 'multipart/form-data',
        },
      })
      .then(({ data }) => data?.facility?.FacilityId || '')
  }

  static async createFacilityOnboarding(name: string, email?: string, phone?: string): Promise<any> {
    const body = { name, phone, email }

    return axiosInstance.post('/facilities', body).then(({ data }) => data.facility)
  }

  static async update(facility: Facility, image: File | null): Promise<any> {
    const formData = facility.toFormData()
    console.log('formData', formData)

    if (image) {
      formData.append('image', image)
    }

    return axiosInstance.patch('/facilities', formData, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    })
  }

  static async edit(data: { facilityId: string; ChargebeeCustomerId: string }): Promise<any> {
    if (!data.facilityId) throw new Error('FacilityId required')

    return axiosInstance.patch('/facilities', data)
  }

  static async inviteCollaborators(facilityId: string, userList: User[], message: string): Promise<any> {
    const users = userList.map((user) => ({
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      idRole: user.idRole,
      message,
      isVet: true,
    }))

    return axiosInstance.post(`/facilities/invite?facilityId=${facilityId}`, { users })
  }

  // static async invitePetOwners(facilityId: string, userList: User[], message: string): Promise<any> {
  //   const users = userList.map((user) => ({
  //     email: user.email,
  //     firstName: user.firstName,
  //     lastName: user.lastName,
  //     message,
  //     isVet: false,
  //   }))

  //   return axiosInstance.post(`/facilities/invite?facilityId=${facilityId}`, { users })
  // }

  static async invitePetOwners(facilityId: string, userList: User[], message?: string, inviteType = 'email'): Promise<any> {
    const users = userList.map((user) => ({
      id: user.id,
      message,
      ...(inviteType === 'email' ? { email: user.email } : { phone: user.phone }),
      isVet: false,
    }))

    return axiosInstance.post(`/facilities/users/invite?facilityId=${facilityId}`, { ...(users.length > 0 ? { users } : {}) })
  }

  static addCustomersToFacility(facilityId: string, users: any[]): any {
    return axiosInstance
      .post(`/facilities/users/import?facilityId=${facilityId}&properties=all`, { users })
      .then((response: any) => response.data)
  }

  static async getFacilityDetails(facilityId: string, skipCache = false): Promise<Facility> {
    return axiosInstance.get(`/facilities?facilityId=${facilityId}`, getCacheConfig(skipCache)).then(({ data }) => new Facility(data))
  }

  static async getFacilities(facilities: string[]): Promise<Facility[]> {
    const ids = facilities.join(',')

    if (ids.length === 0) {
      return Promise.resolve([])
    }

    return axiosInstance
      .get(`/facilities?facilityId=${ids}`)
      .then(({ data }) => {
        if (isPlainObject(data)) {
          return [new Facility(data)]
        }

        const facilities = data || []
        return sortBy(facilities, 'name').map((f: any) => new Facility(f))
      })
      .catch(() => Promise.resolve([]))
  }

  static async getClients(facilityId: string, skipCache = false): Promise<User[]> {
    return axiosInstance.get(`/users/facility/${facilityId}/clients`, getCacheConfig(skipCache)).then(({ data }) => {
      const users = data || []
      return sortBy(
        users.map((user: any) => new User(user)),
        'lastName'
      )
    })
  }

  static async getVeterinarians(facilityId: string, skipCache = false): Promise<User[]> {
    return axiosInstance.get(`/users/facility/${facilityId}/vets`, getCacheConfig(skipCache)).then(({ data }) => {
      const users = data || []
      return sortBy(
        users.map((user: any) => new User(user)),
        'lastName'
      )
    })
  }

  static async getVeterinaryRoles(skipCache = false): Promise<any[]> {
    return axiosInstance.get(`/users/roles`, getCacheConfig(skipCache)).then(({ data }) =>
      sortBy(data, 'name')
        .filter((role: any) => role.name.startsWith('VET_'))
        .map((role: any) => new UserRole(role))
    )
  }

  static async updateCollaborator(vet: User, facility: Facility): Promise<any[]> {
    return axiosInstance.patch(`/users/vet?id=${vet.id}&FacilityId=${facility.id}`, vet.getVetData())
  }

  static async removeCollaborator(vet: User, facility: Facility): Promise<any[]> {
    return axiosInstance.delete(`/users/link?id_user=${vet.id}&id_facility=${facility.id}`)
  }

  static async removeClient(petOwner: User, facility: Facility): Promise<any[]> {
    return axiosInstance.delete(`/users/link?id_user=${petOwner.id}&id_facility=${facility.id}&id_family=${petOwner.FamilyId}`, {
      data: {
        id_user: petOwner.id,
        id_family: petOwner.FamilyId,
        id_facility: facility.id,
      },
    })
  }

  static async getByCode(code: string): Promise<Facility> {
    return axiosInstance.get(`/facilities/code/${code}`).then((response: any) => new Facility(response.data.facility))
  }

  static async petOwnerJoinFacility(code: string, user: User, family: Family): Promise<any> {
    return axiosInstance.post(`/users/link`, {
      facilityCode: code,
      userId: user.id,
      familyId: family.id,
    })
  }

  static async petOwnerUnlinkFacility(facilityId: string, familyId: string): Promise<any> {
    return axiosInstance.delete(`/v2/users/link?id_facility=${facilityId}&id_family=${familyId}`)
  }

  static async veterinaryJoinFacility(code: string, user: User): Promise<any> {
    return axiosInstance.post(`/users/link`, {
      facilityCode: code,
      userId: user.id,
    })
  }

  static async updateClient(data: any): Promise<any> {
    if (!data.clientFrom) {
      delete data.clientFrom
    }

    return axiosInstance.patch(`/users/client`, data)
  }

  static async getStripePageId(facilityId: string, organizationId: string): Promise<any> {
    const response = await axiosInstance.post(`/stripe/create`, {
      OrganizationId: organizationId,
      FacilityId: facilityId,
    })

    return response.data.onboardingLink
  }

  static async createPaymentIntent(
    organizationId: string,
    familyId: string,
    eventId: string,
    amount: number,
    type: string,
    upfront = 0,
    payment_type = EventPaymentPaymentType.PAYMENT
  ) {
    const body = {
      OrganizationId: organizationId,
      FamilyId: familyId,
      eventId,
      amount,
      type,
      upfront,
      payment_type,
      date: new Date(),
    }

    const response = await axiosInstance.post(`/payment`, body)

    return response.data
  }

  //TODO: Silvia controlla qui :)
  static async updatePaymentStatus(stripeId: string) {
    function wait(ms = 1000) {
      return new Promise((resolve) => {
        setTimeout(resolve, ms)
      })
    }

    let response = await axiosInstance.get(`/stripe/status`, { params: { stripeId } })
    let step = 500
    let status = response.data.message
    while (step < 5000 && response.data.message === 'PENDING') {
      await wait(step)
      step = step * 2
      response = await axiosInstance.get(`/stripe/status`, { params: { stripeId } })
      status = response.data.message
    }
    return status
  }

  static async getIntentStatus(stripeId: string) {
    const response = await axiosInstance.get(`/stripe/status`, { params: { stripeId } })

    return response.data
  }

  static async getClientSecret(eventIds: string[]) {
    const response = await axiosInstance.post(`/stripe/intent`, { eventIds })

    return response.data
  }

  static async deletePetsMapping(pets: any[]) {
    const response = await axiosInstance.post(`/users/unlink/pets`, { pets: [pets] })

    return response.data
  }

  static async postPetsMapping(pets: any[]) {
    const response = await axiosInstance.post(`/users/link/pets`, { pets: [pets] })

    return response.data
  }

  static async getOnboardingConfiguration(facilityId: string) {
    const response = await axiosInstance.get(`/facilities/configuration`, { params: { facilityId } })

    return response.data
  }

  static async updateFacilityOnboarding(facilityId: string, name: string, email: string, phone: string) {
    const body = { id: facilityId, name, email, phone }

    const response = await axiosInstance.patch(`/facilities/onboarding`, body)
    return response.data
  }

  static async completeOnboarding(facilityId: string) {
    const response = await axiosInstance.get(`/facilities/complete`, { params: { facilityId } })
    return response.data
  }

  static async subscribe(facilityId: string, vat: string, businessName: string, ChargebeeCustomerId: string) {
    const body = {
      ChargebeeCustomerId,
      vat,
      businessName,
      facilityId,
    }

    const response = await axiosInstance.post('/facilities/subscribe', body)

    return response.data
  }
}
