import React, { ChangeEvent, forwardRef, useEffect, useState } from 'react'
import { IonItem } from '@ionic/react'
import { IconCamera } from '../icons'
import { FormError } from './FormError'

type UploadFieldProps = {
  error?: string
  label: string
  editLabel?: string
  placeholder?: string
  className?: string
  description?: string
  defaultValue?: string
  readonly?: boolean
  [key: string]: any
}

export const UploadField = forwardRef<HTMLInputElement, UploadFieldProps>(
  ({ error, defaultValue = '', label, editLabel, className = '', description, readonly = false, ...rest }: UploadFieldProps, ref) => {
    const [preview, setPreview] = useState<{ style: string; src: string }>({
      style: '',
      src: '',
    })

    useEffect(() => {
      setPreview({ style: '', src: defaultValue })
    }, [])

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
      const files = event.target.files
      if (files && files.length) {
        const image = new Image()
        const objectUrl = URL.createObjectURL(files[0])
        image.onload = () => {
          rest.onBlur(event)
          setPreview({
            style: '',
            src: objectUrl,
          })
        }

        image.src = objectUrl
      }
    }

    return (
      <div className={`cursor-pointer block mb-6 ${className}`}>
        {readonly ? (
          <div className="flex-shrink-0 w-20 h-20 mr-6 bg-grey-200 rounded-full flex flex-col items-center justify-center overflow-hidden border border-solid border-grey-200">
            {preview.src ? (
              <div className="w-full h-full bg-no-repeat bg-cover bg-center" style={{ backgroundImage: `url(${preview.src})` }} />
            ) : (
              <IconCamera className="w-10 h-10" />
            )}
          </div>
        ) : (
          <>
            <IonItem color="transparent" className="input-upload relative flex flex-row items-center">
              <input
                type="file"
                ref={ref}
                {...rest}
                onChange={onChange}
                accept="image/*"
                className="cursor-pointer absolute bg-grey-100 inset-0 opacity-0 z-10 appearance-none"
              />
              <div className="flex-shrink-0 w-20 h-20 mr-6 bg-grey-200 rounded-full flex flex-col items-center justify-center overflow-hidden border border-solid border-grey-200">
                {preview.src ? (
                  <div className="w-full h-full bg-no-repeat bg-cover bg-center" style={{ backgroundImage: `url(${preview.src})` }} />
                ) : (
                  <IconCamera className="w-10 h-10" />
                )}
              </div>
              <p className="text-body-small font-bold text-primary-500">{preview.src && editLabel ? editLabel : label}</p>
            </IonItem>
            {description || error ? (
              <p className="relative">
                {description && !error && <span className="text-grey-600 text-body-tiny mt-1">{description}</span>}
                {error && <FormError message={error} />}
              </p>
            ) : null}
          </>
        )}
      </div>
    )
  }
)

UploadField.displayName = 'UploadField'
