import formatDistanceStrict from 'date-fns/formatDistanceStrict'
import groupBy from 'lodash/groupBy'
import map from 'lodash/map'
import sortBy from 'lodash/sortBy'
import { Pet, User } from '..'
import { DateConverter, formatDate, isValidDate } from '../../utils'
import { EventPriority, EventStatus, EventType } from './enums'
import { AllEventMask, ChildEventMask, HealthEvent } from './index'

export type EventsByDate = {
  date: Date
  events: ChildEventMask[]
}

export class Conversation {
  static _invalidEvent: Conversation
  id: string
  parentEvent: AllEventMask
  children: ChildEventMask[]

  constructor(evt: AllEventMask, children: ChildEventMask[] = []) {
    this.id = evt.id || ''
    this.parentEvent = evt
    this.children = children
  }

  get eventsByDate() {
    return sortBy(
      map(
        groupBy(this.children, (evt) => formatDate(evt.payload.date, 'dd/MM/yyyy')),
        (events: any) => ({
          date: events[0].payload.date,
          events,
        })
      ),
      (data) => data.date
    )
  }

  get isValid() {
    return !!this.id
  }

  get isAssigned() {
    return !!this.parentEvent.payload.assigneeId
  }

  get isClosed() {
    return this.parentEvent.payload.status === EventStatus.STATUS_CLOSED
  }

  get User(): User | null {
    return this.parentEvent.User
  }

  get pets(): Pet[] {
    return this.parentEvent.Pets
  }

  static generateInvalid() {
    if (!Conversation._invalidEvent) {
      Conversation._invalidEvent = new Conversation(
        new HealthEvent(
          {},
          {
            payment: {},
            priority: EventPriority.EVENT_PRIORITY_LOW,
            status: EventStatus.STATUS_CLOSED,
            userId: '1',
            familyId: '1',
            facilityId: '1',
            seen: false,
            videomeeting: {},
            // TODO: check if this is correct
          }
        ),
        []
      )
    }
    return Conversation._invalidEvent
  }

  get duration(): string {
    const { payload } = this.parentEvent

    const openDate = DateConverter.fromServer(payload?.openDate || '')
    const closeDate = DateConverter.fromServer(payload?.closeDate || '')

    if (isValidDate(openDate) && !isValidDate(closeDate)) {
      // pending
      return ''
    }

    if (!isValidDate(openDate) || !isValidDate(closeDate)) {
      return ''
    }

    const minutes = parseInt(formatDistanceStrict(closeDate, openDate, { addSuffix: false, unit: 'minute' })) || 0
    const hours = parseInt(formatDistanceStrict(closeDate, openDate, { addSuffix: false, unit: 'hour' })) || 0

    if (hours > 0 && (minutes === 0 || minutes === 60)) {
      return `${hours}h`
    }

    if (hours > 0 && minutes > 0) {
      return `${hours}h ${minutes}min`
    }

    if (minutes > 0) {
      return `${minutes}min`
    }

    return ''
  }

  getCalendarEvent(): ChildEventMask | undefined {
    const event = this.children.find((child) => child.type === EventType.EVENT_TYPE_CALENDAR)
    return event
  }
}
