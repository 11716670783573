import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IconClose } from 'src/components/icons'
import { CheckboxGroupField } from 'src/components/inputs/CheckboxGroupField'
import { FacilityService } from 'src/models/FacilityService'
import { IonButton, IonButtons, IonContent, IonPage } from '@ionic/react'
import { NavHeader } from '../components/Header/NavHeader'

type ModalEventCategoryProps = {
  listOfServicesToBeChoose: any
  onDismiss: () => void
  onSuccess: (value: FacilityService) => void
}
export const ModalEventCategory: FC<ModalEventCategoryProps> = ({ listOfServicesToBeChoose, onDismiss, onSuccess }) => {
  const { t } = useTranslation()

  /*
   * Use and array because CheckboxGroupField accept only an array of fields --> checkbox is for multiple selection
   */

  const elements = listOfServicesToBeChoose.map((service: any) => ({ value: service.id, label: service.name }))

  const [value] = useState([])

  const onFilterChange = (types: string[]) => {
    if (types.length) {
      const service = listOfServicesToBeChoose.find((s: any) => s.id === types[0])

      if (service) onSuccess(service)
    }
  }

  return (
    <IonPage>
      <NavHeader title={t('TITLE_SELECT_REASON')} showBackButton={false} backAction={onDismiss}>
        <IonButtons slot="end">
          <IonButton className="px-3 link" fill="clear" onClick={onDismiss}>
            <IconClose className="w-6 h-6" />
          </IonButton>
        </IonButtons>
      </NavHeader>
      <IonContent>
        <CheckboxGroupField values={elements} defaultValues={value} onChange={onFilterChange} reverse={true} separator={true} />
      </IonContent>
    </IonPage>
  )
}
