import memoize from 'fast-memoize'
import { useCallback } from 'react'
import { Facility, Family, Pet, User } from '..'
import { HealthEvent } from './HealthEvent'
import { EventCategory, EventPriority, EventStatus } from './enums'

const generateEmptyHealthEvent = (facility: Facility, family: Family, user: User, pets: Pet[]) => {
  const emptyEvent = new HealthEvent(
    {
      category: EventCategory.EVENT_CATEGORY_HEALTH_CONSULTATION,
    },
    {
      facilityId: facility.id,
      familyId: family.id,
      payment: {},
      priority: EventPriority.EVENT_PRIORITY_MEDIUM,
      status: EventStatus.STATUS_NEW,
      userId: user.id,
      seen: false,
      videomeeting: {},
      // TODO: check if this is correct
    }
  )
  emptyEvent.enrich({
    Assignee: null,
    Facility: facility,
    Family: family,
    LastMessage: null,
    LastUserMessage: null,
    Pets: pets,
    User: user,
  })

  return emptyEvent
}

export const useEmptyEvent = () => ({
  EmptyHealth: useCallback(
    memoize((facility: Facility, family: Family, user: User, pets: Pet[]) => generateEmptyHealthEvent(facility, family, user, pets)),
    []
  ),
})
