export class FacilityServiceCategory {
  id: string
  name: string
  enabled: boolean

  constructor(data: { [key: string]: any }) {
    this.id = data.id || ''
    this.name = data.name || ''
    this.enabled = data.enabled || false
  }

  json() {
    return {
      ...(this.id ? { id: this.id } : {}),
      name: this.name,
      enabled: this.enabled,
    }
  }
}
