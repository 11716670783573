export const IconMicOn = ({ className }: { className?: string }) => (
  <svg className={`fill-current ${className}`} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 11C16 13.2091 14.2091 15 12 15C9.79086 15 8 13.2091 8 11V6C8 3.79086 9.79086 2 12 2C14.2091 2 16 3.79086 16 6V11ZM18 10C18.5523 10 19 10.4477 19 11C18.9959 14.4766 16.4409 17.4233 13 17.92V20H15.11C15.6015 20 16 20.3985 16 20.89V21.11C16 21.6015 15.6015 22 15.11 22H8.89C8.39847 22 8 21.6015 8 21.11V20.89C8 20.3985 8.39847 20 8.89 20H10.9998V17.92C7.5589 17.4232 5.00399 14.4764 5 10.9998C5.00009 10.4476 5.44777 10 6 10C6.55229 10 7 10.4477 7 11C7 13.7614 9.23858 16 12 16C14.7614 16 17 13.7614 17 11C17 10.4477 17.4477 10 18 10ZM5 10.9998C5 10.9998 5 10.9998 5 10.9998V11C5 10.9999 5 10.9999 5 10.9998Z"
      style={{ fill: 'rgb(255, 255, 255)' }}
    />
  </svg>
)
