import { decode } from 'js-base64'
import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { FormNewPassword } from 'src/components/forms/FormNewPassword'
import { authThunks } from 'src/features/Auth/store/thunks'
import { NewPasswordParameters } from 'src/features/Auth/store/types'
import { useToast } from 'src/hooks/useToast'
import { IonBackButton, IonButtons, IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react'
import { LayoutGuest } from '../layouts/LayoutGuest'

export const PagePasswordNew = (): ReactElement => {
  const { email } = useParams<{ email: string }>()

  const { t } = useTranslation()
  const toast = useToast()
  const history = useHistory()
  const dispatch = useDispatch()

  const onSubmit = async ({ code, password }: NewPasswordParameters) => {
    try {
      await dispatch<any>(authThunks.resetPassword({ email: decode(email), code, password }))
      toast.success(t('NEW_PASSWORD_SUCCESS_MESSAGE'))
      history.push({ pathname: '/login' })
    } catch ({ message }) {
      toast.error(t(message as string))
    }
  }

  return (
    <IonPage>
      <IonHeader className="ion-no-border border-b border-grey-300">
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/" />
          </IonButtons>
          <IonTitle>
            <h1 className="mb-0 py-8 text-h200 text-center">{t('TITLE_PAGE_NEW_PASSWORD')}</h1>
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <LayoutGuest>
          <div className="w-full h-full flex flex-col pt-8">
            <div className="w-full overflow-auto">
              <FormNewPassword onSubmit={onSubmit} />
            </div>
          </div>
        </LayoutGuest>
      </IonContent>
    </IonPage>
  )
}
