import { GlobalStoreState } from 'src/Store'
import { User } from 'src/models'
import { AllEventMask } from 'src/models/events'
import { DailyCall } from '@daily-co/daily-js'

export const getCallObject = ({ call }: GlobalStoreState): DailyCall | undefined => call.callObject

export const getStartTime = ({ call }: GlobalStoreState): Date | undefined => call.startTime

export const getCallee = ({ call }: GlobalStoreState): User | undefined => call.callee

export const getEvent = ({ call }: GlobalStoreState): AllEventMask | undefined => call.event
