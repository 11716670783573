import every from 'lodash/every'
import first from 'lodash/first'
import isEmpty from 'lodash/isEmpty'
import isPlainObject from 'lodash/isPlainObject'
import uniqBy from 'lodash/uniqBy'
import { DateConverter, safeArray, uniqueById } from '../utils'
import { Facility } from './Facility'
import { Family } from './Family'
import { Pet } from './Pet'
import { BaseModel } from './partials/BaseModel'

export enum UserRole {
  PET_OWNER = 'petOwner',
  VETERINARY = 'veterinary',
}

export enum StatusTypes {
  IMPORTED = 'IMPORTED',
  ACTIVE = 'ACTIVE',
  WAITING = 'INVITED',
  DISABLED_BY_PO = 'DISABLED_BY_PO',
  DISABLED_BY_VET = 'DISABLED_BY_VET',
  IGNORED = 'IGNORED',
  REMOVE = 'REMOVED',
}

export enum trialStatusTypes {
  NEW = 'NEW',
  ONBOARDING = 'ONBOARDING',
  IN_PROGRESS = 'IN_PROGRESS',
  EXPIRED = 'EXPIRED',
}

type UserTrial = {
  status: trialStatusTypes
  facilityId?: string
  leftDays?: number
  [key: string]: any
}

export class User extends BaseModel {
  id: string
  username: string
  idCognito?: string
  enabled?: boolean
  status: string
  email?: string
  firstName: string
  lastName: string
  isVet: boolean
  isPetOwner: boolean
  marketing: boolean
  thirdparties: boolean

  /*
   * Generic User fields
   */
  phone: string
  mobile: string
  address: string
  city: string
  country: string
  region: string | null
  taxNumber: string
  postcode: string | null

  /*
   * Extra PetOwner Fields
   */
  FamilyId: string
  facilities: Facility[] = []
  facilitiesToBeCompleted: Facility[] = []
  families: Family[] = []
  pets: Pet[] = []
  vetFacilities: Facility[] = []
  clientFrom: Date | null = null
  balance = 0
  note = ''
  pendingFacilities: any[] = []
  pendingEvents: any[] = []

  /*
   * Extra Veterinary fields
   */
  idRole = 0
  species: string[] = []
  services: string[] = []
  TimetableId = ''
  organizations: any[] = []

  trial: UserTrial

  constructor(data: { [key: string]: any }) {
    super(data)

    this.id = data.id || ''
    this.username = data.email
    this.idCognito = data.idCognito || null
    this.enabled = !!data.enabled
    this.status = data.status || 'active'

    this.email = data.email
    this.firstName = data.firstName || ''
    this.lastName = data.lastName || ''

    this.isVet = !!data.isVet
    this.isPetOwner = (!!data.isPetOwner || !!data.isPetowner) && !this.isVet

    this.marketing = data.marketing || false
    this.thirdparties = data.thirdparties || false

    const addressData = isPlainObject(data.address) ? data.address : data

    /*
     * User fields
     */
    this.phone = !isEmpty(addressData.phone) ? addressData.phone : ''
    this.mobile = !isEmpty(addressData.mobile) ? addressData.mobile : ''
    this.address = !isEmpty(addressData.address) ? addressData.address : ''
    this.city = !isEmpty(addressData.city) ? addressData.city : ''
    this.country = !isEmpty(addressData.country) ? addressData.country : ''
    this.region = !isEmpty(addressData.region) ? addressData.region : ''
    this.taxNumber = !isEmpty(data.taxNumber) ? data.taxNumber : ''
    this.postcode = !isEmpty(addressData.postcode) ? addressData.postcode : ''

    /*
     * Extra PetOwner fields
     */
    this.facilities = safeArray(data.Facilities).map((facility: any) => new Facility(facility))
    this.facilitiesToBeCompleted = safeArray(data.FacilitiesToBeCompleted).map((facility: any) => new Facility(facility))
    this.families = safeArray(data.Families).map((family: any) => new Family(family))
    if (data.FamilyId) {
      this.families = [new Family({ id: data.FamilyId }), ...this.families]
    }
    this.FamilyId = first(this.families)?.id || ''

    this.pets = uniqueById([safeArray(data.PetAccounts).map((petAccount: any) => petAccount.pets), data.pets || []], 'id').map(
      (pet) => new Pet(pet)
    )
    this.vetFacilities = uniqBy(
      safeArray(data.vets).map((vet: any) => new Facility(vet)),
      'id'
    )
    this.clientFrom = data.clientFrom ? DateConverter.fromServer(data.clientFrom) : null
    this.balance = parseInt(data.balance) || 0
    this.note = data.note || ''

    this.pendingFacilities = data.pendingFacilities
    this.pendingEvents = data.pendingEvents

    /*
     * Extra Veterinary fields
     */
    this.idRole = data.idRole || data?.UserFacility?.RoleId || 0
    this.species = data.species || []
    this.services = data.services || []
    this.TimetableId = data.timetable || data.TimetableId || ''
    this.organizations = data.Organizations || []
    this.trial = data.trial || {}
  }

  hasPendingOnboarding(): boolean {
    return this.trial.status === trialStatusTypes.ONBOARDING
  }

  isOnboarding(): boolean {
    return this.trial.status === trialStatusTypes.ONBOARDING || this.trial.status === trialStatusTypes.NEW
  }

  hasTrialExpired(): boolean {
    return this.trial.status === trialStatusTypes.EXPIRED
  }

  isInTrial(): boolean {
    return this.trial.status === trialStatusTypes.IN_PROGRESS
  }

  isActive(): boolean {
    return !!this.enabled && this.status === 'ACTIVE'
  }

  isManageble(): boolean {
    return ['IMPORTED', 'IGNORED', 'INVITED', 'ACTIVE'].includes(this.status)
  }

  isStripeEnabled(organizationId: string): boolean {
    return this.organizations.find((organization) => organization.id === organizationId)?.ExternalAccounts[0]?.enableStripe || false
  }

  get role(): UserRole {
    return this.isVet ? UserRole.VETERINARY : UserRole.PET_OWNER
  }

  get initials(): string {
    return `${(this.firstName || '').charAt(0)}${(this.lastName || '').charAt(0)}`.trim()
  }

  get fullName(): string {
    return [this.firstName, this.lastName].join(' ')
  }

  get safeName(): string {
    return `${this.firstName} ${(this.lastName || '').charAt(0)}.`.trim()
  }

  get isProfileCompleted() {
    return every(
      [this.firstName, this.lastName, this.phone, this.taxNumber, this.address, this.city, this.country],
      (value) => !isEmpty(value)
    )
  }

  getRoleFor(facilityId: string): number {
    return this.facilities.find((f) => f.id === facilityId)?.UserFacility?.RoleId || -1
  }

  get FacilityId() {
    return first(this.facilities)?.id || ''
  }

  getVetData() {
    return {
      roleId: this.idRole,
      idRole: this.idRole,
      firstName: this.firstName,
      lastName: this.lastName,
      services: this.services,
      TimetableId: this.TimetableId,
      species: this.species,
    }
  }
}
