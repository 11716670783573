export const IconPhoneHangUp = ({ className }: { className?: string }) => (
  <svg className={`fill-current ${className}`} viewBox="0 0 29 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.69523 12.1577L5.69523 12.1577C10.7146 7.1345 18.8524 7.12084 23.8885 12.1272L23.8885 12.1272C25.3917 13.6253 25.3958 16.0583 23.8977 17.5615L23.8977 17.5615C23.7465 17.7155 23.5805 17.8543 23.4022 17.9758L23.4022 17.9758C23.236 18.0996 23.0582 18.2067 22.8711 18.2957L22.8711 18.2957C22.6046 18.4243 22.29 18.4023 22.044 18.238L17.686 15.5104C17.446 15.3597 17.2988 15.0975 17.295 14.8141C17.2888 14.6546 17.2888 14.6428 17.6355 13.5376C15.7761 12.8461 13.7302 12.8454 11.8703 13.5354C12.2207 14.6513 12.2207 14.6631 12.2151 14.8226C12.2122 15.106 12.0659 15.3687 11.8264 15.5202L7.47755 18.2625C7.23325 18.4153 6.92741 18.4315 6.66838 18.3052C6.47816 18.2151 6.29615 18.1087 6.1244 17.9871C5.95197 17.8627 5.78976 17.7247 5.63934 17.5744L5.63935 17.5744C4.16574 16.0605 4.1907 13.6409 5.69523 12.1577L5.69523 12.1577Z"
      fill="white"
    />
  </svg>
)
