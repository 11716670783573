import { matchSorter } from 'match-sorter'
import React, { forwardRef, ReactElement, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { ListChildComponentProps } from 'react-window'
import useSelect from 'use-select'
import { IconArrow, IconArrowType, IconClose, IconSearch } from '../icons'

const SelectOption: React.FC<ListChildComponentProps & { highlighted: boolean; selected: boolean }> = ({
  children,
  selected,
  ...rest
}): ReactElement => (
  <div className="ml-4 border-b border-solid border-grey-100 cursor-pointer" {...rest}>
    <div
      className={`h-10 flex flex-col justify-center -ml-4 pl-4 hover:bg-primary-500 hover:text-white hover:font-bold ${
        selected ? 'bg-primary-500 text-white font-bold' : ''
      }`}
    >
      {children}
    </div>
  </div>
)

type CustomSelectProps = {
  large?: boolean
  onChange?: any
  options: any
  value?: string
  multi?: boolean
  searchable?: boolean
  readonly?: boolean
  pageSize?: number
  itemHeight?: number
  itemElement?: any
  className?: string
  ionicStyle?: boolean
  [key: string]: any
}

export const DefaultItemElement = ({
  option,
  large = false,
  readonly = false,
}: {
  option: any
  isRow?: boolean
  large?: boolean
  readonly?: boolean
}) => <div className={`truncate ${large ? 'text-h200' : 'text-body-medium'} ${readonly ? 'text-grey-400' : ''}`}>{option.label}</div>

export const CustomSelect: React.FC<CustomSelectProps> = forwardRef(
  (
    {
      className = '',
      ionicStyle = false,
      large = false,
      value,
      searchable = false,
      options,
      multi = false,
      readonly = false,
      pageSize = 10,
      itemHeight = 41,
      itemElement: ItemElement = DefaultItemElement,
      ...rest
    },
    ref
  ) => {
    const { t } = useTranslation()
    const optionsRef = useRef<any>(null)
    const reactWindowInstanceRef = useRef<any>()

    const scrollToIndex = (index: any) => {
      if (!reactWindowInstanceRef.current) {
        return
      }
      reactWindowInstanceRef.current.scrollToItem(index)
    }

    const { visibleOptions, getInputProps, getOptionProps, selectedOption, removeValue, highlightedOption, isOpen, setOpen } = useSelect({
      multi,
      options,
      value,
      onChange: (data: any) => {
        setOpen(false)
        rest.onChange(data)
      },
      scrollToIndex,
      optionsRef,
      shiftAmount: pageSize,
      filterFn: (options, value) => matchSorter(options, value, { keys: ['label'] }),
    })

    const height = Math.max(Math.min(pageSize, visibleOptions.length), 1) * itemHeight
    const opts = selectedOption as any

    const renderRow = (option: any, index: number): ReactElement | null => {
      if (!option) {
        return null
      }

      return (
        <SelectOption
          {...getOptionProps({
            index,
            option,
            highlighted: highlightedOption?.includes(option),
            selected: selectedOption?.value === option?.value,
          })}
        >
          <ItemElement option={option} isRow={true} />
        </SelectOption>
      )
    }

    const toggleOpen = () => {
      if (!readonly) {
        setOpen(!isOpen)
      }
    }

    const removeElement = (index: number) => {
      removeValue(index)
    }

    //console.log(selectedOption)
    //const selected = options.find((option: any) => option.value === Number(selectedOption?.value))

    return (
      <div className="block">
        <div
          className={`input-element relative ${large ? 'h-12 text-h300' : 'h-12'} ${
            readonly ? `${!ionicStyle && 'border-none'} ml-0` : ''
          } ${className}`}
        >
          <div className="flex h-full justify-between items-center cursor-pointer" onClick={toggleOpen}>
            <ItemElement readonly={readonly} option={selectedOption} large={large} />
            <IconArrow
              type={isOpen ? IconArrowType.TOP : IconArrowType.BOTTOM}
              className={`text-primary-500 w-6 h-6 ${readonly ? 'invisible' : ''}`}
            />
          </div>
          <div ref={optionsRef} className="options-ref absolute left-0 top-12 z-1000 w-full">
            {isOpen ? (
              <div className={`input-element p-0 bg-white ${large ? 'mt-2' : 'mt-0'}`}>
                {searchable ? (
                  <div className="relative">
                    <IconSearch className="absolute top-0 left-0 w-6 h-6 m-3 text-grey-500" />
                    <input
                      className="input-element border-t-0 border-l-0 border-r-0 rounded-b-none py-2 mt-0 pl-12"
                      {...getInputProps()}
                      ref={ref}
                      placeholder={t('SELECT_SEARCH_PLACEHOLDER')}
                    />
                  </div>
                ) : null}

                {!visibleOptions.length ? (
                  <div className="ml-4 h-10 flex flex-col items-center border-b border-solid border-grey-100 py-1">
                    {t('NO_OPTIONS_WERE_FOUND')}
                  </div>
                ) : (
                  <div style={{ height }} className="w-full overflow-scroll">
                    {visibleOptions.map(renderRow)}
                  </div>
                )}
              </div>
            ) : null}
          </div>
        </div>
        {multi ? (
          <div className="mt-2">
            {opts?.map((v: any, index: number) => (
              <div
                className="cursor-pointer bg-grey-200 pl-3 pr-2 py-1 rounded-full mr-1 mb-1 inline-flex items-center"
                key={v.value}
                onClick={() => removeElement(index)}
              >
                <span className="mr-2">{v.label}</span>
                <div className="p-1 bg-grey-500 rounded-full">
                  <IconClose className="w-3 h-3 text-grey-200" />
                </div>
              </div>
            ))}
          </div>
        ) : null}
      </div>
    )
  }
)
