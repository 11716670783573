import React from 'react'
import { I18nextProvider } from 'react-i18next'
import { Redirect, Route } from 'react-router-dom'
import { PrivateRoute } from 'src/components/PrivateRoute'
import { PageMobileAnimation } from 'src/components/animations'
import { useCurrentCall, useCurrentUser } from 'src/hooks/useCurrentValues'
import { ModalProvider } from 'src/hooks/useModal'
import { SocketProvider } from 'src/hooks/useSocket'
import i18n from 'src/services/i18n'
import { DailyProvider } from '@daily-co/daily-react'
import { IonRouterOutlet } from '@ionic/react'
import { IonReactRouter } from '@ionic/react-router'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { CallOverlay } from './components/overlays/CallOverlay'
import './custom.css'
import { LayoutAppPetOwner } from './layouts/LayoutAppPetOwner'
import { LayoutAppVeterinary } from './layouts/LayoutAppVeterinary'
import { PageEmailConfirmation } from './pages/PageEmailConfirmation'
import { PageLogin } from './pages/PageLogin'
import { PagePasswordNew } from './pages/PagePasswordNew'
import { PagePasswordReset } from './pages/PagePasswordReset'
import { PageRegister } from './pages/PageRegister'

const App: React.FC = () => {
  const { callObject } = useCurrentCall()
  const user = useCurrentUser()

  const layoutApp = user.isPetOwner ? LayoutAppPetOwner : LayoutAppVeterinary

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 0,
      },
    },
  })

  return (
    <I18nextProvider i18n={i18n}>
      <SocketProvider>
        <QueryClientProvider client={queryClient}>
          <DailyProvider callObject={callObject}>
            <ModalProvider>
              <CallOverlay>
                <IonReactRouter>
                  <IonRouterOutlet basePath="/" mode="md" animation={PageMobileAnimation}>
                    <Route path="/login" exact={true} render={() => <PageLogin />} />
                    <Route path="/password/reset" exact={true} render={() => <PagePasswordReset />} />
                    <Route path="/password/new/:email" exact={true} render={() => <PagePasswordNew />} />
                    <Route path="/sign-up" exact={true} render={() => <PageRegister />} />
                    <Route path="/email-confirmation/:email" exact={true} render={() => <PageEmailConfirmation />} />
                    <PrivateRoute strict={true} path="/app" component={layoutApp} />
                    <Redirect exact={true} from="/" to="/app" />
                  </IonRouterOutlet>
                </IonReactRouter>
              </CallOverlay>
            </ModalProvider>
          </DailyProvider>{' '}
        </QueryClientProvider>
      </SocketProvider>
    </I18nextProvider>
  )
}

export default App
