import { GlobalStoreState } from 'src/Store'
import { User } from 'src/models'
import { AllEventMask } from 'src/models/events'
import { DailyCall } from '@daily-co/daily-js'
import { Dispatch } from '@reduxjs/toolkit'
import { callReducers } from './slice'

export const callThunks = {
  setCallObject:
    (callObject: DailyCall | undefined) =>
    async (dispatch: Dispatch): Promise<void> => {
      await dispatch(callReducers.setCallObject(callObject))

      return
    },
  setStartTime:
    (start: Date | undefined) =>
    async (dispatch: Dispatch): Promise<void> => {
      await dispatch(callReducers.setStartTime(start))

      return
    },
  setCallee:
    (callee: User | undefined) =>
    async (dispatch: Dispatch): Promise<void> => {
      await dispatch(callReducers.setCallee(callee))

      return
    },
  setEvent:
    (event: AllEventMask | undefined) =>
    async (dispatch: Dispatch): Promise<void> => {
      await dispatch(callReducers.setEvent(event))
      return
    },
  closeCall:
    () =>
    async (dispatch: Dispatch, getState: () => GlobalStoreState): Promise<void> => {
      const { callObject } = getState().call

      callObject?.leave()
      dispatch(callReducers.setCallObject(undefined))
      dispatch(callReducers.setStartTime(undefined))
      dispatch(callReducers.setCallee(undefined))

      return
    },
}
