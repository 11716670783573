import xor from 'lodash/xor'
import React, { useState } from 'react'
import { IonCheckbox, IonItem } from '@ionic/react'
import { GenericProps } from '../../helpers/props'

export type CheckboxGroupFieldParams = GenericProps & {
  label?: string
  values: { label: string; value: string; description?: string }[]
  onChange?: (values: string[]) => void
  defaultValues?: string[]
  reverse?: boolean
  separator?: boolean
}

export const CheckboxGroupField: React.FC<CheckboxGroupFieldParams> = ({
  className = '',
  defaultValues = [],
  label = '',
  values,
  onChange = () => ({}),
  reverse = false,
  separator = false,
}) => {
  const [state, setState] = useState<string[]>(defaultValues)

  const toggle = (value: string) => {
    const newState = xor(state, [value])
    setState(newState)
    onChange(newState)
  }

  return (
    <div className={`${className} -mr-8 mt-6`}>
      {label && <div className="text-body-large text-black mb-4">{label}</div>}
      {values.map((item) => (
        <div key={item.value}>
          <IonItem className={`item-input-checkbox flex mt-2 mr-8 px-0 ${reverse ? '' : 'items-center'}`} lines="none">
            <IonCheckbox
              slot={reverse ? 'end' : 'start'}
              value={item.value}
              checked={state.includes(item.value)}
              onClick={() => toggle(item.value)}
              className="flex-shrink-0 mr-2 my-0"
            />
            <div className="ml-2">
              <div>{item.label}</div>
              {item.description && <div className="mt-4 text-grey-500">{item.description}</div>}
            </div>
          </IonItem>
          {separator && <div className="h-px bg-grey-200 my-6" />}
        </div>
      ))}
    </div>
  )
}
