import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { axiosInstance } from 'src/api/axios'
import { parseEventsToConversations } from 'src/api/eventApiUtilities'
import { Avatar } from 'src/components/Avatar'
import { ConversationsSectionContent } from 'src/components/events/ConversationsSectionContent'
import { EventCardMode } from 'src/components/events/EventCard'
import { IconFilters } from 'src/components/icons'
import { familyThunks } from 'src/features/Family/store/thunks'
import { useCurrentFacility, useCurrentFamily, useCurrentUser, useFamilyConversations } from 'src/hooks/useCurrentValues'
import { useModal } from 'src/hooks/useModal'
import { AllEventMask, DiaryEvent } from 'src/models/events'
import { APP_VET_MODE } from 'src/utils'
import { RefresherEventDetail } from '@ionic/core'
import { IonBadge, IonButton, IonPage, IonRouterLink, IonContent, IonRefresher, IonRefresherContent } from '@ionic/react'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { FilterState, FilterStateDefaultValues } from '../../veterinary/forms/FormDiaryFilters'
import { NavHeader } from '../components/Header/NavHeader'
import { AppRouterHelper } from '../services/AppRouterHelper'
import { ModalDiaryFilters } from './ModalDiaryFilters'
import { PageAddDiaryEvent } from './PageAddDiaryEvent'
import { PageEditDiaryEvents } from './PageEditDiaryEvent'

export const PageDiary = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const { showModal, hideModal } = useModal()
  const dispatch = useDispatch()
  const currentUser = useCurrentUser()
  //   const { conversations } = useFamilyConversations()
  const [conversationsLenght, setConversationsLenght] = useState(0)
  const { family } = useCurrentFamily()
  const { facility } = useCurrentFacility()
  const queryClient = useQueryClient()

  const [filters, setFilters] = useState<FilterState>(FilterStateDefaultValues)
  const filtersCount =
    filters.pets.length + filters.types.length + filters.statuses.length + filters.payments.length + filters.message.length > 0 ? 1 : 0

  useEffect(() => {
    reload()
  }, [filters])

  const onFilterClick = () => {
    showModal(
      <ModalDiaryFilters
        defaultValues={filters}
        onDismiss={() => {
          setTimeout(reload, 50)
          hideModal()
        }}
        onSuccess={(newFilters) => {
          setFilters(newFilters)
          hideModal()
        }}
      />
    )
  }

  const onAddDiaryEvent = () => {
    showModal(
      <PageAddDiaryEvent
        onDismiss={() => {
          setTimeout(reload, 50)
          hideModal()
        }}
      />
    )
  }

  const onDiaryCardClick = (event: DiaryEvent) => {
    showModal(
      <PageEditDiaryEvents
        event={event}
        onDismiss={() => {
          setTimeout(reload, 50)
          hideModal()
        }}
      />
    )
  }

  const onCardClick = (event: AllEventMask) => {
    history.push(`/app/chat/${event.id || ''}`)
  }

  const reload = async () => {
    // await dispatch(familyThunks.getConversationsByFamily(0, filters))
  }

  const doRefresh = async (event: CustomEvent<RefresherEventDetail>) => {
    // queryClient.invalidateQueries({
    //   queryKey: ['conversations'],
    // })

    await dispatch(familyThunks.getConversationsByFamily(0, filters))

    setTimeout(() => {
      event.detail.complete()
    }, 500)
  }

  return (
    <IonPage className="">
      <NavHeader
        title={t('TITLE_EVENTS', { count: conversationsLenght })}
        showBackButton={false}
        leftActions={
          <div className="relative ml-3">
            <IonButton className="px-3 text-primary-500" fill="clear" onClick={onFilterClick}>
              <IconFilters className="w-6 h-6" />
            </IonButton>
            {filtersCount > 0 ? (
              <IonBadge color="danger" className="absolute top-1 right-0 rounded-full ml-2 px-2">
                {filtersCount}
              </IonBadge>
            ) : null}
          </div>
        }
      >
        <IonRouterLink routerLink={AppRouterHelper.ROUTES.PROFILE}>
          <Avatar image={currentUser.avatar} title={currentUser.initials} className="cursor-pointer h-12 w-12 mr-3" />
        </IonRouterLink>
      </NavHeader>
      <IonContent className="ion-content-diary">
        {/* <IonRefresher slot="fixed" onIonRefresh={doRefresh} pullFactor={0.5} pullMin={100} pullMax={200}>
          <IonRefresherContent />
        </IonRefresher> */}
        <ConversationsSectionContent
          onConversationsLenght={setConversationsLenght}
          onCardClick={onCardClick}
          onDiaryCardClick={onDiaryCardClick}
          mode={EventCardMode.CARD_MODE_PET_OWNER}
          filters={filters}
          actions={
            <div className="fixed w-full flex flex-col items-center bottom-0 left-0 pt-4 pb-8">
              <IonButton size="small" fill="outline" className="bg-white" onClick={onAddDiaryEvent}>
                {t('ADD_EVENT')}
              </IonButton>
            </div>
          }
        />
      </IonContent>
    </IonPage>
  )
}
