import React from 'react'
import { IonIcon } from '@ionic/react'
import { SquaredCard } from './SquaredCard'

type CTACardProps = {
  icon?: React.ReactElement
  ionicIcon?: string
  label: string
  [key: string]: unknown
}

export const CTACard: React.FC<CTACardProps> = ({ ionicIcon, icon: Icon, label, ...props }) => (
  <SquaredCard className="bg-grey-100" contentClass="flex flex-col justify-center items-center text-primary-500" {...props}>
    {ionicIcon ? <IonIcon icon={ionicIcon} className="text-h600" /> : Icon}
    <span className="link mt-4">{label}</span>
  </SquaredCard>
)
