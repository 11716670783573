import { format } from 'date-fns'
import { enUS } from 'date-fns/locale'
import parse from 'html-react-parser'
import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { FacilitiesAPI } from 'src/api'
import { EventsAPI } from 'src/api/EventsAPI'
import { ModalEventSuggestions } from 'src/apps/petOwner/pages/ModalEventSuggestions'
import { useCurrentUser } from 'src/hooks/useCurrentValues'
import { useModal } from 'src/hooks/useModal'
import { useToast } from 'src/hooks/useToast'
import { CalendarEvent, ChildEventMask, Conversation } from 'src/models/events'
import { EventSuggestion } from 'src/models/events/EventSuggestion'
import { PaymentEventPayload } from 'src/models/events/payload/PaymentEventPayload'
import { DateConverter, formatCurrency } from 'src/utils'
import { IonButton, getPlatforms } from '@ionic/react'
import { useQueryClient } from '@tanstack/react-query'
import { CalendarEventStatus, EventPaymentPaymentType, EventPaymentStatus, EventStatus } from '../../models/events/enums'
import { getWindowDimensions } from '../DateTimeRangePicker'
import { BackgroundLogo, LogoThemesEnum } from '../icons/Logos'
import { ChatMessageWrapper } from './ChatMessageWrapper'

type CalendarMessageProps = {
  conversation?: Conversation
  event: ChildEventMask
  className?: string
  doRefresh?: () => void
}

// const formatEventDates = (event: any) => {
//   console.log(event)
//   const date = formatDate(event.start, 'EEEE, dd MMMM yyyy')
//   const messageTime = formatDate(event.dateUpdated, 'HH:mm')
//   const startTime = formatDate(event.start, 'HH:mm')
//   const endTime = formatDate(event.end, 'HH:mm')

//   return { date, messageTime, startTime, endTime }
// }

const formatEventDates = (event: any) => {
  if (event.start) {
    const date = format(event.start, 'EEEE MMMM dd, yyyy', { locale: enUS })
    const messageTime = event?.dateUpdated && format(event.dateUpdated, 'hh:mm a', { locale: enUS })
    const startTime = format(event.start, 'h:mm a', { locale: enUS })
    const endTime = format(event.end, 'h:mm a', { locale: enUS })
    return { date, messageTime, startTime, endTime }
  } else {
    const date = ''
    const messageTime = ''
    const startTime = ''
    const endTime = ''
    return { date, messageTime, startTime, endTime }
  }
}

export const CalendarMessage: React.FC<CalendarMessageProps> = ({ conversation, event, className, doRefresh }) => {
  const toast = useToast()
  const history = useHistory()
  const { t } = useTranslation()
  const currentUser = useCurrentUser()
  const { showModal, hideModal } = useModal()
  const [newEvent, setNewEvent] = useState<EventSuggestion>()
  const queryClient = useQueryClient()

  const isNewEvent = conversation?.parentEvent.payload.status === EventStatus.STATUS_NEW
  const isClosedEvent = conversation?.parentEvent.payload.status === EventStatus.STATUS_CLOSED

  const payment: PaymentEventPayload = conversation?.parentEvent.payload.payment as PaymentEventPayload

  const appointmentServices = useMemo(
    () => conversation?.parentEvent?.payload?.services?.map((service: { name: any }) => service.name).join(','),
    [conversation]
  )

  const calendarEvent: CalendarEvent = event as CalendarEvent

  const isValidEvent = calendarEvent.isValid()
  const isToBeSelected = calendarEvent.isMissingStartingDate()
  const isToBeConfirmedEvent = calendarEvent.isToBeConfirmed()
  const isToBeBookedEvent = calendarEvent.isToBeBooked()

  const { date, messageTime, startTime, endTime } = formatEventDates(calendarEvent.payload.event)

  const showCalendarMessage =
    calendarEvent.payload.status != CalendarEventStatus.CALENDAR_EVENT_STATUS_TO_BE_CONFIRMED || (!currentUser.isVet && !isToBeSelected)

  const updatePayment = useCallback(async () => {
    if (payment.status != EventPaymentStatus.PAYMENT_STATUS_TO_BE_PAID || payment?.type === null) {
      return
    }

    const eventId = conversation?.parentEvent.id || ''
    const organizationId = conversation?.parentEvent.Facility?.OrganizationId || ''
    const familyId = currentUser.isVet ? conversation?.parentEvent.Family?.id || '' : currentUser.FamilyId

    try {
      await FacilitiesAPI.createPaymentIntent(
        organizationId,
        familyId,
        eventId,
        payment?.amount || 0,
        payment?.type,
        0, // REVIEW: where to get the upfront value?
        EventPaymentPaymentType.UPFRONT
      )
    } catch (error) {
      console.error(error)
    }
  }, [conversation])

  const onSelectedFromModal = async (suggestion: EventSuggestion) => {
    setNewEvent(suggestion)

    calendarEvent.payload.event.start = suggestion.from
    calendarEvent.payload.event.end = suggestion.to
    calendarEvent.payload.valid = true
    calendarEvent.payload.invalidatedAt = null
    calendarEvent.payload.invalidatedBy = null
    calendarEvent.payload.updatedByUserId = currentUser.id
    calendarEvent.payload.userId = currentUser.id
    calendarEvent.payload.event.resource = currentUser.isVet ? currentUser.id : suggestion.ids

    if (payment.isAnUpfrontPayment()) {
      if (isToBeConfirmedEvent) {
        calendarEvent.payload.status = CalendarEventStatus.CALENDAR_EVENT_STATUS_TO_BE_CONFIRMED
      }

      if (isToBeBookedEvent) {
        calendarEvent.payload.status = CalendarEventStatus.CALENDAR_EVENT_STATUS_TO_BE_BOOKED
      }

      if (calendarEvent.isReserved()) {
        calendarEvent.payload.status = CalendarEventStatus.CALENDAR_EVENT_STATUS_RESERVATION
      }
    } else {
      calendarEvent.payload.status = CalendarEventStatus.CALENDAR_EVENT_STATUS_UPDATED
    }

    await EventsAPI.updateEvent(calendarEvent, true)

    //Se i servizi prevedono un anticipo qui va creata la richiesta di pagamento
    await updatePayment()

    doRefresh && doRefresh()
  }

  const onCancelEvent = async () => {
    try {
      const parentEvent = conversation?.parentEvent
      if (!parentEvent) return null

      parentEvent.payload.status = EventStatus.STATUS_CLOSED
      parentEvent.payload.closeDate = DateConverter.now()

      await EventsAPI.updateEvent(parentEvent, true)

      calendarEvent.payload.valid = false
      calendarEvent.payload.invalidatedAt = DateConverter.now()
      calendarEvent.payload.invalidatedBy = currentUser.id
      calendarEvent.payload.updatedByUserId = currentUser.id
      calendarEvent.payload.status = CalendarEventStatus.CALENDAR_EVENT_STATUS_CANCELED

      await EventsAPI.updateEvent(calendarEvent, true)

      toast.success(t('CALENDAR_EVENT_DELETED_SUCCESSFULLY'))
      doRefresh && doRefresh()

      history.goBack()
    } catch (error) {
      console.error(error)
      toast.error(t(error as string))
    }
  }

  const chooseSlot = () => {
    showModal(
      <ModalEventSuggestions
        facilityId={event.Facility?.id || event?.payload.facilityId || ''}
        onDismiss={hideModal}
        onSuccess={onSelectedFromModal}
        duration={30}
        familyId={event.Family?.id || event?.payload.familyId || ''}
        petId={conversation?.pets[0].id || ''}
        servicesIds={conversation?.parentEvent?.payload?.services?.map((service: any) => service.id)}
        onCancelEvent={onCancelEvent}
        queryClient={queryClient}
      />
    )
  }

  let content = null
  let actions = null

  if (isClosedEvent) {
    // Se la prestazione è chiusa
    content = <div>{t('LABEL_APPOINTMENT_ALREADY_DONE', { date, time: startTime })}</div>
  } else if (!isValidEvent) {
    // se la prestazione non è valida ?!?
    content = <div>{t('LABEL_APPOINTMENT_DECLINED', { date, time: startTime })}</div>
  } else if (isToBeSelected && currentUser.isVet) {
    // se la prestazione non ha date e l'utente corrente è un veterinario, allora deve sempre poter cambiare date
    content = (
      <div>
        {t('CHAT_CALENDAR_APPOINTMENT_DETAILS_VET', {
          ownerName: conversation?.parentEvent.Family?.userFamily?.firstName,
        })}
      </div>
    )

    actions = isNewEvent && (
      <IonButton color="primary" fill="outline" size="small" className="button-xsmall mt-3" onClick={chooseSlot}>
        {t('LABEL_PROPOSE_TIME')}
      </IonButton>
    )
  } else if (isToBeSelected && !currentUser.isVet) {
    // se la prestazione non ha date e l'utente corrente NON è un veterinario
    // deve mostrare le informazioni qui: https://miro.com/app/board/o9J_lHd4OYY=/
    content = (
      <div>
        {parse(
          t('CHAT_CALENDAR_APPOINTMENT_DETAILS', {
            name: currentUser.firstName,
            vet: conversation?.parentEvent.Assignee?.safeName,
            duration: conversation?.parentEvent.payload.duration,
            services: appointmentServices,
            amount: formatCurrency(conversation?.parentEvent.payload.payment.amount),
            date: t('CHAT_CALENDAR_CHOOSE_DATE'),
            time: '',
          })
        )}
      </div>
    )
    actions = isNewEvent && (
      <IonButton color="primary" fill="outline" size="small" className="button-xsmall mt-3" onClick={chooseSlot}>
        {t('LABEL_SELECT_DATE_AND_HOURS')}
      </IonButton>
    )
  } else if (
    !currentUser.isVet &&
    !isToBeSelected &&
    calendarEvent.payload.status == CalendarEventStatus.CALENDAR_EVENT_STATUS_TO_BE_BOOKED
  ) {
    content = (
      <>
        <div>
          {parse(
            t('CHAT_CALENDAR_APPOINTMENT_DETAILS', {
              name: currentUser.firstName,
              vet: conversation?.parentEvent.Assignee?.safeName,
              duration: conversation?.parentEvent.payload.duration,
              services: appointmentServices,
              amount: formatCurrency(conversation?.parentEvent.payload.payment.amount),
              date: date,
              time: `${startTime} - ${endTime}`,
            })
          )}
        </div>
      </>
    )
    actions = currentUser.isPetOwner && (
      <IonButton color="primary" fill="outline" size="small" className="button-xsmall mt-3" onClick={chooseSlot}>
        {t('LABEL_CHANGE_DATE_AND_HOURS')}
      </IonButton>
    )
  } else if (!isToBeSelected && isToBeConfirmedEvent) {
    const message = currentUser.isVet ? 'CHAT_APPOINTMENT_INVITE_FOR_VET' : 'CHAT_APPOINTMENT_INVITE_RECAP'

    const dateRecap = format(calendarEvent.payload.event.start, 'EEEE dd MMMM yyyy', { locale: enUS })

    const poName = conversation?.parentEvent.Family?.userFamily?.firstName
    const vetName = conversation?.parentEvent.Assignee?.firstName

    const petName = conversation?.parentEvent.Pets[0].name

    content = (
      <div>
        {parse(
          t(message, {
            poName,
            vetName,
            date: dateRecap,
            startTime,
            endTime,
            services: appointmentServices,
            petName,
          })
        )}
      </div>
    )

    actions = !currentUser.isVet && calendarEvent.isEditableFromPetOwner() && isNewEvent && (
      <IonButton color="primary" fill="outline" size="small" className="button-xsmall mt-3" onClick={chooseSlot}>
        {t('LABEL_CHANGE_DATE_AND_HOURS')}
      </IonButton>
    )
  } else if (!isToBeSelected && !currentUser.isVet && calendarEvent.isEditableFromPetOwner()) {
    // se la prestazione ha LE date e l'utente corrente NON è un veterinario, allora deve poter cambiare date solo se l'evento non è entro le 48H

    let title = 'LABEL_APPOINTMENT_CONFIRMED'
    let message = 'CHAT_CALENDAR_APPOINTMENT_DATE'

    if (calendarEvent.isNotConfirmed()) {
      title = 'LABEL_APPOINTMENT_NOT_CONFIRMED'
      message = 'CHAT_CALENDAR_APPOINTMENT_NOT_CONFIRMED_FOR_PO'
    }

    content = (
      <>
        <div className="font-bold">{t(title)}</div>
        <div>{t(message, { date, time: startTime })}</div>
      </>
    )
    actions = isNewEvent && (
      <IonButton color="primary" fill="outline" size="small" className="button-xsmall mt-3" onClick={chooseSlot}>
        {t('LABEL_CHANGE_DATE_AND_HOURS')}
      </IonButton>
    )
  } else if (currentUser.isVet && calendarEvent.isConfirmed()) {
    content = (
      <>
        <div className="font-bold">{t('LABEL_APPOINTMENT_CONFIRMED')}</div>
        <div>{t('CHAT_CALENDAR_APPOINTMENT_DATE', { date, time: startTime })}</div>
      </>
    )
  } else if (calendarEvent.isNotConfirmed()) {
    const poName = conversation?.parentEvent.Family?.userFamily?.firstName
    const message = currentUser.isVet ? 'CHAT_CALENDAR_APPOINTMENT_NOT_CONFIRMED_FOR_VET' : 'CHAT_CALENDAR_APPOINTMENT_NOT_CONFIRMED_FOR_PO'

    content = (
      <>
        <div className="font-bold">{t('LABEL_APPOINTMENT_NOT_CONFIRMED')}</div>
        <div>{t(message, { poName })}</div>
      </>
    )
  } else {
    // il caso rimanente è il caso confermato
    content = (
      <>
        <div className="font-bold">{t('LABEL_APPOINTMENT_CONFIRMED')}</div>
        <div>{t('CHAT_CALENDAR_APPOINTMENT_DATE', { date, time: startTime })}</div>
      </>
    )
    actions = isNewEvent && !newEvent && currentUser.isPetOwner && calendarEvent.isEditableFromPetOwner() && (
      <IonButton color="primary" fill="outline" size="small" className="button-xsmall mt-3" onClick={chooseSlot}>
        {t('LABEL_CHANGE_DATE_AND_HOURS')}
      </IonButton>
    )
  }

  return showCalendarMessage ? (
    <ChatMessageWrapper className={className} event={event}>
      <div className="h-12 w-12 shadow-light-40 rounded-full flex items-center justify-center bg-white flex-shrink-0 p-2">
        <BackgroundLogo theme={LogoThemesEnum.DARK} />
      </div>
      <div className="flex-grow">
        <div className="text-grey-600 text-body-tiny mb-1">{messageTime}</div>
        <div
          className={`whitespace-pre-line shadow-light-40 rounded rounded-tl-none p-4 ${
            isValidEvent ? 'bg-primary-200' : 'bg-grey-100 opacity-50'
          }`}
        >
          {content}
        </div>
        {actions}
      </div>
    </ChatMessageWrapper>
  ) : (
    <></>
  )
}
