import { BotEvent } from './BotEvent'
import { CalendarEvent } from './CalendarEvent'
import { ChatEvent } from './ChatEvent'
import { Conversation } from './Conversation'
import { DiaryEvent } from './DiaryEvent'
import { HealthEvent } from './HealthEvent'
import { EventCategory, EventType } from './enums'

type AllEventMask = HealthEvent | CalendarEvent | ChatEvent | DiaryEvent | BotEvent
type ChildEventMask = CalendarEvent | ChatEvent | DiaryEvent | BotEvent
type ParentEventMask = HealthEvent

export { Conversation, HealthEvent, CalendarEvent, DiaryEvent, ChatEvent, BotEvent }
export type { AllEventMask, ChildEventMask, ParentEventMask }
export const isHealthEvent = (event: AllEventMask) => event.type === EventType.EVENT_TYPE_HEALTH
export const isVideoconsultationEvent = (event: AllEventMask) => event.category === EventCategory.EVENT_CATEGORY_HEALTH_VIDEOCONSULTATION
