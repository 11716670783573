import React, { forwardRef, ReactChild } from 'react'
import { TextFieldTypes } from '@ionic/core'
import { IonInput } from '@ionic/react'
import { Tooltip } from '../Tooltip'
import { IconInfoFill } from '../icons'
import { FormError } from './FormError'

export type InputFieldProps = {
  error?: string
  label?: string
  type?: TextFieldTypes
  placeholder?: string
  tooltip?: string
  className?: string
  description?: string
  rightIcon?: ReactChild | null
  readonly?: boolean
  labelClassName?: string
  onIonChange?: (event: CustomEvent) => void
  [key: string]: any
}

export const InputField: React.FC<InputFieldProps> = forwardRef<HTMLIonInputElement, InputFieldProps>(
  (
    {
      error,
      label,
      type = 'text',
      placeholder,
      className = '',
      tooltip,
      rightIcon = null,
      description,
      readonly = false,
      labelClassName = '',
      onIonChange = () => null,
      ...rest
    }: InputFieldProps,
    ref
  ) => (
    <div className={`input-field ${className}`}>
      <div>
        {label && (
          <div className={`text-body-large flex items-center ${labelClassName}`}>
            <span>{label}</span>
            {tooltip && (
              <Tooltip text={tooltip} className="ml-2">
                <IconInfoFill />
              </Tooltip>
            )}
          </div>
        )}
        <IonInput
          onIonChange={onIonChange}
          placeholder={placeholder}
          type={type}
          ref={ref}
          className={error ? `has-error` : ''}
          {...rest}
          readonly={readonly}
        />
        {rightIcon}
      </div>
      {description || error ? (
        <p className="relative">
          {description && !error && <span className="text-grey-600 text-body-tiny mt-1">{description}</span>}
          {error && <FormError message={error} />}
        </p>
      ) : null}
    </div>
  )
)

InputField.displayName = 'InputField'
