import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { EventCategory } from 'src/models/events/enums'
import { IonButton } from '@ionic/react'
import { BotActionMessage } from '../BotActionMessage'
import { BotMessageProps } from './constants'

type BotChooseEventCategoryProps = BotMessageProps & {
  userFirstName?: string
  selectedCategory?: string
  facilityId: string
  facilities: any
  onSelect?: (category: EventCategory, services: any, action: string) => void
}

export const BotChooseEventCategory: React.FC<BotChooseEventCategoryProps> = ({
  event,
  userFirstName,
  selectedCategory = '',
  facilityId,
  facilities,
  onSelect,
  onSuccess,
}) => {
  const { t } = useTranslation()
  const userName = userFirstName || event.User?.firstName || ''
  const [category, setCategory] = useState<string>(selectedCategory)

  const facility = facilities.find((element: any) => element.id === facilityId)
  const keys = Object.keys(facility.MainServices)

  const onSetCategory = (id: any) => {
    setCategory(id)
    let ss = undefined
    if (facility.MainServices[id].services.length === 1) {
      ss = facility.MainServices[id].services[0]
    }

    onSelect && onSelect(id, ss ? [ss] : [], facility.MainServices[id].action)

    if (onSuccess) {
      event.category = id
      if (ss) {
        event.payload.services = [ss]
      }

      onSuccess(event)
    }
  }

  return (
    <BotActionMessage message={t('BOT_CONVERSATION_OPEN_MESSAGE', { name: userName })}>
      <div className="flex flex-col space-y-3">
        {keys.map((k) => (
          <IonButton
            key={k}
            color="primary"
            fill={category === facility.MainServices[k].category ? 'solid' : 'outline'}
            size="small"
            className="button-xsmall"
            onClick={() => onSetCategory(facility.MainServices[k].category)}
          >
            {t(facility.MainServices[k].category)}
          </IonButton>
        ))}
      </div>
    </BotActionMessage>
  )
}
