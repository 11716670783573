// import format from 'date-fns/format'
// import getDay from 'date-fns/getDay'
// import itLocale from 'date-fns/locale/it'
// import parse from 'date-fns/parse'
// import startOfWeek from 'date-fns/startOfWeek'
import { format, parse, startOfWeek, getDay } from 'date-fns'
import enUs from 'date-fns/locale/en-US'
import moment from 'moment'
import { dateFnsLocalizer, momentLocalizer } from 'react-big-calendar'

const locales = {
  'en-US': enUs,
}

export const calendarLocalizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
})

moment.locale('en-US', {
  week: {
    dow: 0,
    doy: 4,
  },
})

export const enLocalizer = momentLocalizer(moment)
