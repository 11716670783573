import { isEqual } from 'lodash'
import { Facility, Family, Pet, User } from 'src/models'
import { FilterState, FilterStateDefaultValues } from '../apps/veterinary/forms/FormDiaryFilters'
import { FamilyByIdPayload } from '../features/Family/payload/FamilyByIdPayload'
import { Conversation, AllEventMask } from '../models/events'
import { EventType } from '../models/events/enums'
import { APP_VET_MODE, safeArray } from '../utils'
import { DateConverter } from '../utils'
import { EventsAPI } from './EventsAPI'
import { axiosInstance, getCacheConfig } from './axios'
//import { DefaultSearchOptions, parseEventsToConversations, SearchOptionsParams } from './eventApiUtilities'
import { parseEventsToConversations } from './eventApiUtilities'

export const getVetConversationQueryParams = (family: Family, facility: Facility, filters?: FilterState): any => ({
  facilityId: facility.id,
  type: {
    type: 'OR',
    values: [EventType.EVENT_TYPE_HEALTH, EventType.EVENT_TYPE_DIARY],
  },
  ...(filters?.period && filters.period.from != '' && filters.period.to != ''
    ? {
        date_created: {
          from: filters?.period.from,
          to: filters?.period.to,
        },
      }
    : {}),
  payload: {
    ...(family.id ? { familyId: family.id } : {}),
    //facilityId: {
    //  facilityId: {
    //   type: 'OR',
    //    values: [facility.id, null],
    //  },
    //},
    ...(filters?.statuses?.length ? { status: { type: 'OR', values: filters.statuses } } : {}),
    ...(filters?.pets?.length ? { pets: { type: 'OR', values: filters.pets.map((id) => [id]) } } : {}),
    ...(filters?.payments?.length ? { payment: { status: { type: 'OR', values: filters.payments } } } : {}),
  },
})

export const getConversationQueryParams = (family: Family, facility: Facility, filters?: FilterState): any => ({
  type: {
    type: 'OR',
    values: [EventType.EVENT_TYPE_HEALTH, EventType.EVENT_TYPE_DIARY],
  },
  ...(filters?.period && filters.period.from != '' && filters.period.to != ''
    ? {
        date_created: {
          from: filters?.period.from,
          to: filters?.period.to,
        },
      }
    : {}),
  payload: {
    ...(family.id ? { familyId: family.id } : {}),
    ...(facility.id ? { facilityId: facility.id } : {}),
    ...(filters?.statuses?.length ? { status: { type: 'OR', values: filters.statuses } } : {}),
    ...(filters?.pets?.length ? { pets: { type: 'OR', values: filters.pets.map((id) => [id]) } } : {}),
    ...(filters?.payments?.length ? { payment: { status: { type: 'OR', values: filters.payments } } } : {}),
  },
})

const createPayload = (filters?: FilterState, lastUpdatedDate?: Date): any => {
  const types = (filters?.types && [...filters.types]) || []
  filters?.types && filters.types.includes('OTHER') && types.push(EventType.EVENT_TYPE_DIARY)

  return {
    ...(types.length
      ? {
          type: {
            type: 'OR',
            values: types,
          },
        }
      : {}),
    ...(filters?.period && filters.period.from != '' && filters.period.to != ''
      ? {
          date_created: {
            from: filters?.period.from,
            to: filters?.period.to,
          },
        }
      : {}),
    ...(lastUpdatedDate ? { date_updated: { from: DateConverter.toServer(lastUpdatedDate) } } : {}),
    payload: {
      ...(filters?.message?.length ? { message: filters.message } : {}),
      ...(filters?.statuses?.length ? { status: { type: 'OR', values: filters.statuses } } : {}),
      ...(filters?.pets?.length ? { pets: { type: 'OR', values: filters.pets.map((id) => [id]) } } : {}),
      ...(filters?.payments?.length ? { payment: { status: { type: 'OR', values: filters.payments } } } : {}),
    },
  }
}

export class FamilyAPI {
  static async getById(familyId: string, facilityId: string, skipCache = true): Promise<FamilyByIdPayload> {
    return axiosInstance
      .get(`/families?FamilyId=${familyId}&FacilityId=${facilityId || ''}`, getCacheConfig(skipCache))
      .then((response: any) => ({
        family: new Family(response.data),
        members: safeArray(response.data.Users).map((user: any) => new User(user)),
        pets: safeArray(response.data.Pets).map((pet: any) => new Pet(pet)),
        facilities: safeArray(response.data.Facilities).map((facility: any) => new Facility(facility)),
      }))
  }

  static async getPendingConversations(
    family: Family,
    facility: Facility,
    filters?: FilterState,
    lastUpdatedDate?: Date
  ): Promise<Conversation[]> {
    let events: AllEventMask[] = []

    try {
      let url = `/${family.id}/diary`

      if ((filters && !isEqual(filters, FilterStateDefaultValues)) || lastUpdatedDate) {
        url = url.concat('/search')
        if (APP_VET_MODE) url = url.concat(`?filterByFacilityId=${facility.id}`)

        events = (await axiosInstance.post(url, createPayload(filters, lastUpdatedDate))).data
      } else {
        if (APP_VET_MODE) url = url.concat(`?filterByFacilityId=${facility.id}`)
        events = (await axiosInstance.get(url)).data
      }
    } catch (error) {
      console.error(error)
    }

    return parseEventsToConversations(events)
  }

  static async getConversationTreeById(
    parentEventId: string,
    family: Family
    // facility: Facility
    // filters: FilterState
    //opts: SearchOptionsParams = DefaultSearchOptions
  ): Promise<Conversation> {
    return await EventsAPI.getSingleConversation(family.id, parentEventId)
  }

  static async setClientStatus(userId: string, facilityId: string, status: string) {
    return await axiosInstance.patch(`/client/status?userId=${userId}&facilityId=${facilityId}&status=${status}`)
  }
}
