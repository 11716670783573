import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { IconMicOff, IconMicOn, IconPhoneHangUp, IconVideoOff, IconVideoOn } from 'src/components/icons'
import { useDaily, useLocalParticipant, useVideoTrack, useAudioTrack } from '@daily-co/daily-react'

export const Tray = ({ onClose, className }: { onClose: () => void; className: string }) => {
  const { t } = useTranslation()
  const callObject = useDaily()

  const localParticipant = useLocalParticipant()
  const localVideo = useVideoTrack(localParticipant?.session_id || '')
  const localAudio = useAudioTrack(localParticipant?.session_id || '')

  const mutedVideo = localVideo.isOff
  const mutedAudio = localAudio.isOff

  const toggleVideo = useCallback(() => {
    callObject && callObject.setLocalVideo(mutedVideo)
  }, [callObject, mutedVideo])

  const toggleAudio = useCallback(() => {
    callObject && callObject.setLocalAudio(mutedAudio)
  }, [callObject, mutedAudio])

  return (
    <div className={`${className}`}>
      <div className="flex flex-col items-center space-y-1">
        <div className="w-12 h-12 bg-primary-500 rounded-full flex justify-center items-center cursor-pointer" onClick={toggleVideo}>
          {mutedVideo ? <IconVideoOff className="w-6 h-6" /> : <IconVideoOn className="w-6 h-6 text-white" />}
        </div>
        <span className="text-body-small">{t('LABEL_VIDEO')}</span>
      </div>
      <div className="mx-8 flex flex-col items-center space-y-1">
        <div className="w-12 h-12 bg-primary-500 rounded-full flex justify-center items-center cursor-pointer" onClick={toggleAudio}>
          {mutedAudio ? <IconMicOff className="w-6 h-6" /> : <IconMicOn className="w-6 h-6" />}
        </div>
        <span className="text-body-small">{t('LABEL_AUDIO')}</span>
      </div>
      <div className="flex flex-col items-center space-y-1">
        <div className="w-12 h-12 bg-error-500 rounded-full flex justify-center items-center cursor-pointer" onClick={onClose}>
          <IconPhoneHangUp className="w-8 h-8" />
        </div>
        <span className="text-body-small">{t('LABEL_CLOSE')}</span>
      </div>
    </div>
  )
}
