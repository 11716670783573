import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Redirect, Route, useHistory } from 'react-router-dom'
import { PageMobileAnimation } from 'src/components/animations'
import { IconHome, IconNotification, IconPlusBoxed, IconCalendar } from 'src/components/icons'
import { familyThunks } from 'src/features/Family/store/thunks'
import { useCurrentCall, useCurrentFamily, useCurrentUser } from 'src/hooks/useCurrentValues'
import { useSocket } from 'src/hooks/useSocket'
import { IonBadge, IonLabel, IonLoading, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs } from '@ionic/react'
import { ModalAccountMapping } from '../pages/ModalAccountMapping'
import { PageAddPerformance } from '../pages/PageAddPerformance'
import { PageChat } from '../pages/PageChat'
import { PageDiary } from '../pages/PageDiary'
import { PageDisclaimer } from '../pages/PageDisclaimer'
import { PageEditPet } from '../pages/PageEditPet'
import { PageEditProfile } from '../pages/PageEditProfile'
import { PageHome } from '../pages/PageHome'
import { PageNotifications } from '../pages/PageNotifications'
import { PagePerformances } from '../pages/PagePerformances'
import { PageProfile } from '../pages/PageProfile'
import { PageProfileChangePassword } from '../pages/PageProfileChangePassword'
import { AppRouterHelper } from '../services/AppRouterHelper'

export const LayoutAppPetOwner: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const currentUser = useCurrentUser()
  const { isLoading, family } = useCurrentFamily()
  const { callObject } = useCurrentCall()
  const { setQuery } = useSocket()

  const onCall = !!callObject

  const isFamilyLoaded = family.id || !isLoading

  const { pathname } = history.location
  const showTabs =
    pathname.startsWith(AppRouterHelper.ROUTES.HOME) ||
    pathname === AppRouterHelper.ROUTES.PERFORMANCES ||
    pathname === AppRouterHelper.ROUTES.NOTIFICATIONS ||
    pathname.startsWith(AppRouterHelper.ROUTES.DIARY)

  useEffect(() => {
    setQuery({ userId: currentUser.id, familyId: undefined })
  }, [])

  useEffect(() => {
    !currentUser.isVet && dispatch(familyThunks.setFamily(currentUser.FamilyId))
  }, [])

  useEffect(() => {
    currentUser.pendingFacilities?.length > 0 && history.replace(AppRouterHelper.ROUTES.MAP)
  }, [])

  return (
    <>
      <IonLoading isOpen={!isFamilyLoaded} />
      {isFamilyLoaded ? (
        <IonTabs className="">
          <IonRouterOutlet className={`${onCall && 'mt-4'}`} basePath="/app" mode="md" animation={PageMobileAnimation}>
            <Route path={AppRouterHelper.ROUTES.HOME} exact={true} render={() => <PageHome />} />
            <Route path={AppRouterHelper.ROUTES.PERFORMANCES} exact={true} render={() => <PagePerformances />} />
            <Route path={AppRouterHelper.ROUTES.CHAT} exact sensitive strict component={PageChat} />
            <Route path={AppRouterHelper.ROUTES.DIARY} exact={true} render={() => <PageDiary />} />
            <Route path={AppRouterHelper.ROUTES.NOTIFICATIONS} exact={true} render={() => <PageNotifications />} />
            <Route path={AppRouterHelper.ROUTES.PROFILE} exact={true} render={() => <PageProfile />} />
            <Route path={AppRouterHelper.ROUTES.EDIT_PROFILE} exact={true} render={() => <PageEditProfile />} />
            <Route path={AppRouterHelper.ROUTES.EDIT_PET} exact={true} render={() => <PageEditPet />} />
            <Route path={AppRouterHelper.ROUTES.CHANGE_PASSWORD} exact={true} render={() => <PageProfileChangePassword />} />
            <Route path={AppRouterHelper.ROUTES.ADD_PERFORMANCE} exact={true} render={() => <PageAddPerformance />} />
            <Route path={AppRouterHelper.ROUTES.MAP} exact={true} render={() => <ModalAccountMapping />} />
            <Route path={AppRouterHelper.ROUTES.WRONG_APP} exact={true} render={() => <PageDisclaimer />} />
            <Redirect exact={true} from={AppRouterHelper.ROUTES.ROOT} to={AppRouterHelper.ROUTES.HOME} />
          </IonRouterOutlet>

          <IonTabBar
            slot="bottom"
            className={`transform ease-in-out duration-200 border-none py-1 ${showTabs ? '' : 'translate-y-full hidden'}`}
          >
            <IonTabButton href={AppRouterHelper.ROUTES.HOME} tab="home">
              <IconHome className="w-6 h-6" />
              <IonLabel>{t('PAGE_HOME')}</IonLabel>
            </IonTabButton>
            <IonTabButton href={AppRouterHelper.ROUTES.PERFORMANCES} tab="performance">
              <IconCalendar className="w-6 h-6" />
              <IonLabel>{t('PAGE_PERFORMANCE')}</IonLabel>
            </IonTabButton>
            <IonTabButton href={AppRouterHelper.ROUTES.DIARY} tab="diary">
              <IconPlusBoxed className="w-6 h-6" />
              <IonLabel>{t('PAGE_DIARY')}</IonLabel>
            </IonTabButton>
            <IonTabButton href={AppRouterHelper.ROUTES.NOTIFICATIONS} tab="notification" className="hidden">
              <div className="relative">
                <IconNotification className="w-6 h-6" />
                <IonBadge
                  className="hidden -mr-1 -mt-1 h-3 w-3 p-2 flex justify-center items-center text-body-tiny rounded-full absolute top-0 right-0"
                  color="primary"
                >
                  1
                </IonBadge>
              </div>
              <IonLabel>{t('PAGE_NOTIFICATION')}</IonLabel>
            </IonTabButton>
          </IonTabBar>
        </IonTabs>
      ) : null}
    </>
  )
}
