import React from 'react'

type AvatarPropsType = {
  className?: string
  classNameTitle?: string
  image?: string
  title?: string | null
}

export const AvatarComponent: React.FC<AvatarPropsType> = ({ className, classNameTitle = '', title, image }) => (
  <div
    className={`avatar border-white border-2 shadow-light-40 rounded-full flex items-center justify-center overflow-hidden ${className}`}
  >
    <div className="h-full w-full bg-primary-500 flex justify-center items-center">
      {image && <img className="object-cover h-full" src={image} />}
      {title && !image && <span className={`text-white ${classNameTitle}`}>{title.toUpperCase()}</span>}
    </div>
  </div>
)

export const Avatar = React.memo(AvatarComponent)
