import { format } from 'date-fns'
import React, { forwardRef, useState } from 'react'
import 'react-clock/dist/Clock.css'
import TimePicker from 'react-time-picker'
// import 'react-time-picker/dist/TimePicker.css'
import 'src/theme/timepicker.css'
import { IonLabel } from '@ionic/react'
import { DateConverter, formatDate } from '../utils'
import { FieldWrapper, FieldWrapperProps } from './forms/FieldWrapper'

const createDate = (date: string, fallback = ''): Date => {
  const d = DateConverter.from(`2021-01-01 ${date}`)

  if (isNaN(d.getTime()) || date.length < 4) {
    return DateConverter.from(`2021-01-01 ${fallback}`)
  }

  return d
}

const timeToString = (d: Date) => formatDate(d, 'HH:mm')

export type TimeFieldProps = FieldWrapperProps & {
  min?: string
  max?: string
  value?: string | Date
  onChange?: (value: string) => void
  label?: string
  [key: string]: any
  hasError?: boolean
}

export const TimeField: React.FC<TimeFieldProps> = forwardRef<HTMLInputElement, TimeFieldProps>(
  (
    { min = '', max = '', value = undefined, className = '', label = '', onChange = () => ({}), description, error, hasError, ...rest },
    ref
  ) => {
    const tmp = createDate(value instanceof Date ? format(value, 'h:mm') : value || '00:00')
    const minValue = createDate(min, '00:00')
    const maxValue = createDate(max, '23:59')

    // const [currentValue, setCurrentValue] = useState<Date>(tmp < minValue ? minValue : tmp > maxValue ? maxValue : tmp)
    const [currentValue, setCurrentValue] = useState<Date>(tmp < minValue ? minValue : tmp > maxValue ? maxValue : tmp)

    const onChangeValue = (evt: any) => {
      const newCurrentDate = createDate(evt.target.value)
      setCurrentValue(newCurrentDate)
      onChange(evt.target.value)
    }

    function onChangeTime(value: any) {
      const newCurrentDate = createDate(value)
      setCurrentValue(newCurrentDate)
      onChange(value)
    }

    return (
      // NOTE: This is the original EU timepicker
      //   <FieldWrapper className={className} description={description} error={error}>
      //     {label ? <IonLabel position="stacked">{label}</IonLabel> : null}
      //     <TimePicker onChange={onChangee} value={currentValue} className="h-[32px] py-3" locale="en-US" />

      //     {/* <input
      //         ref={ref}
      //         type="time"
      //         value={timeToString(currentValue)}
      //        onChange={onChangeValue}
      //         className={`input-element ${hasError ? `has-error` : ''} text-h100`}
      //         {...rest}
      //       /> */}
      //   </FieldWrapper>
      <div className="flex flex-col mt-4">
        {label ? <IonLabel position="stacked">{label}</IonLabel> : null}
        <TimePicker
          onChange={onChangeTime}
          value={currentValue}
          className={`input-element ${hasError ? `has-error` : ''} text-h100`}
          locale="en-US"
          disableClock={true}
          clearIcon={null}
        />
      </div>
    )
  }
)

TimeField.displayName = 'TimeField'
