export const IconMicOff = ({ className }: { className?: string }) => (
  <svg className={`fill-current ${className}`} viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.6096 11.0043C13.836 10.55 13.956 10.0501 13.9604 9.54243V5.3656C13.9398 3.52029 12.4271 2.04109 10.5818 2.06174C9.0064 2.07936 7.65736 3.195 7.34432 4.73908L13.6096 11.0043ZM16.4636 9.54229C16.4636 9.08093 16.0896 8.70692 15.6283 8.70692C15.1669 8.70692 14.7929 9.08093 14.7929 9.54229C14.7905 10.2721 14.5914 10.9878 14.2165 11.614L15.4445 12.8838C16.1168 11.8998 16.4723 10.7339 16.4636 9.54229ZM10.7509 12.8838H10.6173C8.77182 12.8838 7.2758 11.3878 7.2758 9.54236V9.40034L10.7509 12.8838ZM17.8935 16.4676L4.5276 3.10177C4.20003 2.77421 3.66894 2.77421 3.34138 3.10177C3.01381 3.42934 3.01381 3.96043 3.34138 4.28799L16.7072 17.6538C17.0321 17.9814 17.561 17.9836 17.8886 17.6587L17.8934 17.6538C18.221 17.329 18.2232 16.8 17.8983 16.4725L17.8935 16.4676ZM11.4526 17.0606H13.1234C13.5847 17.0606 13.9587 17.4346 13.9587 17.8959C13.9587 18.3573 13.5847 18.7313 13.1234 18.7313H8.11116C7.6498 18.7313 7.2758 18.3573 7.2758 17.8959C7.2758 17.4346 7.6498 17.0606 8.11116 17.0606H9.78173V15.323C6.90732 14.908 4.77302 12.4463 4.7697 9.54213C4.76978 9.08084 5.14376 8.70692 5.60506 8.70692C6.06642 8.70692 6.44043 9.08093 6.44043 9.54229C6.44043 11.8491 8.31046 13.7191 10.6173 13.7191C10.9125 13.7197 11.2069 13.6861 11.4944 13.6189L12.831 14.9555C12.3883 15.1338 11.9254 15.2572 11.4526 15.323V17.0606ZM4.7697 9.54213C4.7697 9.54218 4.7697 9.54224 4.7697 9.54229L4.7697 9.54213Z"
      fill="white"
    />
  </svg>
)
