import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { FacilitiesAPI } from 'src/api'
import { Avatar } from 'src/components/Avatar'
import { useLoader } from 'src/hooks/useLoader'
import { useToast } from 'src/hooks/useToast'
import { Facility } from 'src/models'
import { formatCurrency } from 'src/utils'
import { IonContent, IonPage } from '@ionic/react'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe, Stripe } from '@stripe/stripe-js'
import { NavHeader } from '../components/Header/NavHeader'
import { ModalCheckout } from './ModalCheckout'

type CheckoutWrapperProps = {
  clientSecret: any
  accountId: string
  intentId: string
  sequenceNumber?: string[]
  facility?: Facility
  amount: string
  onDismiss: () => void
}

export const CheckoutWrapper: React.FC<CheckoutWrapperProps> = ({
  clientSecret,
  accountId,
  intentId,
  sequenceNumber,
  facility,
  amount,
  onDismiss,
}) => {
  const [stripeObject, setStripeObject] = useState<Stripe | null>(null)
  const { t } = useTranslation()
  const toast = useToast()
  const [showLoading, hideLoading] = useLoader()

  const options = {
    clientSecret,
    appearance: {},
  }

  useEffect(() => {
    const fetchStripeObject = async () => {
      if (accountId && process.env.REACT_APP_STRIPE_PK) {
        const res = await loadStripe(process.env.REACT_APP_STRIPE_PK, {
          stripeAccount: accountId,
        })
        setStripeObject(res)
      }
    }
    fetchStripeObject()
  }, [accountId])

  const complete = async () => {
    try {
      showLoading()
      if (intentId) {
        const response = await FacilitiesAPI.updatePaymentStatus(intentId)

        if (response === 'PAID') {
          toast.success(t('MESSAGE_PAYMENT_PAID'))
        } else {
          toast.error(t('MESSAGE_PAYMENT_FAILED'))
        }
      }
    } catch (error) {
      console.error(error)
    } finally {
      onDismiss()
      hideLoading()
    }
  }

  return (
    <Elements stripe={stripeObject} options={options}>
      <IonPage>
        <NavHeader title={t('TITLE_PAYMENT')} showBackButton={true} backAction={onDismiss} />
        <IonContent>
          <div className="my-4 h-full flex flex-col ">
            <div className="w-full h-64 flex flex-col justify-center items-center">
              <Avatar title={facility?.initials} image={facility?.avatar} classNameTitle="text-h400" className="w-24 h-24" />
              <span className="font-bold text-h200">{facility?.name}</span>
              {sequenceNumber && (
                <span className="text-grey-600">
                  {t('LABEL_PERFORMANCE_NUMBER')} {sequenceNumber?.map((seq: string) => `#${seq}`).join(', ')}
                </span>
              )}
              <span>{formatCurrency(Number(amount) / 100)}</span>
            </div>
            <ModalCheckout onComplete={complete} />
          </div>
        </IonContent>
      </IonPage>
    </Elements>
  )
}
