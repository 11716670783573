import { format } from 'date-fns'
import { enUS } from 'date-fns/locale'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { FacilitiesAPI } from 'src/api'
import { EventsAPI } from 'src/api/EventsAPI'
import { Avatar } from 'src/components/Avatar'
import { Tag, TagVariant } from 'src/components/Tag'
import { BotAction } from 'src/components/chat/Bot/constants'
import { BotRenderer } from 'src/components/chat/BotRenderer'
import { ChatRenderer } from 'src/components/chat/ChatRenderer'
import { useConversation } from 'src/hooks/useConversation'
import { useCurrentFamily, useCurrentValues } from 'src/hooks/useCurrentValues'
import { useModal } from 'src/hooks/useModal'
import { useSocket } from 'src/hooks/useSocket'
import { Pet } from 'src/models'
import { ChatEvent } from 'src/models/events/ChatEvent'
import { Conversation } from 'src/models/events/Conversation'
import {
  EventCategory,
  EventNotificationAction,
  EventPaymentPaymentType,
  EventPaymentStatus,
  EventPaymentType,
} from 'src/models/events/enums'
import { IonButton, IonContent, IonFooter, IonLoading, IonPage } from '@ionic/react'
import { useQueryClient } from '@tanstack/react-query'
import { IconPlus } from '../../../components/icons'
import { TextareaFooter } from '../../../components/inputs/TextareaFooter'
import { NavHeader } from '../components/Header/NavHeader'
import { CheckoutWrapper } from './CheckoutWrapper'
import { ModalPendingPayments } from './ModalPendingPayments'

export const PageChat: React.FC<{ match: any }> = ({ match }) => {
  const { eventId } = match.params
  const { t } = useTranslation()
  const { showModal, hideModal } = useModal()
  const { socket } = useSocket()
  const queryClient = useQueryClient()
  const [firstClick, setFirstClick] = useState(true)

  const history = useHistory()
  const onBack = () => history.goBack()

  const { currentUser, currentFacility } = useCurrentValues()
  const { family } = useCurrentFamily()

  const messagesEndRef = useRef<any>(null)
  const scrollToBottom = () => messagesEndRef && messagesEndRef?.current && messagesEndRef?.current?.scrollIntoView({ behavior: 'smooth' })
  const { conversation, reloadConversation } = useConversation(eventId, scrollToBottom)
  const { parentEvent, children, eventsByDate } = conversation || Conversation.generateInvalid()

  const [botAction, setBotAction] = useState<BotAction>()

  const addAttachment = () => setBotAction(BotAction.ADD_ATTACHMENT)

  const onBotCompleted = async () => {
    await reloadConversation()
    setBotAction(undefined)
  }

  const sendMessage = async (text: string) => {
    if (!text.length) {
      return
    }

    const message = new ChatEvent(
      {
        parent_id: parentEvent?.id as string,
      },
      {
        userId: currentUser.id,
        familyId: family.id,
        facilityId: currentFacility.id,
        message: text,
      }
    )

    await EventsAPI.createMessage(message)
    await reloadConversation()

    queryClient.invalidateQueries({
      queryKey: ['requests'],
    })
  }

  const checkout = async () => {
    if (!parentEvent.Facility) {
      return
    }

    const facilityNotifications =
      currentUser.pendingEvents?.filter((event: any) => event.payload.facility.id === parentEvent.Facility?.id) || []

    const paymentNotifications = facilityNotifications.filter((event: any) => event.action == EventNotificationAction.EVENT_TYPE_PAYMENT)
    if (paymentNotifications.length > 1) {
      showModal(
        <ModalPendingPayments
          facility={parentEvent.Facility}
          fromEvent={false}
          onDismiss={async () => {
            hideModal()
            await reloadConversation()
          }}
        />
      )
    } else {
      if (parentEvent.id) {
        const result = await FacilitiesAPI.getClientSecret([parentEvent.id])

        showModal(
          <CheckoutWrapper
            clientSecret={result.clientSecret}
            accountId={result.stripeAccountId}
            intentId={result.stripePaymentId}
            amount={result.amount}
            sequenceNumber={[`${parentEvent.seq}`]}
            event={parentEvent}
            facility={parentEvent.Facility}
            onDismiss={async () => {
              hideModal()
              await reloadConversation()
            }}
          />
        )
      }
    }
  }

  useEffect(() => {
    if (socket != null)
      socket.on('NOTIFICATIONS', ({}) => {
        reloadConversation()
      })
  }, [socket])

  useEffect(() => {
    scrollToBottom()
  }, [children, botAction])

  useEffect(() => {
    setTimeout(scrollToBottom, 300)
  }, [match])

  useEffect(() => {
    setTimeout(scrollToBottom, 300)
  }, [conversation])

  return (
    <IonPage>
      <NavHeader
        className="z-50"
        title={parentEvent.Facility?.name || ''}
        backAction={onBack}
        extra={
          <div className="w-full py-4 px-6 flex-grow flex justify-between items-center z-100 shadow-light-60">
            <Tag variant={TagVariant.TAG_OUTLINE_NEUTRAL}>#{parentEvent.seq}</Tag>
            <div className="text-center">
              <div className="text-black text-body-small">{format(parentEvent?.dateCreated || '', 'MMMM dd, yyyy', { locale: enUS })}</div>
              {parentEvent.Assignee ? (
                <div className="text-grey-500 text-body-small">{t('LABEL_MANAGED_BY', { name: parentEvent.Assignee?.safeName })}</div>
              ) : null}
            </div>
            <div className="flex flex-row">
              {parentEvent.Assignee ? (
                <Avatar title={parentEvent.Assignee?.initials} image={parentEvent.Assignee?.avatar} className="w-8 h-8 z-10 -mr-2" />
              ) : null}
              {parentEvent.Pets.map((pet: Pet) => (
                <Avatar key={pet.id} title={pet.initials} image={pet.avatar} className="w-8 h-8" />
              ))}
            </div>
          </div>
        }
      />
      <IonContent className="ion-bg-grey-100">
        {conversation.id !== eventId ? (
          <IonLoading isOpen={true} spinner="lines" backdropDismiss={false} />
        ) : (
          <>
            <section className="min-h-full flex flex-grow -mx-12">
              <div className="w-full pt-14 px-10 bg-grey-200 z-0 flex flex-col justify-end pb-12">
                <ChatRenderer conversation={conversation} events={eventsByDate} vetMode={false} doRefresh={reloadConversation} />
                <BotRenderer action={botAction} event={parentEvent} onSuccess={onBotCompleted} />
                <div ref={messagesEndRef} />
              </div>
            </section>
          </>
        )}
      </IonContent>

      <IonFooter>
        {!conversation.isClosed && conversation.parentEvent.category === EventCategory.EVENT_CATEGORY_HEALTH_CONSULTATION ? (
          <div className="flex px-4 py-2 items-center">
            <IonButton fill="clear" className="flex-shrink-0 px-3" onClick={addAttachment}>
              <IconPlus className="w-6 h-6 text-primary-500" />
            </IonButton>

            <TextareaFooter onSend={sendMessage} className="flex-grow" />
          </div>
        ) : (
          conversation.parentEvent.payload.payment.type == EventPaymentType.PAYMENT_TYPE_DIGITAL &&
          conversation.parentEvent.payload.payment.status == EventPaymentStatus.PAYMENT_STATUS_PENDING && (
            <div className="flex px-4 py-2 items-center justify-center bg-grey-200">
              <IonButton className="w-full" fill="solid" onClick={checkout}>
                {conversation.parentEvent.payload.payment.payment_type === EventPaymentPaymentType.UPFRONT
                  ? t('ACTION_PAY_AND_CONFIRM_APPOINTMENT')
                  : t('ACTION_GO_TO_PAYMENT')}
              </IonButton>
            </div>
          )
        )}
      </IonFooter>
    </IonPage>
  )
}
