import { format } from 'date-fns'
import { enUS } from 'date-fns/locale'
import parse from 'html-react-parser'
import { uniqueId } from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { User } from 'src/models'
import { AllEventMask, BotEvent, Conversation } from 'src/models/events'
import { EventType, EventCategory } from 'src/models/events/enums'
// import { formatDate } from 'src/utils'
import { Avatar } from '../../Avatar'
import { getActionText } from '../../chat/BotMessage'

export const EventCardFooter: React.FC<{ event: AllEventMask }> = ({ event }) => {
  const { t } = useTranslation()

  let message = event.LastMessage?.payload?.message || ''
  const isBotEvent = event.type === EventType.EVENT_TYPE_BOT

  const conversation = new Conversation(event, [])
  let user = null

  const dummyEvent = new BotEvent(
    { _id: uniqueId() },
    {
      eventAction: '',
      facilityId: '',
      userId: '',
    }
  )

  const lastMessage = event.LastMessage || dummyEvent
  const actionText = getActionText(lastMessage, conversation)

  if (event.type === EventType.EVENT_TYPE_DIARY) {
    return null
  }

  if (event.LastUserMessage) {
    user = new User(event.LastUserMessage)
  }

  // const showAvatar =
  //   (mode === EventCardMode.CARD_MODE_VETERINARY && !event.LastUserMessage?.isVet) ||
  //   (mode === EventCardMode.CARD_MODE_PET_OWNER && !!event.LastUserMessage?.isVet)

  if (event.LastMessage?.type === EventType.EVENT_TYPE_BOT) {
    message = actionText
  }

  const getFooterDateMessage = (): string => {
    if (event.category === EventCategory.EVENT_CATEGORY_HEALTH_VISIT) {
      return t('LABEL_CREATED_ON') + ' ' + format(event.dateCreated, 'MMM dd yyyy h:mm a', { locale: enUS })
    } else {
      if (event.LastMessage) {
        return t('LABEL_UPDATED_ON') + ' ' + format(event.LastMessage?.payload?.date, 'MMM dd yyyy h:mm a', { locale: enUS })
      } else {
        return t('LABEL_CREATED_ON') + ' ' + format(event.dateCreated, 'MMM dd yyyy h:mm a', { locale: enUS })
      }
    }
  }

  return (
    <div className="bg-grey-100 px-4 pt-2 pb-1">
      <p className="mt-2 text-grey-600 truncate">{parse(message)}</p>

      {
        <div className="flex items-center justify-end space-x-2">
          <div className="text-body-tiny text-grey-600">{getFooterDateMessage()}</div>
          {!isBotEvent && event.LastUserMessage ? (
            <Avatar image={event.LastUserMessage?.avatar} title={user && user.initials} className="w-6 h-6 text-body-tiny" />
          ) : null}
        </div>
      }
    </div>
  )
}
