import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { IonApp } from '@ionic/react'
import { store } from './Store'
import './app-global-config'
import App from './apps/petOwner/App'
import Wrapper from './wrapper'

ReactDOM.render(
  <Provider store={store}>
    <IonApp className="safe-top safe-left safe-right safe-bottom">
      <Wrapper>
        <App />
      </Wrapper>
    </IonApp>
  </Provider>,
  document.getElementById('root')
)
