import { FC } from 'react'

export enum TagVariant {
  TAG_FILL_TERTIARY,
  TAG_FILL_SUCCESS,
  TAG_FILL_WARNING,
  TAG_FILL_DANGER,
  TAG_FILL_NEUTRAL,
  TAG_FILL_WHITE,
  TAG_FILL_PRIMARY,
  TAG_OUTLINE_NEUTRAL,
  TAG_CLEAR,
  TAG_DANGER,
  TAG_SUCCESS,
}

type TagProps = {
  className?: string
  variant?: TagVariant
}

const variantToCss = (variant: TagVariant) => {
  switch (variant) {
    case TagVariant.TAG_FILL_PRIMARY:
      return 'bg-primary-500 text-white'
    case TagVariant.TAG_FILL_NEUTRAL:
      return 'bg-grey-100 border-grey-400 text-grey-600'
    case TagVariant.TAG_FILL_WHITE:
      return 'bg-white border-grey-400 text-grey-600'
    case TagVariant.TAG_FILL_WARNING:
      return 'bg-warning-100 border-warning-400 text-warning-600 '
    case TagVariant.TAG_FILL_DANGER:
      return 'bg-error-100 border-error-400 text-error-600'
    case TagVariant.TAG_FILL_SUCCESS:
      return 'text-success-700 bg-success-100'
    case TagVariant.TAG_FILL_TERTIARY:
      return 'bg-tertiary-500 text-white'
    case TagVariant.TAG_OUTLINE_NEUTRAL:
      return 'text-grey-600 border-grey-300'
    case TagVariant.TAG_DANGER:
      return 'bg-error-100 text-error-600'
    case TagVariant.TAG_SUCCESS:
      return 'text-success-700 bg-success-100 border-success-100'
    default:
  }

  return 'text-grey-600 border-transparent'
}

export const Tag: FC<TagProps> = ({ className = '', variant = TagVariant.TAG_OUTLINE_NEUTRAL, children }) => (
  <div
    className={`component-tag inline-block text-body-tiny border-solid border px-2 flex items-center rounded-md ${variantToCss(
      variant
    )} ${className}`}
  >
    {children}
  </div>
)
