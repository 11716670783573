import { ReactChild, ReactElement } from 'react'
import { IonContent } from '@ionic/react'
import { IconArrowBackward, IconClose } from './icons'

export enum DrawerSize {
  SMALL = 'drawer-small',
  MEDIUM = 'drawer-medium',
  LARGE = 'drawer-large',
}

export type DrawerContentProps = {
  id?: string | undefined
  title?: string
  showClose?: boolean
  showBack?: boolean
  onDismiss?: () => void
  onBack?: () => void
  children: ReactChild
  header?: ReactElement | null
  actions?: ReactElement | null
  headerClassName?: string
  contentClassName?: string
}

export const DrawerContent = ({
  id = '',
  title,
  onBack,
  onDismiss,
  showBack = false,
  showClose = true,
  actions = null,
  header = null,
  headerClassName = '',
  contentClassName = 'pb-12 py-6',
  children,
}: DrawerContentProps) => (
  <IonContent id={id}>
    <div className="flex flex-col h-full w-full">
      <header className={`flex flex-row justify-between items-center py-2 pl-12 pr-6 ${headerClassName}`}>
        {header || showBack || title ? (
          <div className="flex items-center flex-grow">
            {showBack ? (
              <div className="cursor-pointer p-4 -ml-4" onClick={onBack}>
                <IconArrowBackward className="text-primary-500 w-8 h-8" />
              </div>
            ) : null}
            {header ? header : <p className="my-auto text-h500 font-normal text-black truncate">{title}</p>}
          </div>
        ) : null}
        <div className="flex flex-row items-center">
          {actions}
          {showClose ? (
            <div className="cursor-pointer p-4" onClick={onDismiss}>
              <IconClose className="text-primary-500 w-7 h-7" />
            </div>
          ) : null}
        </div>
      </header>
      <div className={`px-12 ${contentClassName}`}>{children}</div>
    </div>
  </IonContent>
)
