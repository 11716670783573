import { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { Avatar } from 'src/components/Avatar'
import { Tag, TagVariant } from 'src/components/Tag'
import { veterinaryReducers } from 'src/features/Veterinary/slice'
import { Pet } from 'src/models'
import { AllEventMask } from 'src/models/events'
import { EventStatus } from 'src/models/events/enums'
import { RequestsFilters } from 'src/models/filters/RequestsFilters'
import { formatDate } from 'src/utils'
import { IonButton } from '@ionic/react'
import { AppRouterHelper } from '../../services/AppRouterHelper'
import { NavHeader } from '../Header/NavHeader'

export const ChatHeader: FC<{ parentEvent: AllEventMask; onTerminate: () => void }> = ({ parentEvent, onTerminate }) => {
  const history = useHistory()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const onBack = useCallback(() => {
    dispatch(veterinaryReducers.setSelectedRequest(undefined))
    history.goBack()
  }, [])

  const goToClientRequests = async () => {
    const filters = new RequestsFilters({ facilities: [], statuses: [], customer: parentEvent.payload.familyId })
    await dispatch(veterinaryReducers.setRequestsFilters(filters))
    history.replace(AppRouterHelper.VETERINARY_ROUTES.REQUESTS)
  }

  const getExtra = () => {
    switch (parentEvent.payload.status) {
      case EventStatus.STATUS_NEW:
      case EventStatus.STATUS_CLOSED:
        return (
          <>
            <div className="w-full py-2 px-6 flex-grow flex justify-between items-center z-100 shadow-light-60">
              <div className="flex">
                <Tag variant={TagVariant.TAG_OUTLINE_NEUTRAL}>#{parentEvent.seq}</Tag>
              </div>

              <div className="text-center">
                <div className="text-black font-bold text-body-small">
                  {t('LABEL_CONSULTATION_FOR', { name: parentEvent.Pets[0]?.name || '' })}
                </div>
                <div className="text-black text-body-small">{formatDate(parentEvent?.dateCreated || '', 'dd/MM/yyyy')}</div>
              </div>

              <div className="flex flex-row">
                {parentEvent.Pets.map((pet: Pet) => (
                  <Avatar key={pet.id} title={pet.initials} image={pet.avatar} className="w-8 h-8" />
                ))}
              </div>
            </div>
          </>
        )
      case EventStatus.STATUS_OPEN:
        return (
          <>
            <div className="w-full py-2 px-6 flex-grow flex justify-between items-center z-100 shadow-light-60">
              <div className="flex">
                <Tag variant={TagVariant.TAG_OUTLINE_NEUTRAL}>#{parentEvent.seq}</Tag>
                {parentEvent.Pets.map((pet: Pet) => (
                  <Avatar key={pet.id} title={pet.initials} image={pet.avatar} className="w-8 h-8" />
                ))}
              </div>

              <div className="text-center">
                <div className="text-black font-bold text-body-small">
                  {t('LABEL_CONSULTATION_FOR', { name: parentEvent.Pets[0]?.name || '' })}
                </div>
                <div className="text-black text-body-small">{formatDate(parentEvent?.dateCreated || '', 'dd/MM/yyyy')}</div>
              </div>

              <div className="flex flex-row">
                <IonButton color="danger" size="small" onClick={onTerminate}>
                  {t('ACTION_TERMINATE')}
                </IonButton>
              </div>
            </div>
          </>
        )
      default:
        return <></>
    }
  }

  return (
    <NavHeader className="py-2 z-50" title={parentEvent.User?.safeName || ''} titleClassName="ml-10" backAction={onBack} extra={getExtra()}>
      <IonButton className="link" fill="clear" onClick={goToClientRequests}>
        <span className="mr-2 text-h100 font-bold">{t('ACTION_HISTORY')}</span>
      </IonButton>
    </NavHeader>
  )
}
