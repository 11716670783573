import { BaseEvent, BaseEventInterface } from './BaseEvent'
import { EventCategory, EventType } from './enums'
import { DiaryEventPayload, DiaryEventPayloadInterface } from './payload/DiaryEventPayload'

const VALID_DIARY_CATEGORIES = [
  EventCategory.EVENT_CATEGORY_DIARY_GENERIC,
  EventCategory.EVENT_CATEGORY_DIARY_BIRTH,
  EventCategory.EVENT_CATEGORY_DIARY_DEATH,
  EventCategory.EVENT_CATEGORY_DIARY_TRAINING,
  EventCategory.EVENT_CATEGORY_DIARY_TRIP,
  EventCategory.EVENT_CATEGORY_BOT_PET,
]

const getValidDiaryCategory = (category: EventCategory): EventCategory =>
  VALID_DIARY_CATEGORIES.includes(category) ? category : EventCategory.EVENT_CATEGORY_DIARY_GENERIC

export class DiaryEvent extends BaseEvent<DiaryEventPayloadInterface> {
  constructor(data: BaseEventInterface, payload: DiaryEventPayloadInterface) {
    data.type = EventType.EVENT_TYPE_DIARY
    data.category = getValidDiaryCategory(data.category || EventCategory.EVENT_CATEGORY_DIARY_GENERIC)
    super(data, payload)
  }

  parsePayload(payload: any) {
    return new DiaryEventPayload(payload)
  }
}
