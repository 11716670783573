import React, { useState } from 'react'
// import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { IonModal } from '@ionic/react'
// import { useIonAlert } from '@ionic/react'
import { DrawerEvent, DrawerEventProps } from '../apps/veterinary/drawers/DrawerEvent'
import { DrawerSize } from '../components/Drawer'
import { DrawerEnterFromLeft, DrawerLeaveFromLeft } from '../components/animations'
import { facilityThunks } from '../features/Facility/store/thunks'
import { useCurrentFamily } from './useCurrentValues'

const EventDrawerContext = React.createContext<any>(undefined)

const EventDrawerProvider: React.FC = ({ children }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [options, setOptions] = useState<DrawerEventProps>({})
  const { family, reloadFamily } = useCurrentFamily()
  const dispatch = useDispatch()
  const [blockDismiss, setBlockDismiss] = useState(false)
  //   const [present] = useIonAlert()
  //   const { t } = useTranslation()

  const onClose = async (data?: any) => {
    setIsOpen(false)
    options.onClose && options.onClose(data)

    family.id && (await dispatch(reloadFamily()))
    await dispatch(facilityThunks.updateClientList(true))
  }

  const onDismiss = (
    data?: any
    // force = false
  ) => {
    // if (!force && blockDismiss)
    //   present({
    //     header: t('ALERT_EDITS_NOT_SAVED_TITLE'),
    //     message: t('ALERT_EDITS_NOT_SAVED_TEXT'),
    //     buttons: [t('ACTION_CANCEL'), { text: t('ACTION_PROCEED'), handler: onClose }],
    //   })
    // else
    onClose(data)
  }

  const openEventDrawer = (options: DrawerEventProps) => {
    if (isOpen) {
      console.warn('You cannot open multiple Appointment Drawers')
      return
    }

    setOptions(options)
    setIsOpen(true)
  }

  return (
    <EventDrawerContext.Provider value={{ options, openEventDrawer, isOpen, setIsOpen }}>
      <IonModal
        isOpen={isOpen}
        cssClass={`${DrawerSize.LARGE} drawer-with-backdrop`}
        showBackdrop={true}
        enterAnimation={DrawerEnterFromLeft}
        leaveAnimation={DrawerLeaveFromLeft}
        backdropDismiss={false}
      >
        <DrawerEvent {...options} onClose={onDismiss} setBlockDismiss={setBlockDismiss} />
      </IonModal>

      {children}
    </EventDrawerContext.Provider>
  )
}
/**
 * Need to use this hook in order to have the possibility to use Redux store inside modals
 */
const useEventDrawer = (): { openEventDrawer: (options: DrawerEventProps) => void } => {
  const { openEventDrawer } = React.useContext(EventDrawerContext)
  return { openEventDrawer }
}

export { EventDrawerContext, EventDrawerProvider, useEventDrawer }
