import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Redirect, Route, useHistory } from 'react-router-dom'
import { PageMobileAnimation } from 'src/components/animations'
import { familyThunks } from 'src/features/Family/store/thunks'
import { useCurrentCall, useCurrentFamily, useCurrentUser } from 'src/hooks/useCurrentValues'
import { useSocket } from 'src/hooks/useSocket'
import { IonLoading, IonRouterOutlet, IonTabBar, IonTabs } from '@ionic/react'
import { PageProfile } from '../pages/PageProfile'
import { PageChat } from '../pages/veterinary/PageChat'
import { PageNotifications } from '../pages/veterinary/PageNotifications'
import { PageRequests } from '../pages/veterinary/PageRequests'
import { AppRouterHelper } from '../services/AppRouterHelper'

export const LayoutAppVeterinary: React.FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const currentUser = useCurrentUser()
  const { isLoading, family } = useCurrentFamily()
  const { callObject } = useCurrentCall()
  const { setQuery } = useSocket()

  const onCall = !!callObject

  const isFamilyLoaded = family.id || !isLoading

  // const { pathname } = history.location
  // const showTabs =
  //   pathname.startsWith(AppRouterHelper.VETERINARY_ROUTES.NOTIFICATIONS) || pathname === AppRouterHelper.VETERINARY_ROUTES.REQUESTS

  useEffect(() => {
    setQuery({ userId: currentUser.id, familyId: undefined })
  }, [])

  useEffect(() => {
    !currentUser.isVet && dispatch(familyThunks.setFamily(currentUser.FamilyId))
  }, [])

  useEffect(() => {
    currentUser.pendingFacilities?.length > 0 && history.replace(AppRouterHelper.ROUTES.MAP)
  }, [])

  return (
    <>
      <IonLoading isOpen={!isFamilyLoaded} />
      <IonTabs>
        <IonRouterOutlet className={`${onCall && 'mt-4'}`} basePath="/app" mode="md" animation={PageMobileAnimation}>
          <Route path={AppRouterHelper.VETERINARY_ROUTES.REQUESTS} exact={true} render={() => <PageRequests />} />
          <Route path={AppRouterHelper.VETERINARY_ROUTES.NOTIFICATIONS} exact={true} render={() => <PageNotifications />} />
          <Route path={AppRouterHelper.VETERINARY_ROUTES.PROFILE} exact={true} render={() => <PageProfile vetMode />} />
          <Route path={AppRouterHelper.VETERINARY_ROUTES.REQUEST} exact sensitive strict component={PageChat} />
          <Redirect exact={true} from={AppRouterHelper.VETERINARY_ROUTES.ROOT} to={AppRouterHelper.VETERINARY_ROUTES.REQUESTS} />
        </IonRouterOutlet>
        {/* TODO: remove and use the second IonTabBar if more tabs are needed */}
        <IonTabBar slot="bottom" style={{ display: 'none' }} />
        {/* <IonTabBar
          slot="bottom"
          className={`transform ease-in-out duration-200 border-none py-1 ${showTabs ? '' : 'translate-y-full hidden'}`}
        >
          <IonTabButton href={AppRouterHelper.VETERINARY_ROUTES.REQUESTS} tab="requests">
            <div className="relative">
              <IconInbox className="w-6 h-6" />
            </div>
            <IonLabel>{t('PAGE_REQUESTS')}</IonLabel>
          </IonTabButton>

          <IonTabButton href={AppRouterHelper.VETERINARY_ROUTES.NOTIFICATIONS} tab="notifications">
            <div className="relative">
              <IconNotification className="w-6 h-6" />
              <IonBadge
                className="-mr-1 -mt-1 h-3 w-3 p-2 flex justify-center items-center text-body-tiny rounded-full absolute top-0 right-0"
                color="primary"
              >
                1
              </IonBadge>
            </div>
            <IonLabel>{t('PAGE_NOTIFICATION')}</IonLabel>
          </IonTabButton>
        </IonTabBar> */}
      </IonTabs>
    </>
  )
}
