import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Avatar } from 'src/components/Avatar'
import { useCurrentFamily, useCurrentUser } from 'src/hooks/useCurrentValues'
import { AllEventMask } from 'src/models/events'
import { IonContent, IonPage, IonRouterLink } from '@ionic/react'
import { NavHeader } from '../components/Header/NavHeader'
import { AppRouterHelper } from '../services/AppRouterHelper'

export const PageNotifications = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const currentUser = useCurrentUser()
  const { reloadFamily } = useCurrentFamily()
  const [events] = useState<AllEventMask[]>([])

  useEffect(() => {
    dispatch(reloadFamily)
  }, [])

  return (
    <IonPage>
      <NavHeader title={t('TITLE_NOTIFICATIONS')} showBackButton={false}>
        <IonRouterLink routerLink={AppRouterHelper.ROUTES.PROFILE}>
          <Avatar image={currentUser.avatar} title={currentUser.initials} className="cursor-pointer h-12 w-12 mr-3" />
        </IonRouterLink>
      </NavHeader>
      <IonContent>
        <div className="flex flex-col h-full mb-6">
          <section className="flex py-6">
            {!events.length && (
              <div className="flex flex-col px-6 w-full">
                <div className="flex-1 text-center">
                  <h1 className="text-lead font-bold my-6">Coming soon</h1>
                </div>
              </div>
            )}
          </section>
        </div>
      </IonContent>
    </IonPage>
  )
}
