import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Facility } from 'src/models'
import { EventCategory } from 'src/models/events/enums'
import { FacilityChip } from '../../FacilityChip'
import { BotActionMessage } from '../BotActionMessage'
import { BotMessageProps } from './constants'

type BotFacilitySelectionProps = BotMessageProps & {
  facilities?: Facility[]
  facilityId?: string
  category?: EventCategory
  onSelectedFacility?: (facilityId: string) => void
}

export const BotFacilitySelection: React.FC<BotFacilitySelectionProps> = ({
  event,
  category,
  facilities,
  facilityId,
  onSelectedFacility,
}) => {
  const { t } = useTranslation()
  const facilityList = facilities || ([] as Facility[])
  const [selectedFacility, setSelectedFacility] = useState<string>(facilityId || '')

  const onFacilitySelection = (id: string) => {
    setSelectedFacility(id)
    onSelectedFacility && onSelectedFacility(id)
  }

  const message =
    category === EventCategory.EVENT_CATEGORY_HEALTH_VISIT
      ? 'BOT_CONVERSATION_VISIT_SELECT_FACILITY'
      : 'BOT_CONVERSATION_CONSULTATION_SELECT_FACILITY'

  return (
    <BotActionMessage date={event.payload.date} message={t(message)}>
      <div className="flex flex-wrap w-full">
        {facilityList.map((facility: Facility) => (
          <div key={facility.id} className="flex mb-2 mr-2">
            <FacilityChip facility={facility} active={selectedFacility === facility.id} onClick={() => onFacilitySelection(facility.id)} />
          </div>
        ))}
      </div>
    </BotActionMessage>
  )
}
