import { User } from 'src/models/User'
import { ChildEventPayload } from './ChildEventPayload'
import { ParentEventPayloadInterface } from './ParentEventPayload'

export type ChatEventPayloadInterface = ParentEventPayloadInterface & {
  message: string
  user: User
  seen: boolean
}

export class ChatEventPayload extends ChildEventPayload {
  message: string
  user: User
  seen: boolean

  constructor(payload: ChatEventPayloadInterface) {
    super(payload)
    this.message = payload.message

    this.user = payload.user

    this.seen = payload.seen
  }

  json(): any {
    return {
      ...super.json(),
      message: this.message || '',
      user: JSON.stringify(this.user) || null,
    }
  }
}
