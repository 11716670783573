import { User } from 'src/models'
import { AllEventMask } from 'src/models/events'
import { DailyCall } from '@daily-co/daily-js'
import { PayloadAction } from '@reduxjs/toolkit'
import { CallState } from './slice'

export const callReducers = {
  setCallObject: (state: CallState, action: PayloadAction<DailyCall | undefined>): void => {
    state.callObject = action.payload
  },
  setStartTime: (state: CallState, action: PayloadAction<Date | undefined>): void => {
    state.startTime = action.payload
  },
  setCallee: (state: CallState, action: PayloadAction<User | undefined>): void => {
    state.callee = action.payload
  },
  setEvent: (state: CallState, action: PayloadAction<AllEventMask | undefined>): void => {
    state.event = action.payload
  },
}
