export enum EventType {
  EVENT_TYPE_HEALTH = 'HEALTH',
  EVENT_TYPE_DIARY = 'DIARY',
  EVENT_TYPE_CHAT = 'CHAT',
  EVENT_TYPE_BOT = 'BOT',
  EVENT_TYPE_CALENDAR = 'CALENDAR',
}

export enum EventCategory {
  EVENT_CATEGORY_NONE = 'NONE',
  EVENT_CATEGORY_HEALTH_VISIT = 'VISIT',
  EVENT_CATEGORY_HEALTH_VIDEOCONSULTATION = 'VIDEOCONSULTATION',
  EVENT_CATEGORY_HEALTH_CONSULTATION = 'CONSULTATION',
  EVENT_CATEGORY_DIARY_GENERIC = 'DIARY_GENERIC',
  EVENT_CATEGORY_DIARY_BIRTH = 'DIARY_BIRTH',
  EVENT_CATEGORY_DIARY_DEATH = 'DIARY_DEATH',
  EVENT_CATEGORY_DIARY_TRAINING = 'DIARY_TRAINING',
  EVENT_CATEGORY_DIARY_TRIP = 'DIARY_TRIP',
  EVENT_CATEGORY_BOT_PET = 'BOT_PET',
  EVENT_CATEGORY_CALENDAR_BOOKING = 'BOOKING',
  EVENT_CATEGORY_CALENDAR_OUT_OF_OFFICE = 'OOO',
}

export enum EventNotificationAction {
  EVENT_TYPE_PAYMENT = 'PAYMENT',
  EVENT_TYPE_BOOKING = 'BOOKING',
  EVENT_TYPE_NEXT_APPOINTMENT = 'NEXT_APPOINTMENT',
}

export enum EventApplicationSource {
  EVENT_SOURCE_APP = 'APP',
  EVENT_SOURCE_WHATSAPP = 'WHATSAPP',
  EVENT_SOURCE_TELEGRAM = 'TELEGRAM',
}
export enum EventPriority {
  EVENT_PRIORITY_LOW = 'LOW',
  EVENT_PRIORITY_MEDIUM = 'MEDIUM',
  EVENT_PRIORITY_HIGH = 'HIGH',
}

export enum EventCalendarStatus {
  STATUS_NEW = 'NEW',
  STATUS_OPEN = 'OPEN',
  STATUS_CLOSED = 'CLOSED',
  STATUS_TO_BE_CONFIRMED = 'TO_BE_CONFIRMED',
  STATUS_RESERVED = 'RESERVED',
}

export enum EventStatus {
  STATUS_NEW = 'NEW',
  STATUS_OPEN = 'OPEN',
  STATUS_CLOSED = 'CLOSED',
}

export enum EventAction {
  EVENT_ACTION_PROPOSE_TIME = 'PROPOSE_TIME',
  EVENT_ACTION_OPEN_PERFORMANCE = 'OPEN_PERFORMANCE',
  EVENT_ACTION_CLOSE_PERFORMANCE = 'CLOSE_PERFORMANCE',
  EVENT_ACTION_APPOINTMENT = 'APPOINTMENT',
  EVENT_ACTION_APPOINTMENT_NO_SHOW = 'APPOINTMENT_NO_SHOW',
  EVENT_ACTION_RELATED_TO_APPOINTMENT = 'RELATED_TO_APPOINTMENT',
}

export enum EventPaymentStatus {
  PAYMENT_STATUS_UNKNOWN = 'UNKNOWN',
  PAYMENT_STATUS_FREE = 'FREE',
  PAYMENT_STATUS_PENDING = 'PENDING',
  PAYMENT_STATUS_PAID = 'PAID',
  PAYMENT_STATUS_FAILED = 'FAILED',
  PAYMENT_STATUS_TO_BE_PAID = 'TO_BE_PAID',
}

export enum EventPaymentType {
  PAYMENT_TYPE_UNKNOWN = 'UNKNOWN',
  PAYMENT_TYPE_DIGITAL = 'DIGITAL',
  PAYMENT_TYPE_CASH = 'CASH',
}

export enum EventPaymentPaymentType {
  PAYMENT = 'PAYMENT',
  UPFRONT = 'UPFRONT',
  RESERVATION = 'RESERVATION',
  INSTALLMENT = 'INSTALLMENT',
}

export enum EventPaymentCurrency {
  PAYMENT_CURRENCY_EUR = 'EUR',
}

export enum EventBotActions {
  BOT_ERROR_INVALID_CONVERSATION = 'INVALID_CONVERSATION',
  BOT_VISIT_EVENT_OPENED_BY_PET_OWNER = 'VISIT_OPENED_BY_PET_OWNER',
  BOT_CONSULTATION_EVENT_OPENED_BY_PET_OWNER = 'OPENED_BY_PET_OWNER',
  BOT_VISIT_EVENT_OPENED_BY_VET = 'VISIT_OPENED_BY_VET',
  BOT_VISIT_EVENT_UPDATED_BY_VET = 'VISIT_UPDATED_BY_VET',
  BOT_VISIT_EVENT_UPDATED_BY_PET_OWNER = 'VISIT_UPDATED_BY_PET_OWNER',
  BOT_CONSULTATION_EVENT_OPENED_BY_VET = 'OPENED_BY_VET',
  BOT_CONSULTATION_EVENT_ASSIGNED_TO_FACILITY = 'ASSIGNED_TO_FACILITY',
  BOT_CONSULTATION_DETAILS = 'DETAILS',
  BOT_EVENT_OPENED = 'OPENED',
  BOT_EVENT_CLOSED = 'CLOSED',
  BOT_PAYMENT_REQUEST = 'BOT_MESSAGE_PAYMENT_REQUEST',
  BOT_PAYMENT_REQUEST_UPFRONT = 'BOT_MESSAGE_PAYMENT_REQUEST_UPFRONT',
  BOT_PAYMENT_PAID = 'PAYMENT_PAID',
  BOT_PAYMENT_FAILED = 'PAYMENT_FAILED',
  BOT_JOIN_VIDEOMEETING = 'JOIN_VIDEOMEETING',
  BOT_CLOSE_VIDEOMEETING = 'CLOSE_VIDEOMEETING',
}

export enum CalendarEventStatus {
  CALENDAR_EVENT_STATUS_NEW = 'NEW', // Default value
  CALENDAR_EVENT_STATUS_UPDATED = 'UPDATED', // The old event was CANCELED and a new event was created
  CALENDAR_EVENT_STATUS_CANCELED = 'CANCELED',
  CALENDAR_EVENT_STATUS_TO_BE_CONFIRMED = 'TO_BE_CONFIRMED',
  CALENDAR_EVENT_STATUS_TO_BE_BOOKED = 'TO_BE_BOOKED',
  CALENDAR_EVENT_STATUS_RESERVATION = 'RESERVATION',
}

export const BotMessageWithActions = [EventBotActions.BOT_JOIN_VIDEOMEETING]

export const ServiceCategory = {
  VIDEOCONSULTATION: 3,
}

export const VideomeetingStatus = {
  CLOSED: 'CLOSED',
}
