import { orderBy } from 'lodash'
import uniqueId from 'lodash/uniqueId'
import { BotEvent, Conversation } from 'src/models/events'
import { CalendarEventStatus, EventBotActions, EventCategory } from 'src/models/events/enums'
import { DateConverter } from 'src/utils'
import { calendarEventFinder } from 'src/validators/calendarEventValidators'

export const generateBotEvents = (conversation: Conversation): Conversation => {
  const createdDate = conversation.parentEvent.dateCreated
  const { children } = conversation

  const calendarEvent = calendarEventFinder(children)

  if (conversation.parentEvent.Pets.length === 0) {
    children.unshift(BotEvent.uiMessage(new Date(createdDate.getTime() - 1000), EventBotActions.BOT_ERROR_INVALID_CONVERSATION))
    conversation.children = children
    return conversation
  }

  if (conversation.parentEvent.category === EventCategory.EVENT_CATEGORY_HEALTH_VISIT) {
    if (calendarEvent?.payload?.status === CalendarEventStatus.CALENDAR_EVENT_STATUS_UPDATED && calendarEvent.dateUpdated) {
      if (conversation.parentEvent.User?.isVet) {
        children.unshift(
          BotEvent.uiMessage(new Date(calendarEvent.dateUpdated.getTime() - 1000), EventBotActions.BOT_VISIT_EVENT_UPDATED_BY_VET)
        )
      } else {
        children.unshift(
          BotEvent.uiMessage(new Date(calendarEvent.dateUpdated.getTime() - 1000), EventBotActions.BOT_VISIT_EVENT_UPDATED_BY_PET_OWNER)
        )
      }
    } else {
      if (conversation.parentEvent.User?.isVet) {
        children.unshift(BotEvent.uiMessage(new Date(createdDate.getTime() - 1000), EventBotActions.BOT_VISIT_EVENT_OPENED_BY_VET))
      } else {
        children.unshift(BotEvent.uiMessage(new Date(createdDate.getTime() - 1000), EventBotActions.BOT_VISIT_EVENT_OPENED_BY_PET_OWNER))
      }
    }
  } else {
    if (conversation.parentEvent.User?.isVet) {
      children.unshift(BotEvent.uiMessage(new Date(createdDate.getTime() - 1000), EventBotActions.BOT_CONSULTATION_EVENT_OPENED_BY_VET))
    } else {
      children.unshift(
        BotEvent.uiMessage(new Date(createdDate.getTime() - 1000), EventBotActions.BOT_CONSULTATION_EVENT_OPENED_BY_PET_OWNER)
      )
    }
  }

  if (!conversation.parentEvent.User?.isVet && children.length === 0) {
    children.unshift(
      BotEvent.uiMessage(new Date(createdDate.getTime() - 1000), EventBotActions.BOT_CONSULTATION_EVENT_ASSIGNED_TO_FACILITY)
    )
  }

  children.unshift(BotEvent.uiMessage(new Date(createdDate.getTime() - 1000), EventBotActions.BOT_CONSULTATION_DETAILS))

  if (conversation.parentEvent.payload.openDate) {
    const openEventBot = new BotEvent(
      { _id: uniqueId(), date_created: conversation.parentEvent.payload.openDate },
      {
        eventAction: EventBotActions.BOT_EVENT_OPENED,
        date: conversation.parentEvent.payload.openDate,
        facilityId: '',
        userId: '',
      }
    )

    children.push(openEventBot)
  }

  if (conversation.parentEvent.isClosed()) {
    const closeEventBot = new BotEvent(
      { _id: uniqueId(), date_created: conversation.parentEvent.payload.closeDate },
      {
        eventAction: EventBotActions.BOT_EVENT_CLOSED,
        date: conversation.parentEvent.payload.closeDate,
        facilityId: '',
        userId: '',
      }
    )

    children.push(closeEventBot)
  }

  conversation.children = orderBy(children, (c) => [c.seq, DateConverter.toServer(c.payload.date)])

  return conversation
}
