import { decode } from 'js-base64'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { FormEmailConfirm } from 'src/components/forms/FormEmailConfirm'
import { SignUpConfirmationPayload } from 'src/features/Auth/payload/SignUpConfirmationPayload'
import { authLoadingSelector } from 'src/features/Auth/store/selectors'
import { authThunks } from 'src/features/Auth/store/thunks'
import { useToast } from 'src/hooks/useToast'
import { IonContent, IonPage } from '@ionic/react'
import { NavHeader } from '../components/Header/NavHeader'

export const PageEmailConfirmation: React.FC = () => {
  const { email } = useParams<{ email: string }>()
  const username = decode(email)

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const isSubmitting = useSelector(authLoadingSelector)
  const history = useHistory()
  const toast = useToast()

  const onSubmit = async ({ code }: { code: string }) => {
    if (!username) return

    try {
      await dispatch(authThunks.confirmSignUp(new SignUpConfirmationPayload(username as string, code)))
      toast.success(t('ACCOUNT_VERIFIED'))
      history.push('/login')
    } catch ({ message }) {
      toast.error(t(message as string))
    }
  }

  const resendConfirmationSignUp = async () => {
    if (!username) return
    try {
      await dispatch(authThunks.resendConfirmationSignUp(username))
      toast.success(t('CONFIRMATION_SIGN_UP_SENT'))
    } catch ({ message }) {
      toast.error(t(message as string))
    }
  }

  return (
    <IonPage>
      <NavHeader titleClassName="text-center" title={t('TITLE_PAGE_VERIFY_EMAIL')} showBackButton={false} />
      <IonContent className="text-center">
        <FormEmailConfirm onSubmit={onSubmit} onResendSubmit={resendConfirmationSignUp} isSubmitting={isSubmitting} />
      </IonContent>
    </IonPage>
  )
}
