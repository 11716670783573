import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FilterState, FormDiaryFilters } from 'src/apps/veterinary/forms/FormDiaryFilters'
import { useCurrentFamily } from 'src/hooks/useCurrentValues'
import { IonButton, IonButtons, IonContent, IonPage } from '@ionic/react'
import { useQueryClient } from '@tanstack/react-query'
import { NavHeader } from '../components/Header/NavHeader'

type ModalDiaryFiltersProps = {
  defaultValues: any
  onDismiss: () => void
  onSuccess: (filters: FilterState) => void
}
export const ModalDiaryFilters: FC<ModalDiaryFiltersProps> = ({ defaultValues, onDismiss, onSuccess }) => {
  const { t } = useTranslation()
  const { pets } = useCurrentFamily()
  const [filters, setFilters] = useState<FilterState>(defaultValues)

  const onFiltersChange = (opts: any) => setFilters(opts)
  const onApplyClicked = () => {
    onSuccess(filters)
  }

  return (
    <IonPage>
      <NavHeader title={t('TITLE_FILTER_EVENTS_SHORT')} showBackButton={true} backAction={onDismiss}>
        <IonButtons slot="end">
          <IonButton className="px-3 link" fill="clear" onClick={onApplyClicked}>
            <span className="font-bold">{t('ACTION_APPLY')}</span>
          </IonButton>
        </IonButtons>
      </NavHeader>
      <IonContent>
        <FormDiaryFilters onChange={onFiltersChange} className="py-6" loading={false} pets={pets} defaultValues={defaultValues} />
      </IonContent>
    </IonPage>
  )
}
