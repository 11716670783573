import { useEffect, useState } from 'react'
import { EventsAPI } from 'src/api/EventsAPI'
import { generateBotEvents } from 'src/features/Family/store/botEvents'
import { Conversation } from '../models/events'

export const useConversationByEventId = (parentEventId: string, onLoaded?: () => void) => {
  const [conversation, setConversation] = useState<Conversation>(Conversation.generateInvalid)

  const loadConversation = async () => {
    const conv: Conversation = await EventsAPI.getSingleConversationByRequestId(parentEventId)
    const enrichedConversation = generateBotEvents(conv)

    setConversation(enrichedConversation)
    onLoaded && onLoaded()
  }

  useEffect(() => {
    loadConversation()
  }, [])

  return { conversation, reloadConversation: loadConversation }
}
