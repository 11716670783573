import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { axiosInstance } from 'src/api/axios'
import { parseEventsToConversations } from 'src/api/eventApiUtilities'
import { Avatar } from 'src/components/Avatar'
import { ChatEventPreview } from 'src/components/events/ChatEventPreview'
import { IconCalendar, IconUserPlus } from 'src/components/icons'
import { familyThunks } from 'src/features/Family/store/thunks'
import { useCurrentFamily, useCurrentUser, useFamilyConversations } from 'src/hooks/useCurrentValues'
import { useModal } from 'src/hooks/useModal'
import { AllEventMask } from 'src/models/events'
import { isHealthEvent } from 'src/models/events'
import { EventStatus } from 'src/models/events/enums'
import { RefresherEventDetail } from '@ionic/core'
import {
  IonButton,
  IonContent,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonRouterLink,
  IonSpinner,
  useIonViewWillEnter,
} from '@ionic/react'
import { IonInfiniteScroll, IonInfiniteScrollContent } from '@ionic/react'
import { useQuery } from '@tanstack/react-query'
import { NavHeader } from '../components/Header/NavHeader'
import { AppRouterHelper } from '../services/AppRouterHelper'
import { PageAddVeterinary } from './PageAddVeterinary'

export const PagePerformances: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const currentUser = useCurrentUser()
  const { showModal, hideModal } = useModal()
  const [currentPage, setCurrentPage] = useState(0)
  const [disableInfiniteScroll] = useState<boolean>(false)
  const { family } = useCurrentFamily()
  const {
    isPending,
    error,
    refetch,
    data: conversations = [],
  } = useQuery({
    queryKey: ['performances'],
    queryFn: async () => {
      const url = `/${family.id}/diary`

      const response = await axiosInstance.get(url)

      return parseEventsToConversations(response.data)
    },
  })

  //   const { conversations } = useFamilyConversations()
  const performances = conversations.filter(
    ({ parentEvent }) => isHealthEvent(parentEvent) && parentEvent.payload.status !== EventStatus.STATUS_CLOSED
  )

  const hasVeterinary = currentUser.vetFacilities.length > 0

  const onCardClick = (event: AllEventMask) => {
    history.push(`/app/chat/${event.id || ''}`)
  }

  const doRefresh = async (event: CustomEvent<RefresherEventDetail>) => {
    // await dispatch(familyThunks.setFamily(family.id))
    refetch()

    setTimeout(() => {
      event.detail.complete()
    }, 500)
  }

  async function searchNext($event: CustomEvent<void>) {
    const nextPage = currentPage + 1
    setCurrentPage(nextPage)
    dispatch(familyThunks.getConversationsByFamily(currentPage))
    ;($event.target as HTMLIonInfiniteScrollElement).complete()
  }

  const onAddVeterinary = () => {
    showModal(<PageAddVeterinary onDismiss={hideModal} />)
  }

  useIonViewWillEnter(async () => await dispatch(familyThunks.setFamily(family.id)), [])

  if (isPending) {
    return (
      <div className="flex w-full h-full items-center justify-center">
        <IonSpinner name="lines" color="primary" />
      </div>
    )
  }

  return (
    <IonPage>
      <NavHeader title={t('TITLE_PERFORMANCES')} showBackButton={false}>
        <IonRouterLink routerLink={AppRouterHelper.ROUTES.PROFILE}>
          <Avatar image={currentUser.avatar} title={currentUser.initials} className="cursor-pointer h-12 w-12 mr-3" />
        </IonRouterLink>
      </NavHeader>
      <IonContent className="ion-content-services">
        <IonRefresher slot="fixed" onIonRefresh={doRefresh} pullFactor={0.5} pullMin={100} pullMax={200}>
          <IonRefresherContent />
        </IonRefresher>
        {!hasVeterinary ? (
          <div className="flex flex-col mt-32 text-center items-center">
            <IconUserPlus className="w-12 h-12 text-primary-500" />
            <h1>{t('PAGE_PERFORMANCE_NO_VET_TITLE')}</h1>
            <p className="text-body-large">{t('PAGE_PERFORMANCE_NO_VET_TEXT')}</p>
          </div>
        ) : performances.length === 0 ? (
          <section className="h-full flex flex-col items-center justify-center text-center">
            <IconCalendar className="text-primary-500 h-16" />
            <h1 className="mt-8 mb-4">{t('PAGE_PERFORMANCE_NO_ITEMS')}</h1>
            <p className="text-body-large">{t('PAGE_PERFORMANCE_NO_ITEMS_DESCRIPTION')}</p>
          </section>
        ) : (
          <>
            <div className="flex flex-col mt-6">
              {performances.map(({ parentEvent: event }) => (
                <ChatEventPreview key={event.id} event={event} onClick={onCardClick} />
              ))}
            </div>
            <IonInfiniteScroll threshold="100px" disabled={disableInfiniteScroll} onIonInfinite={(e: CustomEvent<void>) => searchNext(e)}>
              <IonInfiniteScrollContent loadingText="Loading more good doggos..." />
            </IonInfiniteScroll>
          </>
        )}
        <div className="fixed w-full flex flex-col items-center bottom-0 left-0 pt-4 pb-8">
          {hasVeterinary ? (
            <IonButton size="small" fill="outline" className="bg-white" routerLink={AppRouterHelper.ROUTES.ADD_PERFORMANCE}>
              {t('PAGE_PERFORMANCE_ASK_PERFORMANCE')}
            </IonButton>
          ) : (
            <IonButton size="small" fill="outline" className="bg-white" onClick={onAddVeterinary}>
              {t('ACTION_CONNECT_TO')}
            </IonButton>
          )}
        </div>
      </IonContent>
    </IonPage>
  )
}
