import isPlainObject from 'lodash/isPlainObject'
import { Pet } from 'src/models'
import { DateConverter } from 'src/utils'
import { EventApplicationSource, EventPriority, EventStatus } from '../enums'
import { ParentEventPayload, ParentEventPayloadInterface } from './ParentEventPayload'
import { PaymentEventPayload, PaymentEventPayloadInterface } from './PaymentEventPayload'

export type HealthEventPayloadInterface = ParentEventPayloadInterface & {
  source?: EventApplicationSource
  note?: string
  pets?: string[] | Pet[]
  priority: EventPriority
  status: EventStatus
  assigneeId?: string
  collaborators?: string[]
  openDate?: Date
  closeDate?: Date
  services?: number[]
  duration?: number
  payment: PaymentEventPayloadInterface
  seen: boolean
  videomeeting: any
}

export class HealthEventPayload extends ParentEventPayload {
  source: EventApplicationSource
  note: string
  pets: string[] | Pet[]
  priority: EventPriority
  status: EventStatus
  assigneeId: string | null
  collaborators: string[]

  openDate: Date | null
  closeDate: Date | null

  services: number[]
  duration: number | null
  payment: PaymentEventPayload

  seen: boolean

  videomeeting: any

  constructor(payload: HealthEventPayloadInterface) {
    super(payload)
    this.source = payload.source || EventApplicationSource.EVENT_SOURCE_APP
    this.note = payload.note || ''
    this.pets = payload.pets || []
    this.priority = payload.priority || EventPriority.EVENT_PRIORITY_MEDIUM
    this.status = payload.status || EventStatus.STATUS_NEW
    this.assigneeId = payload.assigneeId || null
    this.collaborators = payload.collaborators || []

    this.openDate = payload.openDate ? DateConverter.fromServer(payload.openDate) : null
    this.closeDate = payload.closeDate ? DateConverter.fromServer(payload.closeDate) : null
    this.services = payload.services || []
    this.duration = payload.duration || null
    this.payment = new PaymentEventPayload(payload.payment || {})

    this.seen = payload.seen

    this.videomeeting = payload.videomeeting || {}
  }

  get petIds() {
    return this.pets.map((pet: any) => (isPlainObject(pet) ? pet.id : pet)) || []
  }

  get servicesIds() {
    return this.services.map((service: any) => (isPlainObject(service) ? service.id : service)) || []
  }

  json(): any {
    return {
      ...super.json(),
      source: this.source,
      note: this.note,
      pets: this.petIds,
      priority: this.priority,
      status: this.status,
      assigneeId: this.assigneeId,
      collaborators: this.collaborators,
      openDate: this.openDate ? DateConverter.toServer(this.openDate) : null,
      closeDate: this.closeDate ? DateConverter.toServer(this.closeDate) : null,
      services: this.servicesIds,
      duration: this.duration,
      ...(this.payment ? { payment: this.payment } : {}),
      videomeeting: this.videomeeting,
    }
  }
}
