import set from 'date-fns/set'
import React, { useEffect, useState } from 'react'
import { useController } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ButtonGroup } from 'src/components/ButtonGroup'
import { TimeField } from 'src/components/TimeField'
import { DailyEventSuggestions } from 'src/components/calendar/DailyEventSuggestions'
import { Scheduler } from 'src/components/calendar/Scheduler'
import { RemoteWomanCheck } from 'src/components/icons/RemoteWomanCheck'
import { DatePickerField } from 'src/components/inputs/DatePickerField'
import { useCurrentValues } from 'src/hooks/useCurrentValues'
import { User } from 'src/models'
import { EventSuggestion } from 'src/models/events/EventSuggestion'
import { DateConverter, formatDate } from 'src/utils'

export enum ActivityTimeType {
  NOW = 'now',
  PAST = 'past',
  FUTURE = 'future',
}

type ActivityParams = {
  name: string
  client: User | null
  control: any
  fields: any
  isEditingMode?: boolean
  onSelectedTime?: (value: boolean) => void
  onClientChoice?: (value: boolean) => void
}

export const ActivityTimeSwitcher = ({
  activityTime,
  name,
  client,
  control,
  fields,
  isEditingMode = false,
  onSelectedTime,
  onClientChoice,
}: { activityTime: string; isEditingMode: boolean } & ActivityParams) => {
  if (activityTime === ActivityTimeType.PAST) {
    return <ActivityTimePast name={name} client={client} control={control} fields={fields} onSelectedTime={onSelectedTime} />
  }
  if (activityTime == ActivityTimeType.FUTURE) {
    return (
      <ActivityTimeFuture
        name={name}
        client={client}
        control={control}
        fields={fields}
        isEditingMode={isEditingMode}
        onSelectedTime={onSelectedTime}
        onClientChoice={onClientChoice}
      />
    )
  }

  /*
   * Activity Near
   */
  return <ActivityTimeNow name={name} client={client} control={control} fields={fields} />
}

export const ActivityTimePast: React.FC<ActivityParams> = ({ name, control, onSelectedTime }) => {
  const { t } = useTranslation()
  const [date, setDate] = useState<Date>(DateConverter.now())

  const {
    field: { value, onChange },
  } = useController({ name, control })

  const onDateChange = (selectedDate: Date) => {
    const newDate = set(selectedDate, { hours: date.getHours(), minutes: date.getMinutes() })
    setDate(newDate)
    // onSelectedTime && onSelectedTime(true)
  }

  const onTimeChange = (time: string) => {
    const [hours, minutes] = time?.split(':') || ['00', '00']

    const newDate = set(date, { hours: parseInt(hours), minutes: parseInt(minutes) })
    setDate(newDate)
    // onSelectedTime && onSelectedTime(true)
  }

  useEffect(() => onChange({ mode: ActivityTimeType.PAST, date }), [])
  useEffect(() => onChange({ mode: ActivityTimeType.PAST, date }), [date])

  return (
    <div>
      <div className="flex flex-col items-center justify-center my-20">
        <p className="text-lead text-center">{t('ACTIVITY_PAST_TITLE')}</p>
      </div>

      <DatePickerField label={t('LABEL_DATE')} value={value.date} max={DateConverter.now()} onChange={onDateChange} />
      <TimeField className="mt-4" label={t('LABEL_TIME')} value={formatDate(value.date, 'HH:mm')} onChange={onTimeChange} />
    </div>
  )
}

export const ActivityTimeNow: React.FC<ActivityParams> = () => {
  const { t } = useTranslation()
  return (
    <div className="flex flex-col items-center justify-center mt-12">
      <p className="text-lead">{t('ACTIVITY_NOW_TITLE')}</p>
      <p className="text-lead">{t('ACTIVITY_NOW_SUBTITLE')}</p>
      <RemoteWomanCheck className="w-80 mt-8" />
    </div>
  )
}

export const ActivityTimeFuture: React.FC<ActivityParams> = ({
  name,
  control,
  client,
  fields,
  isEditingMode = false,
  onSelectedTime,
  onClientChoice,
}) => {
  const { duration, pets, services } = fields
  const { t } = useTranslation()
  const { currentFacility } = useCurrentValues()
  const [date, setDate] = useState<Date>(DateConverter.now())
  const [type, setType] = useState<string>('auto')

  const {
    field: { onChange },
  } = useController({ name, control })

  const [appointmentType, setAppointmentType] = useState<string>('auto')

  const onSuggestionSelected = (value: EventSuggestion) => {
    setDate(value.from)
    onSelectedTime && onSelectedTime(false)
  }

  useEffect(() => onChange({ mode: ActivityTimeType.FUTURE, date, type: appointmentType }), [])
  useEffect(() => onChange({ mode: ActivityTimeType.FUTURE, date, type: appointmentType }), [date, appointmentType])

  return (
    <div className="flex-grow flex-flex-col">
      <ButtonGroup
        selected={appointmentType}
        className="mt-6"
        buttons={[
          { value: 'auto', label: t('LABEL_APPOINTMENT_AUTONOMY') },
          { value: 'suggestions', label: t('LABEL_APPOINTMENT_FROM_SUGGESTIONS') },
          { value: 'calendar', label: t('LABEL_APPOINTMENT_FROM_CALENDAR'), disabled: true },
        ]}
        onChange={(appointmentType: string) => {
          setAppointmentType(appointmentType)

          if (appointmentType === 'auto') {
            onClientChoice && onClientChoice(true)
          }

          //   if (appointmentType === 'suggestions') {
          //     onSelectedTime && onSelectedTime(true)
          //   } else {
          //     onSelectedTime && onSelectedTime(false)
          //   }
        }}
      />
      <div className="flex flex-col items-center flex-grow">
        {appointmentType === 'auto' ? (
          <>
            <p className="text-lead mt-12">{t('PET_OWNER_CAN_SELECT_HOURS_AUTOMATICALLY', { name: client?.firstName })}</p>
            <RemoteWomanCheck className="w-80 mt-8" />
          </>
        ) : appointmentType === 'suggestions' ? (
          <DailyEventSuggestions
            className="mb-20 mt-4"
            startDate={DateConverter.now()}
            onSuccess={onSuggestionSelected}
            options={{
              familyId: client?.FamilyId || '',
              facilityId: currentFacility.id,
              petId: pets[0] || '',
              services: services || [],
              duration: parseInt(duration),
            }}
            cardOptions={{
              showDate: true,
              shadow: true,
              className: 'p-4',
            }}
            isEditingMode={isEditingMode}
            clientName={client?.firstName}
          />
        ) : (
          <Scheduler events={[]} />
        )}
      </div>
    </div>
  )
}
