import React from 'react'
import { GenericProps } from 'src/helpers/props'
import { ChildEventMask } from 'src/models/events'
import { DebugDisplay } from '../DebugDisplay'

type ChatMessageWrapper = GenericProps & {
  event?: ChildEventMask
  children: any
}

export const ChatMessageWrapper: React.FC<ChatMessageWrapper> = ({ event, className = '', children }) =>
  event ? (
    <div className={`relative flex space-x-2 ${className || ''}`}>
      <DebugDisplay>{event.stringify()}</DebugDisplay>
      {children}
    </div>
  ) : (
    <>{children}</>
  )
