import React, { forwardRef } from 'react'
import { IonItem, IonLabel, IonTextarea } from '@ionic/react'
import { FormError } from './FormError'

type InputTextAreaProps = {
  error?: string
  label?: string
  placeholder?: string
  className?: string
  description?: string
  [key: string]: any
}

export const InputTextArea = forwardRef<HTMLIonTextareaElement, InputTextAreaProps>(
  ({ error, label, placeholder, className = '', description, ...rest }, ref) => (
    <div className={`block mb-6 ${className}`}>
      <IonItem color="transparent">
        <IonLabel position="stacked">{label}</IonLabel>
        <IonTextarea placeholder={placeholder} {...rest} ref={ref} className={error ? `has-error` : ''} />
      </IonItem>
      {description || error ? (
        <p className="relative">
          {description && !error && <span className="text-grey-600 text-body-tiny mt-1">{description}</span>}
          {error && <FormError message={error} />}
        </p>
      ) : null}
    </div>
  )
)

InputTextArea.displayName = 'InputTextArea'
