import React, { createContext, FC, useEffect, useState } from 'react'
import io, { Socket } from 'socket.io-client'
import { NotificationMessage } from '../models/NotificationMessage'

type SocketPetOwnerQuery = {
  userId: string
  familyId: string
}

type SocketFacilityQuery = {
  userId: string
  facilityId: string
}

type SocketState = {
  events: NotificationMessage[]
  query: SocketFacilityQuery | SocketPetOwnerQuery
  socket: Socket | null
  setQuery: (query: SocketFacilityQuery | SocketPetOwnerQuery) => void
}

const SocketContext = createContext<SocketState>({
  setQuery(_: SocketFacilityQuery | SocketPetOwnerQuery): void {
    _
  },
  events: [],
  query: {
    userId: '',
    familyId: '',
  },
  socket: null,
})

const SocketProvider: FC = ({ children }) => {
  const [socket, setSocket] = useState<Socket | null>(null)
  const [query, setQuery] = useState<SocketPetOwnerQuery | SocketFacilityQuery>({
    userId: '',
    familyId: '',
  })
  const [events] = useState<NotificationMessage[]>([])

  useEffect(() => {
    if (socket) {
      socket.disconnect()
    }

    const newSocket = io(`${process.env.REACT_APP_API_PATH}`, { query })
    setSocket(newSocket)
  }, [query])

  return <SocketContext.Provider value={{ events, query, socket, setQuery }}>{children}</SocketContext.Provider>
}

const useSocket = (): any => {
  const { socket, events, query, setQuery } = React.useContext(SocketContext)
  return { socket, events, query, setQuery }
}

export { SocketContext, SocketProvider, useSocket }
