import React, { forwardRef, ReactChild } from 'react'
import { TextFieldTypes } from '@ionic/core'
import { IonDatetime, IonItem, IonLabel } from '@ionic/react'
import { DateConverter } from '../../utils'
import { IconCalendar } from '../icons'
import { FormError } from './FormError'

type InputDateProps = {
  error?: string
  label: string
  type?: TextFieldTypes
  placeholder?: string
  className?: string
  description?: string
  value?: string
  rightIcon?: ReactChild | null
  [key: string]: any
}

export const InputDate: React.FC<InputDateProps> = forwardRef<HTMLIonDatetimeElement, InputDateProps>(
  ({ error, label, className = '', rightIcon = null, description, ...rest }: InputDateProps, ref) => (
    <div className={`input-field ${className}`}>
      <IonItem color="transparent">
        <IonLabel position="stacked">{label}</IonLabel>
        <div className="w-full relative flex flex-col">
          <IconCalendar className="absolute z-50 m-4 w-6 h-6 text-grey-500" />
          <IonDatetime
            ref={ref}
            className={error ? `pl-4 has-error` : 'pl-4 '}
            {...rest}
            displayFormat="DD MMMM YYYY"
            min="1950"
            max={'' + DateConverter.now().getFullYear()}
          />
          {rightIcon}
        </div>
      </IonItem>
      {description || error ? (
        <p className="relative">
          {description && !error && <span className="text-grey-600 text-body-tiny mt-1">{description}</span>}
          {error && <FormError message={error} />}
        </p>
      ) : null}
    </div>
  )
)

InputDate.displayName = 'InputDate'
