import React, { forwardRef, ReactChild } from 'react'
import { IonLabel } from '@ionic/react'
import { IconDollar } from '../icons'
import { FormError } from './FormError'

type CurrencyFieldProps = {
  error?: string
  label?: string
  placeholder?: string
  className?: string
  description?: string
  tooltip?: ReactChild
  [key: string]: any
}

export const CurrencyField: React.FC<CurrencyFieldProps> = forwardRef<HTMLInputElement, CurrencyFieldProps>(
  ({ error, label = '', placeholder, className = '', description, tooltip, ...rest }: CurrencyFieldProps, ref) => (
    <div className={`input-field ${className}`}>
      <div className="flex flex-col">
        {label ? (
          <IonLabel className="flex items-center" position="stacked">
            {label} <div className="inline-block">{tooltip}</div>
          </IonLabel>
        ) : null}
        <div className="relative">
          {/* <IconEuro className="absolute top-4 left-3 w-6 h-6 text-tertiary-500 z-10" /> */}
          <IconDollar className="absolute top-4 left-3 w-6 h-6 text-tertiary-500 z-10" />
          <input
            placeholder={placeholder}
            type="text"
            step="0.01"
            ref={ref}
            className={`input-element pl-12 ${error ? `has-error` : ''}`}
            {...rest}
          />
        </div>
      </div>
      {description || error ? (
        <p className="relative">
          {description && !error && <span className="text-grey-600 text-body-tiny mt-1">{description}</span>}
          {error && <FormError message={error} />}
        </p>
      ) : null}
    </div>
  )
)

CurrencyField.displayName = 'CurrencyField'
