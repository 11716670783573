import { format } from 'date-fns'
import { enUS } from 'date-fns/locale'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { CalendarAPI, EventSuggestionsByDate } from 'src/api/CalendarAPI'
import { ModalEventSuggestions } from 'src/apps/petOwner/pages/ModalEventSuggestions'
import { getWindowDimensions } from 'src/components/DateTimeRangePicker'
import { useModal } from 'src/hooks/useModal'
import { FacilityService } from 'src/models/FacilityService'
import { EventSuggestion } from 'src/models/events/EventSuggestion'
import { DateConverter, formatDate } from 'src/utils'
import { IonButton, getPlatforms } from '@ionic/react'
import { useQueryClient } from '@tanstack/react-query'
import { AppRouterHelper } from '../../../apps/petOwner/services/AppRouterHelper'
import { EventSuggestionCard } from '../../events/EventSuggestionCard'
import { BotActionMessage } from '../BotActionMessage'
import { BotMessageProps } from './constants'

type BotAvailabilityProps = BotMessageProps & {
  date?: Date
  petId: string
  services: FacilityService[]
  onSuggestionSelected?: (suggestion: EventSuggestion) => void
}

export const BotAvailability: React.FC<BotAvailabilityProps> = ({ event, petId, services, onSuggestionSelected }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const queryClient = useQueryClient()

  const [selectedSlot, setSelectedSlot] = useState<EventSuggestion>()
  const [todaySlots, setTodaySlots] = useState<EventSuggestionsByDate>()
  const { showModal, hideModal } = useModal()

  const getSuggestions = async (date: Date = DateConverter.now()) => {
    const suggestions = await CalendarAPI.getSuggestions(
      selectedSlot?.from || date,
      event.Family?.id || '',
      event.payload?.facilityId || '',
      petId,
      services.map((service) => `${service.id}`),
      10,
      event.payload.duration || 30
    )
    if (suggestions.length) {
      setTodaySlots(suggestions[0])
    }
  }

  const onCancelEvent = () => history.push(AppRouterHelper.ROUTES.PERFORMANCES)

  const onSelected = (suggestion: EventSuggestion) => {
    onSuggestionSelected && onSuggestionSelected(suggestion)
  }
  const onSelectedFromModal = (suggestion: EventSuggestion) => {
    setSelectedSlot(suggestion)
    onSuggestionSelected && onSuggestionSelected(suggestion)
  }

  const chooseSlot = () => {
    showModal(
      <ModalEventSuggestions
        startDate={todaySlots?.date || DateConverter.now()}
        facilityId={event.payload.facilityId || ''}
        onDismiss={hideModal}
        onSuccess={onSelectedFromModal}
        duration={30}
        familyId={event.Family?.id || ''}
        petId={petId}
        servicesIds={services.map((service) => `${service.id}`)}
        onCancelEvent={onCancelEvent}
        queryClient={queryClient}
      />
    )
  }

  useEffect(() => {
    getSuggestions()
  }, [])

  return (
    <BotActionMessage message={t('BOT_CONVERSATION_CHOOSE_APPOINTMENT')}>
      <div>
        {todaySlots && (
          <div>
            {selectedSlot ? (
              <div className="bg-white p-4 space-y-4 shadow-light-40 rounded">
                <div className="text-body-large font-bold">{format(selectedSlot.from, 'MMMM dd, yyyy', { locale: enUS })}</div>
                <div>
                  <div className="text-grey-600 text-body-small mb-2">{t('LABEL_CHOSEN_TIME')}</div>
                  <IonButton color="primary" fill="solid" size="small" className="button-xsmall">
                    {selectedSlot.slot}
                  </IonButton>
                </div>
              </div>
            ) : (
              <EventSuggestionCard
                date={todaySlots.date}
                suggestions={todaySlots.suggestions}
                rows={2}
                showDate={true}
                label={t('LABEL_FIRST_AVAILABLE_SLOTS')}
                onChange={onSelected}
                className="p-3 rounded"
              />
            )}
            <IonButton color="primary" fill="outline" size="small" className="button-xsmall mt-4" onClick={chooseSlot}>
              {t('ACTION_SHOW_OTHER_AVAILABILITIES')}
            </IonButton>
          </div>
        )}
      </div>
    </BotActionMessage>
  )
}
