const t = (v: any) => v

export const yupLocale = {
  mixed: {
    default: {
      key: t('ERR_VALIDATION_INVALID'),
    },
    required: {
      key: t('ERR_VALIDATION_REQUIRED'),
    },
    notType: ({ type }: any) => ({
      key: t('ERR_VALIDATION_INVALID_TYPE'),
      values: { type },
    }),
  },
  string: {
    email: {
      key: t('ERR_VALIDATION_EMAIL'),
    },
    min: ({ min }: any) => ({
      key: t('ERR_VALIDATION_STRING_MIN'),
      values: { min },
    }),
    max: ({ max }: any) => ({
      key: t('ERR_VALIDATION_STTRING_MAX'),
      values: { max },
    }),
  },
  number: {},
  boolean: {},
}
