import FormData from 'form-data'
import first from 'lodash/first'
import { RequestsFilterState } from 'src/apps/veterinary/forms/FormRequestsFilters'
import { Facility } from 'src/models'
import { ChatEvent } from 'src/models/events/ChatEvent'
import { RequestsFilters } from 'src/models/filters/RequestsFilters'
import { FilterState } from '../apps/veterinary/forms/FormDiaryFilters'
import { AllEventMask, BotEvent, Conversation, HealthEvent } from '../models/events'
import { EventCategory, EventStatus } from '../models/events/enums'
import { EventAdapter } from '../services/EventAdapter'
import { DateConverter } from '../utils'
import { axiosInstance } from './axios'
import {
  DefaultSearchOptions,
  getConversationsByQuery,
  getEventsByQuery,
  getVeterinaryRequestQuery,
  SearchOptionsParams,
  parseEventsToConversations,
  getVeterinaryMobileRequestQuery,
} from './eventApiUtilities'

export class EventsAPI {
  /**
   *
   * @param event
   * @param message
   */
  static async createChatConversation(event: HealthEvent, message?: string, sync = false): Promise<string> {
    const parentEventId = await EventsAPI.createEvent(event, true)

    if (message && message.length) {
      const messageEvent = new ChatEvent(
        { parent_id: parentEventId as string },
        {
          userId: event.payload.userId,
          customerId: event.payload.userId,
          facilityId: event.payload.id,
          message,
        }
      )

      await EventsAPI.createMessage(messageEvent, sync)
    }

    return parentEventId
  }

  static getInputEventPayload(form: AllEventMask, sync = false) {
    const formData = new FormData()

    if (form.parent_id) {
      formData.append('parent_id', form.parent_id)
    }

    formData.append('type', form.type)
    formData.append('category', form.category || EventCategory.EVENT_CATEGORY_NONE)
    formData.append('payload', JSON.stringify(form.jsonPayload()))

    if (form.file) {
      formData.append('image', form.file)
    }

    if (sync) {
      formData.append('sync', true)
    }

    return formData
  }

  /**
   *
   * @param opts
   * @param lastUpdatedDate
   */
  static async getLatestEvents(opts: any, lastUpdatedDate: Date): Promise<Conversation[]> {
    return await getConversationsByQuery({ ...opts, date_updated: { from: DateConverter.toServer(lastUpdatedDate) } }, DefaultSearchOptions)
  }

  /**
   *
   * @param facility
   * @param opts
   */
  static async getRequests(
    facility: Facility,
    filters?: RequestsFilterState
    //opts: SearchOptionsParams = DefaultSearchOptions
  ): Promise<any[]> {
    //return await getConversationsByQuery(getVeterinaryRequestQuery(facility, filters), opts)

    let events: AllEventMask[] = []

    try {
      events = (await axiosInstance.post(`/v2/${facility.id}/requests/search`, getVeterinaryRequestQuery(facility, filters))).data
    } catch (error) {
      console.error(error)
    }

    return parseEventsToConversations(events)
  }

  static async getAllUserRequests(): Promise<Conversation[]> {
    let events: AllEventMask[] = []

    try {
      events = (await axiosInstance.get('/requests', { headers: { SkipCache: true } })).data
    } catch (error) {
      console.error(error)
    }

    return parseEventsToConversations(events)
  }

  static async getFilteredRequests(filters: RequestsFilters): Promise<Conversation[]> {
    let events: AllEventMask[] = []

    try {
      events = (await axiosInstance.post('/requests/search', getVeterinaryMobileRequestQuery(filters), { headers: { SkipCache: true } }))
        .data
    } catch (error) {
      console.error(error)
    }

    return parseEventsToConversations(events)
  }

  static async getSingleConversation(
    familyId: string,
    parentId: string
    //opts: SearchOptionsParams = DefaultSearchOptions
  ): Promise<Conversation> {
    //const conversations = await getConversationsByQuery({ _id: parentId }, opts, false, true)

    try {
      const events = (await axiosInstance.get(`/${familyId}/diary/${parentId}`)).data
      const conversations = parseEventsToConversations([events])
      return first(conversations) || Conversation.generateInvalid()
    } catch (error) {
      console.error(error)
      return Conversation.generateInvalid()
    }

    /*
     * Conversation invalid added in order to avoid nullable values
     */
  }

  static async getSingleConversationByRequestId(parentId: string): Promise<Conversation> {
    try {
      const events = (await axiosInstance.get(`/requests/${parentId}`)).data
      const conversations = parseEventsToConversations([events])
      return first(conversations) || Conversation.generateInvalid()
    } catch (error) {
      console.error(error)
      return Conversation.generateInvalid()
    }
  }

  static async getConversations(
    filters?: FilterState,
    opts: SearchOptionsParams = DefaultSearchOptions,
    message?: string,
    showChildren = true,
    skipChildren = false
  ): Promise<Conversation[]> {
    return await getConversationsByQuery(filters, opts, skipChildren, true, message, showChildren)
  }

  static getEvent(id: string): Promise<AllEventMask> {
    return axiosInstance.get(`/events/?id=${id}`).then((response) => EventAdapter.from(response.data))
  }

  static async getEvents(query: any, opts: SearchOptionsParams = DefaultSearchOptions): Promise<AllEventMask[]> {
    return await getEventsByQuery(query, opts)
  }

  static deleteEvent(id: string): Promise<AllEventMask> {
    return axiosInstance.delete(`/events?id=${id}`)
  }

  static async deleteEventTree(parentId: string): Promise<boolean> {
    const childEvents = await EventsAPI.getEvents({ parent_id: parentId }, { perPage: 10000 })

    const promises = [
      EventsAPI.deleteEvent(parentId),
      ...childEvents.map((event) => (event.id ? EventsAPI.deleteEvent(event.id) : Promise.resolve())),
    ]

    return Promise.all(promises as any)
      .then(() => true)
      .catch(() => false)
  }

  static createEvent(data: AllEventMask, sync = false) {
    return axiosInstance
      .post('/events', EventsAPI.getInputEventPayload(data, sync), {
        headers: {
          'content-type': 'multipart/form-data',
          SkipCache: true,
        },
      })
      .then((response) => (sync ? response.data.id : null))
  }

  static async createMessage(data: ChatEvent, sync = false) {
    return axiosInstance
      .post('/events', EventsAPI.getInputEventPayload(data, sync), {
        headers: {
          'content-type': 'multipart/form-data',
          SkipCache: true,
        },
      })
      .then((response) => (sync ? response.data.id : null))
  }

  static updateEvent(data: AllEventMask, sync = false) {
    return axiosInstance.patch('/events', EventsAPI.getInputEventPayload(data, sync), {
      headers: {
        'content-type': 'multipart/form-data',
        SkipCache: true,
      },
      params: {
        id: data.id,
      },
    })
  }

  // static createBotEvent(eventToClone: AllEventMask, parentEventId: string, action: EventAction, category: EventCategory, sync = false) {
  //   const eventData = new BotEvent(
  //     { ...eventToClone, parent_id: parentEventId, category },
  //     {
  //       ...eventToClone.payload,
  //       date: DateConverter.now(),
  //       eventAction: action,
  //     }
  //   )

  //   return axiosInstance
  //     .post('/events', EventsAPI.getInputEventPayload(eventData, sync), {
  //       headers: {
  //         'content-type': 'multipart/form-data',
  //       },
  //     })
  //     .then((response) => (sync ? response.data.id : null))
  // }

  static createBotEvent(event: BotEvent, sync = false) {
    return axiosInstance
      .post('/events', EventsAPI.getInputEventPayload(event, sync), {
        headers: {
          'content-type': 'multipart/form-data',
          SkipCache: true,
        },
      })
      .then((response) => (sync ? response.data.id : null))
  }

  /*
  static closeEvent(event: AllEventMask) {
    console.log('Evento')
    event.payload.status = EventStatus.STATUS_CLOSED
    delete event.payload.payment
    event.payload.closeDate = new Date() 
    //FIX veloce

    return axiosInstance.patch('/events/close', EventsAPI.getInputEventPayload(event), {
      headers: {
        'content-type': 'multipart/form-data',
        SkipCache: true,
      },
      params: {
        id: event.id,
      },
    })
  }
  */

  //TODO da andare aprendere la versione aggiornata
  static closeEvent(event: AllEventMask) {
    event.payload.status = EventStatus.STATUS_CLOSED
    delete event.payload.payment
    event.payload.closeDate = new Date()
    if (event.payload.videomeeting) {
      event.payload.videomeeting.status = EventStatus.STATUS_CLOSED
    }

    //FIX veloce

    return axiosInstance.patch('/events/close', EventsAPI.getInputEventPayload(event), {
      params: {
        id: event.id,
      },
    })
  }
}
