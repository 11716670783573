import add from 'date-fns/add'
import { FacilitiesAPI } from 'src/api'
import { CalendarAPI } from 'src/api/CalendarAPI'
import { EventsAPI } from 'src/api/EventsAPI'
import { Facility, Pet, User } from 'src/models'
import { FacilityService } from 'src/models/FacilityService'
import { AllEventMask, CalendarEvent, HealthEvent } from 'src/models/events'
import { CalendarEventStatus, EventCategory, EventPaymentPaymentType, EventPaymentStatus, EventStatus } from 'src/models/events/enums'
import { DateConverter, safeArray } from 'src/utils'
import { HealthEventAppointmentProps } from './HealthAppointmentBody'

export const useHealthEventGenerator = () => ({ generateNewPastActivity, generateNewCurrentActivity, generateNewFutureActivity })

const getPetNameString = (ids: string[], pets?: Pet[]) =>
  safeArray(pets)
    .filter((pet) => ids.includes(pet.id))
    .map((pet) => pet.name)
    .join(', ')

/**
 * Generate a CLOSED HEALTH event in the PAST
 *
 */
const generateNewPastActivity = async (
  client: User | null,
  currentUser: User,
  currentFacility: Facility,
  services: FacilityService[],
  data: HealthEventAppointmentProps,
  event?: AllEventMask | null
) => {
  const familyId = client?.FamilyId || ''

  const dataServices = services.filter((s) => data.services.includes(s.id))

  const payload = {
    userId: currentUser?.id,
    customerId: client?.id,
    familyId: familyId,
    facilityId: currentFacility.id,
    assigneeId: currentUser.id,
    date: data.activityTime.date,
    services: data.services,
    pets: data.pets,
    duration: data.duration,
    openDate: data.activityTime.date,
    closeDate: add(data.activityTime.date, { minutes: parseInt(`${data.duration}`) }),
    payment: {
      ...data.payment,
      status: EventPaymentStatus.PAYMENT_STATUS_PAID,
    },
    priority: data.priority,
    status: EventStatus.STATUS_CLOSED,
  }

  const healthEvent = new HealthEvent(data as any, payload as any)
  const calendarEvent = new CalendarEvent(
    {
      category: EventCategory.EVENT_CATEGORY_CALENDAR_BOOKING,
    },
    {
      userId: currentUser.id,
      customerId: client?.id,
      familyId: familyId,
      facilityId: currentFacility.id,
      date: data.activityTime.date,
      event: {
        title: `${client?.safeName} > ${getPetNameString(data.pets, client?.pets)}`,
        subtitle: dataServices.map((s) => s.name).join(', '),
        start: data.activityTime.date,
        end: add(data.activityTime.date, { minutes: parseInt(`${data.duration}`) }),
        resource: currentUser.id, // VET ID
        allDay: false,
      },
      valid: true,
    }
  )

  const parentEventId = await EventsAPI.createEvent(healthEvent, true)

  await CalendarAPI.addBookingEvent(parentEventId, calendarEvent)

  return parentEventId
}

const generateNewCurrentActivity = async (
  client: User | null,
  currentUser: User,
  currentFacility: Facility,
  services: FacilityService[],
  data: HealthEventAppointmentProps,
  event?: AllEventMask | null
) => {
  const familyId = client?.FamilyId || ''

  const now = DateConverter.now()
  const dataServices = services.filter((s) => data.services.includes(s.id))

  const payload = {
    userId: currentUser?.id,
    customerId: client?.id,
    familyId: familyId,
    facilityId: currentFacility.id,
    assigneeId: currentUser.id,
    date: now,
    services: data.services,
    pets: data.pets,
    duration: data.duration,
    openDate: null,
    closeDate: null,
    payment: {
      ...data.payment,
      status: EventPaymentStatus.PAYMENT_STATUS_UNKNOWN,
    },
    priority: data.priority,
    status: EventStatus.STATUS_NEW,
  }

  const healthEvent = new HealthEvent(data as any, payload as any)
  const calendarEvent = new CalendarEvent(
    {
      category: EventCategory.EVENT_CATEGORY_CALENDAR_BOOKING,
    },
    {
      userId: currentUser.id,
      customerId: client?.id,
      familyId: familyId,
      facilityId: currentFacility.id,
      date: add(now, { seconds: 2 }), // adds 2 seconds in order to have a clear sort by
      event: {
        title: `${client?.safeName} > ${getPetNameString(data.pets, client?.pets)}`,
        subtitle: dataServices.map((s) => s.name).join(', '),
        start: now,
        end: add(now, { minutes: parseInt(`${data.duration}`) }),
        resource: currentUser.id, // VET ID
        allDay: false,
      },
      valid: true,
    }
  )

  const parentEventId = await EventsAPI.createEvent(healthEvent, true)

  await CalendarAPI.addBookingEvent(parentEventId, calendarEvent)

  return parentEventId
}

const generateNewFutureActivity = async (
  client: User | null,
  currentUser: User,
  currentFacility: Facility,
  services: FacilityService[],
  data: HealthEventAppointmentProps,
  event?: AllEventMask | null
) => {
  const familyId = client?.FamilyId || ''
  const now = DateConverter.now()
  const date = data.activityTime.date
  const dataServices = services.filter((s) => data.services.includes(s.id))

  const sendUpfrontPayment = data.payment.status === EventPaymentStatus.PAYMENT_STATUS_PENDING

  if (data.activityTime.type == 'auto') {
    data.payment.status = EventPaymentStatus.PAYMENT_STATUS_FREE
  }

  const payload = {
    userId: currentUser?.id,
    familyId: familyId,
    customerId: client?.id,
    facilityId: currentFacility.id,
    assigneeId: currentUser.id,
    date: data.activityTime.type == 'auto' ? now : date,
    creationDate: now,
    services: data.services,
    pets: data.pets,
    duration: data.duration,
    openDate: null,
    closeDate: null,
    payment: {
      ...data.payment,
      ...(data.payment?.status == null ? { status: EventPaymentStatus.PAYMENT_STATUS_UNKNOWN } : {}),
    },
    priority: data.priority,
    status: EventStatus.STATUS_NEW,
  }

  const healthEvent = new HealthEvent(data as any, payload as any)
  const calendarEvent = new CalendarEvent(
    {
      category: EventCategory.EVENT_CATEGORY_CALENDAR_BOOKING,
    },
    {
      userId: currentUser.id,
      customerId: client?.id,
      familyId: familyId,
      facilityId: currentFacility.id,
      date: add(now, { seconds: 2 }), // adds 2 seconds in order to have a clear sort by
      event: {
        title: `${client?.safeName} > ${getPetNameString(data.pets, client?.pets)}`,
        subtitle: dataServices.map((s) => s.name).join(', '),
        start: data.activityTime.type === 'auto' ? null : date,
        end: data.activityTime.type === 'auto' ? null : add(date, { minutes: parseInt(`${data.duration}`) }),
        resource: currentUser.id, // VET ID
        allDay: false,
      },
      valid: true,
      ...(sendUpfrontPayment ? { status: CalendarEventStatus.CALENDAR_EVENT_STATUS_TO_BE_CONFIRMED } : {}),
    }
  )

  calendarEvent.payload.status = CalendarEventStatus.CALENDAR_EVENT_STATUS_NEW

  //se vet ha proposto data
  if (sendUpfrontPayment) {
    calendarEvent.payload.status = CalendarEventStatus.CALENDAR_EVENT_STATUS_TO_BE_CONFIRMED
  }

  //se po può prenotare in autonomia
  if (data.activityTime.type == 'auto') {
    calendarEvent.payload.status = CalendarEventStatus.CALENDAR_EVENT_STATUS_TO_BE_BOOKED
  }

  const parentEventId = await EventsAPI.createEvent(healthEvent, true)

  await CalendarAPI.addBookingEvent(parentEventId, calendarEvent)

  if (sendUpfrontPayment) {
    await FacilitiesAPI.createPaymentIntent(
      currentFacility.OrganizationId,
      familyId,
      parentEventId,
      payload.payment?.amount,
      payload.payment?.type,
      payload.payment?.amount,
      EventPaymentPaymentType.UPFRONT
    )
  }

  return parentEventId
}
