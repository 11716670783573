import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useCurrentCall } from 'src/hooks/useCurrentValues'
import { IconVideoOn } from '../icons'

export const CallBanner: React.FC<{ className?: string; showTimer?: boolean; onClick: () => void }> = ({
  className = '',
  showTimer = true,
  onClick,
}) => {
  const { event } = useCurrentCall()
  const { t } = useTranslation()
  const startTime = event?.payload?.openDate

  const [hours, setHours] = useState<string>()
  const [minutes, setMinutes] = useState<string>()
  const [seconds, setSeconds] = useState<string>()

  const getTime = (start: Date) => {
    const endTime = Date.parse(start.toISOString()) + event?.payload?.duration * 60 * 1000
    const time = endTime - Date.now()

    let seconds = Math.floor((time / 1000) % 60)
    let minutes = Math.floor((time / 1000 / 60) % 60)
    let hours = Math.floor((time / (1000 * 60 * 60)) % 24)

    if (!(seconds > 0 || minutes > 0 || hours > 0)) {
      seconds = 0
      minutes = 0
      hours = 0
    }

    setHours(('0' + hours).slice(-2))
    setMinutes(('0' + minutes).slice(-2))
    setSeconds(('0' + seconds).slice(-2))
  }

  useEffect(() => {
    if (!startTime) return

    const interval = setInterval(() => getTime(startTime), 1000)

    return () => clearInterval(interval)
  }, [startTime])

  return (
    <div className={className} onClick={onClick}>
      <div className="flex text-white animate-pulse justify-between">
        <div className="flex items-center space-x-2">
          <IconVideoOn className="w-6 h-6" />
          <span className="text-body-small">{t('MESSAGE_CLICK_HERE_TO_RETURN_TO_CALL')}</span>
        </div>
        {showTimer && !!hours && !!minutes && !!seconds && (
          <div className="mr-2">
            -{hours !== '00' && `${hours}:`}
            {minutes}:{seconds}
          </div>
        )}
      </div>
    </div>
  )
}
