import isPlainObject from 'lodash/isPlainObject'
import { Pet } from '../../../models'
import { EventStatus } from '../enums'
import { BaseEventPayload, BaseEventPayloadInterface } from './BaseEventPayload'

export type DiaryEventPayloadInterface = BaseEventPayloadInterface & {
  familyId: string
  facilityId: string
  pets: string[] | Pet[]
  message?: string
  status?: string
}

export class DiaryEventPayload extends BaseEventPayload {
  familyId: string
  facilityId: string
  pets: string[] | Pet[]
  message: string
  status: string

  constructor(payload: DiaryEventPayloadInterface) {
    super(payload)
    this.familyId = payload.familyId
    this.facilityId = payload.facilityId || ''
    this.pets = payload.pets
    this.message = payload.message || ''
    this.status = payload.status || ''
  }

  get petIds() {
    return this.pets.map((pet: any) => (isPlainObject(pet) ? pet.id : pet)) || []
  }

  json(): any {
    return {
      ...super.json(),
      familyId: this.familyId,
      facilityId: this.facilityId,
      pets: this.petIds,
      message: this.message || '',
      status: EventStatus.STATUS_CLOSED, // added for search usage
    }
  }
}
