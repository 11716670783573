import React from 'react'
import { IonSlides } from '@ionic/react'
import './slider.css'

export const Slider: React.FC<{ title: string; className?: string; children: any; sliderKey: any; actions?: any }> = ({
  title,
  className = '',
  children,
  sliderKey,
  actions,
}) => (
  <section className={`home__my-pets ${className}`}>
    <div className="pl-6 mb-6 flex items-center">
      <h1 className="mb-0">{title}</h1>
      {actions}
    </div>
    <IonSlides key={sliderKey} options={{ slidesPerView: 'auto', zoom: false, grabCursor: true, spaceBetween: 20 }} className="pl-6">
      {children}
    </IonSlides>
  </section>
)
