import cloneDeep from 'lodash/cloneDeep'
import { DateConverter } from '../../utils'
import { EnrichedEventData } from './EnrichedEventData'
import { EventCategory, EventStatus, EventType } from './enums'
import { BaseEventPayload } from './payload/BaseEventPayload'

export interface BaseEventInterface {
  _id?: string
  parent_id?: string
  type?: EventType
  category?: EventCategory
  seq?: number
  file?: File | null
  date_created?: string
  date_updated?: string
}

export class BaseEvent<T> extends EnrichedEventData {
  id?: string
  parent_id: string | null
  type?: EventType
  category?: EventCategory
  seq?: number
  file?: File | null
  dateCreated: Date
  dateUpdated: Date | null

  payload: any

  constructor(data: BaseEventInterface, payload: T) {
    super()
    this.id = data._id || ''
    this.parent_id = data.parent_id || null
    this.type = data.type || EventType.EVENT_TYPE_HEALTH
    this.category = data.category || EventCategory.EVENT_CATEGORY_NONE
    this.seq = data.seq || -1
    this.payload = this.parsePayload(payload)
    this.file = data.file || null

    this.dateCreated = data.date_created ? DateConverter.fromServer(data.date_created) : DateConverter.now()
    this.dateUpdated = data.date_updated ? DateConverter.fromServer(data.date_updated) : null
  }

  /**
   * Methods that will be used to override the payload generation
   *
   * @param {any} payload
   */
  parsePayload(payload: any) {
    return new BaseEventPayload(payload)
  }

  /**
   *
   */
  json() {
    return {
      ...(this.parent_id ? { parent_id: this.parent_id } : null),
      type: this.type,
      category: this.category || EventCategory.EVENT_CATEGORY_NONE,
      payload: this.jsonPayload(),
      ...(this.file ? { image: this.file } : null),
    }
  }

  jsonPayload() {
    return this.payload.json()
  }

  get FileUrl() {
    if (!this.payload.file) {
      return null
    }

    if (this.payload.filetype === 'image-jpeg') {
      return `${process.env.REACT_APP_FILES_PATH}/${this.payload.file}?width=1200&format=png`
    }

    if (this.payload.filetype === 'application-pdf') {
      return `${process.env.REACT_APP_FILES_PATH}/${this.payload.file}`
    }

    return `${process.env.REACT_APP_FILES_PATH}/${this.payload.file}?width=1200&format=png`
  }

  stringify() {
    return JSON.stringify(
      {
        _id: this.id,
        parent_id: this.parent_id || null,
        ...this.json(),
      },
      null,
      4
    )
  }

  isClosed() {
    return this.payload.status === EventStatus.STATUS_CLOSED
  }

  public static clone<T>(source: T): T {
    return cloneDeep(source)
  }
}
