import parse from 'html-react-parser'
import React from 'react'
import { DateConverter, formatDate } from '../../utils'
import { BackgroundLogo, LogoThemesEnum } from '../icons/Logos'

type BotMessageProps = {
  className?: string
  message: string
  actions?: any
  date?: Date
}

export const BotActionMessage: React.FC<BotMessageProps> = ({ date, className, message, children, actions = null }) => {
  const time = formatDate(date || DateConverter.now(), 'HH:mm')

  return (
    <>
      <div className={`flex space-x-2 ml-2 ${className || ''}`}>
        <div className="h-12 w-12 shadow-light-40 rounded-full flex items-center justify-center bg-white flex-shrink-0 p-2">
          <BackgroundLogo theme={LogoThemesEnum.DARK} />
        </div>
        <div className="flex-grow">
          <div className="text-grey-600 text-body-tiny mb-1">{time}</div>
          <div className="whitespace-pre-line shadow-light-40 rounded rounded-tl-none p-4 bg-primary-200 mb-4">{parse(message)}</div>
          {children}
        </div>
      </div>
      {actions}
    </>
  )
}
