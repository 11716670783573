import React from 'react'
import { useTranslation } from 'react-i18next'
import { CallAPI } from 'src/api/CallAPI'
import { EventsAPI } from 'src/api/EventsAPI'
import { useCurrentValues } from 'src/hooks/useCurrentValues'
import { EventStatus } from 'src/models/events/enums'
import { PaymentEventPayload } from 'src/models/events/payload/PaymentEventPayload'
import { IonButton } from '@ionic/react'
import { DateConverter } from '../../../utils'
import { BotActionMessage } from '../BotActionMessage'
import { BotMessageProps } from './constants'

export const BotOpenConversation: React.FC<BotMessageProps> = ({ event, onSuccess }) => {
  const { t } = useTranslation()
  const { currentUser, currentFacility } = useCurrentValues()

  const payment: PaymentEventPayload = event.payload.payment

  const showAction = !payment.isPending() && !payment.hasToBePaid()

  //TODO: mettere in comune con action Avvia dell'header
  const onChatOpen = async () => {
    event.payload.status = EventStatus.STATUS_OPEN
    event.payload.assigneeId = currentUser.id
    event.payload.openDate = DateConverter.now()

    await EventsAPI.updateEvent(event)

    const idServices = event.payload.services?.map((item: any) => item.id)
    const isVideoConsultation = await CallAPI.isVideomeeting(idServices, currentFacility.id)

    if (isVideoConsultation.isVideoService && event.id) {
      await CallAPI.requestVideomeeting(event.id)
    }

    onSuccess && onSuccess(event)
  }

  return showAction ? (
    <BotActionMessage date={event.payload.date} message={t('BOT_OPEN_CHAT_MESSAGE')}>
      <div>
        <IonButton color="tertiary" onClick={onChatOpen}>
          <span className="text-white text-h100">{t('BOT_OPEN_CHAT_START')}</span>
        </IonButton>
      </div>
    </BotActionMessage>
  ) : (
    <></>
  )
}
