import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import translationEN from '../locales/en.json'
import translationIT from '../locales/it.json'

const resources = {
  it: { translation: translationIT },
  en: { translation: translationEN },
}

i18n.use(initReactI18next).init({
  resources,
  lng: 'en',
})

export default i18n
