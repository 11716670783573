import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { EventsAPI } from 'src/api/EventsAPI'
import { IconClose } from 'src/components/icons'
import { useCurrentFamily, useCurrentValues } from 'src/hooks/useCurrentValues'
import { useToast } from 'src/hooks/useToast'
import { DiaryEvent } from 'src/models/events'
import { IonButton, IonButtons, IonContent, IonPage } from '@ionic/react'
import { NavHeader } from '../components/Header/NavHeader'
import { FormNewDiaryEvent } from '../forms/FormNewDiaryEvent'

type PageAddPetProps = {
  onDismiss: () => void
}
export const PageAddDiaryEvent: FC<PageAddPetProps> = ({ onDismiss }) => {
  const { currentFamily, currentFacility } = useCurrentValues()
  const { pets } = useCurrentFamily()
  const { t } = useTranslation()
  const toast = useToast()
  const [loading, setLoading] = useState(false)

  const onSubmit = async (event: DiaryEvent) => {
    setLoading(true)
    event.payload.facilityId = currentFacility.id

    try {
      await EventsAPI.createEvent(event)
      toast.success(t('EVENT_CREATED_SUCCESSFULLY'))
      onDismiss()
    } catch ({ message }) {
      toast.error(t(message as string))
    } finally {
      setLoading(false)
    }
  }

  return (
    <IonPage>
      <NavHeader title={t('TITLE_NEW_EVENT')} showBackButton={false}>
        <IonButtons slot="end">
          <IonButton className="px-3 link" fill="clear" onClick={onDismiss}>
            <IconClose className="w-6 h-6" />
          </IonButton>
        </IonButtons>
      </NavHeader>
      <IonContent>
        <FormNewDiaryEvent onSubmit={onSubmit} className="py-6" loading={loading} familyId={currentFamily?.id} pets={pets} />
      </IonContent>
    </IonPage>
  )
}
