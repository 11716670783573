import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { DropdownTransition } from 'src/components/Dropdown'
import { authThunks } from 'src/features/Auth/store/thunks'
import { useCurrentFamily } from 'src/hooks/useCurrentValues'
import { useModal } from 'src/hooks/useModal'
import { Facility } from 'src/models'
import { Notification } from 'src/models/events/Notification'
import { EventCategory, EventNotificationAction } from 'src/models/events/enums'
import { Menu, Transition } from '@headlessui/react'
import { IonButton } from '@ionic/react'
import { ModalPendingPayments } from '../pages/ModalPendingPayments'
import { AppRouterHelper } from '../services/AppRouterHelper'

type FacilityCardActionsProps = {
  facility: Facility
  notification?: Notification
  showPendingPayments: boolean
}

const OtherButton: React.FC<{ contactFacility: () => void; setAppointment: () => void }> = ({ contactFacility, setAppointment }) => {
  const { t } = useTranslation()

  return (
    <Menu as="div">
      {({ open }) => (
        <>
          <Menu.Button>
            <IonButton size="small" fill="outline" className="flex-1">
              {t('LABEL_OTHER')}
            </IonButton>
          </Menu.Button>
          <Transition show={open} {...DropdownTransition} as={Fragment}>
            <Menu.Items static className="absolute right-4 bottom-16 z-50 w-64">
              <div className="bg-white rounded-md shadow-lg border border-grey-200 border-solid py-1 pl-6">
                <Menu.Item>
                  <div className="cursor-pointer flex w-full items-center justify-between py-2 pr-2" onClick={contactFacility}>
                    <span className="text-body-medium text-primary-500">{t('ACTION_CONTACT_US')}</span>
                  </div>
                </Menu.Item>
                <div className="h-px bg-grey-200" />
                <Menu.Item>
                  <div className="cursor-pointer flex w-full items-center justify-between py-2 pr-2" onClick={setAppointment}>
                    <span className="text-body-medium text-primary-500">{t('ACTION_SET_AN_APPOINTMENT')}</span>
                  </div>
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  )
}

export const FacilityCardActions: React.FC<FacilityCardActionsProps> = ({ facility, notification }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const { showModal, hideModal } = useModal()
  const dispatch = useDispatch()
  const { pets } = useCurrentFamily()

  const openPendingPaymentsModal = () => {
    showModal(
      <ModalPendingPayments
        facility={facility}
        fromEvent={false}
        onDismiss={async () => {
          await dispatch(authThunks.refreshUser())
          hideModal()
        }}
      />
    )
  }

  const contactFacility = (facility: Facility, category: EventCategory, pets: any) => {
    let step = 2
    if (pets.length == 1) {
      step = 3
    }

    history.push(AppRouterHelper.ROUTES.ADD_PERFORMANCE, { facility, category, step: step })
  }

  const switchAction = (type: EventNotificationAction) => {
    if (type === EventNotificationAction.EVENT_TYPE_PAYMENT) {
      openPendingPaymentsModal()
    } else {
      goToChat()
    }
  }

  const goToChat = () => {
    notification?.id && history.push(`/app/chat/${notification.id}`)
  }

  return (
    <div className="flex flex-row justify-between space-x-3 mt-6 px-4">
      {!notification ? (
        <>
          <IonButton
            size="small"
            fill="outline"
            className="flex-1"
            onClick={() => contactFacility(facility, EventCategory.EVENT_CATEGORY_HEALTH_CONSULTATION, pets)}
          >
            {t('ACTION_CONTACT_US')}
          </IonButton>
          <IonButton
            size="small"
            className="flex-1"
            onClick={() => contactFacility(facility, EventCategory.EVENT_CATEGORY_HEALTH_VISIT, pets)}
          >
            {t('ACTION_SET_AN_APPOINTMENT')}
          </IonButton>
        </>
      ) : (
        <>
          <div className="w-16">
            <IonButton size="small" fill="solid" className="flex-1" onClick={() => switchAction(notification.action)}>
              {t(`ACTION_FACILITY_CARD_${notification.action}`)}
            </IonButton>
          </div>
          <OtherButton
            contactFacility={() => contactFacility(facility, EventCategory.EVENT_CATEGORY_HEALTH_CONSULTATION, pets)}
            setAppointment={() => contactFacility(facility, EventCategory.EVENT_CATEGORY_HEALTH_VISIT, pets)}
          />
        </>
      )}
    </div>
  )
}
