import currency from 'currency.js'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ChildEventMask, isHealthEvent } from 'src/models/events'
import { EventPaymentStatus, EventStatus } from 'src/models/events/enums'
import { HealthEventPayload } from 'src/models/events/payload/HealthEventPayload'
import { Tag, TagVariant } from '../../Tag'
import { EventCardMode } from '../EventCard'

export const EventCardAction: React.FC<{ event: ChildEventMask; mode: EventCardMode }> = ({ event }) => {
  const { t } = useTranslation()
  const { payload } = event
  const { status, payment } = payload as HealthEventPayload

  if (!isHealthEvent(event)) {
    return null
  }

  if (status === EventStatus.STATUS_NEW) {
    return null
  }

  if (status === EventStatus.STATUS_CLOSED) {
    if (payment.status === EventPaymentStatus.PAYMENT_STATUS_PENDING) {
      return (
        <Tag variant={TagVariant.TAG_FILL_WARNING}>
          {t('EVENT_PAYMENT_STATUS_PENDING')} € {currency(payment.amount || 0).toString()}
        </Tag>
      )
    }

    if (payment.status === EventPaymentStatus.PAYMENT_STATUS_PAID) {
      return (
        <Tag variant={TagVariant.TAG_FILL_SUCCESS}>
          {t('EVENT_PAYMENT_STATUS_PAID_VET')} $ {currency(payment.amount || 0).toString()}
        </Tag>
      )
    }

    return <Tag variant={TagVariant.TAG_OUTLINE_NEUTRAL}>{t('EVENT_LABEL_TERMINATED')}</Tag>
  }

  if (status === EventStatus.STATUS_OPEN) {
    return <Tag variant={TagVariant.TAG_FILL_TERTIARY}>{t('EVENT_LABEL_IN_PROGRESS')}</Tag>
  }

  return null
}
