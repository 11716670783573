import React from 'react'
import { GenericProps } from '../../helpers/props'

type EventSectionProps = GenericProps & {
  empty?: boolean
  dotted?: boolean
}

type EventSectionTitleProps = EventSectionProps & {
  title: string
}

export const EventSection: React.FC<EventSectionProps> = ({ empty = true, className = '', dotted = false, children }) => (
  <section
    className={`events_section events_section--${empty ? 'empty' : 'full'} ${
      dotted && 'events_section--dotted'
    } flex flex-col pl-6 pt-6 w-full min-w-0 space-y-4 ${className}`}
  >
    {children}
  </section>
)

export const EventSectionTitle: React.FC<EventSectionTitleProps> = ({ title }) => (
  <p className="text-body-large text-grey-600 uppercase font-bold pl-4 mb-2">{title}</p>
)
