import React from 'react'
import { IonCard } from '@ionic/react'

type CardProps = {
  children: React.ReactElement[] | React.ReactNode
  className?: string
  contentClass?: string
  [key: string]: unknown
}

export const SquaredCard: React.FC<CardProps> = ({ children, className = '', contentClass = '', ...props }) => (
  <IonCard
    style={{ paddingTop: '100%' }}
    className={`w-full rounded-lg border border-grey-200 shadow-none m-0 relative ${className}`}
    {...props}
  >
    <div className="absolute h-full w-full top-0 left-0">
      <div className={`h-full w-full ${contentClass}`}>{children}</div>
    </div>
  </IonCard>
)
