import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { IconClose } from 'src/components/icons'
import { callThunks } from 'src/features/Call/thunks'
import { useCurrentCall } from 'src/hooks/useCurrentValues'
import { usePopupModal } from 'src/hooks/usePopupModal'
import { DailyEventObjectWaitingParticipant, DailyWaitingParticipant } from '@daily-co/daily-js'
import { useWaitingParticipants } from '@daily-co/daily-react'
import { IonButton } from '@ionic/react'

export const AccessModal = () => {
  const dispatch = useDispatch()
  const { showModal, hideModal } = usePopupModal()
  const { t } = useTranslation()
  const { startTime } = useCurrentCall()

  const openModal = (ev: DailyEventObjectWaitingParticipant) => {
    showWaitingParticipantModal(ev.participant)
  }

  const waitingParticipants = useWaitingParticipants({ onWaitingParticipantAdded: (ev) => openModal(ev) })

  const grantAccess = (participant: DailyWaitingParticipant) => {
    waitingParticipants.grantAccess(participant.id)
    !startTime && dispatch(callThunks.setStartTime(new Date()))
    hideModal()
  }

  const denyAccess = (participant: DailyWaitingParticipant) => {
    waitingParticipants.denyAccess(participant.id)
    hideModal()
  }

  const showWaitingParticipantModal = (participant: DailyWaitingParticipant) => {
    showModal(
      <>
        <div className="pl-4 pr-4 pt-2 flex justify-between items-center">
          <div className="text-h300 font-bold">{t('TITLE_WAITING_PARTICIPANT')}</div>
          <div onClick={() => hideModal()}>
            <IconClose className="w-6 h-6" />
          </div>
        </div>
        <div className="w-full mt-8 mb-4 pr-2 h-3/5 flex flex-col items-start pl-4 text-body-medium overflow-y-scroll">
          {t('DESCRIPTION_PARTICIPANT_IS_WAITING', { name: participant.name })}
        </div>
        <div className="w-full flex justify-between border-t border-solid border-grey-200 py-2 px-2">
          <IonButton className="px-2 link" fill="clear" size="small" onClick={() => denyAccess(participant)}>
            {t('ACTION_DENY_ACCESS')}
          </IonButton>
          <IonButton size="small" onClick={() => grantAccess(participant)}>
            {t('ACTION_GRANT_ACCESS')}
          </IonButton>
        </div>
      </>
    )
  }

  return <></>
}
