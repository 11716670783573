import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Pet } from 'src/models'
import { EventCategory } from 'src/models/events/enums'
import { PetChip } from '../../PetChip'
import { BotActionMessage } from '../BotActionMessage'
import { BotMessageProps } from './constants'

type BotPetSelectionProps = BotMessageProps & {
  pets?: Pet[]
  petId?: string
  category?: EventCategory
  onSelectedPet?: (petId: string) => void
}

export const BotPetSelection: React.FC<BotPetSelectionProps> = ({ event, category, petId, onSelectedPet }) => {
  const { t } = useTranslation()
  const petList = event.Pets
  const [selectedPet, setSelectedPet] = useState<string>(petId || '')

  const onPetSelection = (id: string) => {
    setSelectedPet(id)
    onSelectedPet && onSelectedPet(id)
  }

  const message =
    category === EventCategory.EVENT_CATEGORY_HEALTH_VISIT
      ? 'BOT_CONVERSATION_VISIT_SELECT_PET'
      : 'BOT_CONVERSATION_CONSULTATION_SELECT_PET'

  return (
    <BotActionMessage date={event.payload.date} message={t(message)}>
      <div className="flex flex-wrap w-full">
        {petList.map((pet: Pet) => (
          <div key={pet.id} className="flex mb-2 mr-2">
            <PetChip pet={pet} active={selectedPet === pet.id} onClick={() => onPetSelection(pet.id)} />
          </div>
        ))}
      </div>
    </BotActionMessage>
  )
}
