import { format } from 'date-fns'
import { enUS } from 'date-fns/locale'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Pet } from 'src/models'
import { AllEventMask } from 'src/models/events'
import { ChatEvent } from 'src/models/events/ChatEvent'
import { EventCategory } from 'src/models/events/enums'
import { formatDate } from 'src/utils'
import { Avatar } from '../Avatar'
import { PetChip } from '../PetChip'

export const ChatEventPreview = ({ event, onClick }: { event: AllEventMask; onClick: any }) => {
  const { t } = useTranslation()
  const lastMessage: ChatEvent | undefined = event.LastMessage as ChatEvent
  const isNew = event.LastUserMessage?.isVet

  const onCardClick = () => {
    onClick(event)
  }

  return (
    <div className="cursor-pointer  py-2 mb-2 border-b border-solid border-grey-200 space-y-1" onClick={onCardClick}>
      <div className="flex justify-between text-grey-500 text-body-small relative">
        <span className="">{event.Facility?.name}</span>
        <span>{formatDate(lastMessage?.payload?.date, 'dd/MM/yyyy HH:mm')}</span>
        {isNew ? (
          <div className="absolute -bottom-6 right-0 rounded-full w-4 h-4 bg-primary-500 border border-solid border-white shadow-light-40" />
        ) : null}
      </div>
      <div className="flex items-center space-x-2">
        {event.Pets.map((pet: Pet) => (
          <PetChip key={pet.id} pet={pet} />
        ))}
        <div className="border border-grey-200 rounded text-body-tiny text-grey-600 px-2">#{event.seq}</div>
      </div>
      <div className="text-grey-600 py-2">{lastMessage?.payload?.message || ''}</div>

      {event.category === EventCategory.EVENT_CATEGORY_HEALTH_VISIT ||
      event.category === EventCategory.EVENT_CATEGORY_HEALTH_VIDEOCONSULTATION ? (
        event._enrichedData.LastMessage?.payload?.event?.start ? (
          <div className="flex justify-left text-body-tiny text-grey-500">
            {t('CHAT_EVENT_PREVIEW_BOOKED_AT', {
              date: formatDate(event._enrichedData.LastMessage?.payload.event.start, 'dd MMM yyyy HH:mm'),
            })}
          </div>
        ) : event._enrichedData.Appointment?.payload.event.start ? (
          <div className="flex justify-left text-body-tiny text-grey-500">
            {t('CHAT_EVENT_PREVIEW_BOOKED_AT', {
              date: format(new Date(event._enrichedData.Appointment?.payload.event.start), 'EEEE MMMM dd, yyyy - hh:mm a', {
                locale: enUS,
              }),
            })}
          </div>
        ) : (
          <div className="flex justify-left text-body-tiny text-grey-500">{t('CHAT_EVENT_PREVIEW_TO_BE_BOOKED')}</div>
        )
      ) : (
        <div className="flex justify-end text-body-tiny text-grey-500">
          {!isNew ? (
            <span>{t('EVENT_STATUS_WAITING')}</span>
          ) : (
            <div className="flex items-center">
              <span>{event.LastUserMessage?.safeName}</span>
              <Avatar title={event.LastUserMessage?.initials} image={event.LastUserMessage?.avatar} className="w-6 h-6 ml-2" />
            </div>
          )}
        </div>
      )}
    </div>
  )
}
