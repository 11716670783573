import React, { useState } from 'react'

const developmentMode = !!window.localStorage.getItem('__VET_DEBUG')

export const DebugDisplay: React.FC<{ children: any }> = ({ children }) => {
  const [show, setShowDebug] = useState<boolean>(false)

  const stopClick = (evt: any) => {
    evt.preventDefault()
    evt.stopPropagation()
  }

  const toggle = (evt: any) => {
    stopClick(evt)
    setShowDebug(!show)
  }

  return (
    <div className={developmentMode ? 'text-debug' : 'debug-display text-debug'}>
      <div
        className="absolute top-1 left-1 w-5 h-5 rounded-full cursor-pointer border border-solid border-tertiary-500 text-tertiary-500 bg-white hover:bg-tertiary-500 hover:text-white"
        onClick={toggle}
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="fill-current">
          <path d="M143.5 174.1c-11.2 4.2-16.5 16.7-11.6 27.5 1.5 3.3 7.8 9.2 31.4 29 16.3 13.7 29.6 25.1 29.6 25.4 0 .3-13.3 11.7-29.6 25.4-25 21.1-29.8 25.5-31.5 29.4-4.2 9.4-.3 20.9 8.7 25.6 4.9 2.5 13.6 2.7 18 .4 1.7-.9 20.6-16.5 42.2-34.8 42.1-35.6 43.3-36.9 43.3-46 0-9.1-1.2-10.4-43.3-46-21.6-18.3-40.5-33.8-42-34.6-3.8-2-11.5-2.6-15.2-1.3zM264.5 300.4c-9 4-14 14.3-11.5 23.7 1.5 5.6 8 12.3 13.5 13.8 2.7.7 19.8 1.1 51.5 1.1h47.5l5.2-2.4c15-7 15-28.9-.2-35.7-3.7-1.7-7.6-1.9-53.5-1.8-39 0-50.1.3-52.5 1.3z" />
        </svg>
      </div>
      {show ? (
        <pre className="cursor-text	z-50 bg-primary-100 p-6" onClick={stopClick}>
          {children}
        </pre>
      ) : null}
    </div>
  )
}
