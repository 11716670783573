import React, { forwardRef, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { PetChip } from 'src/components/PetChip'
import { IconPhoto } from 'src/components/icons'
import { ButtonInputUpload } from 'src/components/inputs/ButtonInputUpload'
import { InputDate } from 'src/components/inputs/InputDate'
import { InputTextArea } from 'src/components/inputs/InputTextArea'
import { SelectField } from 'src/components/inputs/SelectField'
import { useCurrentUser } from 'src/hooks/useCurrentValues'
import { Pet } from 'src/models'
import { DiaryEvent } from 'src/models/events/DiaryEvent'
import { usePetCategories } from 'src/models/events/constants'
import { EventCategory } from 'src/models/events/enums'
import { mixed, object, string } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { IonButton } from '@ionic/react'
import { DateConverter } from '../../../utils'

type FormCreateDiaryEventPropsType = {
  loading: boolean
  pets: Pet[]
  showAction?: boolean
  className?: string
  familyId?: string
  onSubmit: (event: DiaryEvent) => Promise<void>
  defaultValue?: DiaryEvent
}

export const FormNewDiaryEvent = forwardRef<HTMLFormElement, FormCreateDiaryEventPropsType>(
  ({ pets, familyId, loading, className, showAction = true, onSubmit, defaultValue = null }, ref) => {
    const { t } = useTranslation()
    const currentUser = useCurrentUser()
    const petCategories = usePetCategories()
    const [selectedPets, setSelectedPets] = useState<string[]>([])

    useEffect(() => {
      if (defaultValue) {
        setSelectedPets(defaultValue.Pets.map((pet: Pet) => pet.id) || [])
      }
    }, [])

    const toggleSelectedPets = (petId: string) => {
      if (selectedPets.includes(petId)) {
        setSelectedPets(selectedPets.filter((id) => id !== petId))
      } else {
        setSelectedPets([...selectedPets, petId])
      }
    }

    const validationSchema = object().shape({
      date: string().nullable(),
      category: string().required(),
      message: string().nullable(),
      file: mixed(),
    })

    const {
      register,
      handleSubmit,
      control,
      formState: { errors },
    } = useForm({
      mode: 'onChange',
      reValidateMode: 'onChange',
      resolver: yupResolver(validationSchema),
      defaultValues: {
        category: defaultValue?.category || '',
        message: defaultValue?.payload?.message,
        date: isNaN(DateConverter.from(defaultValue?.payload?.date || '').getTime())
          ? null
          : DateConverter.from(defaultValue?.payload?.date || '').toUTCString(),
        file: '',
      },
    })

    const onSubmitCallback = (values: any) => {
      const event = new DiaryEvent(
        { category: values.category as EventCategory, file: values.file ? values.file[0] : null },
        {
          userId: currentUser.id,
          familyId: familyId || '',
          facilityId: '',
          date: DateConverter.from(values.date),
          pets: selectedPets,
          message: values.message,
        }
      )
      onSubmit(event)
    }

    return (
      <form ref={ref} className={className || ''} onSubmit={handleSubmit(onSubmitCallback)}>
        <div className="text-body-large text-black">{t('LABEL_KIND_OF_PET')}</div>
        <div className="flex flex-row w-full flex-wrap pb-6">
          {pets.map((pet) => (
            <PetChip
              className="mt-2 mr-2"
              key={pet.id}
              pet={pet}
              active={selectedPets.includes(pet.id)}
              onClick={() => toggleSelectedPets(pet.id)}
            />
          ))}
        </div>
        <fieldset disabled={loading}>
          <Controller
            control={control}
            name="category"
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <SelectField
                value={value}
                onChange={onChange}
                label={t('LABEL_CATEGORY')}
                options={petCategories}
                placeholder={t('FIELD_TYPE_PLACEHOLDER')}
                error={error && error.message}
              />
            )}
          />
          <InputDate label={t('LABEL_DATE')} {...register('date')} error={errors.date?.message} />
          <InputTextArea label={t('LABEL_NOTE')} {...register('message')} error={errors.message?.message} />
          <div className="flex mb-6">
            <ButtonInputUpload
              size="small"
              fill="outline"
              className="button-xsmall flex items-center mr-2"
              defaultValue={defaultValue?.FileUrl}
              {...register('file')}
              error={errors.file?.message}
            >
              <IconPhoto className="w-4 h-4 mr-2" />
              {defaultValue?.FileUrl ? t('LABEL_EDIT_PHOTO') : t('LABEL_LOAD_PHOTO')}
            </ButtonInputUpload>
          </div>
          {showAction ? (
            <IonButton expand="block" type="submit">
              {t('ADD')}
            </IonButton>
          ) : null}
        </fieldset>
      </form>
    )
  }
)
