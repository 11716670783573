import xor from 'lodash/xor'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import DateTimeRangePicker from 'src/components/DateTimeRangePicker'
import { PetChip } from 'src/components/PetChip'
import { CheckboxGroupField } from 'src/components/inputs/CheckboxGroupField'
import { SearchField } from 'src/components/inputs/SearchField'
import { useCurrentValues } from 'src/hooks/useCurrentValues'
import { Pet } from 'src/models'
import { getEventPaymentStatuses, getEventStatuses, getEventTypes } from 'src/models/events/constants'
import { IonButton } from '@ionic/react'

type FormDiaryFiltersProps = {
  loading: boolean
  pets: Pet[]
  defaultValues?: FilterState
  showAction?: boolean
  className?: string
  familyId?: string
  onChange: (opts: any) => any
}

type periodType = {
  from: string
  to: string
}

export type FilterState = {
  pets: string[]
  types: string[]
  statuses: string[]
  payments: string[]
  message: string
  period: periodType
}

export const FilterStateDefaultValues = { pets: [], types: [], statuses: [], payments: [], message: '', period: { from: '', to: '' } }

export const FormDiaryFilters: React.FC<FormDiaryFiltersProps> = ({
  pets,
  className = '',
  onChange,
  defaultValues = FilterStateDefaultValues,
}) => {
  const { t } = useTranslation()
  const types = getEventTypes()
  const statuses = getEventStatuses()
  const { currentUser } = useCurrentValues()
  const paymentStatuses = getEventPaymentStatuses()

  const [state, setState] = useState<FilterState>(defaultValues)

  const togglePets = (value: string) => mergeState({ pets: xor(state.pets, [value]) })
  //   const totalActivities = types.length + statuses.length + paymentStatuses.length

  const filterSearch = (event: any) => {
    mergeState({ message: event.target[0].value })
    event.preventDefault()
  }

  const onReset = () => {
    mergeState({ message: '' })
  }

  const mergeState = (data: any) => {
    const newState = {
      ...state,
      ...data,
    }

    setState(newState)
    onChange(newState)
  }

  return (
    <section className={className}>
      <div className="mb-6">
        <div className="text-body-large text-black">{t('LABEL_FOR_WORD')}</div>
        <form className="flex space-x-2 items-center" onSubmit={filterSearch}>
          <SearchField
            autoComplete="off"
            className="mb-0 flex-grow"
            onChange={onReset}
            onIonChange={(e: any) => {
              const newState = {
                ...state,
                message: e.detail.value,
              }
              setState(newState)
              currentUser.isPetOwner ? onChange(newState) : null
            }}
            value={state.message}
            clearable={state.message.length > 0}
            placeholder={t('SEARCH_ACTIVITIES_PLACEHOLDER')}
          />
          {currentUser.isVet ? (
            <IonButton type="submit" size="small" fill="outline" className="mt-1">
              {t('TITLE_FILTER_EVENTS_SHORT')}
            </IonButton>
          ) : null}
        </form>
      </div>

      {/* <div className="text-body-large text-black">{t('LABEL_FOR_PERIOD')}</div>
      <DateTimeRangePicker
        onConfirm={(period) => {
          mergeState({ period })
        }}
        values={state.period}
      /> */}

      <div className="text-body-large text-black mb-2">{t('LABEL_FOR_PET')}</div>
      <div className="flex flex-col w-full space-y-1">
        {pets.map((pet) => (
          <div key={pet.id} className="flex">
            <PetChip pet={pet} active={state.pets.includes(pet.id)} onClick={() => togglePets(pet.id)} />
          </div>
        ))}
      </div>

      {currentUser.isVet ? (
        <CheckboxGroupField
          values={types}
          defaultValues={state.types}
          label={t('LABEL_FOR_TYPES')}
          onChange={(types) => mergeState({ types })}
          //className={filteredTypes.length === 0 ? 'hidden' : ''}
        />
      ) : null}

      <CheckboxGroupField
        values={paymentStatuses}
        defaultValues={state.payments}
        label={t('LABEL_FOR_PAYMENT_TYPE')}
        onChange={(payments) => mergeState({ payments })}
        //className={filteredPaymentStatuses.length === 0 ? 'hidden' : ''}
      />

      {currentUser.isVet ? (
        <CheckboxGroupField
          values={statuses}
          defaultValues={state.statuses}
          label={t('LABEL_FOR_STATUS')}
          onChange={(statuses) => mergeState({ statuses })}
          //className={filteredStatuses.length === 0 ? 'hidden' : ''}
        />
      ) : null}
    </section>
  )
}
