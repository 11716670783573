import { AllEventMask, ChildEventMask } from 'src/models/events'

export enum BotAction {
  CREATE_CONVERSATION = 'CREATE_CONVERSATION',
  OPEN_CHAT = 'OPEN_CHAT',
  COME_TO_FACILITY = 'COME_TO_FACILITY',
  ADD_ATTACHMENT = 'ADD_ATTACHMENT',
  JOIN_VIDEOMEETING = 'JOIN_VIDEOMEETING',
}

export type BotMessageProps = {
  event: AllEventMask
  name?: string
  onSuccess?: (event: AllEventMask, childEvents?: ChildEventMask[]) => void
  onReset?: () => void
}
