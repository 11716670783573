import sortBy from 'lodash/sortBy'
import { Facility } from '../models'
import { FacilityService } from '../models/FacilityService'
import { FacilityServiceCategory } from '../models/FacilityServiceCategory'
import { axiosInstance, getCacheConfig } from './axios'

export class FacilityServicesAPI {
  static async getServiceCategories(skipCache = false): Promise<FacilityServiceCategory[]> {
    return axiosInstance
      .get(`/services/category`, getCacheConfig(skipCache))
      .then((response) => sortBy(response.data.service_category || [], 'name').map((data: any) => new FacilityServiceCategory(data)))
      .catch(() => Promise.resolve([]))
  }

  static async getServices(facilityId: string, skipCache = false): Promise<FacilityService[]> {
    const categories = await FacilityServicesAPI.getServiceCategories()

    return axiosInstance
      .get(`/services/list`, {
        ...getCacheConfig(skipCache),
        params: {
          FacilityId: facilityId,
        },
      })
      .then((response) =>
        sortBy(response.data, 'name').map((data: any) => {
          data.category = categories.find((cat: any) => parseInt(cat.id) === parseInt(data.ServiceCategoryId))
          return new FacilityService(data)
        })
      )
  }

  static async getServicesForClient(facilityId: string, skipCache = false): Promise<FacilityService[]> {
    const categories = await FacilityServicesAPI.getServiceCategories()

    return axiosInstance
      .get(`/services/client/list`, {
        ...getCacheConfig(skipCache),
        params: {
          FacilityId: facilityId,
        },
      })
      .then((response) =>
        sortBy(response.data, 'name').map((data: any) => {
          data.category = categories.find((cat: any) => parseInt(cat.id) === parseInt(data.ServiceCategoryId))
          return new FacilityService(data)
        })
      )
  }

  static async getServicesForClientV2(facilityId: string, skipCache = false): Promise<FacilityService[]> {
    //const categories = await FacilityServicesAPI.getServiceCategories()

    return axiosInstance
      .get(`/services/client/list`, {
        ...getCacheConfig(skipCache),
        params: {
          FacilityId: facilityId,
        },
      })
      .then((response) => response.data)
  }

  static async getServicesByUser(userId: string, facilityId: string): Promise<any> {
    return axiosInstance.get(`/services/user/list`, {
      params: {
        UserId: userId,
        FacilityId: facilityId,
      },
    })
  }

  static async getMainServices(): Promise<any> {
    return axiosInstance.get(`/services/main`).then((response) => response.data.medicalServices)
  }

  static async create(facility: Facility, services: any[]): Promise<any> {
    return axiosInstance.post(`/services`, {
      services,
      FacilityId: facility.id,
    })
  }

  static async update(service: FacilityService): Promise<any> {
    return axiosInstance.patch(`/services?id=${service.id}`, service.json())
  }

  static async getDefaults(): Promise<any> {
    return axiosInstance.get('/services/defaults').then((data) => data.data)
  }

  static async setServices(facilityId: string, serviceIds: number[]): Promise<any> {
    const body = {
      facilityId,
      serviceIds,
    }

    return axiosInstance.patch('/services/defaults', body).then((data) => data.data)
  }

  static async updateServices(facilityId: string, serviceIds: number[]): Promise<any> {
    const body = {
      facilityId,
      serviceIds,
    }

    return axiosInstance.patch('/services/defaults', body).then((data) => data.data)
  }
}
