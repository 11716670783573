import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CurrentWorkingHours, TimeTableAPI } from 'src/api/TimeTableAPI'
import { Avatar } from 'src/components/Avatar'
import { Tag, TagVariant } from 'src/components/Tag'
import { useCurrentUser } from 'src/hooks/useCurrentValues'
import { Facility } from 'src/models'
import { Notification } from 'src/models/events/Notification'
import { EventNotificationAction } from 'src/models/events/enums'
import { IonCard } from '@ionic/react'
import { FacilityCardActions } from './FacilityCardActions'
import { FacilityCardNotification } from './FacilityCardNotification'

type FacilityCardProps = {
  facility: Facility
}

export const FacilityCard: React.FC<FacilityCardProps> = ({ facility }) => {
  const { t } = useTranslation()
  const currentUser = useCurrentUser()

  const facilityNotifications = currentUser.pendingEvents?.filter((event: any) => event.payload.facility.id === facility.id) || []

  const paymentNotifications = facilityNotifications.filter((event: any) => event.action == EventNotificationAction.EVENT_TYPE_PAYMENT)

  const createNotification = () => {
    if (facilityNotifications.length == 0) {
      return null
    }

    if (paymentNotifications.length > 0) {
      const amount = paymentNotifications
        .map((notification: any) => notification.payload?.payment.amount)
        .reduce((accumulator, currentValue) => accumulator + currentValue, 0)

      return new Notification({
        _id: '0',
        action: EventNotificationAction.EVENT_TYPE_PAYMENT,
        payload: { payment: { amount } },
      })
    }

    return new Notification(facilityNotifications[0])
  }

  const notification = createNotification()

  const [workingHours, setWorkingHours] = useState<CurrentWorkingHours>()

  const loadWorkingHours = async () => {
    const response = await TimeTableAPI.getCurrentWorkingHours(facility.id)
    setWorkingHours(response)
  }

  useEffect(() => {
    loadWorkingHours()
  }, [])

  return (
    <IonCard className="w-full rounded-lg border border-grey-200 shadow-none m-0 relative py-4 mb-5" key={facility.id}>
      <div className="flex flex-row justify-between space-x-4 px-4">
        <Avatar className="w-12 h-12 flex-shrink-0" image={facility.avatar} title={facility.initials} />
        <div className="flex-grow flex flex-col overflow-hidden">
          <h2 className="text-body-large font-bold text-black mb-2 truncate">{facility.name}</h2>
          {workingHours && (
            <div className="flex flex-row items-center justify-between">
              <div className="text-body-tiny text-grey-600">{workingHours.hours}</div>
              {workingHours.isOpen ? (
                <Tag variant={TagVariant.TAG_FILL_SUCCESS}>{t('LABEL_OPEN')}</Tag>
              ) : (
                <Tag variant={TagVariant.TAG_FILL_NEUTRAL}>{t('LABEL_CLOSED')}</Tag>
              )}
            </div>
          )}
        </div>
      </div>
      {notification && <FacilityCardNotification facility={facility} notification={notification} />}
      <FacilityCardActions facility={facility} notification={notification} showPendingPayments={paymentNotifications.length > 0} />
    </IonCard>
  )
}
