import { close } from 'ionicons/icons'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FacilitiesAPI } from 'src/api'
import { IconLink } from 'src/components/icons/IconLink'
import { InputField } from 'src/components/inputs/InputField'
import { useCurrentFamily, useCurrentUser } from 'src/hooks/useCurrentValues'
import { useToast } from 'src/hooks/useToast'
import { object, string } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { IonButton, IonButtons, IonContent, IonFooter, IonIcon, IonPage } from '@ionic/react'
import { NavHeader } from '../components/Header/NavHeader'

export const PageAddVeterinary: React.FC<{ onDismiss: () => void }> = ({ onDismiss }) => {
  const { t } = useTranslation()
  const currentUser = useCurrentUser()
  const { family } = useCurrentFamily()
  const [loading, setLoading] = useState(false)
  const toast = useToast()

  const validationSchema = object().shape({
    code: string().required(t('FIELD_VETERINARY_CODE_REQUIRED')),
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: yupResolver(validationSchema),
  })

  const onSubmit = async (payload: { code: string }) => {
    setLoading(true)
    try {
      await FacilitiesAPI.petOwnerJoinFacility(payload.code, currentUser, family)
      toast.success(t('FACILITY_ASSOCIATED_SUCCESSFULLY'))
      onDismiss()
    } catch ({ message }) {
      toast.error(t(message as string))
    } finally {
      setLoading(false)
    }
  }

  return (
    <IonPage>
      <NavHeader title={t('ADD_VETERINARY')} showBackButton={false}>
        <IonButtons slot="end">
          <IonButton className="px-3 link" fill="clear" onClick={onDismiss}>
            <IonIcon icon={close} />
          </IonButton>
        </IonButtons>
      </NavHeader>
      <IonContent>
        <form className="pb-6 pt-8">
          <fieldset disabled={loading}>
            <IconLink className="h-12 w-12 mx-auto" />
            <h1 className="mt-6">{t('TITLE_CONNECT_TO_VETERINARY')}</h1>
            <InputField
              {...register('code')}
              label={t('FORM_LABEL_VETERINARY_CODE')}
              placeholder="XXXX-XXXX"
              tooltip={t('TOOLTIP_VETERINARY_CODE')}
              error={errors.code?.message}
            />
          </fieldset>
        </form>

        <div className="items-center text-body-large">
          <div>
            <span>{t('ACTION_MISSING_CODE')}</span>
          </div>
          <div className="pt-1 blue-link font-bold">
            <a
              href="https://7anmevw3l5c.typeform.com/to/yhqzzeRh?utm_source=app&utm_medium=referral&utm_campaign=segnala_vet&utm_content=VET50"
              target="_blank"
              rel="noreferrer"
            >
              {t('ACTION_INVITE_VET')}
            </a>
          </div>
        </div>
      </IonContent>
      <IonFooter className="p-6 pt-0 ion-no-border">
        <IonButton expand="block" onClick={handleSubmit(onSubmit)} className="">
          {t('FORM_ACTION_PROCEED')}
        </IonButton>
      </IonFooter>
    </IonPage>
  )
}
