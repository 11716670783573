import React from 'react'
import { isHealthEvent, isVideoconsultationEvent } from 'src/models/events'
import { EventPriority } from 'src/models/events/enums'
import { ConvertEventToCard } from 'src/services/EventCardConverter'
import { IconStarFill, IconVideoOn } from '../icons'
import { IconThermometer } from '../icons/IconThermometer'
import { EventCardAction } from './partials/EventCardAction'
import { EventCardFooter } from './partials/EventCardFooter'
import { EventCardWrapper } from './partials/EventCardWrapper'

export enum EventCardMode {
  CARD_MODE_VETERINARY = 'VET',
  CARD_MODE_PET_OWNER = 'PET',
}

export type EventCardProps = {
  event: any
  mode?: EventCardMode
  active?: boolean
  onClick: (event: any) => void
  onEditClick?: (event: any) => void
}

export const EventCard: React.FC<EventCardProps> = ({
  event,
  mode = EventCardMode.CARD_MODE_VETERINARY,
  active = false,
  onClick = () => ({}),
  onEditClick = () => ({}),
}) => {
  const cardData = ConvertEventToCard(event)
  const healthEvent = isHealthEvent(event)
  const onCardClick = () => (healthEvent ? onClick(event) : onEditClick(event))

  const iconColor =
    event.payload.priority === EventPriority.EVENT_PRIORITY_HIGH
      ? 'text-error-500'
      : event.payload.priority === EventPriority.EVENT_PRIORITY_MEDIUM
      ? 'text-warning-500'
      : 'text-primary-500'

  const getIcon = () => {
    let icon = <IconStarFill className="h-4 text-primary-500" />

    if (isVideoconsultationEvent(event)) {
      icon = <IconVideoOn className={`w-4 h-4 ${iconColor}`} />
    } else {
      if (isHealthEvent(event)) icon = <IconThermometer className={`h-4 ${iconColor}`} />
    }

    return icon
  }

  //   console.log(event)

  return (
    <EventCardWrapper
      event={event}
      className="-ml-9"
      onClick={onCardClick}
      active={active}
      opts={cardData}
      icon={getIcon()}
      action={<EventCardAction event={event} mode={mode} />}
      footer={<EventCardFooter event={event} />}
    />
  )
}
