export const IconVideoOff = ({ className }: { className?: string }) => (
  <svg className={`fill-current ${className}`} viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.7196 11.5422L14.3903 13.213L17.4895 16.3038L17.4944 16.3087C17.8193 16.6362 17.8171 17.1651 17.4895 17.49L17.4847 17.4949C17.1571 17.8198 16.6282 17.8176 16.3033 17.49L13.8808 15.0591L12.6611 13.8395L4.85881 6.03717L3.38857 4.56692L2.93747 4.12418C2.60991 3.79662 2.60991 3.26553 2.93747 2.93796C3.26504 2.6104 3.79613 2.6104 4.12369 2.93796L5.54381 4.36644L7.21455 6.03717L12.7196 11.5422ZM17.7301 6.16232C17.2113 5.92871 16.6031 6.02733 16.1847 6.41293L14.3886 8.08366V6.87238C14.3886 5.4883 13.2666 4.36628 11.8825 4.36628H6.72831L7.80593 5.4439L13.311 10.949L14.9817 12.6197L16.6525 14.2904C16.8128 14.3505 16.9825 14.3816 17.1537 14.3823C17.3525 14.3819 17.549 14.3391 17.7301 14.257C18.2341 14.0531 18.5643 13.5643 18.5654 13.0207V7.39868C18.5644 6.85502 18.2341 6.3662 17.7301 6.16233L17.7301 6.16232ZM12.0679 14.4327L4.26555 6.63037L2.79531 5.20189L2.69506 5.03482C2.17127 5.50322 1.86819 6.17 1.8597 6.87262V13.5556C1.8597 14.9396 2.98172 16.0616 4.36579 16.0616H11.8841C12.3787 16.0625 12.862 15.914 13.2708 15.6356L12.0679 14.4327Z"
      fill="white"
    />
  </svg>
)
