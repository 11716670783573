import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ModalEventCategory } from 'src/apps/petOwner/pages/ModalEventCategory'
import { useModal } from 'src/hooks/useModal'
import { FacilityService } from 'src/models/FacilityService'
import { IonButton } from '@ionic/react'
import { BotActionMessage } from '../BotActionMessage'
import { BotMessageProps } from './constants'

type BotChooseEventServicesProps = BotMessageProps & {
  petName?: string
  selectedServices?: FacilityService[]
  listOfServicesToBeChoose: any
  onSelect?: (services: FacilityService[]) => void
}

export const BotChooseEventServices: React.FC<BotChooseEventServicesProps> = ({
  event,
  petName,
  selectedServices,
  listOfServicesToBeChoose,
  onSelect,
  onSuccess,
}) => {
  const { t } = useTranslation()
  const { showModal, hideModal } = useModal()
  const [services, setServices] = useState<FacilityService[]>(selectedServices || [])

  const onServiceSelected = (service: FacilityService) => {
    const selectedServices = [service]
    setServices(selectedServices)
    onSelect && onSelect(selectedServices)

    if (onSuccess) {
      event.payload.services = selectedServices
      onSuccess(event)
    }
    hideModal()
  }

  const chooseService = () => {
    showModal(
      <ModalEventCategory listOfServicesToBeChoose={listOfServicesToBeChoose} onDismiss={hideModal} onSuccess={onServiceSelected} />
    )
  }

  return (
    <BotActionMessage message={t('BOT_CONVERSATION_SELECT_REASON', { name: petName || '' })}>
      {services.length > 0 ? (
        <div>
          {services.map((service) => (
            <IonButton key={service.id} color="primary" fill="solid" size="small" className="button-xsmall">
              {service.name}
            </IonButton>
          ))}
        </div>
      ) : (
        <IonButton color="primary" fill="outline" size="small" className="button-xsmall" onClick={chooseService}>
          {t('ACTION_REASON_SPECIFY')}
        </IonButton>
      )}
    </BotActionMessage>
  )
}
