import { User } from 'src/models'
import { Conversation } from 'src/models/events'
import { EventStatus } from 'src/models/events/enums'
import { RequestsFilters } from 'src/models/filters/RequestsFilters'
import { createSlice } from '@reduxjs/toolkit'
import { PayloadAction } from '@reduxjs/toolkit'

export type VeterinaryState = {
  requests: Conversation[]
  requestsFilters: RequestsFilters
  selectedRequest?: Conversation
  customers: User[]
}

const initialState: VeterinaryState = {
  requests: [],
  requestsFilters: new RequestsFilters({
    facilities: [],
    statuses: [EventStatus.STATUS_NEW, EventStatus.STATUS_OPEN],
    customer: undefined,
  }),
  selectedRequest: undefined,
  customers: [],
}

export const reducers = {
  setRequests: (state: VeterinaryState, action: PayloadAction<Conversation[]>): void => {
    state.requests = action.payload
  },
  setRequestsFilters: (state: VeterinaryState, action: PayloadAction<RequestsFilters>): void => {
    state.requestsFilters = action.payload
  },
  setSelectedRequest: (state: VeterinaryState, action: PayloadAction<Conversation | undefined>): void => {
    state.selectedRequest = action.payload
  },
  setCustomers: (state: VeterinaryState, action: PayloadAction<User[]>): void => {
    state.customers = action.payload
  },
}

export const slice = createSlice({
  name: 'veterinary',
  initialState,
  reducers,
})

export const { actions: veterinaryReducers } = slice

export const veterinarySliceReducer = slice.reducer
