import { forwardRef, ReactElement } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { object, string, mixed } from 'yup'
import { Browser } from '@capacitor/browser'
import { yupResolver } from '@hookform/resolvers/yup'
import { IonButton, IonRouterLink, IonSpinner } from '@ionic/react'
import { SignUpFormType } from '../../features/Auth/forms/SignUpForm'
import { InputCheckbox } from '../inputs/InputCheckbox'
import { InputField } from '../inputs/InputField'
import { InputPassword } from '../inputs/InputPassword'
import { FormPropsType } from './Form'

export type FormPetOwnerRegisterParams = {
  onSubmit: (parameters: SignUpFormType) => void
} & FormPropsType

/**
 * @description FormRegister
 *
 * @param onSubmit
 * @param className
 * @param isSubmitting
 * @param showSubmit
 * @param rest
 * @constructor
 */
export const FormPetOwnerRegister = forwardRef<HTMLFormElement, FormPetOwnerRegisterParams>(
  ({ onSubmit, className = '', isSubmitting = false, showSubmit = true, ...rest }: FormPetOwnerRegisterParams, ref): ReactElement => {
    const { t } = useTranslation()

    const goToPrivacy = async () => await Browser.open({ url: 'https://veterly.com/condizioni-generali/' })
    const goToGeneralConditions = async () => await Browser.open({ url: 'https://veterly.com/informativa-privacy/' })

    const validationSchema = object().shape({
      firstName: string().required(t('FIELD_REQUIRED_NAME')),
      lastName: string().required(t('FIELD_REQUIRED_SURNAME')),
      email: string().required(t('FIELD_REQUIRED_EMAIL')),
      terms: mixed().test('active', t('FIELD_MUST_BE_CHECKED'), (v) => v === 'on'),
      password: string()
        .required('FIELD_REQUIRED_PASSWORD')
        .min(8, t('PASSWORD_FIELD_MIN_LENGTH', { count: 8 }))
        .matches(/(?=.*\d)(?=.*[A-Z])/, t('PASSWORD_FIELD_SECURITY')),
    })

    const {
      register,
      handleSubmit,
      formState: { errors },
    } = useForm({
      mode: 'onBlur',
      reValidateMode: 'onBlur',
      resolver: yupResolver(validationSchema),
    })

    return (
      <form className={`h-full flex flex-col justify-between ${className}`} ref={ref} onSubmit={handleSubmit(onSubmit)} {...rest}>
        <div className="h-full">
          <div className={'flex flex-col'}>
            <InputField
              label={t('FORM_LABEL_NAME')}
              required={true}
              placeholder={t('FORM_PLACEHOLDER_NAME')}
              {...register('firstName')}
              error={errors.firstName && errors.firstName.message}
            />
            <InputField
              label={t('FORM_LABEL_SURNAME')}
              required={true}
              placeholder={t('FORM_PLACEHOLDER_SURNAME')}
              error={errors.lastName && errors.lastName.message}
              {...register('lastName')}
            />
            <InputField
              type="email"
              required={true}
              label={t('FORM_LABEL_EMAIL')}
              placeholder={t('FORM_LABEL_EMAIL_PLACEHOLDER')}
              error={errors.email && errors.email.message}
              {...register('email')}
            />
            <InputPassword
              label={t('FORM_LABEL_PASSWORD')}
              placeholder={t('FORM_PLACEHOLDER_PASSWORD')}
              description={t('FORM_DESCRIPTION_PASSWORD')}
              error={errors.password && errors.password.message}
              {...register('password')}
            />
            <InputCheckbox
              className="mb-6"
              itemClassName="flex items-start"
              checkboxClassName="mt-4"
              label={
                <div className="text-body-medium leading-6">
                  {t('LABEL_PET_OWNER_GENERAL_CONDITION_1')}
                  <IonRouterLink className="mx-1 cursor-pointer" onClick={goToGeneralConditions} target="_blank">
                    {t('LABEL_PET_OWNER_GENERAL_CONDITION_LINK')}
                  </IonRouterLink>
                  {t('LABEL_PET_OWNER_GENERAL_CONDITION_2')}
                  <IonRouterLink className="mx-1 cursor-pointer" onClick={goToPrivacy} target="_blank">
                    {t('LABEL_PET_OWNER_GENERAL_CONDITION_LINK_2')}
                  </IonRouterLink>
                  {t('LABEL_PET_OWNER_GENERAL_CONDITION_3')}
                </div>
              }
              {...register('terms')}
              error={errors.terms && errors.terms.message}
            />
            <InputCheckbox
              className="mb-6"
              itemClassName="flex items-start"
              checkboxClassName="mt-4"
              label={<div className="text-body-medium leading-6">{t('LABEL_PET_OWNER_PERSONAL_DATA')}</div>}
              {...register('terms_marketing')}
            />
            <InputCheckbox
              className="mb-6"
              itemClassName="flex items-start"
              checkboxClassName="mt-4"
              label={<div className="text-body-medium leading-6">{t('LABEL_PET_OWNER_MARKETING')}</div>}
              {...register('terms_thirdparties')}
            />
          </div>
        </div>
        {showSubmit ? (
          <IonButton disabled={isSubmitting} type="submit" className="mb-4" expand="block">
            {!isSubmitting ? t('SIGN_UP') : <IonSpinner />}
          </IonButton>
        ) : null}
      </form>
    )
  }
)
