import { BaseEvent, BaseEventInterface } from './BaseEvent'
import { EventCategory, EventType } from './enums'
import { ChatEventPayload, ChatEventPayloadInterface } from './payload/ChatEventPayload'

export class ChatEvent extends BaseEvent<ChatEventPayloadInterface> {
  constructor(data: BaseEventInterface, payload: ChatEventPayloadInterface) {
    data.type = EventType.EVENT_TYPE_CHAT
    data.category = EventCategory.EVENT_CATEGORY_NONE
    super(data, payload)
  }

  parsePayload(payload: any) {
    return new ChatEventPayload(payload)
  }
}
