import { CalendarEvent, ChildEventMask } from 'src/models/events'
import { EventType, EventCategory } from 'src/models/events/enums'

const isCalendarEvent = (event: ChildEventMask): event is CalendarEvent => (event as CalendarEvent).type === EventType.EVENT_TYPE_CALENDAR

export const calendarEventValidator = (event: ChildEventMask): boolean =>
  event.type === EventType.EVENT_TYPE_CALENDAR && event.category === EventCategory.EVENT_CATEGORY_CALENDAR_BOOKING && event.payload?.valid

export const calendarEventFinder = (events: ChildEventMask[]): CalendarEvent | undefined => {
  const event = (events ?? []).find(calendarEventValidator)
  if (event === undefined || isCalendarEvent(event)) return event
  else return undefined
}
