import { useMemo } from 'react'

export enum LogoThemesEnum {
  DARK = 'dark',
  LIGHT = 'light',
}

type LogoPropsType = {
  className?: string
  theme?: LogoThemesEnum
}

export const BackgroundLogo = ({ className, theme = LogoThemesEnum.LIGHT }: LogoPropsType) => (
  <svg
    viewBox="0 0 254 254"
    className={`${className} ${theme === LogoThemesEnum.LIGHT ? 'text-grey-200 fill-current' : 'text-primary-500 fill-current'}`}
    xmlns="http://www.w3.org/2000/svg"
    width="254"
    height="254"
  >
    <path d="M166.606 130.433C169.813 125.613 171.716 119.679 169.863 114.194C165.772 102.087 152.72 97.2349 137.252 97.2349L117.287 97.2349C102.063 97.2349 89.1802 101.935 84.8767 113.625C82.7441 119.418 84.9151 125.778 88.5867 130.74C95.4169 139.972 101.751 144.756 108.43 149.673C114.233 153.945 121.227 156.438 128.432 156.438C134.198 156.438 139.894 154.891 144.701 151.706C153.849 145.646 160.13 140.17 166.606 130.433Z" />
    <path d="M101.652 244.041C98.7193 243.255 95.5332 242.033 92.094 240.377C88.6548 238.72 84.7785 237.313 80.4654 236.158C77.5324 235.372 76.1795 236.73 72.7026 238.214C69.4254 239.613 67.4673 240.377 63.9683 242.093C59.9996 244.041 57.6689 244.772 54.0465 246.559C50.0778 248.047 46.6051 249.039 40.652 250.031C38.1715 250.528 26.7053 251.169 23.2887 248.047C19.9181 244.754 19.3202 239.117 20.3124 233.66C21.8007 220.762 25.9007 215.463 22.7579 203.038C13.3672 187.717 5.4567 171.383 4.21168 159.467C3.01262 147.38 2.7165 135.718 3.32331 124.481C3.93013 113.245 5.54348 102.739 8.16335 92.9611C12.5758 76.4938 19.8179 61.8878 29.8897 49.1431C39.835 36.1807 51.5809 25.8149 65.1272 18.046C78.5471 10.0593 93.2442 4.89691 109.219 2.55864C125.067 0.00261414 141.099 0.897329 157.317 5.24282C173.189 9.49586 187.389 16.8858 199.917 27.4126C212.317 37.7217 222.372 49.884 230.081 63.9C237.79 77.9159 242.728 93.3035 244.895 110.063C247.279 126.697 246.242 143.333 241.784 159.972C237.418 176.268 230.613 190.623 221.369 203.038C212.125 215.453 201.316 225.427 188.943 232.958C176.397 240.444 162.723 245.237 147.922 247.338C132.948 249.393 117.525 248.294 101.652 244.041ZM196.718 141.83C198.649 134.625 199.182 126.77 198.317 118.266C197.452 109.761 195.11 101.595 191.29 93.769C187.343 85.7251 181.867 78.5584 174.861 72.2688C167.901 65.8077 159.332 61.2134 149.153 58.4859C138.801 55.7122 128.634 55.0103 118.651 56.3801C108.669 57.75 99.503 60.9015 91.1544 65.8347C82.6792 70.5501 75.3258 76.9449 69.0942 85.0192C62.9085 92.922 58.4138 102.105 55.6101 112.569C50.5083 131.609 51.6048 147.53 58.8996 160.332C66.0219 173.088 79.8484 182.216 100.379 187.717C111.593 190.722 122.468 191.889 133.003 191.219C143.411 190.331 152.956 187.833 161.639 183.724C170.149 179.57 177.468 173.993 183.596 166.994C189.77 159.824 194.145 151.436 196.718 141.83Z" />
  </svg>
)

export const FullLogo = ({ className, theme = LogoThemesEnum.LIGHT }: LogoPropsType) => {
  const colors = useMemo(() => {
    const isDarkTheme = theme === LogoThemesEnum.DARK
    return {
      icon: isDarkTheme ? 'text-white fill-current' : 'text-primary-500 fill-current',
      text: isDarkTheme ? 'text-white fill-current' : 'text-black fill-current',
    }
  }, [theme])

  return (
    <svg viewBox="0 0 217 48" fill="none" className={className}>
      <path
        d="M31.4846 24.6487C32.0905 23.7377 32.4503 22.6164 32.1 21.5798C31.3269 19.292 28.8605 18.375 25.9374 18.375L22.1643 18.375C19.2874 18.375 16.8529 19.2632 16.0396 21.4724C15.6366 22.5672 16.0469 23.7689 16.7407 24.7068C18.0315 26.4514 19.2284 27.3554 20.4907 28.2846C21.5873 29.0918 22.9089 29.563 24.2705 29.563C25.3602 29.563 26.4365 29.2706 27.345 28.6688C29.0737 27.5235 30.2606 26.4887 31.4846 24.6487Z"
        className={colors.icon}
      />
      <path
        d="M19.21 46.1178C18.6557 45.9693 18.0536 45.7385 17.4037 45.4254C16.7537 45.1123 16.0212 44.8465 15.2061 44.6281C14.6519 44.4796 14.3962 44.7363 13.7392 45.0168C13.1198 45.2811 12.7498 45.4254 12.0886 45.7498C11.3386 46.1178 10.8982 46.2559 10.2136 46.5937C9.4636 46.875 8.80735 47.0624 7.68236 47.2498C7.2136 47.3437 5.04675 47.4649 4.4011 46.875C3.76413 46.2526 3.65115 45.1874 3.83865 44.1562C4.1199 41.7187 4.89471 40.7173 4.30079 38.3693C2.52616 35.474 1.03127 32.3873 0.795992 30.1354C0.569398 27.8512 0.513438 25.6473 0.628113 23.524C0.742787 21.4006 1.04767 19.4151 1.54276 17.5674C2.37661 14.4554 3.7452 11.6952 5.64854 9.28679C7.52796 6.8372 9.74765 4.87832 12.3076 3.41017C14.8436 1.90087 17.621 0.925306 20.6398 0.483429C23.6347 0.000399828 26.6645 0.16948 29.7293 0.990675C32.7288 1.7944 35.4122 3.19092 37.7796 5.18024C40.123 7.12841 42.0232 9.42681 43.48 12.0755C44.9369 14.7242 45.87 17.6321 46.2794 20.7992C46.73 23.9426 46.5341 27.0865 45.6916 30.2308C44.8664 33.3104 43.5804 36.0232 41.8335 38.3693C40.0867 40.7155 38.0441 42.6002 35.7058 44.0235C33.335 45.4381 30.751 46.3439 27.9538 46.741C25.1241 47.1293 22.2095 46.9216 19.21 46.1178ZM37.1752 26.8024C37.54 25.4409 37.6407 23.9565 37.4773 22.3493C37.3139 20.7421 36.8713 19.199 36.1493 17.72C35.4035 16.1999 34.3686 14.8456 33.0447 13.657C31.7294 12.436 30.11 11.5678 28.1864 11.0524C26.2302 10.5282 24.3088 10.3955 22.4224 10.6544C20.5359 10.9133 18.8038 11.5089 17.2261 12.4411C15.6245 13.3322 14.2349 14.5407 13.0573 16.0665C11.8883 17.56 11.0389 19.2954 10.5091 21.2727C9.54496 24.8709 9.75217 27.8796 11.1307 30.2989C12.4767 32.7094 15.0895 34.4344 18.9694 35.474C21.0886 36.0419 23.1437 36.2625 25.1345 36.1358C27.1014 35.9679 28.9052 35.4958 30.546 34.7195C32.1542 33.9343 33.5373 32.8805 34.6954 31.5579C35.8622 30.2028 36.6888 28.6176 37.1752 26.8024Z"
        className={colors.icon}
      />
      <path d="M67.682 36.2H77.388L85.116 11.82H78.722L72.558 32.382L66.348 11.82H60L67.682 36.2Z" className={colors.text} />
      <path
        d="M99.0288 36.568C103.537 36.568 108.551 34.176 110.529 30.174L105.285 28.748C103.721 30.358 101.329 31.278 99.0288 31.278C95.7628 31.278 93.0948 29.484 92.4048 26.218H111.357C111.725 24.654 111.633 23.09 111.311 21.618C110.207 15.822 105.101 11.406 99.0288 11.406C92.1748 11.406 86.4708 16.926 86.4708 24.01C86.4708 30.818 91.9448 36.568 99.0288 36.568ZM105.515 21.664H92.4048C93.0488 18.996 95.4868 16.696 98.9828 16.696C102.479 16.696 104.871 18.996 105.515 21.664Z"
        className={colors.text}
      />
      <path
        d="M131.488 36.016V30.22C126.336 32.06 123.99 30.404 123.99 28.15V16.972H131.258V11.82H123.99V4.506L118.056 6.208V11.82H113.686V16.972H118.056V28.196C118.056 35.418 123.99 38.132 131.488 36.016Z"
        className={colors.text}
      />
      <path
        d="M146.421 36.568C150.929 36.568 155.943 34.176 157.921 30.174L152.677 28.748C151.113 30.358 148.721 31.278 146.421 31.278C143.155 31.278 140.487 29.484 139.797 26.218H158.749C159.117 24.654 159.025 23.09 158.703 21.618C157.599 15.822 152.493 11.406 146.421 11.406C139.567 11.406 133.863 16.926 133.863 24.01C133.863 30.818 139.337 36.568 146.421 36.568ZM152.907 21.664H139.797C140.441 18.996 142.879 16.696 146.375 16.696C149.871 16.696 152.263 18.996 152.907 21.664Z"
        className={colors.text}
      />
      <path
        d="M162.54 36.2H168.52V23.78C168.52 18.996 173.074 16.604 177.628 18.122V11.728C172.89 10.992 169.946 12.924 168.52 15.454V11.82H162.54V36.2Z"
        className={colors.text}
      />
      <path d="M181.409 36.2H187.389V4H181.409V36.2Z" className={colors.text} />
      <path
        d="M200.423 36.062L196.559 45.86H202.769L216.293 11.82H209.945L203.505 29.024L196.697 11.82H190.303L200.423 36.062Z"
        className={colors.text}
      />
    </svg>
  )
}
