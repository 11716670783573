import React, { ChangeEvent, forwardRef, ReactChild, useEffect, useState } from 'react'
import { IonButton } from '@ionic/react'
import { FormError } from './FormError'

type ButtonInputUploadProps = {
  error?: string
  className?: string
  defaultValue?: string
  children: ReactChild
  [key: string]: any
}

export const ButtonInputUpload = forwardRef<HTMLInputElement, ButtonInputUploadProps>(
  ({ children, error, defaultValue = '', className = '', ...rest }: ButtonInputUploadProps, ref) => {
    const [preview, setPreview] = useState<{ style: string; src: string }>({
      style: '',
      src: '',
    })

    useEffect(() => {
      setPreview({ style: '', src: defaultValue })
    }, [])

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
      const files = event.target.files
      if (files && files.length) {
        const image = new Image()
        const objectUrl = URL.createObjectURL(files[0])
        image.onload = () => {
          rest.onBlur(event)
          setPreview({
            style: '',
            src: objectUrl,
          })
        }

        image.src = objectUrl
      }
    }

    return (
      <div className={`cursor-pointer flex-col ${className || 'mb-6'}`}>
        {preview.src && (
          <div className="w-full h-auto py-6 flex justify-center">
            <img src={preview.src} className="rounded shadow-lg" />
          </div>
        )}
        <IonButton size="small" fill="outline" className="button-xsmall relative flex flex-row items-center">
          <input
            type="file"
            ref={ref}
            {...rest}
            onChange={onChange}
            accept="image/*, video/*"
            className="cursor-pointer absolute bg-grey-100 inset-0 opacity-0 z-10 appearance-none"
          />
          {children}
        </IonButton>

        {error ? <p className="relative">{error && <FormError message={error} />}</p> : null}
      </div>
    )
  }
)

ButtonInputUpload.displayName = 'ButtonInputUpload'
