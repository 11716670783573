import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { EventsAPI } from 'src/api/EventsAPI'
import { BotAction } from 'src/components/chat/Bot/constants'
import { BotRenderer } from 'src/components/chat/BotRenderer'
import { ChatRenderer } from 'src/components/chat/ChatRenderer'
import { IconPlus } from 'src/components/icons'
import { TextareaFooter } from 'src/components/inputs/TextareaFooter'
import { getSelectedRequest } from 'src/features/Veterinary/selectors'
import { useConversationByEventId } from 'src/hooks/useConversationByEventId'
import { useCurrentValues } from 'src/hooks/useCurrentValues'
import { useSocket } from 'src/hooks/useSocket'
import { BaseEventInterface } from 'src/models/events/BaseEvent'
import { ChatEvent } from 'src/models/events/ChatEvent'
import { Conversation } from 'src/models/events/Conversation'
import { EventCategory, EventStatus } from 'src/models/events/enums'
import { ChatEventPayloadInterface } from 'src/models/events/payload/ChatEventPayload'
import { DateConverter } from 'src/utils'
import { Dialog } from '@capacitor/dialog'
import { IonButton, IonContent, IonFooter, IonLoading, IonPage } from '@ionic/react'
import { ChatHeader } from '../../components/veterinary/ChatHeader'

export const PageChat: React.FC<{ match: any }> = ({ match }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const messagesEndRef = useRef<any>(null)
  const { currentUser } = useCurrentValues()
  const selectedRequest = useSelector(getSelectedRequest)
  const { socket } = useSocket()

  const { eventId } = match.params
  const scrollToBottom = () => messagesEndRef && messagesEndRef?.current && messagesEndRef?.current?.scrollIntoView({ behavior: 'smooth' })
  const { conversation, reloadConversation } = useConversationByEventId(eventId, scrollToBottom)
  const { parentEvent, children, eventsByDate } = conversation || Conversation.generateInvalid()

  const isNew = parentEvent.payload.status === EventStatus.STATUS_NEW
  const isOpen = parentEvent.payload.status === EventStatus.STATUS_OPEN

  const [botAction, setBotAction] = useState<BotAction>()

  useEffect(() => {
    if (socket != null)
      socket.on('NOTIFICATIONS', ({}) => {
        reloadConversation()
      })
  }, [socket])

  const onBotCompleted = async () => {
    await reloadConversation()
    setBotAction(undefined)
  }

  const sendMessage = async (text: string) => {
    if (!text.length) {
      return
    }

    const data: BaseEventInterface = { parent_id: parentEvent.id as string }
    const payload: ChatEventPayloadInterface = {
      userId: currentUser.id,
      familyId: parentEvent.Family?.id || '',
      facilityId: parentEvent.Facility?.id || '',
      message: text,
      user: currentUser,
    }
    const message = new ChatEvent(data, payload)

    await EventsAPI.createMessage(message)
    await reloadConversation()
  }

  const showConfirm = useCallback(async () => {
    const { value } = await Dialog.confirm({
      title: t('TITLE_WARNING'),
      message: t('MESSAGE_DIALOG_CLOSE_CONSULTATION'),
      cancelButtonTitle: t('ACTION_CANCEL'),
      okButtonTitle: t('ACTION_TERMINATE'),
    })

    return value
  }, [Dialog])

  const finalizeCloseConversation = useCallback(async () => {
    showConfirm().then(async (result) => {
      if (!result) return

      parentEvent.payload.closeDate = DateConverter.now()
      await EventsAPI.closeEvent(parentEvent)
      await onBotCompleted()
    })
  }, [parentEvent, showConfirm])

  const scroll = () => {
    !!conversation.parentEvent.payload.seen && scrollToBottom()
  }

  useEffect(() => {
    if (!selectedRequest) history.replace('/')
  }, [])

  useEffect(() => {
    if (isNew && botAction === undefined) {
      setBotAction(BotAction.OPEN_CHAT)
    }

    setTimeout(scroll, 300)
  }, [conversation])

  useEffect(() => {
    scroll()
  }, [children, botAction])

  useEffect(() => {
    setTimeout(scroll, 300)
  }, [match])

  useEffect(() => {
    setTimeout(scroll, 300)
  }, [conversation])

  console.log('conversation', conversation)

  return (
    <IonPage>
      <ChatHeader parentEvent={parentEvent} onTerminate={finalizeCloseConversation} />
      <IonContent className="ion-bg-grey-100">
        {conversation.id !== eventId ? (
          <IonLoading isOpen={true} spinner="lines" backdropDismiss={false} />
        ) : (
          <>
            <section className="min-h-full flex flex-grow -mx-12">
              <div className="w-full pt-14 px-10 bg-grey-200 z-0 flex flex-col justify-end pb-12">
                <ChatRenderer conversation={conversation} events={eventsByDate} vetMode={false} />
                <BotRenderer action={botAction} event={parentEvent} onSuccess={onBotCompleted} />
                <div ref={messagesEndRef} />
              </div>
            </section>
          </>
        )}
      </IonContent>

      {isOpen && (
        <IonFooter className="h-20">
          {!conversation.isClosed && !isNew && conversation.parentEvent.category === EventCategory.EVENT_CATEGORY_HEALTH_CONSULTATION && (
            <div className="h-full flex px-4 items-center">
              <IonButton fill="clear" className="flex-shrink-0" onClick={() => null}>
                <IconPlus className="w-6 h-6 text-primary-500" />
              </IonButton>
              <TextareaFooter onSend={sendMessage} className="flex-grow" mobile />
            </div>
          )}
        </IonFooter>
      )}
    </IonPage>
  )
}
