import { ReactElement } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { object, string } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { IonButton } from '@ionic/react'
import { InputField } from '../inputs/InputField'

export type FormResetPasswordParams = {
  // eslint-disable-next-line no-unused-vars
  onSubmit: (email: string) => void
  className?: string
}

/**
 * @description FormLogin
 *
 * @param onSubmit
 * @param className
 * @constructor
 */
export const FormResetPassword = ({ onSubmit, className = '' }: FormResetPasswordParams): ReactElement => {
  const { t } = useTranslation()

  const validationSchema = object().shape({
    email: string().required(t('FIELD_REQUIRED_EMAIL')).email(t('FIELD_INVALID_EMAIL')),
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: yupResolver(validationSchema),
  })

  return (
    <form onSubmit={handleSubmit(({ email }) => onSubmit(email))} className={`w-full flex flex-col ${className}`}>
      <InputField
        label={t('FORM_LABEL_EMAIL')}
        placeholder={t('FORM_LABEL_EMAIL_PLACEHOLDER')}
        type="email"
        {...register('email')}
        error={errors.email?.message}
      />
      <div className="block pt-4">
        <IonButton expand="block" type="submit" className="md:inline-block">
          {t('FORM_ACTION_RESET_PASSWORD')}
        </IonButton>
      </div>
    </form>
  )
}
