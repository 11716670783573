import React, { useEffect, useState } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { FacilitiesAPI } from 'src/api'
import { IconInfo } from 'src/components/icons'
import { usePetAttributes } from 'src/hooks/usePetAttributes'
import { IonButton, IonContent, IonPage, IonFooter } from '@ionic/react'
import { NavHeader } from '../components/Header/NavHeader'

type ModalPetsMappingProps = {
  facilityName: string
  pendingPets: any[]
  onComplete: () => void
}

export const ModalPetsMapping: React.FC<ModalPetsMappingProps> = ({ facilityName, pendingPets, onComplete }) => {
  const { t } = useTranslation()
  const [index, setIndex] = useState<number>(0)
  const [ownedPet, setOwnedPet] = useState<any>()
  const [mergingPet, setMergingPet] = useState<any>()
  const [showSupportMessage, setShowSupportMessage] = useState<boolean>(false)
  const [speciesConfig] = usePetAttributes()

  const species = new Map()

  const setPetsToMap = () => {
    if (pendingPets[index]) {
      const pets = pendingPets[index]
      if (pets.length > 2) {
        setShowSupportMessage(true)
        return
      }
      const newPets = pets
        .filter((pet: any) => !!pet.FacilityId)
        .map((entry: any) => ({ name: entry.name, speciesName: species.get(entry.SpeciesId) }))
      const basePet = pets
        .filter((pet: any) => !pet.FacilityId)
        .map((entry: any) => ({ name: entry.name, speciesName: species.get(entry.SpeciesId) }))

      setOwnedPet(basePet[0])
      setMergingPet(newPets[0])
    }
    return
  }

  const takeAction = async (merge: boolean) => {
    try {
      if (merge) {
        await FacilitiesAPI.postPetsMapping(pendingPets[index])
      } else {
        await FacilitiesAPI.deletePetsMapping(pendingPets[index])
      }

      //TODO: dobbiamo fare qualcosa con la risposta?
      await setIndex(index + 1)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    speciesConfig.species.forEach((entry: any) => {
      species.set(entry.id, entry.name)
    })
    setPetsToMap()
  }, [speciesConfig])

  useEffect(() => {
    index < pendingPets.length ? setPetsToMap() : onComplete()
  }, [index])

  return (
    <IonPage>
      <NavHeader title={t('TITLE_CONFIRM_PETS')} showBackButton={false} />
      <IonContent className="flex items-start">
        <div className="w-full mt-24 flex flex-col justify-center">
          <IconInfo className="mb-8 w-16 h-16 w-full text-center text-grey-500" />
          <div className="text-center mb-4 font-bold text-h200">{t('TITLE_WARNING')}</div>

          <div className="flex flex-col items-center text-center">
            {showSupportMessage ? (
              <div className="w-5/6">
                {t('MESSAGE_CONTACT_SUPPORT_TO_MERGE')}
                <a className="ml-1 text-base-primary font-semibold" href="mailto:info@veterly.com">
                  {t('LABEL_SUPPORT')}
                </a>
              </div>
            ) : (
              <>
                <div className="w-5/6">
                  <Trans
                    i18nKey={'DESCRIPTION_CONFIRM_PETS_1'}
                    values={{
                      name1: ownedPet?.name,
                      species1: ownedPet?.speciesName,
                      name2: mergingPet?.name,
                      species2: mergingPet?.speciesName,
                      facilityName,
                    }}
                  />
                </div>
                <div className="w-5/6 mt-2">
                  <Trans
                    i18nKey={'DESCRIPTION_CONFIRM_PETS_2'}
                    values={{
                      name: ownedPet?.name,
                      species: ownedPet?.speciesName,
                    }}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </IonContent>

      <IonFooter className="bg-transparent flex justify-around mb-4">
        {showSupportMessage ? (
          <IonButton className="link mr-4" fill="clear" onClick={onComplete}>
            <span className="font-bold text-h100">{t('ACTION_FORWARD')}</span>
          </IonButton>
        ) : (
          <>
            <IonButton className="link mr-4" fill="clear" onClick={() => takeAction(false)}>
              <span className="font-bold text-h100">{t('ACTION_KEEP_PETS_DIVIDED')}</span>
            </IonButton>
            <IonButton onClick={() => takeAction(true)}>
              <span className="font-bold text-h100">{t('ACTION_MERGE_PETS')}</span>
            </IonButton>
          </>
        )}
      </IonFooter>
    </IonPage>
  )
}
