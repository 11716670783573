import { FC, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { Avatar } from 'src/components/Avatar'
import { IconFilters, IconInbox } from 'src/components/icons'
import { getRequests, getRequestsFilters, getSelectedRequest } from 'src/features/Veterinary/selectors'
import { veterinaryReducers } from 'src/features/Veterinary/slice'
import { veterinaryThunks } from 'src/features/Veterinary/thunks'
import { useCurrentUser } from 'src/hooks/useCurrentValues'
import { useModal } from 'src/hooks/useModal'
import { useSocket } from 'src/hooks/useSocket'
import { Conversation } from 'src/models/events'
import { RefresherEventDetail } from '@ionic/core'
import { IonBadge, IonButton, IonContent, IonList, IonPage, IonRefresher, IonRefresherContent, IonRouterLink } from '@ionic/react'
import { NavHeader } from '../../components/Header/NavHeader'
import { Request } from '../../components/veterinary/Request'
import { AppRouterHelper } from '../../services/AppRouterHelper'
import { ModalRequestsFilters } from './ModalRequestsFilters'

const EmptyState = () => {
  const { t } = useTranslation()
  return (
    <div className="w-full h-full flex flex-col justify-center items-center space-y-2">
      <IconInbox className="h-12 text-grey-600" />
      <span className="text-h100 font-bold">{t('LABEL_NO_REQUESTS_MOBILE')}</span>
      <span className="w-4/5 text-body-small text-center text-grey-600">{t('DESCRIPTION_NO_REQUESTS_MOBILE')}</span>
    </div>
  )
}

export const PageRequests: FC = () => {
  const { t } = useTranslation()
  const currentUser = useCurrentUser()
  const requests = useSelector(getRequests)
  const filters = useSelector(getRequestsFilters)
  const [requestsList, setRequestsList] = useState<Conversation[]>(requests)
  const selectedRequest = useSelector(getSelectedRequest)
  const dispatch = useDispatch()
  const history = useHistory()
  const { socket } = useSocket()
  const { showModal, hideModal } = useModal()

  // TODO: provare con React Query

  const refreshRequests = () => {
    dispatch(veterinaryThunks.getRequests())
  }

  const doRefresh = useCallback((event: CustomEvent<RefresherEventDetail>) => {
    refreshRequests()
    setTimeout(() => {
      event.detail.complete()
    }, 500)
  }, [])

  const onFilterClick = () => {
    showModal(
      <ModalRequestsFilters
        onDismiss={() => {
          setTimeout(refreshRequests, 50)
          hideModal()
        }}
        onSuccess={() => {
          refreshRequests()
          hideModal()
        }}
      />
    )
  }

  const goToRequest = (request: Conversation, href: string) => {
    dispatch(veterinaryReducers.setSelectedRequest(request))
    history.push(href)
  }

  useEffect(() => {
    if (socket)
      socket.on('NOTIFICATIONS', () => {
        refreshRequests()
      })
  }, [socket])

  useEffect(() => {
    setRequestsList([...requests])
  }, [requests])

  useEffect(() => {
    if (history.location.pathname == AppRouterHelper.VETERINARY_ROUTES.REQUESTS) {
      dispatch(veterinaryReducers.setSelectedRequest(undefined))
      refreshRequests()
    }
  }, [history.location, selectedRequest])

  return (
    <IonPage>
      <NavHeader
        titleClassName="text-center p-0"
        showBackButton={false}
        title={t('TITLE_REQUESTS', { count: requestsList.length })}
        leftActions={
          <div className="relative ml-3">
            <IonButton className="px-3 text-primary-500" fill="clear" onClick={onFilterClick}>
              <IconFilters className="w-6 h-6" />
            </IonButton>
            {filters.getCount() > 0 && (
              <IonBadge color="danger" className="absolute top-1 right-0 rounded-full ml-2 px-2">
                {filters.getCount()}
              </IonBadge>
            )}
          </div>
        }
      >
        <IonRouterLink routerLink={AppRouterHelper.VETERINARY_ROUTES.PROFILE}>
          <Avatar image={currentUser.avatar} title={currentUser.initials} className="cursor-pointer h-12 w-12" />
        </IonRouterLink>
      </NavHeader>
      <IonContent className="no-padding">
        <IonRefresher slot="fixed" onIonRefresh={doRefresh} pullFactor={0.5} pullMin={100} pullMax={200}>
          <IonRefresherContent />
        </IonRefresher>
        <div className="h-full w-full flex items-start justify-center">
          {requestsList.length == 0 ? (
            <EmptyState />
          ) : (
            <IonList className="w-full pt-0">
              {requestsList.map((request, index: any) => (
                <Request key={index} event={request} onClick={() => goToRequest(request, `/app/requests/${request.id}`)} />
              ))}
            </IonList>
          )}
        </div>
      </IonContent>
    </IonPage>
  )
}
