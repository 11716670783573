import endOfMonth from 'date-fns/endOfMonth'
import endOfWeek from 'date-fns/endOfWeek'
import startOfMonth from 'date-fns/startOfMonth'
import startOfWeek from 'date-fns/startOfWeek'
import groupBy from 'lodash/groupBy'
import map from 'lodash/map'
import sortBy from 'lodash/sortBy'
import { View } from 'react-big-calendar'
import { CalendarEvent, Conversation } from '../models/events'
import { EventSuggestion } from '../models/events/EventSuggestion'
import { EventCategory, EventStatus, EventType } from '../models/events/enums'
import { formatDate } from '../utils'
import { EventsAPI } from './EventsAPI'
import { axiosInstance } from './axios'
import { getConversationByCalendar } from './calendarApiUtilities'
import { parseEventsToConversations } from './eventApiUtilities'

export type EventSuggestionsByDate = {
  date: Date
  suggestions: EventSuggestion[]
}

export class CalendarAPI {
  static async setUnavailability(event: CalendarEvent) {
    event.category = EventCategory.EVENT_CATEGORY_CALENDAR_OUT_OF_OFFICE
    event.parent_id = null
    return await EventsAPI.createEvent(event, true)
  }

  static async deleteUnavailability(eventId: string) {
    return await EventsAPI.deleteEvent(eventId)
  }

  static async addBookingEvent(parentId: string, event: CalendarEvent) {
    /*
     * We need to sync a booking event in order to send email and messages
     */
    event.parent_id = parentId
    event.category = EventCategory.EVENT_CATEGORY_CALENDAR_BOOKING
    return await EventsAPI.createEvent(event, true)
  }

  static async getSuggestions(
    date: Date,
    familyId: string,
    facilityId: string,
    petId: string,
    services: string[] = [],
    days = 1,
    duration: string | number = 30
  ): Promise<any> {
    const query: string[] = [
      `FamilyId=${familyId}`,
      `FacilityId=${facilityId}`,
      `PetId=${petId}`,
      `day=${formatDate(date, 'yyyy-MM-dd')}`,
      `days=${days || 2}`,
      `duration=${duration || 20}`,
      ...services.map((service) => `services[]=${service}`),
    ]

    const queryString = query.join('&')

    return axiosInstance
      .get(`/calendar/suggestions?${queryString}`)
      .then((response) => response.data.map((suggestion: any) => new EventSuggestion(suggestion)))
      .then((suggestions) =>
        sortBy(
          map(
            groupBy(suggestions, (evt) => formatDate(evt.from, 'dd/MM/yyyy')),
            (suggestions: EventSuggestion[]) => ({
              date: suggestions[0].from,
              suggestions,
            })
          ),
          (data: EventSuggestionsByDate) => data.date
        )
      )
  }

  static async getAgendaEventsV2(facilityId: string) {
    return axiosInstance.get(`/${facilityId}/calendars`).then((response) =>
      response.data.map((event: any) => {
        const conversation = !!event.conversation
          ? parseEventsToConversations([event.conversation])[0]
          : parseEventsToConversations([event.event])[0]

        const id = event.conversation?.appointment._id || conversation.id

        return {
          id,
          title: event.title,
          subtitle: event.subtitle,
          allDay: event.allDay || false,
          start: new Date(event.start),
          end: new Date(event.end),
          conversation: conversation,
          status: event.status,
          category: event.category,
        }
      })
    )
  }

  static async getAgendaEvents(date: Date, facilityId: string, view: View) {
    const conversations = await getConversationByCalendar(
      {
        type: EventType.EVENT_TYPE_CALENDAR,
        payload: {
          facilityId: facilityId,
          event: {
            start: {
              from: view === 'month' ? startOfMonth(date) : startOfWeek(date),
              to: view === 'month' ? endOfMonth(date) : endOfWeek(date),
            },
            end: {
              from: view === 'month' ? startOfMonth(date) : startOfWeek(date),
              to: view === 'month' ? endOfMonth(date) : endOfWeek(date),
            },
          },
        },
      },
      { perPage: 10000 }
    )

    //console.log(conversations)
    return conversations
      .filter((conversation) => conversation.parentEvent?.payload?.status !== EventStatus.STATUS_CLOSED)
      .map((conversation: Conversation) => {
        const event = conversation.children[0]

        return {
          id: event.id,
          title: event.payload.event.title,
          subtitle: event.payload.event.subtitle,
          allDay: event.payload.event.allDay || false,
          start: new Date(event.payload.event.start),
          end: new Date(event.payload.event.end),
          services: conversation.parentEvent?.payload?.services || [],
          conversation,
        }
      })
  }
}
