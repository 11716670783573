import { Avatar } from 'src/components/Avatar'
import { IconClose, IconCollapse } from 'src/components/icons'
import { User } from 'src/models'
import { IonButton } from '@ionic/react'

export const CallHeader = ({ callee, onClose, onDismiss }: { callee: User | null; onClose: () => void; onDismiss: () => void }) => (
  <div className="w-full h-1/6 flex justify-between">
    <IonButton className="link" fill="clear" onClick={onDismiss}>
      <IconCollapse className="text-primary-500 w-8 h-8" />
    </IonButton>
    {callee && (
      <div className="text-h500 font-normal text-black flex items-center">
        {callee.fullName}
        <Avatar className="ml-2 w-12 h-12 text-h200" title={callee.initials} image={callee.avatar} />
      </div>
    )}
    <IonButton className="link" fill="clear" onClick={onClose}>
      <IconClose className="text-primary-500 w-8 h-8" />
    </IonButton>
  </div>
)
