/* Core CSS required for Ionic components to work properly */
import React, { useEffect, useState } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { useDispatch } from 'react-redux'
import { IonLoading } from '@ionic/react'
import '@ionic/react/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/typography.css'
import { authThunks } from './features/Auth/store/thunks'
import './theme/datepicker.css'
import './theme/global.css'
import './theme/style.css'

/* Theme variables */
import './theme/variables.css'

const Wrapper: React.FC = ({ children }) => {
  const dispatch = useDispatch()
  const [loaded, setLoaded] = useState(false)

  const checkLogin = async () => {
    try {
      await dispatch(authThunks.checkLogin())
    } catch (e) {
    } finally {
      setLoaded(true)
    }
  }

  useEffect(() => {
    checkLogin()
  }, [])

  return <>{loaded ? children : <IonLoading showBackdrop={false} isOpen={true} />}</>
}

export default Wrapper
