import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useToast } from 'src/hooks/useToast'
import { IonContent, IonPage } from '@ionic/react'
import { FormChangePassword } from '../../../components/forms/FormChangePassword'
import { authThunks } from '../../../features/Auth/store/thunks'
import { NavHeader } from '../components/Header/NavHeader'

export const PageProfileChangePassword: React.FC = () => {
  const toast = useToast()
  const dispatch = useDispatch()
  const history = useHistory()
  const { t } = useTranslation()

  const onSubmit = async (data: any) => {
    try {
      await dispatch(authThunks.changePassword(data.oldPassword, data.newPassword))
      history.goBack()
      toast.success(t('MESSAGE_PASSWORD_SUCCESSFULLY_CHANGED'))
    } catch ({ message }) {
      toast.error(t(message as string))
    }
  }

  return (
    <IonPage>
      <NavHeader titleClassName="text-center p-0" title={t('TITLE_CREATE_NEW_PASSWORD')} showBackButton={true} />
      <IonContent>
        <FormChangePassword className="pt-6" onSubmit={onSubmit} />
      </IonContent>
    </IonPage>
  )
}
