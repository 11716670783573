import React from 'react'
import { CallBanner } from 'src/components/chat/CallBanner'
import { useCurrentCall } from 'src/hooks/useCurrentValues'
import { useModal } from 'src/hooks/useModal'
import { ModalCall } from '../../pages/ModalCall'
import { CallMiniature } from './CallMiniature'

const CallOverlay: React.FC = ({ children }) => {
  const { callObject, event } = useCurrentCall()
  const { showModal, hideModal } = useModal()

  const returnToCallDrawer = () => {
    showModal(<ModalCall event={event} onDismiss={hideModal} />)
  }

  return (
    <>
      {callObject && event && (
        <CallBanner className="bg-error-500 w-full z-20 py-2 pl-2 cursor-pointer" showTimer={false} onClick={returnToCallDrawer} />
      )}
      <CallMiniature />
      {children}
    </>
  )
}
export { CallOverlay }
