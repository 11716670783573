import { FC } from 'react'
import { Facility } from '../models'
import { Avatar } from './Avatar'

export type FacilityChipProps = {
  className?: string
  active?: boolean
  readonly?: boolean
  onClick?: any
  facility: Facility
}

export const FacilityChip: FC<FacilityChipProps> = ({ facility, className = '', active = false, readonly = false, onClick }) => {
  const classes = active ? 'bg-primary-500 text-white' : 'bg-grey-100 text-black'

  return (
    <div
      className={`h-8 rounded-full p-1 pr-3 inline-flex items-center ${classes} ${!readonly && 'cursor-pointer'} ${className}`}
      {...(onClick && !readonly ? { onClick } : null)}
    >
      <Avatar image={facility.avatar} title={facility.initials} className="w-6 h-6 mr-2 text-body-tiny" />
      <span className="text-body-small">{facility.name}</span>
    </div>
  )
}
