import thunk from 'redux-thunk'
import { authSliceReducer, AuthState } from 'src/features/Auth/store/slice'
import { facilitySliceReducer, FacilityState } from 'src/features/Facility/store/slice'
import { familySliceReducer, FamilyState } from 'src/features/Family/store/slice'
import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { callSliceReducer, CallState } from './features/Call/slice'
import { UtilitesState, utilitiesSliceReducer } from './features/Utilities/slice'
import { veterinarySliceReducer, VeterinaryState } from './features/Veterinary/slice'

export type GlobalStoreState = {
  auth: AuthState
  call: CallState
  facility: FacilityState
  family: FamilyState
  veterinary: VeterinaryState
  utilities: UtilitesState
}

const rootReducer = combineReducers({
  auth: authSliceReducer,
  call: callSliceReducer,
  facility: facilitySliceReducer,
  family: familySliceReducer,
  veterinary: veterinarySliceReducer,
  utilities: utilitiesSliceReducer,
})

export const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [thunk],
})

export type AppDispatch = typeof store.dispatch
