import { Family, User, Pet } from 'src/models'
import { Conversation } from 'src/models/events/Conversation'
import { createSlice } from '@reduxjs/toolkit'
import { familyReducers as reducers } from './reducers'

/**
 * This STORE is used for event and family management
 * On Veterinary side is used when the veterinarian accesses the chat with the family
 * On PetOwner side is used for family management
 *
 */
export type FamilyState = {
  loading: boolean
  loadingConversations: boolean
  family: Family
  members: User[]
  pets: Pet[]
  conversations: Conversation[] // TO MANAGE
  pastConversations: Conversation[]
  lastUpdated: Date | null
}

const initialState: FamilyState = {
  loading: false,
  loadingConversations: false,
  family: new Family({}),
  members: [],
  pets: [],
  conversations: [],
  pastConversations: [],
  lastUpdated: null,
}

export const slice = createSlice({
  name: 'family',
  initialState,
  reducers,
})

export const { actions: familyReducers } = slice

export const familySliceReducer = slice.reducer
