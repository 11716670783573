import { format } from 'date-fns'
import { enUS } from 'date-fns/locale'
import { DateConverter } from 'src/utils'

export interface EventSuggestionInterface {
  from?: string
  to?: string
  ids?: string[]

  // Debug values
  from_formatted?: string
  to_formatted?: string
}

export class EventSuggestion {
  from: Date
  to: Date
  ids: string[]

  from_formatted?: string
  to_formatted?: string

  constructor(data: EventSuggestionInterface) {
    this.from = DateConverter.fromServer(Number(data.from) * 1000)
    this.to = DateConverter.fromServer(Number(data.to) * 1000)
    this.ids = data.ids || []

    this.from_formatted = data.from_formatted
    this.to_formatted = data.to_formatted
  }

  get id() {
    return `${this.from.getTime()}-${this.to.getTime()}`
  }

  get minutes() {
    const diffMs = this.to.getTime() - this.from.getTime()
    return Math.round(((diffMs % 86400000) % 3600000) / 60000)
  }

  get startingFrom() {
    return format(this.from, 'h:mm a', { locale: enUS })
  }

  get slot() {
    return `${format(this.from, 'h:mm a', { locale: enUS })} - ${format(this.to, 'h:mm a', { locale: enUS })}`
  }
}

const fakeEventSuggestion = new EventSuggestion({})

export const FakeEventSuggestion = fakeEventSuggestion
