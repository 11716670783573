import { FC, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { PetChip } from 'src/components/PetChip'
import { Tag, TagVariant } from 'src/components/Tag'
import { Conversation } from 'src/models/events'
import { EventStatus } from 'src/models/events/enums'
import { convertMillisecondsToUnits, DATE_FORMAT, formatDate } from 'src/utils'
import { IonItem } from '@ionic/react'

type RequestProps = {
  event: Conversation
  onClick?: () => void
}

const colorMap = {
  [EventStatus.STATUS_OPEN]: TagVariant.TAG_FILL_TERTIARY,
  [EventStatus.STATUS_NEW]: TagVariant.TAG_FILL_PRIMARY,
  [EventStatus.STATUS_CLOSED]: TagVariant.TAG_OUTLINE_NEUTRAL,
}

const NewMessageIndicator = () => (
  <div
    className={`avatar w-4 h-4 bg-primary-500 border-white border-2 shadow-light-40 rounded-full flex items-center justify-center overflow-hidden`}
  />
)

export const Request: FC<RequestProps> = ({ event, onClick }) => {
  const { t } = useTranslation()

  const parsedRequest = {
    clientName: event.User?.safeName,
    seq: event.parentEvent.seq,
    pet: event.pets[0],
    facilityName: event.parentEvent.Facility?.name,
    lastMessage: event.parentEvent.LastMessage?.payload.message,
    lastDateUpdate: event.parentEvent.dateUpdated,
    status: event.parentEvent.payload.status as EventStatus,
    seen: event.parentEvent.payload.seen,
  }

  const isNew = useMemo(() => parsedRequest.status === EventStatus.STATUS_NEW || !parsedRequest.seen, [parsedRequest])

  const getLastUpdateLabel = useCallback((date: Date) => {
    const difference = Date.now() - date.getTime()

    const differenceUnits = convertMillisecondsToUnits(difference)

    if (differenceUnits.minutes == 0) return t('LABEL_DATE_NOW')
    if (differenceUnits.minutes < 60) return t('LABEL_MINUTES_AGO', { count: differenceUnits.minutes })
    if (differenceUnits.hours < 24) return t('LABEL_HOURS_AGO', { count: differenceUnits.hours })
    if (differenceUnits.days < 31) return t('LABEL_DAYS_AGO', { count: differenceUnits.days })

    return formatDate(date, DATE_FORMAT)
  }, [])

  return (
    <IonItem className="w-full border-b border-grey-300" onClick={onClick}>
      <div className={`w-full flex justify-between pt-4 pb-1 px-6 ${isNew && 'bg-grey-200'}`}>
        <div className="w-2/3 flex flex-col space-y-2">
          <span className="flex items-center space-x-2">
            <span className="font-bold">{parsedRequest.clientName}</span>
            <PetChip pet={parsedRequest.pet} />
            <Tag className="h-6 pl-2 pr-2" variant={TagVariant.TAG_FILL_WHITE}>
              <span className="text-body-small">#{parsedRequest.seq}</span>
            </Tag>
          </span>
          <span className="text-body-small text-grey-500">{parsedRequest.facilityName}</span>
          <span className="text-grey-500 truncate">{parsedRequest.lastMessage}</span>
        </div>
        <div className="w-1/3 flex flex-col items-end justify-between">
          {parsedRequest.lastDateUpdate && (
            <span className="text-body-small text-grey-500">{getLastUpdateLabel(parsedRequest.lastDateUpdate)}</span>
          )}
          {isNew && <NewMessageIndicator />}
          <Tag variant={colorMap[parsedRequest.status]}>
            <span className="text-h100">{t(`LABEL_REQUEST_STATUS_${parsedRequest.status}`)}</span>
          </Tag>
        </div>
      </div>
    </IonItem>
  )
}
