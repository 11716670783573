import { Facility, User } from 'src/models'
import { Conversation } from 'src/models/events/Conversation'
import { createSlice } from '@reduxjs/toolkit'
import { facilityReducers as reducers } from './reducers'

export type FacilityState = {
  currentFacility: Facility
  petOwners: User[]
  veterinarians: User[]
  requests: Conversation[]
  loaded: boolean
  lastUpdated: Date | null
}

const initialState: FacilityState = {
  currentFacility: new Facility({}),
  petOwners: [],
  veterinarians: [],
  requests: [],
  loaded: false,
  lastUpdated: null,
}

export const slice = createSlice({
  name: 'facility',
  initialState,
  reducers,
})

export const { actions: facilityReducers } = slice

export const facilitySliceReducer = slice.reducer
