import { DateConverter } from 'src/utils'
import { CalendarEventStatus } from '../enums'
import { ParentEventPayload, ParentEventPayloadInterface } from './ParentEventPayload'

export type CalendarEventTypeInterface = {
  title?: string
  subtitle?: string
  start?: string | Date | null
  end?: string | Date | null
  allDay?: boolean
  resource?: string | string[]
}

export type CalendarEventType = {
  title: string
  subtitle: string
  start: Date | null
  end: Date | null
  allDay: boolean
  resource: string | string[]
}

export type CalendarEventPayloadInterface = ParentEventPayloadInterface & {
  event: CalendarEventTypeInterface
  valid?: boolean
  invalidatedAt?: string | Date | null
  invalidatedBy?: string | null
  updatedByUserId?: string | null
  status?: CalendarEventStatus
}

export class CalendarEventPayload extends ParentEventPayload {
  event: CalendarEventType
  valid: boolean
  invalidatedAt: Date | null
  invalidatedBy: string | null
  updatedByUserId?: string | null
  status: CalendarEventStatus

  constructor(payload: CalendarEventPayloadInterface) {
    super(payload)
    this.valid = !!payload.valid
    this.invalidatedAt = payload.invalidatedAt ? DateConverter.fromServer(payload.invalidatedAt) : null
    this.invalidatedBy = payload.invalidatedBy || null
    this.updatedByUserId = payload.updatedByUserId || null
    this.status = payload.status || CalendarEventStatus.CALENDAR_EVENT_STATUS_NEW

    this.event = {
      title: payload.event.title || '',
      subtitle: payload.event.subtitle || '',
      start: payload.event.start ? DateConverter.fromServer(payload.event.start) : null,
      end: payload.event.end ? DateConverter.fromServer(payload.event.end) : null,
      allDay: payload.event.allDay || false,
      resource: payload.event.resource || '',
    }
  }

  json(): any {
    return {
      ...super.json(),
      event: {
        title: this.event.title,
        subtitle: this.event.subtitle,
        start: this.event.start ? DateConverter.toServer(this.event.start) : null,
        end: this.event.end ? DateConverter.toServer(this.event.end) : null,
        allDay: this.event.allDay,
        resource: this.event.resource,
      },
      valid: this.valid,
      invalidatedAt: this.invalidatedAt,
      invalidatedBy: this.invalidatedBy,
      updatedByUserId: this.updatedByUserId,
      status: this.status,
    }
  }
}
