import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { FormRequestsFiltersMobile } from 'src/apps/veterinary/forms/FormRequestsFiltersMobile'
import { getRequestsFilters } from 'src/features/Veterinary/selectors'
import { veterinaryReducers } from 'src/features/Veterinary/slice'
import { useCurrentValues } from 'src/hooks/useCurrentValues'
import { RequestsFilters, RequestsFiltersDefaultState } from 'src/models/filters/RequestsFilters'
import { IonButton, IonButtons, IonContent, IonFooter, IonPage } from '@ionic/react'
import { NavHeader } from '../../components/Header/NavHeader'

type ModalRequestsFiltersProps = {
  onDismiss: () => void
  onSuccess: () => void
}
export const ModalRequestsFilters: FC<ModalRequestsFiltersProps> = ({ onDismiss, onSuccess }) => {
  const { t } = useTranslation()
  const { facilities } = useCurrentValues()
  const dispatch = useDispatch()
  const requestsFilters = useSelector(getRequestsFilters)
  const [filters, setFilters] = useState<RequestsFilters>(requestsFilters)

  const onFiltersChange = (opts: any) => setFilters(opts)
  const onApplyClicked = async () => {
    await dispatch(veterinaryReducers.setRequestsFilters(filters))
    onSuccess()
  }

  const resetFilters = async () => {
    await dispatch(veterinaryReducers.setRequestsFilters(RequestsFiltersDefaultState))
    onSuccess()
  }

  return (
    <IonPage>
      <NavHeader title={t('TITLE_FILTER_EVENTS_SHORT')} showBackButton={true} backAction={onDismiss}>
        <IonButtons slot="end">
          <IonButton className="px-3 link" fill="clear" onClick={onApplyClicked}>
            <span className="font-bold">{t('ACTION_APPLY')}</span>
          </IonButton>
        </IonButtons>
      </NavHeader>
      <IonContent>
        <FormRequestsFiltersMobile
          onChange={onFiltersChange}
          className="py-6"
          loading={false}
          facilities={facilities}
          defaultValues={filters}
        />
      </IonContent>
      <IonFooter class="w-full flex items-center justify-center h-20">
        <div className="cursor-pointer link font-bold text-h200" onClick={resetFilters}>
          {t('ACTION_RESET')}
        </div>
      </IonFooter>
    </IonPage>
  )
}
