import { isSameDay } from 'date-fns'
import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CalendarAPI } from 'src/api/CalendarAPI'
import { EventSuggestionCard } from 'src/components/events/EventSuggestionCard'
import { IconArrow, IconArrowType, IconClose } from 'src/components/icons'
import { EventSuggestion } from 'src/models/events/EventSuggestion'
import { DATE_MONTHS_US, DATE_WEEKDAYS, DateConverter } from 'src/utils'
import { IonButton, IonButtons, IonContent, IonFooter, IonModal, IonPage } from '@ionic/react'
import { useQueryClient } from '@tanstack/react-query'
import { NavHeader } from '../components/Header/NavHeader'

type ModalEventSuggestionsProps = {
  startDate?: Date
  facilityId: string
  familyId: string
  servicesIds: string[]
  petId: string
  duration: string | number
  onCancelEvent: () => void
  onDismiss: () => void
  onSuccess: (value: EventSuggestion) => void
  queryClient?: any
}
export const ModalEventSuggestions: FC<ModalEventSuggestionsProps> = ({
  startDate = DateConverter.now(),
  facilityId,
  familyId,
  servicesIds,
  petId,
  duration,
  onDismiss,
  onCancelEvent,
  onSuccess,
  queryClient,
}) => {
  const { t } = useTranslation()
  const [date, setDate] = useState<Date>(startDate)
  const [firstAvaibleDate, setFirstAvaibleDate] = useState<Date | null>(null)
  const [selectedSuggestion, setSelectedSuggestion] = useState<EventSuggestion>()
  const [suggestions, setSuggestions] = useState<EventSuggestion[]>([])
  const [confirmDelete, setConfirmDelete] = useState(false)
  const dayNumber = date.getDate()
  const weekDay = DATE_WEEKDAYS[date.getDay()]
  const month = DATE_MONTHS_US[date.getMonth()]

  const getSuggestions = async () => {
    const suggestions = await CalendarAPI.getSuggestions(date, familyId, facilityId, petId, servicesIds, 1, duration)
    setSuggestions(suggestions.length ? suggestions[0].suggestions : [])
    if (firstAvaibleDate === null && suggestions?.[0]?.date) {
      setFirstAvaibleDate(suggestions[0]?.date)
      setDate(suggestions[0]?.date)
    }
  }

  const onSelected = () => {
    queryClient.invalidateQueries({ queryKey: ['conversations'] })
    selectedSuggestion && onSuccess && onSuccess(selectedSuggestion)
    onDismiss()
  }

  const onConfirmDelete = () => {
    setConfirmDelete(false)
    onDismiss()
    onCancelEvent()
  }

  const goToToday = () => setDate(DateConverter.now())

  const onPreviousDay = () => {
    const newDate = DateConverter.from(date.getTime())
    newDate.setDate(newDate.getDate() - 1)
    setDate(newDate)
  }

  const onNextDay = () => {
    const newDate = DateConverter.from(date.getTime())
    newDate.setDate(newDate.getDate() + 1)
    setDate(newDate)
  }

  useEffect(() => {
    getSuggestions()
  }, [date, setDate])

  return (
    <IonPage>
      <NavHeader title={t('TITLE_SELECT_DATE_AND_HOUR')} showBackButton={false} backAction={onDismiss}>
        <IonButtons slot="end">
          <IonButton className="px-3 link" fill="clear" onClick={onDismiss}>
            <IconClose className="w-6 h-6" />
          </IonButton>
        </IonButtons>
      </NavHeader>
      <IonContent>
        <IonModal isOpen={confirmDelete} mode="ios">
          <div className="h-full p-4 flex flex-col align-center justify-center text-center">
            <h1 className="text-primary-500 pb-8">{t('ALERT_DELETE_CALENDAR_EVENT_MESSAGE')}</h1>
            <div className="flex justify-between	">
              <IonButton color="primary" size="small" fill="outline" onClick={() => setConfirmDelete(false)}>
                {t('ACTION_CANCEL')}
              </IonButton>
              <IonButton color="danger" size="small" fill="outline" onClick={onConfirmDelete}>
                {t('ACTION_DELETE')}
              </IonButton>
            </div>
          </div>
        </IonModal>
        <div className="flex flex-col min-h-full px-4">
          <section className="flex justify-between items-center mb-10">
            <div className="w-20">
              <IonButton
                disabled={firstAvaibleDate !== null && !isSameDay(DateConverter.now(), firstAvaibleDate)}
                color="primary"
                size="small"
                fill="clear"
                onClick={goToToday}
              >
                {t('LABEL_TODAY')}
              </IonButton>
            </div>

            <div className="flex flex-col items-center py-4 bg-white rounded-t">
              <span className="text-grey-600 uppercase mb-2">
                {weekDay.substr(0, 3)}, {month}
              </span>
              <div className={`w-12 h-12 font-bold text-center text-h400 rounded-full px-1 text-white bg-primary-500`}>{dayNumber}</div>
            </div>

            <div>
              <IonButton
                disabled={firstAvaibleDate !== null && isSameDay(date, firstAvaibleDate)}
                color="primary"
                fill="clear"
                className="toolbar-navigation-button"
                type="button"
                onClick={onPreviousDay}
              >
                <IconArrow className="w-4 h-4 mx-3" type={IconArrowType.LEFT} />
              </IonButton>
              <IonButton color="primary" fill="clear" className="toolbar-navigation-button" type="button" onClick={onNextDay}>
                <IconArrow className="w-4 h-4 mx-3" type={IconArrowType.RIGHT} />
              </IonButton>
            </div>
          </section>
          {suggestions.length > 0 ? (
            <EventSuggestionCard
              date={date}
              suggestions={suggestions}
              showDate={false}
              label={t('LABEL_AVAILABLE_SLOTS')}
              onChange={setSelectedSuggestion}
            />
          ) : (
            <div className="flex-grow flex flex-col items-center justify-center -mt-32 px-8">
              <div className="text-center">
                <h2 className="text-lead font-bold mb-6">{t('TITLE_SORRY')}</h2>
                <p className="text-body-small text-grey-500">{t('LABEL_SLOT_NOT_AVAILABLE')}</p>
              </div>
            </div>
          )}
        </div>
      </IonContent>
      {selectedSuggestion ? (
        <IonFooter className="p-4 flex justify-between items-center">
          <IonButton color="danger" size="small" fill="clear" onClick={() => setConfirmDelete(true)}>
            {t('ACTION_DELETE_APPOINTMENT')}
          </IonButton>
          <IonButton fill="solid" onClick={onSelected}>
            <span className="px-4">{t('ACTION_SAVE')}</span>
          </IonButton>
        </IonFooter>
      ) : (
        <IonFooter className="p-4 ">
          <IonButton color="danger" size="small" fill="clear" onClick={() => setConfirmDelete(true)}>
            {t('ACTION_DELETE_APPOINTMENT')}
          </IonButton>
        </IonFooter>
      )}
    </IonPage>
  )
}
