import React, { forwardRef } from 'react'
import { Control, useController } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FieldWrapper } from 'src/components/forms/FieldWrapper'
import { GenericProps } from 'src/helpers/props'
import { EventPriority } from 'src/models/events/enums'
import { IonItem, IonLabel, IonRadio, IonRadioGroup } from '@ionic/react'

type PrioritySelectorProps = GenericProps & {
  name: string
  control: Control<any>
}

export const PrioritySelector: React.FC<PrioritySelectorProps> = forwardRef<HTMLIonRadioGroupElement, PrioritySelectorProps>(
  ({ name, className = '', control }, ref) => {
    const { t } = useTranslation()

    const {
      field: { value, onChange },
      fieldState: { error },
    } = useController({ name, control })

    return (
      <FieldWrapper className={className} error={error && error.message} label={t('LABEL_PRIORITY')}>
        <IonRadioGroup ref={ref} className="flex space-x-2" value={value} onIonChange={onChange}>
          <IonItem
            className={`rounded-md border-2 border-solid ${
              value === EventPriority.EVENT_PRIORITY_HIGH ? 'border-error-500' : 'border-error-200'
            }`}
          >
            <IonRadio value={EventPriority.EVENT_PRIORITY_HIGH} className="ml-3" />
            <IonLabel className="px-3">{t('LABEL_PRIORITY_HIGH')}</IonLabel>
          </IonItem>
          <IonItem
            className={`rounded-md border-2 border-solid ${
              value === EventPriority.EVENT_PRIORITY_MEDIUM ? 'border-warning-500' : 'border-warning-300'
            }`}
          >
            <IonRadio value={EventPriority.EVENT_PRIORITY_MEDIUM} className="ml-3" />
            <IonLabel className="px-3">{t('LABEL_PRIORITY_MEDIUM')}</IonLabel>
          </IonItem>
          <IonItem
            className={`rounded-md border-2 border-solid ${
              value === EventPriority.EVENT_PRIORITY_LOW ? 'border-grey-500' : 'border-grey-200'
            }`}
          >
            <IonRadio value={EventPriority.EVENT_PRIORITY_LOW} className="ml-3" />
            <IonLabel className="px-3">{t('LABEL_PRIORITY_LOW')}</IonLabel>
          </IonItem>
        </IonRadioGroup>
      </FieldWrapper>
    )
  }
)

PrioritySelector.displayName = 'PrioritySelector'
