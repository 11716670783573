import { format } from 'date-fns'
import { enUS } from 'date-fns/locale'
import React, { ReactChild } from 'react'
import { Avatar } from 'src/components/Avatar'
import { ChildEventMask } from 'src/models/events'
import { EventType } from 'src/models/events/enums'
import { EventCardInternalProps } from 'src/services/EventCardConverter'
import { formatDate } from 'src/utils'
import { valid } from 'tiinvo/date'
import { DebugDisplay } from '../../DebugDisplay'
import { PetChip } from '../../PetChip'
import { Tag, TagVariant } from '../../Tag'
import { IconStarFill } from '../../icons'

type EventCardWrapperProps = {
  event: ChildEventMask
  opts: EventCardInternalProps
  active?: boolean
  icon: ReactChild
  action: ReactChild
  footer: ReactChild
  onClick?: any
  readonly?: boolean
  className?: string
}

export const EventCardWrapper: React.FC<EventCardWrapperProps> = ({
  event,
  className = '',
  opts,
  active = false,
  icon,
  action,
  footer,
  onClick,
  readonly = false,
}) => {
  let test
  const validDate = isDateValid(opts?.enrichmentData?.Appointment?.payload?.event?.start)

  function isDateValid(dateStr: string) {
    if (!dateStr) return false

    return !isNaN(new Date(dateStr).getTime())
  }

  if (validDate) {
    test = new Date(opts?.enrichmentData?.Appointment?.payload?.event?.start)
  }

  return (
    <div className={`event-card flex ${className}`} {...(onClick && !readonly ? { onClick } : null)}>
      <div
        className={`event-card__badge flex-shrink-0 flex justify-center items-center w-8 h-8 mt-8 rounded-full bg-white z-10 border-4 border-primary-500`}
      >
        {icon ? icon : <IconStarFill className="h-4 text-primary-500" />}
      </div>
      <div
        className={`event-card__container relative cursor-pointer bg-white rounded-xl shadow-light-60 w-full flex flex-col min-w-0 ${
          active ? '-mx-4' : 'ml-3'
        }`}
      >
        <DebugDisplay>{event.stringify()}</DebugDisplay>
        <div className="p-4">
          {event.type === EventType.EVENT_TYPE_HEALTH && (
            <div className="flex items-center justify-between text-body-small border-grey-200 border-b h-8 mb-2">
              <div className="flex items-center mb-4">
                <Avatar className="w-8 h-8 mr-2" title={opts.facility?.initials}></Avatar> {opts.facility?.name}
              </div>
              <div className="card-actions mb-4">{action}</div>
            </div>
          )}

          <div className="flex justify-between text-body-small">
            <div className="text-grey-600 text-body-tiny">
              {format(test || opts.eventAppointmentDate || opts.eventCreationDate, 'MMMM dd, yyyy h:mm a', { locale: enUS })}
            </div>
            <Tag variant={TagVariant.TAG_OUTLINE_NEUTRAL}>#{opts.eventNumber}</Tag>
          </div>
          <div className="flex flex-col mt-2">
            <h5 className="font-bold truncate">{opts.title}</h5>
            <div className="relative mt-2" style={{ marginRight: opts.pets.length * 16 }}>
              {opts.pets.map((pet, index) => (
                <div key={pet.id} className={index > 0 ? 'absolute top-0' : ''} style={index > 0 ? { marginLeft: index * 16 + `px` } : {}}>
                  <PetChip pet={pet} readonly={true} active={false} />
                </div>
              ))}
            </div>
          </div>
        </div>
        {footer}
      </div>
    </div>
  )
}
