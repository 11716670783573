import { BaseEvent, BaseEventInterface } from './BaseEvent'
import { EventCategory, EventType } from './enums'
import { HealthEventPayload, HealthEventPayloadInterface } from './payload/HealthEventPayload'

const VALID_HEALTH_CATEGORIES = [
  EventCategory.EVENT_CATEGORY_HEALTH_VISIT,
  EventCategory.EVENT_CATEGORY_HEALTH_CONSULTATION,
  EventCategory.EVENT_CATEGORY_HEALTH_VIDEOCONSULTATION,
]

const getValidHealthCategory = (category: EventCategory): EventCategory =>
  VALID_HEALTH_CATEGORIES.includes(category) ? category : EventCategory.EVENT_CATEGORY_HEALTH_CONSULTATION

export class HealthEvent extends BaseEvent<HealthEventPayloadInterface> {
  constructor(data: BaseEventInterface, payload: HealthEventPayloadInterface) {
    data.type = EventType.EVENT_TYPE_HEALTH
    data.category = getValidHealthCategory(data.category || EventCategory.EVENT_CATEGORY_NONE)
    super(data, payload)
  }

  parsePayload(payload: any) {
    return new HealthEventPayload(payload)
  }
}
