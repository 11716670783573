import { sortBy } from 'lodash'
import orderBy from 'lodash/orderBy'
import sortedUniqBy from 'lodash/sortedUniqBy'
import { Pet } from 'src/models'
import { Conversation } from 'src/models/events/Conversation'
import { PayloadAction } from '@reduxjs/toolkit'
import { DateConverter } from '../../../utils'
import { FamilyByIdPayload } from '../payload/FamilyByIdPayload'
import { generateBotEvents } from './botEvents'
import { FamilyState } from './slice'

export const familyReducers = {
  setLoading: (state: FamilyState, action: PayloadAction<boolean>): void => {
    state.loading = action.payload
  },
  setLoadingConversations: (state: FamilyState, action: PayloadAction<boolean>): void => {
    state.loadingConversations = action.payload
  },
  setFamily: (state: FamilyState, action: PayloadAction<FamilyByIdPayload>): void => {
    state.family = action.payload.family
    state.members = action.payload.members
    state.pets = sortBy(action.payload.pets, 'name')
  },
  setPets: (state: FamilyState, action: PayloadAction<Pet[]>): void => {
    state.pets = sortBy(action.payload, 'name')
  },
  setPastConversations: (state: FamilyState, action: PayloadAction<Conversation[]>): void => {
    const pastConversations = action.payload.map(generateBotEvents)
    state.pastConversations = orderBy(pastConversations || [], (data: Conversation) => data.parentEvent?.payload?.date, 'desc')
    state.lastUpdated = DateConverter.now()
  },
  setConversations: (state: FamilyState, action: PayloadAction<Conversation[]>): void => {
    const conversations = action.payload.map(generateBotEvents)
    state.conversations = orderBy(conversations || [], (data: Conversation) => data.parentEvent.dateUpdated, 'desc')
    state.lastUpdated = DateConverter.now()
  },
  addConversations: (state: FamilyState, action: PayloadAction<Conversation[]>): void => {
    state.conversations = sortedUniqBy([...action.payload.map(generateBotEvents), ...state.conversations], (data: Conversation) => data.id)
    state.lastUpdated = DateConverter.now()
  },
  addPastConversations: (state: FamilyState, action: PayloadAction<Conversation[]>): void => {
    state.pastConversations = sortedUniqBy(
      [...action.payload.map(generateBotEvents), ...state.pastConversations],
      (data: Conversation) => data.id
    )
    state.lastUpdated = DateConverter.now()
  },
}
