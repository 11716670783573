import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { FullLogo } from 'src/components/icons/Logos'
import { authThunks } from 'src/features/Auth/store/thunks'
import { useCurrentUser } from 'src/hooks/useCurrentValues'
import { useToast } from 'src/hooks/useToast'
import { IonButton, IonContent, IonPage } from '@ionic/react'
import { NavHeader } from '../components/Header/NavHeader'

export const PageDisclaimer: FC = () => {
  const history = useHistory()
  const { t } = useTranslation()
  const currentUser = useCurrentUser()
  const dispatch = useDispatch()
  const toast = useToast()

  const onLogout = async () => {
    try {
      await dispatch(authThunks.deletePushNotificationToken())
      await dispatch(authThunks.logout())
      history.push('/login')
    } catch ({ message }) {
      toast.error(t(message as string))
    }
  }

  return (
    <IonPage>
      <NavHeader imageTitle={<FullLogo className="h-12 pl-0 mb-0" />} showBackButton={false} />
      <IonContent>
        <div className="h-full flex flex-col justify-between">
          <div></div>
          <div className="text-h200">
            <div className="my-8 text-h300 font-bold">{t('DESCRIPTION_HELLO_USER', { name: currentUser.firstName })}</div>
            <div>{t('DESCRIPTION_VET_ON_PET_OWNER_APP_2')}</div>
            <div className="link text-h200 my-8">
              {/* TODO: implementare apertura browser mobile */}
              <a href="https://web.veterly.com">web.veterly.com</a>
            </div>
            <div>{t('DESCRIPTION_VET_ON_PET_OWNER_APP_3')}</div>
          </div>
          <IonButton className="w-full mb-4" onClick={onLogout}>
            {t('ACTION_EXIT')}
          </IonButton>
        </div>
      </IonContent>
    </IonPage>
  )
}
