import addHours from 'date-fns/addHours'
import isBefore from 'date-fns/isBefore'
import { BaseEvent, BaseEventInterface } from './BaseEvent'
import { CalendarEventStatus, EventCategory, EventType } from './enums'
import { CalendarEventPayload, CalendarEventPayloadInterface, CalendarEventType } from './payload/CalendarEventPayload'

const VALID_CALENDAR_CATEGORIES = [EventCategory.EVENT_CATEGORY_CALENDAR_OUT_OF_OFFICE, EventCategory.EVENT_CATEGORY_CALENDAR_BOOKING]

const getValidCalendarCategory = (category: EventCategory): EventCategory =>
  VALID_CALENDAR_CATEGORIES.includes(category) ? category : EventCategory.EVENT_CATEGORY_NONE

export class CalendarEvent extends BaseEvent<CalendarEventPayloadInterface> {
  constructor(data: BaseEventInterface, payload: CalendarEventPayloadInterface) {
    data.type = EventType.EVENT_TYPE_CALENDAR
    data.category = getValidCalendarCategory(data.category || EventCategory.EVENT_CATEGORY_NONE)
    super(data, payload)
  }

  get Event(): CalendarEventType {
    return this.payload.event
  }

  parsePayload(payload: any) {
    return new CalendarEventPayload(payload)
  }

  isValid(): boolean {
    return this.payload.valid
  }

  isEditableFromPetOwner() {
    const date = this.payload.event.start
    const compareDate = addHours(new Date(), 24)

    return isBefore(compareDate, date)
  }

  isToBeConfirmed() {
    return this.payload.status === CalendarEventStatus.CALENDAR_EVENT_STATUS_TO_BE_CONFIRMED
  }

  isToBeBooked() {
    return this.payload.status === CalendarEventStatus.CALENDAR_EVENT_STATUS_TO_BE_BOOKED
  }

  isReserved() {
    return this.payload.status === CalendarEventStatus.CALENDAR_EVENT_STATUS_RESERVATION
  }

  isMissingStartingDate() {
    return this.payload.event.start === null
  }

  isConfirmed() {
    const statuses = [CalendarEventStatus.CALENDAR_EVENT_STATUS_UPDATED, CalendarEventStatus.CALENDAR_EVENT_STATUS_NEW]
    return statuses.includes(this.payload.status)
  }

  isNotConfirmed() {
    const statuses = [
      CalendarEventStatus.CALENDAR_EVENT_STATUS_RESERVATION,
      CalendarEventStatus.CALENDAR_EVENT_STATUS_TO_BE_BOOKED,
      CalendarEventStatus.CALENDAR_EVENT_STATUS_TO_BE_CONFIRMED,
    ]
    return statuses.includes(this.payload.status)
  }
}
