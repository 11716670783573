import { useEffect, useState } from 'react'
import { generateBotEvents } from 'src/features/Family/store/botEvents'
import { FamilyAPI } from '../api/FamilyAPI'
import { Conversation } from '../models/events'
import { useCurrentFamily } from './useCurrentValues'

export const useConversation = (parentEventId: string, onLoaded?: () => void) => {
  const { family } = useCurrentFamily()
  const [conversation, setConversation] = useState<Conversation>(Conversation.generateInvalid)

  const loadConversation = async () => {
    const conv: Conversation = await FamilyAPI.getConversationTreeById(parentEventId, family)
    const enrichedConversation = generateBotEvents(conv)

    setConversation(enrichedConversation)
    onLoaded && onLoaded()
  }

  useEffect(() => {
    loadConversation()
  }, [])

  return { conversation, reloadConversation: loadConversation }
}
