import sortBy from 'lodash/sortBy'
import { Facility } from 'src/models'
import { TimeTable, WorkingTime } from '../models/TimeTable'
import { axiosInstance, getCacheConfig } from './axios'

export type CurrentWorkingHours = {
  isOpen: boolean
  hours: string
}

export class TimeTableAPI {
  static async create(facility: Facility, data: TimeTable): Promise<any> {
    return axiosInstance.post('/timetables', {
      timetables: [data.json()],
      FacilityId: facility.id,
    })
  }

  static async update(facility: Facility, time: TimeTable): Promise<any> {
    return axiosInstance.patch(`/timetables?id=${time.id}`, time.json())
  }

  static async getAll(facilityId: string, skipCache = false): Promise<any> {
    return axiosInstance
      .get(`/timetables/list?FacilityId=${facilityId}`, getCacheConfig(skipCache))
      .then(({ data }) => sortBy(data, 'name').map((timetable: any) => new TimeTable(timetable)))
      .catch(() => Promise.resolve([]))
  }

  static async getById(timetableId: string): Promise<any> {
    return axiosInstance
      .get(`/timetables`, { params: { timetableId } })
      .then(({ data }) => new TimeTable(data))
      .catch(() => Promise.resolve([]))
  }

  static async delete(facility: Facility, timeTableId: number): Promise<any[]> {
    return axiosInstance.delete(`/timetables?FacilityId=${facility.id}&id=${timeTableId}`)
  }

  static async getCurrentWorkingHours(facilityId: string, skipCache = false): Promise<CurrentWorkingHours> {
    const now = new Date()
    const weekday = now.getDay()
    let isOpen = false
    let hours = ''

    const timetables = await axiosInstance
      .get(`/timetables/list?FacilityId=${facilityId}`, getCacheConfig(skipCache))
      .then(({ data }) => sortBy(data, 'name').map((timetable: any) => new TimeTable(timetable)))
      .catch(() => Promise.resolve([]))

    const defaultTimetable = timetables.find((timetable) => timetable.default)

    if (!defaultTimetable) {
      return { isOpen, hours }
    }

    const workingHours = defaultTimetable.getHoursByWeekDay(weekday)

    if (!workingHours) {
      return { isOpen, hours }
    }

    isOpen = workingHours.some((h: WorkingTime) => this.isFacilityOpen(now, h))

    hours = workingHours.map((h: any) => `${h.from} - ${h.to}`).join(' / ')

    return { isOpen, hours }
  }

  static isFacilityOpen = (date: Date, h: WorkingTime) => {
    const day = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
    const from = new Date(`${day} ${h.from}`)
    const to = new Date(`${day} ${h.to}`)

    return date >= from && date <= to
  }

  static async getDefaults(): Promise<any> {
    return axiosInstance.get('/timetables/defaults').then((data) => data.data)
  }

  static async setFacilityTimetable(facility: Facility, defTimetableId: string): Promise<any> {
    const body = { facilityId: facility.id, defTimetableId }

    return axiosInstance.patch('/timetables/defaults', body)
  }

  static async updateFacilityTimetable(facility: Facility, defTimetableId: string): Promise<any> {
    const body = { facilityId: facility.id, defTimetableId }

    return axiosInstance.patch('/timetables/defaults', body)
  }
}
