import isEmpty from 'lodash/isEmpty'
import { GlobalStoreState } from 'src/Store'
import { PetsAPI } from 'src/api'
// import { EventsAPI } from 'src/api/EventsAPI'
import { FamilyAPI } from 'src/api/FamilyAPI'
import { FilterState } from 'src/apps/veterinary/forms/FormDiaryFilters'
import { Family } from 'src/models'
import { AllEventMask } from 'src/models/events'
import { Conversation } from 'src/models/events/Conversation'
import { Dispatch } from '@reduxjs/toolkit'
import { familyReducers } from './slice'

export const familyThunks = {
  setFamily:
    (familyId: string) =>
    async (dispatch: Dispatch, getState: () => GlobalStoreState): Promise<any> => {
      dispatch(familyReducers.setLoading(true))
      dispatch(familyReducers.setLoadingConversations(true))

      const facility = getState().facility.currentFacility
      const { family, members, pets, facilities } = await FamilyAPI.getById(familyId, facility.id)

      dispatch(familyReducers.setFamily({ family, members, pets, facilities }))
      dispatch(familyReducers.setLoading(false))

      //   const conversations: Conversation[] = await FamilyAPI.getPendingConversations(family, facility)
      //const pastConversations: Conversation[] = await FamilyAPI.getPastConversations(family, facility, { page: 0 })

      //   dispatch(familyReducers.setConversations(conversations))
      //dispatch(familyReducers.setPastConversations(pastConversations))
      dispatch(familyReducers.setLoadingConversations(false))
    },

  updateFamily:
    () =>
    async (dispatch: Dispatch, getState: () => GlobalStoreState): Promise<any> => {
      const familyId = getState().family.family.id

      const facility = getState().facility.currentFacility
      const { family, members, pets, facilities } = await FamilyAPI.getById(familyId, facility.id)
      dispatch(familyReducers.setFamily({ family, members, pets, facilities }))
    },

  updatePets:
    () =>
    async (dispatch: Dispatch, getState: () => GlobalStoreState): Promise<any> => {
      try {
        const pets = await PetsAPI.getPets(getState().family.family.id)
        dispatch(familyReducers.setPets(pets))
        return Promise.resolve(pets)
      } catch (err) {
        return Promise.reject(err)
      }
    },

  getConversationsByFamily:
    (page: number, filters?: FilterState) =>
    async (dispatch: Dispatch, getState: () => GlobalStoreState): Promise<any> => {
      dispatch(familyReducers.setLoadingConversations(true))

      const family = getState().family.family
      const facility = getState().facility.currentFacility

      //   const conversations: Conversation[] = await FamilyAPI.getPendingConversations(family, facility, filters)
      //const pastConversations: Conversation[] = await FamilyAPI.getPastConversations(family, facility, { page }, filters)

      //   dispatch(familyReducers.setConversations(conversations))

      // if (page === 0) {
      //   dispatch(familyReducers.setPastConversations(pastConversations))
      // } else {
      //   dispatch(familyReducers.addPastConversations(pastConversations))
      // }
      dispatch(familyReducers.setLoadingConversations(false))
    },

  updateFamilyEvents:
    (family: Family) =>
    async (dispatch: Dispatch, getState: () => GlobalStoreState): Promise<AllEventMask[]> => {
      const lastUpdatedDate = getState().family.lastUpdated
      const facility = getState().facility.currentFacility

      if (lastUpdatedDate && family && !isEmpty(family?.id)) {
        const conversations = await FamilyAPI.getPendingConversations(family, facility, undefined, lastUpdatedDate)

        // const conversations = await EventsAPI.getLatestEvents(
        //   {
        //     payload: {
        //       familyId: family.id,
        //     },
        //   },
        //   lastUpdatedDate
        // )

        if (conversations.length === 0) {
          return []
        }

        dispatch(familyReducers.addConversations(conversations))
      }

      return []
    },
}
