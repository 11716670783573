import React, { useState } from 'react'
import { Calendar, CalendarProps } from 'react-big-calendar'
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import 'src/theme/calendar.css'
import { DateConverter, formatDate } from '../../utils'
import { AgendaEvent } from './AgendaEvent'
import { CalendarDayHeader } from './CalendarDayHeader'
import { ToolbarDay } from './ToolbarDay'
import { calendarLocalizer } from './localize'

const DragAndDropCalendar = withDragAndDrop(Calendar as any)

const getConfiguration = (): CalendarProps => ({
  components: {
    event: AgendaEvent,
    toolbar: ToolbarDay,
    week: {
      header: CalendarDayHeader,
    },
    day: {
      header: CalendarDayHeader,
    },
  },
  step: 30,
  timeslots: 2,
  min: new Date(2021, 1, 1, 0, 0),
  max: new Date(2021, 12, 21, 23, 59),
  scrollToTime: DateConverter.now(),
  localizer: calendarLocalizer,
  views: ['day'],
  defaultView: 'day',
})

export type SchedulerProps = {
  events: any[]
  selectable?: boolean
  disabled?: boolean
  allDay?: boolean
  onMoveEvent?: (data: any) => void
  onSelectSlot?: (data: any) => void
  onSelectEvent?: (data: any) => void
}

export const Scheduler: React.FC<SchedulerProps> = ({
  selectable = true,
  events,
  allDay = false,
  onSelectSlot = () => ({}),
  onSelectEvent = () => ({}),
  onMoveEvent = () => ({}),
  disabled = false,
}) => {
  const configuration = getConfiguration() // 'day')

  const [draggedEvent, setDraggedEvent] = useState<any>(null)

  console.log(draggedEvent, events)
  console.log(allDay)

  const moveEvent = ({ event, start, end, isAllDay: droppedOnAllDaySlot }: any) => {
    if (disabled) return null

    let allDay = event.allDay

    if (!event.allDay && droppedOnAllDaySlot) {
      allDay = true
    } else if (event.allDay && !droppedOnAllDaySlot) {
      allDay = false
    }

    const nextEvents = events.map((existingEvent: any) =>
      existingEvent.id == event.id ? { ...existingEvent, start, end, allDay } : existingEvent
    )
    const evt = nextEvents.find((existingEvent: any) => existingEvent.id == event.id)

    if (onMoveEvent) {
      onMoveEvent(evt)
    }
    // alert(`${event.title} was dropped onto ${updatedEvent.start}`)
  }

  const resizeEvent = ({ event, start, end }: any) => {
    const nextEvents = events.map((existingEvent: any) => (existingEvent.id == event.id ? { ...existingEvent, start, end } : existingEvent))
    const evt = nextEvents.find((existingEvent: any) => existingEvent.id == event.id)

    if (onSelectEvent) {
      onSelectEvent(evt)
    }
  }

  const handleDragStart = (event: any) => setDraggedEvent(event)

  return (
    <DragAndDropCalendar
      {...configuration}
      selectable={selectable}
      resizable={selectable}
      toolbar={true}
      events={events}
      showMultiDayTimes
      defaultDate={DateConverter.now()}
      onSelectEvent={onSelectEvent}
      onSelectSlot={onSelectSlot}
      onDragStart={console.log}
      onEventResize={resizeEvent}
      handleDragStart={handleDragStart}
      onEventDrop={moveEvent}
      style={{ height: 2000 }}
      scrollToTime={DateConverter.now()}
      formats={{
        eventTimeRangeFormat: (range) => `${formatDate(range.start, 'HH:mm')} – ${formatDate(range.end, 'HH:mm')}`,
        timeGutterFormat: (date) => `${formatDate(date, 'HH:mm')}`,
      }}
    />
  )
}
