import React, { forwardRef } from 'react'
import { IonCheckbox, IonItem, IonLabel } from '@ionic/react'
import { FormError } from './FormError'

type InputFieldProps = {
  error?: string
  label: any
  className?: string
  itemClassName?: string
  checkboxClassName?: string
  description?: string
  readonly?: boolean
  [key: string]: any
}

export const InputCheckbox: React.FC<InputFieldProps> = forwardRef<HTMLIonCheckboxElement, InputFieldProps>(
  (
    { error, label, className = '', itemClassName = '', checkboxClassName = '', readonly = false, description, ...rest }: InputFieldProps,
    ref
  ) => (
    <div className={`block ${className} ${readonly ? 'item-checkbox-readonly' : ''}`}>
      <div className="flex items-center flex-shrink-0">
        <IonItem className={`${itemClassName}`}>
          <IonCheckbox
            slot="start"
            ref={ref}
            className={error ? `my-2 mr-3 has-error flex-shrink-0` : `my-2 mr-3 flex-shrink-0 ${checkboxClassName}`}
            {...rest}
          />
          <IonLabel class="ion-text-wrap flex items-start">{label}</IonLabel>
        </IonItem>
      </div>
      {description || error ? (
        <p className="relative">
          {description && !error && <span className="text-grey-600 text-body-tiny mt-1">{description}</span>}
          {error && <FormError message={error} />}
        </p>
      ) : null}
    </div>
  )
)

InputCheckbox.displayName = 'InputCheckbox'
