import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
// import { useCurrentCall } from 'src/hooks/useCurrentValues'
import { AllEventMask } from 'src/models/events'
import { useLocalParticipant, useParticipantIds, DailyVideo, DailyAudio } from '@daily-co/daily-react'

export const Call = ({
  className = '',
  // timerClassName = '',
  localParticipantClassName = '',
  remoteParticipantClassName = '',
}: {
  event?: AllEventMask
  className?: string
  timerClassName?: string
  localParticipantClassName: string
  remoteParticipantClassName: string
}) => {
  const { t } = useTranslation()
  const [calleeId, setCalleeId] = useState<string | null>()
  const localParticipant = useLocalParticipant()
  const remoteParticipantIds = useParticipantIds({ filter: 'remote' })
  // const { startTime } = useCurrentCall()
  // const [timer, setTimer] = useState<string>('00:00')

  useEffect(() => {
    const callee = remoteParticipantIds?.length > 0 ? remoteParticipantIds[0] : null
    setCalleeId(callee)
  }, [remoteParticipantIds])

  // const getTime = (start: Date) => {
  //   const time = Date.now() - Date.parse(start.toISOString())

  //   const seconds = ('0' + Math.floor((time / 1000) % 60)).slice(-2)
  //   const minutes = ('0' + Math.floor((time / 1000 / 60) % 60)).slice(-2)
  //   const hours = Math.floor((time / (1000 * 60 * 60)) % 24) > 0 ? ('0' + Math.floor((time / (1000 * 60 * 60)) % 24)).slice(-2) : null

  //   setTimer(`${hours ? `${hours}:` : ''}${minutes}:${seconds}`)
  // }

  // useEffect(() => {
  //   if (!startTime) return

  //   const interval = setInterval(() => getTime(startTime), 1000)

  //   return () => clearInterval(interval)
  // }, [startTime])

  return (
    <div className={`${className}`}>
      {/* <div className={`${timerClassName}`}>
        <span className="w-30">{timer}</span>
      </div> */}
      <div className={`${remoteParticipantClassName}`}>
        {calleeId ? (
          <DailyVideo style={{ borderRadius: 10 }} automirror sessionId={calleeId} type={'video'} />
        ) : (
          <div className="h-full flex items-center">{t('MESSAGE_WAITING_FOR_PARTICIPANTS')}</div>
        )}
      </div>

      {localParticipant && (
        <div className={`${localParticipantClassName}`}>
          <DailyVideo
            style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '90%', borderRadius: 30 }}
            automirror
            sessionId={localParticipant.session_id}
            type={'video'}
          />
        </div>
      )}

      <DailyAudio />
    </div>
  )
}
