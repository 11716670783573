import React from 'react'
import { useTranslation } from 'react-i18next'

export const FormError: React.FC<{ message: any }> = ({ message }) => {
  const { t } = useTranslation()

  if (message === undefined) {
    return null
  } else if (typeof message === 'string') {
    return <span className="text-error-400 text-body-tiny mt-1">{message}</span>
  } else {
    const { key, values } = message
    return <span className="text-error-400 text-body-tiny mt-1">{t(key, values)}</span>
  }
}
