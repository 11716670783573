import React, { FC, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { PetsAPI } from 'src/api'
import { submitForm } from 'src/components/forms/Form'
import { FormEditPet } from 'src/components/forms/FormEditPet'
import { CreatePetFormType } from 'src/features/Family/form/CreatePetFormType'
import { usePetAttributes } from 'src/hooks/data'
import { useCurrentFamily } from 'src/hooks/useCurrentValues'
import { useToast } from 'src/hooks/useToast'
import { IonButton, IonButtons, IonContent, IonLoading, IonPage, useIonAlert } from '@ionic/react'
import { NavHeader } from '../components/Header/NavHeader'

export const PageEditPet: FC = () => {
  const { petId } = useParams<{ petId: string }>()
  const { pets, family } = useCurrentFamily()
  const history = useHistory()

  const { t } = useTranslation()
  const toast = useToast()
  const formRef = useRef<HTMLFormElement>(null)
  const checkValidation = () => submitForm(formRef.current)

  const [showDeleteAlter] = useIonAlert()

  const pet = pets.find((p) => p.id === petId)

  const [loading, setLoading] = useState(false)
  const [speciesConfig] = usePetAttributes()

  const onSubmit = async (values: CreatePetFormType) => {
    setLoading(true)
    try {
      const pet = await PetsAPI.update(petId, values)
      toast.success(t('PET_UPDATED_SUCCESSFULLY', { name: pet.name }))

      setTimeout(() => {
        history.goBack()
      }, 50)
    } catch ({ message }) {
      toast.error(t(message as string))
    } finally {
      setLoading(false)
    }
  }
  const finalizeDeletePet = async () => {
    if (!pet) return

    setLoading(true)
    try {
      await PetsAPI.deletePet(pet)
      toast.success(t('PET_DELETED_SUCCESSFULLY', { name: pet.name }))
      history.push('/app/home')
    } catch ({ message }) {
      toast.error(t(message as string))
    } finally {
      setLoading(false)
    }
  }

  const deletePet = async () => {
    if (!pet) return

    showDeleteAlter({
      header: t('ALERT_DELETE_PET_TITLE'),
      message: t('ALERT_DELETE_PET_MESSAGE'),
      buttons: [t('ACTION_CANCEL'), { text: t('ACTION_DELETE'), handler: () => finalizeDeletePet() }],
    })
  }

  if (speciesConfig.species.length === 0) {
    return <IonLoading isOpen={true} />
  }

  return (
    <IonPage>
      <NavHeader title={pet?.name || ''} showBackButton={true}>
        <IonButtons slot="end">
          <IonButton onClick={checkValidation}>
            <span className="text-primary-500 font-bold px-2">{t('SAVE')}</span>
          </IonButton>
        </IonButtons>
      </NavHeader>
      <IonContent>
        <FormEditPet
          onSubmit={onSubmit}
          showAction={false}
          className="py-6"
          loading={loading}
          data={speciesConfig}
          familyId={family.id}
          defaultValue={pet}
          ref={formRef}
        />
        <IonButton fill="clear" color="danger" className="link mt-2 mb-10" onClick={deletePet}>
          {t('ACTION_DELETE_PET')}
        </IonButton>
      </IonContent>
    </IonPage>
  )
}
