import React, { ReactElement, useState } from 'react'
import { IonModal } from '@ionic/react'
import { DrawerSize } from '../components/Drawer'
import { DrawerEnterFromLeft, DrawerLeaveFromLeft } from '../components/animations'

type DrawerItem = {
  index: number
  size: DrawerSize
  component: ReactElement
  visible: boolean
  showBackdrop: boolean
  onDismiss: any
}

const DrawerContext = React.createContext<any>(undefined)

const DrawerProvider: React.FC = ({ children }) => {
  const [counter, setCounter] = useState(0)
  const [drawers, setDrawers] = useState<DrawerItem[]>([])

  const showDrawer = (component: React.ReactElement, size: DrawerSize, showBackdrop = true, onDismiss: any = () => ({})): number => {
    const index = counter + 1
    setCounter(index)
    setDrawers([
      ...drawers,
      {
        index,
        size,
        component,
        visible: true,
        showBackdrop,
        onDismiss,
      },
    ])

    return index
  }

  const hideLastDrawer = () => {
    const newDrawers = drawers.splice(-1, 1)
    setDrawers(newDrawers)
  }

  const hideAllDrawers = () => {
    setDrawers([])
  }

  return (
    <DrawerContext.Provider value={{ drawers, showDrawer, hideLastDrawer, hideAllDrawers }}>
      {drawers.map((drawer: DrawerItem) => (
        <IonModal
          key={drawer.index}
          isOpen={drawer.visible}
          cssClass={`${drawer.size} ${drawer.showBackdrop ? 'drawer-with-backdrop' : 'drawer-without-backdrop'}`}
          showBackdrop={drawer.showBackdrop}
          onWillDismiss={drawer.onDismiss}
          onDidDismiss={() => drawers.length == 1 && hideAllDrawers()}
          enterAnimation={DrawerEnterFromLeft}
          leaveAnimation={DrawerLeaveFromLeft}
        >
          {drawer.component}
        </IonModal>
      ))}

      {children}
    </DrawerContext.Provider>
  )
}
/**
 * Need to use this hook in order to have the possibility to use Redux store inside modals
 */
const useDrawer = (): any => {
  const { showDrawer, hideLastDrawer, hideAllDrawers } = React.useContext(DrawerContext)
  return { showDrawer, hideLastDrawer, hideAllDrawers }
}

export { DrawerContext, DrawerProvider, useDrawer }
