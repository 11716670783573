export const IconCamera = ({ className }: { className?: string }) => (
  <svg className={`fill-current ${className}`} viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.6666 11.6667H31.6666C34.4281 11.6667 36.6666 13.9052 36.6666 16.6667V30C36.6666 32.7614 34.4281 35 31.6666 35H8.33331C5.5719 35 3.33331 32.7614 3.33331 30V16.6667C3.33331 13.9052 5.5719 11.6667 8.33331 11.6667H13.3333V9.16667C13.3333 6.86548 15.1988 5 17.5 5H22.5C24.8012 5 26.6666 6.86548 26.6666 9.16667V11.6667ZM17.5 8.33333C17.0397 8.33333 16.6666 8.70643 16.6666 9.16667V11.6667H23.3333V9.16667C23.3333 8.70643 22.9602 8.33333 22.5 8.33333H17.5ZM33.3333 30C33.3333 30.9205 32.5871 31.6667 31.6666 31.6667H8.33331C7.41284 31.6667 6.66665 30.9205 6.66665 30V16.6667C6.66665 15.7462 7.41284 15 8.33331 15H31.6666C32.5871 15 33.3333 15.7462 33.3333 16.6667V30ZM20 17.5C16.7783 17.5 14.1666 20.1117 14.1666 23.3333C14.1666 26.555 16.7783 29.1667 20 29.1667C23.2216 29.1667 25.8333 26.555 25.8333 23.3333C25.8333 20.1117 23.2216 17.5 20 17.5ZM20 25.8333C18.6193 25.8333 17.5 24.714 17.5 23.3333C17.5 21.9526 18.6193 20.8333 20 20.8333C21.3807 20.8333 22.5 21.9526 22.5 23.3333C22.5 24.714 21.3807 25.8333 20 25.8333Z"
      fill="#6E7785"
    />
  </svg>
)
