import isArray from 'lodash/isArray'
import isPlainObject from 'lodash/isPlainObject'
import { DateConverter } from '../utils'
import { Facility } from './Facility'
import { Pet } from './Pet'
import { User } from './User'

export class Family {
  id: string
  code: string
  enabled: boolean
  userFamily: User | null

  dateCreated: Date | null
  dateUpdated: Date | null

  pets: Pet[]
  facilities: Facility[]
  balance: number
  clientFrom: Date | null

  constructor(data: { [key: string]: any }) {
    this.id = data.id
    this.code = data.code || ''
    this.enabled = data.enabled || false
    this.pets = data.Pets ? data.Pets.map((p: any) => new Pet(p)) : []
    this.userFamily = Family.getFamilyUserFromData(data, this.pets)
    this.facilities = data.Facilities ? data.Facilities.map((f: any) => new Facility(f)) : []
    this.clientFrom = data.clientFrom ? DateConverter.fromServer(data.clientFrom) : null
    this.balance = parseInt(data.balance) || 0

    this.dateCreated = data.dateCreated ? DateConverter.fromServer(data.dateCreated) : null
    this.dateUpdated = data.dateUpdated ? DateConverter.fromServer(data.dateUpdated) : null
  }

  private static getFamilyUserFromData(data: any, pets: Pet[]): User | null {
    let user = null

    if (isPlainObject(data.UserFamily)) {
      user = new User(data.UserFamily)
      user.pets = pets
    }
    if (isArray(data.Users) && data.Users.length) {
      user = new User(data.Users[0])
      user.pets = pets
    }

    return user
  }
}
