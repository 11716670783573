import { User } from 'src/models'
import { CognitoUser } from '@aws-amplify/auth'
import { createSlice } from '@reduxjs/toolkit'
import { authReducers as reducers } from './reducers'

export type AuthState = {
  user: User | null
  accessToken: string | null
  cognitoid: string | null
  cognitoUser: CognitoUser | null
  initialized: boolean
  isFetching: boolean
}

const initialState: AuthState = {
  user: null,
  accessToken: null,
  cognitoid: null,
  cognitoUser: null,
  initialized: false,
  isFetching: false,
}

export const slice = createSlice({
  name: 'auth',
  initialState,
  reducers,
})

export const { actions: authReducers } = slice

export const authSliceReducer = slice.reducer
