import React, { FC, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { EventsAPI } from 'src/api/EventsAPI'
import { submitForm } from 'src/components/forms/Form'
import { useCurrentFamily } from 'src/hooks/useCurrentValues'
import { useToast } from 'src/hooks/useToast'
import { DiaryEvent } from 'src/models/events/DiaryEvent'
import { IonButton, IonButtons, IonContent, IonPage } from '@ionic/react'
import { NavHeader } from '../components/Header/NavHeader'
import { FormNewDiaryEvent } from '../forms/FormNewDiaryEvent'

type PageEditDiaryEventsProps = {
  onDismiss: () => void
  event: DiaryEvent
}
export const PageEditDiaryEvents: FC<PageEditDiaryEventsProps> = ({ event, onDismiss }) => {
  const formRef = useRef<HTMLFormElement>(null)
  const { family, pets } = useCurrentFamily()
  const { t } = useTranslation()
  const toast = useToast()
  const [loading, setLoading] = useState(false)
  const checkValidation = () => submitForm(formRef.current)

  const onSubmit = async (evt: DiaryEvent) => {
    setLoading(true)
    event.category = evt.category
    event.file = evt.file

    event.payload.date = evt.payload.date
    event.payload.message = evt.payload.message
    event.payload.pets = evt.payload.pets

    try {
      await EventsAPI.updateEvent(event)
      toast.success(t('EVENT_UPDATED_SUCCESSFULLY'))
      onDismiss()
    } catch ({ message }) {
      toast.error(t(message as string))
    } finally {
      setLoading(false)
    }
  }

  return (
    <IonPage>
      <NavHeader title={t('TITLE_EDIT_EVENT')} showBackButton={true} backAction={onDismiss}>
        <IonButtons slot="end">
          <IonButton onClick={checkValidation}>
            <span className="text-primary-500 font-bold px-2">{t('SAVE')}</span>
          </IonButton>
        </IonButtons>
      </NavHeader>
      <IonContent>
        <FormNewDiaryEvent
          onSubmit={onSubmit}
          ref={formRef}
          className="py-6"
          loading={loading}
          familyId={family.id}
          pets={pets}
          defaultValue={event}
          showAction={false}
        />
      </IonContent>
    </IonPage>
  )
}
