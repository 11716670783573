import { get } from 'lodash'
import range from 'lodash/range'
import React, { forwardRef, useState, useRef, useEffect } from 'react'
import DatePicker from 'react-datepicker'
import { DATE_MONTHS, DATE_MONTHS_US, DateConverter } from 'src/utils'
import { IonLabel } from '@ionic/react'
import { GenericEditableProps } from '../../helpers/props'
import { IconArrow, IconArrowType } from '../icons'
import { IconCircleLeft } from '../icons/IconCircleLeft'
import { IconCircleRight } from '../icons/IconCircleRight'
import { FormError } from './FormError'

const getYear = (d: Date) => d.getFullYear()
const getMonth = (d: Date) => d.getMonth()

type DatePickerFieldProps = GenericEditableProps & {
  value?: string
  inline?: boolean
  label?: string
  description?: string
  error?: string
  min?: Date
  max?: Date
}

export const DatePickerField: React.FC<DatePickerFieldProps> = forwardRef<HTMLInputElement, DatePickerFieldProps>(
  ({ value, min, max, label = null, inline = false, description, error, onChange }, ref) => {
    const [startDate, setStartDate] = useState<any>(value ? DateConverter.from(value) : DateConverter.now())
    const [selectedMonth, setSelectedMonth] = useState('')

    const [selectedCode, setSelectedCode] = useState(DATE_MONTHS_US[getMonth(startDate)])

    console.log(selectedCode)

    const handleChange = (event: any) => {
      setSelectedCode(event.target.value)
    }

    const monthSelectRef = useRef<HTMLSelectElement>(null)
    const yearSelectRef = useRef<HTMLSelectElement>(null)

    const dropdownWidth = selectedCode.length * 10.8 + 'px'

    const months = DATE_MONTHS_US
    const years = range(min ? min.getFullYear() : 1990, max ? max.getFullYear() + 1 : getYear(DateConverter.now()) + 1, 1)

    const onDateChange = (date: Date) => {
      setStartDate(date)
      if (onChange) onChange(date)
    }

    return (
      <div className="flex flex-col">
        {label ? <IonLabel position="stacked">{label}</IonLabel> : null}
        <div className="relative">
          <DatePicker
            ref={ref as any}
            dateFormat="MMMM dd yyyy"
            minDate={min}
            maxDate={max}
            useWeekdaysShort={true}
            wrapperClassName="input-element cursor-pointer"
            renderCustomHeader={({
              date,
              changeYear,
              changeMonth,
              decreaseMonth,
              increaseMonth,
              prevMonthButtonDisabled,
              nextMonthButtonDisabled,
            }) => (
              <div className="bg-white flex justify-between items-center px-2">
                <button type="button" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                  <IconCircleLeft className="text-primary-500 w-6 h-6" />
                </button>
                <div className="flex items-centerg gap-2">
                  <select
                    ref={monthSelectRef}
                    className="appearance-none cursor-pointer bg-transparent text-black text-h100 font-bold outline-none focus:outline-none"
                    style={{ width: dropdownWidth }}
                    value={months[getMonth(date)]}
                    onChange={({ target: { value } }) => {
                      changeMonth(months.indexOf(value))
                      handleChange({ target: { value } })
                    }}
                  >
                    {months.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>

                  <select
                    ref={yearSelectRef}
                    className="appearance-none cursor-pointer bg-transparent text-black text-h100 font-bold outline-none focus:outline-none"
                    value={getYear(date)}
                    onChange={({ target: { value } }: any) => {
                      changeYear(value)
                    }}
                  >
                    {years.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </div>
                <button type="button" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                  <IconCircleRight className="text-primary-500 w-6 h-6" />
                </button>
              </div>
            )}
            selected={startDate}
            onChange={onDateChange}
            {...(inline ? { inline: true } : {})}
          />
          <IconArrow type={IconArrowType.BOTTOM} className="absolute top-4 right-3 text-primary-500 w-6 h-6" />
        </div>
        {description || error ? (
          <p className="relative">
            {description && !error && <span className="text-grey-600 text-body-tiny mt-1">{description}</span>}
            {error && <FormError message={error} />}
          </p>
        ) : null}
      </div>
    )
  }
)

DatePickerField.displayName = 'DatePickerField'
